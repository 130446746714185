import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { useSelector } from 'react-redux';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const URLS = {
  fr: 'https://firebasestorage.googleapis.com/v0/b/data-tech-bb3f7.appspot.com/o/translations%2Flocales%2Ffr%2Ftranslation.json?alt=media',
  en: 'https://firebasestorage.googleapis.com/v0/b/data-tech-bb3f7.appspot.com/o/translations%2Flocales%2Fen%2Ftranslation.json?alt=media',
  es: 'https://firebasestorage.googleapis.com/v0/b/data-tech-bb3f7.appspot.com/o/translations%2Flocales%2Fes%2Ftranslation.json?alt=media',
  it: 'https://firebasestorage.googleapis.com/v0/b/data-tech-bb3f7.appspot.com/o/translations%2Flocales%2Fit%2Ftranslation.json?alt=media',
};

const locales = ['en', 'fr', 'es', 'it'];

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      loadPath: lang => URLS[lang],
      crossDomain: true,
    },
    whitelist: locales,
    nsSeparator: false,
    keySeparator: false,

    // do not load a fallback
    fallbackLng: false,
    // fallbackLng: 'en',
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
