import React from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import 'video-react/dist/video-react.css'; // import css
import Grid from '@material-ui/core/Grid';
import CardHighlight from '../Templates/Cards/CardHighlight';
import Loading from '../../Loading/Loading';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import '../../../theme/datasportTheme.css';
import poster1 from 'images/highlights (1).jpg';
import poster2 from 'images/highlights (2).jpg';
import poster3 from 'images/highlights (3).jpg';
import { PreviewHighlight } from '../../../types/PreviewHighlight';
import { useQuery } from 'react-query';
import { Fire } from 'services';
import moment from 'moment';
import { Sport } from './ToggleSport';
import { Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
    },
    teamName: {
      fontWeight: 700,
      color: 'white',
      width: 'max-content',
      margin: 'auto',
      marginTop: '10%',
    },
    smallTeamName: {
      width: 'initial',
    },
    bg: {
      backgroundImage:
        'linear-gradient(to bottom, rgba(11, 24, 71, 0) -16%, #0b1847 104%)',
      pointerEvents: 'none',
    },
    btn: {
      zIndex: 3,
      marginTop: '0 !important',
      marginLeft: '0 !important',
      transform: 'translate(-50%, -50%) !important',
      borderRadius: '50% !important',
      backgroundColor: 'rgba(255, 255, 255, 0.25) !important',

      ':before': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    bigBtn: {
      width: '4em !important',
      height: '4em !important',
    },
    smallBtn: {
      width: '2em !important',
      height: '2em !important',
    },
    card: {
      padding: 0,
      minHeight: '50%',
      [theme.breakpoints.down('sm')]: {
        height: 230,
      },
    },
    littleCard: {
      height: 125,
      [theme.breakpoints.down('sm')]: {
        '&:nth-child(2n)': {
          paddingRight: 0,
          paddingLeft: 10,
        },
        '&:nth-child(2n+1)': {
          paddingLeft: 0,
          paddingRight: 10,
        },
      },
    },
    flag: {
      height: 80,
      width: 80,
      margin: 'auto',
      backgroundSize: 'contain',
    },
    smallFlag: {
      width: 30,
      height: 30,
    },
  }),
);

type CollectionPathType =
  | 'soccer_highlights'
  | 'tennis_highlights'
  | 'nba_highlights'
  | 'nfl_highlights'
  | 'nhl_highlights'
  | 'baseball_highlights';

const getCollectionPath = (sport: Sport): CollectionPathType => {
  switch (sport) {
    case 'FOOTBALL':
    default:
      return 'soccer_highlights';
    case 'TENNIS':
      return 'tennis_highlights';
    case 'NBA':
      return 'nba_highlights';
    case 'NFL':
      return 'nfl_highlights';
    case 'NHL':
      return 'nhl_highlights';
    case 'BASEBALL':
      return 'baseball_highlights';
  }
};

export default function PreviewHighlights({ selectedSport }) {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const collectionPath: CollectionPathType = getCollectionPath(selectedSport);
  const sport = selectedSport;
  const { t } = useTranslation();

  const leagueArray = [
    'German Bundesliga',
    'English Premier League',
    'Spanish La Liga',
    'Italian Serie A',
    'French Ligue 1',
  ];

  const { data: highlights, isLoading } = useQuery(
    `${sport.toLowerCase()}:preview-highlights`,
    async () => {
      const ref = Fire.store()
        .collection(collectionPath)
        .orderBy('timestamp', 'desc')
        .where('league', 'in', leagueArray)
        .limit(10);
      const receivedHighlights = await Fire.list<PreviewHighlight>(ref);
      return receivedHighlights.slice(0, 3);
    },
    { staleTime: 900000 /* 15 mn */ },
  );

  if (!highlights || isLoading)
    return (
      <Grid container item xs={12} className={classes.card}>
        <Loading nobg />
      </Grid>
    );

  if (!highlights.length)
    return (
      <Grid container item xs={12} className={classes.card}>
        <Typography variant="h6" component={'span'}>
          <Box fontWeight={500} color={theme.palette.text.secondary}>
            {t('Pas de Highlight disponible dans ce sport pour le moment')}
          </Box>
        </Typography>
      </Grid>
    );

  return (
    <Grid
      container
      spacing={mobile ? 2 : 1}
      style={{ justifyContent: 'space-between' }}
      className="minHeight400"
      data-tour="outils-videos-step"
    >
      <Grid item xs={12} md={8} className={classes.card}>
        <CardHighlight
          url={highlights[0].url}
          team1Name={highlights[0].homeTeamName}
          team1Logo={highlights[0].homeTeamLogo}
          team2Name={highlights[0].awayTeamName}
          team2Logo={highlights[0].awayTeamLogo}
          finalScore={`${highlights[0].homeScore} - ${highlights[0].awayScore}`}
          timestamp={highlights[0].timestamp}
          poster={highlights[0].thumbnailUrl || poster1}
        />
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={4}
        direction="row"
        spacing={mobile ? 0 : 2}
      >
        <Grid
          item
          xs={6}
          md={12}
          className={clsx(classes.card, mobile && classes.littleCard)}
        >
          <CardHighlight
            url={highlights[1].url}
            team1Name={highlights[1].homeTeamName}
            team1Logo={highlights[1].homeTeamLogo}
            team2Name={highlights[1].awayTeamName}
            team2Logo={highlights[1].awayTeamLogo}
            finalScore={`${highlights[1].homeScore} - ${highlights[1].awayScore}`}
            timestamp={highlights[1].timestamp}
            poster={highlights[1].thumbnailUrl || poster2}
            little
          />
        </Grid>
        <Grid
          item
          xs={6}
          md={12}
          className={clsx(classes.card, classes.littleCard)}
        >
          <CardHighlight
            url={highlights[2].url}
            team1Name={highlights[2].homeTeamName}
            team1Logo={highlights[2].homeTeamLogo}
            team2Name={highlights[2].awayTeamName}
            team2Logo={highlights[2].awayTeamLogo}
            finalScore={`${highlights[2].homeScore} - ${highlights[2].awayScore}`}
            timestamp={highlights[2].timestamp}
            poster={highlights[2].thumbnailUrl || poster3}
            little
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
