const cfg = {
  apiKey: 'AIzaSyAInoGPKtuL-z8Ok1AE-mlS3lQejGjnbbg',
  authDomain: 'data-tech-bb3f7.firebaseapp.com',
  projectId: 'data-tech-bb3f7',
  storageBucket: 'data-tech-bb3f7.appspot.com',
  messagingSenderId: '215410841738',
  appId: '1:215410841738:web:5d650b45fc9f450be17573',
  measurementId: 'G-6KPLYTN6FR',
};

export default cfg;
