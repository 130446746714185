import React from 'react';
import { makeStyles, useTheme, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as PlayBtn } from 'images/btn_play_red.svg';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CardGeneral from './CardGeneral';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Logo from '../Logos/Logo';
import { SummaryWithLogos } from '../../../../types/soccer/summary';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minHeight: 'inherit',
      boxShadow: '0 5px 10px 0 rgba(11, 18, 42, .06)',
    },
    content: {
      height: '100%',
      padding: '1rem .5rem',
      backgroundColor: '#f8f9fa',
      borderRadius: '15px',
    },
    matchLogo: {
      width: 'initial',
      height: '85%',
      borderRadius: '2rem',
    },
  }),
);

type CardLivescoreFootballProps = {
  match: SummaryWithLogos;
};

const CardLivescoreFootball: React.FC<CardLivescoreFootballProps> = ({
  match,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const smallComputer = useMediaQuery(theme.breakpoints.down('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.content}
    >
      <Grid item xs={2} sm={1}>
        <Box
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          {match.sport_event_status.status === 'not_started' ? (
            <Typography variant="body2" component={'span'}>
              <Box color="#848ca5">
                {moment(match.sport_event.start_time).format('HH:mm')}
              </Box>
            </Typography>
          ) : match.sport_event_status.status === 'live' ? (
            <Box
              bgcolor="#e93e5519"
              borderRadius={7}
              p="5px"
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body1" component={'span'}>
                <Box
                  color="#e93e55"
                  fontWeight={600}
                  justifyContent="space-around"
                  alignItems="center"
                  display="flex"
                >
                  <PlayBtn
                    fill="red"
                    height="100%"
                    style={{ marginRight: '.5rem' }}
                  />
                  Live
                </Box>
              </Typography>
            </Box>
          ) : (
            <strong>{t('Fini')}</strong>
          )}
        </Box>
      </Grid>
      {!mobile && <Grid xs={1} />}
      <Grid container item xs={10} sm={8}>
        <Grid item container xs={3} justify="flex-start" alignItems="center">
          <Typography variant="h6" component={'span'}>
            <Box width="100%" textAlign="left" color="primary" fontWeight={700}>
              {match.sport_event.competitors[0].name}
            </Box>
          </Typography>
        </Grid>
        <Grid container item xs={2} justify="center">
          <Logo
            src={match.homeTeamLogo}
            imgHeight="85%"
            width={mobile ? 40 : 50}
            typeImgDefault="club"
            imgDefautl="FOOTBALL"
          />
        </Grid>
        <Grid container item xs={2} justify="center" alignItems="center">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              bgcolor="#e6e9ef"
              borderRadius={25}
              padding={
                match.sport_event_status.status === 'live'
                  ? '2px 9px'
                  : '.4rem .8rem'
              }
              width="fit-content"
              height="fit-content"
            >
              {match.sport_event_status.status === 'live' ? (
                <Typography
                  variant={smallComputer ? 'h6' : 'h5'}
                  component="span"
                >
                  <Box
                    fontWeight="fontWeightBold"
                    color={theme.palette.text.primary}
                  >
                    {`${match.sport_event_status.home_score || 0} : ${
                      match.sport_event_status.away_score || 0
                    }`}
                  </Box>
                </Typography>
              ) : (
                <Typography variant="body2" component="span">
                  <Box
                    fontWeight={600}
                    color={theme.palette.text.secondary}
                    style={{ textTransform: 'uppercase' }}
                  >
                    {mobile ? '-' : t('Prochainement')}
                  </Box>
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid container item xs={2} justify="center">
          <Logo
            src={match.awayTeamLogo}
            imgHeight="85%"
            width={mobile ? 40 : 50}
            typeImgDefault="club"
            imgDefautl="FOOTBALL"
          />
        </Grid>
        <Grid item container xs={3} justify="flex-end" alignItems="center">
          <Typography variant="h6" component={'span'}>
            <Box
              width="100%"
              textAlign="right"
              color="primary"
              fontWeight={700}
            >
              {match.sport_event.competitors[1].name}
            </Box>
          </Typography>
        </Grid>
      </Grid>
      {!mobile && <Grid item xs={2}></Grid>}
    </Grid>
  );
};

export default CardLivescoreFootball;
