import React from 'react';
import './Loading_payment.scss';
import datasport_logo from '../../images/datasport_logo.png';

function Loading_payment() {
  return (
    <div className="payment_loading">
      <div className="payment_loading_loader"></div>
    </div>
  );
}

export default Loading_payment;
