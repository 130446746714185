import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import { Grid } from '@material-ui/core';
import '../../../../theme/_colors.scss';
import Fade from '@material-ui/core/Fade';
import { useTranslation } from 'react-i18next';
import Logo from '../Logos/Logo';
import { PlayerSoccerWithPhoto } from '../../../../types/soccer/soccer_players';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

interface PlayerItemProps {
  player?: PlayerSoccerWithPhoto;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      backgroundColor:
        theme.palette.type === 'dark' ? 'rgba(24,36,79,0.85)' : '#f8f9fa',
      borderRadius: '15px',
      border: 'none',
      boxShadow: 'none',
      minHeight: '74px',
      padding: '5px 5px',

      '&:hover': {
        backgroundColor: theme.palette.type === 'dark' ? '' : 'white',
        boxShadow: '0 5px 10px 0 rgba(10, 22, 62, 0.08)',
      },

      [theme.breakpoints.down('xs')]: {
        minHeight: '55px',
      },
    },
    img: {
      borderRadius: '50px',
      width: '55px',
      [theme.breakpoints.down('sm')]: {
        width: 30,
        height: 30,
      },
      '&>img': {
        '&.MuiAvatar-img': {
          objectFit: 'contain',
        },
      },
    },
    countryImg: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        height: 15,
        width: 24,
      },
    },
    clubImg: {
      display: 'flex',
      height: 'max-content',
      [theme.breakpoints.down('sm')]: {
        height: 24,
        width: 24,
      },
    },
  }),
);

const PlayerItem: React.FC<PlayerItemProps> = ({ player }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  if (!player?.info) return null;
  return (
    <ListItem
      style={{ cursor: 'pointer' }}
      component={Card}
      className={classes.card}
      onClick={() => history.push(`/player/${player.id}`)}
    >
      <Grid container justifyContent="space-between">
        <Grid
          container
          item
          xs={4}
          sm={3}
          style={{ alignItems: 'center', justifyContent: 'center' }}
          className="textAlignCenter"
        >
          <Grid item container xs={4}>
            <Fade in={!!player} timeout={250}>
              <Avatar
                alt={'Data-Sport Player-Logo'}
                src={player?.photoUrl}
                className={classes.img}
              />
            </Fade>
          </Grid>
          <Fade in={!!player} timeout={250}>
            <Grid
              item
              container
              style={{ alignItems: 'center', justifyContent: 'center' }}
              xs={6}
              className="height100 textAlignCenter"
            >
              <ListItemText primary={player?.info.name} className="bold" />
            </Grid>
          </Fade>
        </Grid>
        <Grid
          container
          item
          xs={1}
          sm={3}
          style={{ alignItems: 'center', justifyContent: 'space-around' }}
        >
          {!mobile && (
            <Fade in={!!player} timeout={250}>
              <Grid
                item
                sm={4}
                className="height100 textAlignCenter"
                container
                style={{ alignItems: 'center', justifyContent: 'center' }}
              >
                <ListItemText primary={t(player?.info.position)} />
              </Grid>
            </Fade>
          )}
          <Fade in={!!player} timeout={250}>
            <Grid
              item
              xs={4}
              sm={10}
              className="height100 textAlignCenter"
              container
              style={{ alignItems: 'center', justifyContent: 'center' }}
            >
              <ListItemText primary={`${player?.info.age} ${t('ans')}`} />
            </Grid>
          </Fade>
        </Grid>
        <Grid
          container
          item
          xs={1}
          style={{ alignItems: 'center', justifyContent: 'center' }}
          className="textAlignCenter"
        >
          <Fade in={!!player} timeout={250}>
            <ListItemText
              primary={player?.info.weight ? `${player?.info.weight} kg` : ''}
            />
          </Fade>
        </Grid>
        <Grid
          container
          item
          xs={4}
          style={{ alignItems: 'center', justifyContent: 'center' }}
          className="textAlignCenter"
        >
          <Grid
            item
            xs={5}
            container
            style={{ alignItems: 'center', justifyContent: 'center' }}
            className="height100"
          >
            <Fade in={!!player} timeout={250}>
              <ListItemText
                primary={player?.info.teamName}
                style={{ wordBreak: 'break-word' }}
              />
            </Fade>
          </Grid>
          <Grid
            item
            xs={5}
            container
            style={{ alignItems: 'center', justifyContent: 'center' }}
            className="height100 textAlignCenter"
          >
            <Fade in={!!player?.info.logo} timeout={250}>
              <Logo
                src={player?.info.logo as string}
                width={45}
                imgWidth="100%"
                imgHeight="auto"
                noBg
                club
                typeImgDefault="club"
                imgDefautl="FOOTBALL"
              />
            </Fade>
          </Grid>
          {/* <Grid item xs={4}>
            <ListItemIcon>
              <Button>
                <SportsSoccerSharp fontSize={mobile ? "small" : "large"} />
              </Button>
            </ListItemIcon>
          </Grid> */}
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default PlayerItem;
