import { isTooLong } from './logic';
import { matchTypeValueType } from '../types/pronoLibre';

export const matchTypes = {
  byResult: 'Résultat : ',
  byWin: 'Vainqueur : ',
  byMorePts: 'plus de',
  byLessPts: 'moins de',
  byScorer: 'marque un touchdown',
  byDifference: '',
};

export type matchTypesType =
  | 'byWin'
  | 'byResult'
  | 'byMorePts'
  | 'byLessPts'
  | 'byScorer'
  | 'byDifference';

export const getInfosPronoLibre = (props, t) => {
  const matchType: matchTypesType = props.matchType;
  const matchTypeValue: matchTypeValueType = props.matchTypeValue;

  const pronoPercentage: number = parseInt(
    matchType === 'byWin' || matchType === 'byResult'
      ? props.teamResults?.winner
      : matchTypeValue?.percentage,
  );

  const teamName: string | undefined =
    (matchType === 'byWin' || matchType === 'byResult') && props.winner
      ? isTooLong(props.winner.value.name)
        ? t(props.winner.value.alias)
        : t(props.winner.value.name)
      : matchType === 'byScorer'
      ? matchTypeValue?.name
      : matchType === 'byDifference'
      ? props.match.value.home.sr_id === matchTypeValue?.name
        ? props.match.value.home.name
        : props.match.value.away.name
      : '';

  const pronoName: string =
    matchType === 'byWin' || matchType === 'byResult'
      ? t(matchTypes[matchType]) + t(teamName)
      : t(teamName || '') +
        ' ' +
        (matchType === 'byDifference'
          ? matchTypeValue?.pts && matchTypeValue.pts < 0
            ? t('gagne de')
            : t('ne perd pas ou perd de')
          : t(matchTypes[matchType])) +
        (matchType === 'byMorePts' ||
        matchType === 'byLessPts' ||
        matchType === 'byDifference'
          ? ` ${
              matchType === 'byDifference'
                ? matchTypeValue &&
                  matchTypeValue.pts &&
                  matchTypeValue.pts <= 0
                  ? -matchTypeValue.pts
                  : matchTypeValue?.pts
                : matchTypeValue?.pts
            } ${t('points')}  ${
              matchType === 'byDifference'
                ? matchTypeValue?.pts && matchTypeValue?.pts < 0
                  ? t('ou +')
                  : t("d'écart ou -")
                : ''
            }`
          : '') +
        (matchType === 'byMorePts' || matchType === 'byLessPts'
          ? ` ${t('dans le match')}`
          : '');

  const pronoNameWithPercentage: string =
    pronoName + ' ' + pronoPercentage + '%';

  return {
    pronoName,
    pronoNameWithPercentage,
    pronoPercentage,
  };
};
