import React from 'react';
import './BonusScreen.scss';
import { Footer } from '../Reusable';
import Box from '@material-ui/core/Box';
import NavbarHome from '../Home/NavbarHome/NavbarHome';
import SingleBonus from '../Dashboard/Bonus/SingleBonus';
import useBonus from 'hooks/useBonus';
import { useTranslation } from 'react-i18next';

function BonusScreen() {
  const { t } = useTranslation();
  const { bonus } = useBonus();

  return (
    <div className="bonus-screen">
      <NavbarHome blue />

      <div className="options col-center">
        <h2>
          {bonus.length
            ? t("Récupérez jusqu'à 1200€ de paris gratuits !")
            : t('Les bonus seront bientôt disponibles')}
        </h2>

        <br />
        <br />
        <Box
          position="relative"
          width="100%"
          height="100%"
          maxWidth="1300px"
          margin="auto"
          minHeight="50vh"
        >
          {bonus.map((item, index) => (
            <SingleBonus {...item} key={item.id} number={index} />
          ))}
          {!!bonus.length && (
            <Box
              style={{
                width: 4,
                top: 0,
                height: 'calc(100% - 4rem)',
                position: 'absolute',
                background: '#eaecf3',
                left: '50%',
                transform: 'translate(-50%, 0)',
                zIndex: 0,
              }}
            />
          )}
        </Box>
      </div>
      <div className="footer-bg">
        <Footer />
      </div>
    </div>
  );
}

export default BonusScreen;
