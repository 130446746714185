import React, { useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import '../../../../theme/datasportTheme.css';
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import { CompetitorSummary } from '../../../../types/sportradar/soccer/history';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Loading from '../../../Loading/Loading';

const useStyles = makeStyles(() => ({
  arrow: {
    color: '#7e89b2',
    cursor: 'pointer',
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  pin: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

type RowMatchProps = {
  summary: CompetitorSummary;
  last: boolean;
  teamName?: string;
  goToMatchPage: () => void;
};

const MatchIssuePin: React.FC<{ winnerId: string; teamName?: string }> = ({
  winnerId,
  teamName,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  if (!teamName) return <></>;

  const getData = (
    winnerId: string,
    teamName: string,
  ): { backgroundImage: string; value: string } => {
    if (!winnerId)
      return {
        backgroundImage: 'linear-gradient(45deg, #e09c6a, #eddd99)',
        value: t('N'),
      };
    if (t(winnerId) === teamName)
      return {
        backgroundImage: 'linear-gradient(#7ecdcb, #7ecdcb)',
        value: t('V'),
      };
    return {
      backgroundImage: 'linear-gradient(45deg, #dc5c7d, #ad8dd9)',
      value: t('D'),
    };
  };

  const { value, backgroundImage } = getData(winnerId, teamName);

  return (
    <Box className={classes.pin} style={{ backgroundImage }}>
      {value}
    </Box>
  );
};

const RowMatch: React.FC<RowMatchProps> = ({
  summary,
  last,
  teamName,
  goToMatchPage,
}) => {
  const { date, domId, extId, winnerId, score } = summary;
  return (
    <Grid
      container
      item
      xs={12}
      style={{
        padding: last ? '1rem 0 0' : '1rem 0',
        borderBottom: last ? '' : '1px solid #838ca6',
        // cursor: 'pointer',
      }}
      alignItems="center"
      // onClick={goToMatchPage}
    >
      <Grid item xs={2} justify="center" alignItems="center">
        <Typography variant="body2" component={'span'}>
          <Box color="#838ca6" fontWeight={600} textAlign="center">
            {moment(date).format('DD/MM/YYYY')}
          </Box>
        </Typography>
      </Grid>
      <Grid item container xs={9} justify="center" alignItems="center">
        <Grid item xs={5} justify="center" alignItems="center">
          <Typography variant="body1" component={'span'}>
            <Box
              color={domId === winnerId ? 'white' : '#838ca6'}
              fontWeight={600}
              textAlign="center"
            >
              {domId}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={2} justify="center" alignItems="center">
          <Typography variant="body1" component={'span'}>
            <Box
              color="#838ca6"
              bgcolor="#111d47"
              padding=".25rem .5rem"
              borderRadius="2rem"
              fontWeight={600}
              textAlign="center"
            >
              {score}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={5} justify="center" alignItems="center">
          <Typography variant="body1" component={'span'}>
            <Box
              color={extId === winnerId ? 'white' : '#838ca6'}
              fontWeight={600}
              textAlign="center"
            >
              {extId}
            </Box>
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={1} justify="center" alignItems="center">
        <Typography variant="h6" component={'span'} />
        <Box color="#838ca6" fontWeight={600} textAlign="center">
          <MatchIssuePin teamName={teamName} winnerId={winnerId} />
        </Box>
      </Grid>
    </Grid>
  );
};

const arrangeHistories = (
  teamHistories: CompetitorSummary[],
): CompetitorSummary[][] => {
  const parts: CompetitorSummary[][] = [];
  if (teamHistories.length)
    teamHistories.forEach((number, i) => {
      if (i % 6 === 0) parts.push([]);
      parts[parts.length - 1].push(number);
    });
  return parts;
};

type Props = {
  teamHistories: CompetitorSummary[];
  title: string;
  teamName?: string;
  isLoading: boolean;
};

const MatchHistory: React.FC<Props> = ({
  teamHistories,
  title,
  teamName,
  isLoading,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [page, setPage] = useState(0);

  const histories = useMemo(
    () => arrangeHistories(teamHistories),
    [teamHistories],
  );

  return (
    <Grid container>
      <Grid item container xs={12}>
        <Grid item container justify="space-between" alignItems="center">
          <Typography variant="h5" component={'span'}>
            <Box color="white" fontWeight={600}>
              {t(title)}
            </Box>
          </Typography>

          <Box>
            <NavigateBeforeRoundedIcon
              onClick={() => setPage(page + 1)}
              className={clsx({
                [classes.arrow]: true,
                [classes.disabled]: !histories[page + 1],
              })}
            />
            <NavigateNextRoundedIcon
              onClick={() => setPage(page - 1)}
              className={clsx({
                [classes.arrow]: true,
                [classes.disabled]: page === 0,
              })}
            />
          </Box>
        </Grid>
      </Grid>
      {isLoading ? (
        <Loading nobg small />
      ) : (
        <Grid item container xs={12} style={{ marginTop: '1rem' }}>
          {histories[page]?.map((match, i) => (
            <RowMatch
              key={`matchHistory-${match.id}`}
              summary={match}
              last={histories[page].length - 1 === i}
              teamName={teamName}
              goToMatchPage={() => history.push(`/match/${match.id}`)}
            />
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default MatchHistory;
