import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Theme,
  useTheme,
  createStyles,
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment';
import { Box, Typography } from '@material-ui/core';
import Logo from '../../Templates/Logos/Logo';
import { PlayerInfo } from '../../../../types/soccer/player-team-stats';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bgNumber: {
      position: 'absolute',
      right: '-60%',
      fontSize: 300,
      top: 0,
      color: 'rgba(255, 255, 255, 0.03)',
      '-webkit-text-stroke': '10.2px rgba(132, 140, 165, 0.45)',
      objectFit: 'contain',
      opacity: 0.39,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: 175,
      },
    },
  }),
);

type PlayerInfosProps = {
  playerInfos: PlayerInfo;
};

const PlayerInfos: React.FC<PlayerInfosProps> = ({ playerInfos }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      position="relative"
    >
      <Typography variant="h1" component={'span'}>
        <Box color="white" fontSize={mobile ? 45 : 70} maxWidth="500px">
          {playerInfos.name}
        </Box>
      </Typography>
      <Typography variant="h1" component={'span'} className={classes.bgNumber}>
        <Box>{playerInfos.number}</Box>
      </Typography>
      <Box display="flex" alignItems="center" marginTop="1rem">
        <Logo
          width={mobile ? 35 : 50}
          src={playerInfos.logo}
          club
          typeImgDefault="club"
          imgDefautl="FOOTBALL"
        />
        <Typography variant="h4" component="span">
          <Box color="white" fontWeight={600} marginLeft="1rem">
            {playerInfos.teamName}
          </Box>
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        marginTop={mobile ? '2rem' : '1.5rem'}
      >
        <Box
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          marginRight="1rem"
        >
          <Typography variant={mobile ? 'h5' : 'h6'} component={'span'}>
            <Box color={theme.palette.text.secondary} fontWeight={600}>
              {t('Pays')}
            </Box>
          </Typography>
        </Box>
        <Box display="flex" alignItems="flex-start" flexDirection="column">
          <Typography variant={mobile ? 'h5' : 'h6'} component={'span'}>
            <Box color="white" fontWeight={600}>
              {playerInfos.country}
            </Box>
          </Typography>
        </Box>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        marginTop={mobile ? '2rem' : '1.5rem'}
      >
        <Box
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          marginRight="1rem"
        >
          <Typography variant={mobile ? 'h5' : 'h6'} component={'span'}>
            <Box color={theme.palette.text.secondary} fontWeight={600}>
              {t('Poste')}
            </Box>
          </Typography>
        </Box>
        <Box display="flex" alignItems="flex-start" flexDirection="column">
          <Typography variant={mobile ? 'h5' : 'h6'} component={'span'}>
            <Box color="white" fontWeight={600}>
              {t(playerInfos.position)}
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PlayerInfos;
