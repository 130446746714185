import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Box, Typography } from '@material-ui/core';
import PlayerInfos from './PlayerInfos';
import { PlayerInfo } from '../../../../types/soccer/player-team-stats';

const InfoBox = props => {
  const { title, unit, value } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();

  return (
    <Box
      padding="1.25rem 0"
      display="flex"
      marginTop="1rem"
      width={mobile ? 80 : 95}
      height={mobile ? 70 : 80}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      bgcolor="#242f56"
      borderRadius="15px"
    >
      <Typography variant="body2" component={'span'}>
        <Box
          color={theme.palette.text.secondary}
          style={{ textTransform: 'uppercase' }}
          fontWeight={600}
        >
          {t(title)}
        </Box>
      </Typography>
      <Typography variant="body2" component={'span'}>
        <Box display="flex" flexWrap="nowrap" alignItems="baseline">
          <Box color="white" fontWeight={700} fontSize="25px">
            {value}
          </Box>
          <Box
            color="white"
            style={{ textTransform: 'lowercase' }}
            fontWeight={500}
            marginLeft=".25rem"
          >
            {t(unit)}
          </Box>
        </Box>
      </Typography>
    </Box>
  );
};

type TopSectionProps = {
  playerInfo: PlayerInfo;
};

const TopSection: React.FC<TopSectionProps> = ({ playerInfo }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Grid
      item
      container
      xs={11}
      style={{ margin: 'auto', position: 'relative', overflow: 'hidden' }}
      justify="space-between"
      alignItems="flex-start"
      data-tour="player-presentation-step"
    >
      <PlayerInfos playerInfos={playerInfo} />
      <Box
        display="flex"
        flexDirection={mobile ? 'row' : 'column'}
        justifyContent={mobile ? 'space-between' : 'flex-start'}
        alignItems="flex-end"
        position="relative"
        marginTop={mobile ? '1rem' : 0}
        width={mobile ? '100%' : 'auto'}
      >
        <InfoBox title={'Age'} unit={'ans'} value={playerInfo.age} />
        <InfoBox title={'Poids'} unit={'kg'} value={playerInfo.weight} />
        <InfoBox title={'Taille'} unit={'cm'} value={playerInfo.height} />
      </Box>
    </Grid>
  );
};

export default TopSection;
