import { createTheme } from '@material-ui/core/styles';
import { lightTheme } from './_datasportTheme';

export const darkTheme = createTheme(lightTheme, {
  palette: {
    text: {
      primary: '#fff',
      secondary: '#848ca5',
    },
    background: {
      default: '#0a1437',
      paper: '#fcfcfa',
    },
    type: 'dark',
  },
});
