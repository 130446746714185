import React from 'react';
import {
  makeStyles,
  Theme,
  useTheme,
  createStyles,
} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardGeneral from './CardGeneral';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ButtonFilled from '../Buttons/ButtonFilled';
import LinearProgress from '@material-ui/core/LinearProgress';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import '../../../../theme/datasportTheme.css';
import { useTranslation, initReactI18next } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: '100%',
      height: '100%',
      border: '1px solid #444f78',
    },
    content: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
    linearProgress: {
      width: '100%',
      height: 5,
      borderRadius: 5,
      background: '#475377',
      //Linear Gradient, peut être avec une box shadow à l'intérieur ?
      strokeLinecap: 'round',
      '&>div': {
        borderRadius: 5,
        background:
          'linear-gradient(to right, #68ead1 0%, #67c3e6 46%, #68bdf1 95%)',
      },
    },
    textProgress: {
      '&>h6': {
        fontWeight: 600,
      },
    },
  }),
);

export default function CardPronoPourcentage(props) {
  // const { fiability, date, league, team1, team2, team1Score, team2Score, team1Flag, team2Flag } = props;
  const {
    title,
    subtitle,
    goals4,
    goals5,
    goals6,
    goals7,
    goals8,
    goals9,
    goals10,
    units = 'buts',
  } = props;
  const [progress1, setProgress1] = React.useState(0);
  const [progress2, setProgress2] = React.useState(0);
  const [progress3, setProgress3] = React.useState(0);
  const [progress4, setProgress4] = React.useState(0);
  const [progress5, setProgress5] = React.useState(0);
  const [progress6, setProgress6] = React.useState(0);
  const [progress7, setProgress7] = React.useState(0);

  // Animations for the progressbars

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress1(prevProgress =>
        prevProgress < goals4 ? prevProgress + 3 : prevProgress,
      );
    }, 10);
    return () => {
      clearInterval(timer);
    };
  }, [progress1]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress2(prevProgress =>
        prevProgress < goals5 ? prevProgress + 3 : prevProgress,
      );
    }, 10);
    return () => {
      clearInterval(timer);
    };
  }, [progress2]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress3(prevProgress =>
        prevProgress < goals6 ? prevProgress + 3 : prevProgress,
      );
    }, 10);
    return () => {
      clearInterval(timer);
    };
  }, [progress3]);

  React.useEffect(() => {
    if (!goals7) return;
    const timer = setInterval(() => {
      setProgress4(prevProgress =>
        prevProgress < goals7 ? prevProgress + 3 : prevProgress,
      );
    }, 10);
    return () => {
      clearInterval(timer);
    };
  }, [progress4]);

  React.useEffect(() => {
    if (!goals8) return;
    const timer = setInterval(() => {
      setProgress5(prevProgress =>
        prevProgress < goals8 ? prevProgress + 3 : prevProgress,
      );
    }, 10);
    return () => {
      clearInterval(timer);
    };
  }, [progress5]);

  React.useEffect(() => {
    if (!goals9) return;
    const timer = setInterval(() => {
      setProgress6(prevProgress =>
        prevProgress < goals9 ? prevProgress + 3 : prevProgress,
      );
    }, 10);
    return () => {
      clearInterval(timer);
    };
  }, [progress6]);

  React.useEffect(() => {
    if (!goals10) return;
    const timer = setInterval(() => {
      setProgress7(prevProgress =>
        prevProgress < goals10 ? prevProgress + 3 : prevProgress,
      );
    }, 10);
    return () => {
      clearInterval(timer);
    };
  }, [progress7]);

  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();

  return (
    <CardGeneral className={classes.card} blue>
      <CardContent className={classes.content}>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="space-between"
            justifyContent="flex-start"
            marginBottom="2rem"
          >
            <Typography variant="h4" component={'span'}>
              <Box color="white" fontWeight="600" marginBottom=".5rem">
                {t(title)}
              </Box>
            </Typography>
            {subtitle && (
              <Typography variant="body1" component={'span'}>
                <Box color="#848ca5">{t(subtitle)}</Box>
              </Typography>
            )}
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="space-around"
            alignItems="center"
          >
            {goals4 && (
              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                marginTop={mobile ? '1rem' : 0}
                marginBottom={'1rem'}
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  color="white"
                  marginBottom=".5rem"
                  className={classes.textProgress}
                >
                  <Typography variant="h6">
                    {t('Plus de') + ' ' + '4.5' + ' ' + t(units)}
                  </Typography>
                  <Typography variant="h6">{goals4}%</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <LinearProgress
                    variant="determinate"
                    value={progress1}
                    className={classes.linearProgress}
                  />
                </Box>
              </Box>
            )}
            {goals5 && (
              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                marginTop={mobile ? '1rem' : 0}
                marginBottom={'1rem'}
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  color="white"
                  marginBottom=".5rem"
                  className={classes.textProgress}
                >
                  <Typography variant="h6">
                    {t('Plus de') + ' ' + '5.5' + ' ' + t(units)}
                  </Typography>
                  <Typography variant="h6">{goals5}%</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <LinearProgress
                    variant="determinate"
                    value={progress2}
                    className={classes.linearProgress}
                  />
                </Box>
              </Box>
            )}
            {goals6 && (
              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                marginTop={mobile ? '1rem' : 0}
                marginBottom={'1rem'}
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  color="white"
                  marginBottom=".5rem"
                  className={classes.textProgress}
                >
                  <Typography variant="h6">
                    {t('Plus de') + ' ' + '6.5' + ' ' + t(units)}
                  </Typography>
                  <Typography variant="h6">{goals6}%</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <LinearProgress
                    variant="determinate"
                    value={progress3}
                    className={classes.linearProgress}
                  />
                </Box>
              </Box>
            )}
            {goals7 && (
              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                marginTop={mobile ? '1rem' : 0}
                marginBottom={'1rem'}
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  color="white"
                  marginBottom=".5rem"
                  className={classes.textProgress}
                >
                  <Typography variant="h6">
                    {t('Plus de') + ' ' + '7.5' + ' ' + t(units)}
                  </Typography>
                  <Typography variant="h6">{goals7}%</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <LinearProgress
                    variant="determinate"
                    value={progress4}
                    className={classes.linearProgress}
                  />
                </Box>
              </Box>
            )}
            {goals8 && (
              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                marginTop={mobile ? '1rem' : 0}
                marginBottom={'1rem'}
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  color="white"
                  marginBottom=".5rem"
                  className={classes.textProgress}
                >
                  <Typography variant="h6">
                    {t('Plus de') + ' ' + '8.5' + ' ' + t(units)}
                  </Typography>
                  <Typography variant="h6">{goals8}%</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <LinearProgress
                    variant="determinate"
                    value={progress4}
                    className={classes.linearProgress}
                  />
                </Box>
              </Box>
            )}
            {goals9 && (
              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                marginTop={mobile ? '1rem' : 0}
                marginBottom={'1rem'}
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  color="white"
                  marginBottom=".5rem"
                  className={classes.textProgress}
                >
                  <Typography variant="h6">
                    {t('Plus de') + ' ' + '9.5' + ' ' + t(units)}
                  </Typography>
                  <Typography variant="h6">{goals9}%</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <LinearProgress
                    variant="determinate"
                    value={progress4}
                    className={classes.linearProgress}
                  />
                </Box>
              </Box>
            )}
            {goals10 && (
              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                marginTop={mobile ? '1rem' : 0}
                marginBottom={'1rem'}
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  color="white"
                  marginBottom=".5rem"
                  className={classes.textProgress}
                >
                  <Typography variant="h6">
                    {t('Plus de') + ' ' + '10.5' + ' ' + t(units)}
                  </Typography>
                  <Typography variant="h6">{goals10}%</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <LinearProgress
                    variant="determinate"
                    value={progress4}
                    className={classes.linearProgress}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </CardContent>
    </CardGeneral>
  );
}
