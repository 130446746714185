import { matchTypeValueType } from '../types/pronoLibre';
import {
  getCardPlayers,
  getDoublesPlayersNames,
} from '../components/Dashboard/SinglePronoTennis/utils';
import { DoubleCompetitorsEntity } from '../types/pronoTennis';

export const matchTypes = {
  byWin: 'vainqueur',
  byMoreThan1Set: 'gagne au moins 1 set',
  bySets: 'gagne en',
  byMoreGames: 'plus de',
  byLessGames: 'moins de',
  byMoreGamesPlayer: 'marque plus de',
  byTieBreak: 'Tie-break dans le match ? :',
};

export type matchTypesType =
  | 'byWin'
  | 'bySets'
  | 'byMoreGames'
  | 'byLessGames'
  | 'byMoreThan1Set'
  | 'byMoreGamesPlayer'
  | 'byTieBreak';

export const getInfosPronoLibre = (props, t) => {
  const matchType: matchTypesType = props.matchType;
  const matchTypeValue: matchTypeValueType = props.matchTypeValue;

  const matchTypeTennis = props.competition.value.type;
  const [team1, team2] =
    matchTypeTennis === 'singles'
      ? [
          getCardPlayers(
            props.match.value.sport_event.competitors,
            matchTypeTennis,
          ),
        ]
      : getDoublesPlayersNames(
          props.match.value.sport_event
            .competitors as DoubleCompetitorsEntity[],
        );

  const winner =
    props.winner?.value?.qualifier === 'home'
      ? matchTypeTennis === 'singles'
        ? team1[0].fullName
        : team1[0].fullName + ' / ' + team1[1].fullName
      : matchTypeTennis === 'singles'
      ? team1[1].fullName
      : team2[0].fullName + ' / ' + team2[1].fullName;

  const pronoPercentage: number = parseInt(
    matchType === 'byWin'
      ? props.teamResults?.winner
      : matchTypeValue?.percentage,
  );

  const teamName: string | undefined =
    (matchType === 'byWin' || matchType === 'bySets') && props.winner
      ? t(winner)
      : matchType === 'byTieBreak'
      ? ''
      : matchTypeValue?.name || '';

  const pronoName: string =
    t(teamName || '') +
    ' ' +
    t(matchTypes[matchType]) +
    (matchType === 'bySets' ||
    matchType === 'byMoreGames' ||
    matchType === 'byMoreGamesPlayer' ||
    matchType === 'byLessGames'
      ? ` ${matchTypeValue?.pts} ${t(
          matchType === 'byMoreGames' ||
            matchType === 'byLessGames' ||
            matchType === 'byMoreGamesPlayer'
            ? ' ' + t('jeux')
            : ' ' + t('sets'),
        )}`
      : matchType === 'byTieBreak'
      ? ` ${matchTypeValue?.name}`
      : '');

  const pronoNameWithPercentage: string =
    pronoName + ' ' + pronoPercentage + '%';
  return {
    pronoName,
    pronoNameWithPercentage,
    pronoPercentage,
  };
};
