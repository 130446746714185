import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import calendar from 'images/calendar.svg';
import stade from 'images/stade.svg';
import location from 'images/location.svg';
import whistle from 'images/whistle.svg';
import trophy from 'images/trophy.svg';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

type TeamScoresProps = {
  mobile: boolean;
  homeScore: number;
  awayScore: number;
  date: string;
  venue: string;
  country: string;
  referee: string;
  competition: any;
};

const TeamScores: React.FC<TeamScoresProps> = ({
  mobile,
  homeScore,
  awayScore,
  date,
  venue,
  country,
  referee,
  competition,
}) => {
  const { i18n } = useTranslation();
  const language = i18n.language.toUpperCase();

  return (
    <Grid item container justify="center" alignItems="center" xs={4}>
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        position="relative"
      >
        <Box
          width="90%"
          maxWidth="250px"
          margin="auto"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h2" component="span">
            <Box fontSize={mobile ? '50px' : '100px'} color="white">
              {homeScore}
            </Box>
          </Typography>
          <Typography variant="h2" component="span">
            <Box
              fontWeight="bold"
              fontSize={mobile ? '50px' : '100px'}
              color="#242f56"
            >
              -
            </Box>
          </Typography>
          <Typography variant="h2" component="span">
            <Box fontSize={mobile ? '50px' : '100px'} color="white">
              {awayScore}
            </Box>
          </Typography>
        </Box>
        <Typography variant="body1" component={'span'}>
          <Box
            color="#848ca5"
            fontWeight={500}
            marginTop=".75rem"
            display="flex"
            alignItems="center"
          >
            <img
              src={trophy}
              alt="Data-Sport trophy"
              width="17.5px"
              style={{
                marginRight: '1rem',
              }}
            />
            {competition.name[language] || competition.name}
          </Box>
        </Typography>
        <Typography variant="body1" component={'span'}>
          <Box
            color="#848ca5"
            fontWeight={500}
            marginTop=".75rem"
            display="flex"
            alignItems="center"
          >
            <img
              src={calendar}
              alt="Data-Sport calendar"
              width="17.5px"
              style={{ marginRight: '1rem' }}
            />
            {moment(date).format('L')}
          </Box>
        </Typography>
        <Typography variant="body1" component={'span'}>
          <Box
            color="#848ca5"
            fontWeight={500}
            marginTop=".75rem"
            display="flex"
            alignItems="center"
          >
            <img
              src={stade}
              alt="Data-Sport stade"
              width="17.5px"
              style={{ marginRight: '1rem' }}
            />
            {venue}
          </Box>
        </Typography>
        <Typography variant="body1" component={'span'}>
          <Box
            color="#848ca5"
            fontWeight={500}
            marginTop=".75rem"
            display="flex"
            alignItems="center"
          >
            <img
              src={whistle}
              alt="Data-Sport whistle"
              width="17.5px"
              style={{ marginRight: '1rem' }}
            />
            {referee}
          </Box>
        </Typography>
        <Typography variant="body1" component={'span'}>
          <Box
            color="#848ca5"
            fontWeight={500}
            marginTop=".75rem"
            display="flex"
            alignItems="center"
          >
            <img
              src={location}
              alt="Data-Sport location"
              width="17.5px"
              style={{ marginRight: '1rem' }}
            />
            {country}
          </Box>
        </Typography>
      </Box>
    </Grid>
  );
};

export default TeamScores;
