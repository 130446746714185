import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PreviewTitle from '../../../Templates/Misc/PreviewTitle';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { TFunction } from 'i18next';
import firebase from 'firebase';
import { Fire } from 'services';
import { PronoNHL } from '../../../../../types/pronoNHL';
import CardPredictionNHL from '../../../Templates/Cards/CardPredictionNHL/CardPredictionNHL';
import RowNHLHiddenPrediction from '../../../Templates/Rows/RowNHLHiddenPrediction';
import Loading from '../../../../Loading/Loading';
import { getValidPronosFiltered } from '../../../../../utils/pronos';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    singleCard: {
      maxWidth: 400,
      minWidth: 280,
      width: '30%',
      marginRight: '2rem',
      [theme.breakpoints.down('sm')]: {
        marginRight: '2rem',
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: 280,
      },
    },
    last: {
      [theme.breakpoints.down('xs')]: {
        margin: 'auto',
      },
    },
    fullWidth: {
      [theme.breakpoints.down('xs')]: {
        marginRight: '0px !important',
      },
    },
    bigCard: {
      background: 'rgba(25,38,83,0.85)',
      width: '100%',
      borderRadius: 25,
      paddingBottom: '.75rem',
      marginBottom: '2rem',
    },
    rowContainer: {
      '& > div': {
        margin: '10px 0',
      },
    },
    singleCardContainer: {
      flexFlow: 'row nowrap',
      overflowX: 'scroll',
      marginBottom: '2rem',
      scrollbarColor: theme.palette.text.secondary + ' transparent',
      scrollbarWidth: 'thin',
      paddingTop: '1rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingBottom: '2rem',
      '&::-webkit-scrollbar': {
        height: '7px',
        paddingBottom: '1rem',
      },

      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.text.secondary,
        transition: 'background-color .3s ease-in-out',
        borderRadius: '20px',
        '&:hover': {
          backgroundColor: theme.palette.text.primary,
        },
      },
    },
  }),
);

type TennisPageProps = {
  mobile: boolean;
  isLoading: boolean;
  pronos: PronoNHL[];
  t: TFunction;
};

const NHLTab: React.FC<TennisPageProps> = ({
  mobile,
  pronos,
  isLoading,
  t,
}) => {
  const classes = useStyles();
  const now = new Date().getTime() / 1000;
  const [futurePronos, setFuturePronos] = useState<any>([]);
  const [validPronos, setValidPronos] = useState<any>([]);
  const [customText, setCustomText] = React.useState('');
  const user = useSelector((state: any) => state.authReducer.user);
  const [isProUser, setIsProUser] = React.useState<boolean>(false);

  const verifyIsProUser = async () => {
    const ref = Fire.store()
      .collection('pros')
      .where('email', '==', user.email);
    const list = await Fire.list(ref);
    setIsProUser(!!list.length);
  };

  React.useEffect(() => {
    verifyIsProUser();
  }, []);

  useEffect(() => {
    setFuturePronos(getHiddenPronos());
    setValidPronos(getValidPronos());
  }, [pronos, isProUser]);

  const isPronoCanceled = (
    winner: any,
    dateHideUntil: firebase.firestore.Timestamp,
  ) => !winner && (!dateHideUntil || dateHideUntil.seconds < now);

  const getValidPronos = () => {
    const validPronosFiltered = getValidPronosFiltered(
      pronos,
      false,
      isProUser,
    );

    return validPronosFiltered;
  };

  const getHiddenPronos = () => {
    const hiddenPronos = pronos.filter(
      prono => !prono.winner || prono.dateHideUntil?.seconds > now,
    );
    hiddenPronos.sort((a, b) => {
      const aCanceled = isPronoCanceled(a.winner, a.dateHideUntil);
      const bCanceled = isPronoCanceled(b.winner, b.dateHideUntil);
      if (aCanceled && bCanceled) return 0;
      if (aCanceled) return 1;
      if (bCanceled) return -1;
      return a.dateHideUntil?.seconds - b.dateHideUntil?.seconds;
    });
    const hiddenPronosFiltered = {
      available: [
        ...hiddenPronos
          .filter(prono => !isPronoCanceled(prono.winner, prono.dateHideUntil))
          .sort((a, b) => a.dateHideUntil.seconds - b.dateHideUntil.seconds),
      ],
      canceled: [
        ...hiddenPronos.filter(prono =>
          isPronoCanceled(prono.winner, prono.dateHideUntil),
        ),
      ],
    };
    return hiddenPronosFiltered;
  };

  const getCustomText = async () => {
    try {
      const ref = Fire.store().collection('custom_text').doc('preview_pronos');
      const text = await Fire.doc(ref);
      if (text.nhl) setCustomText(text.nhl);
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    getCustomText();
  }, []);

  return (
    <>
      {validPronos?.length !== 0 && (
        <Grid item xs={12}>
          <PreviewTitle
            title="Analyses"
            whiteColor
            fontWeight="600"
            marginBottom
          />
        </Grid>
      )}
      <Grid
        item
        container
        xs={12}
        className={mobile ? 'marginAuto zIndex1' : 'zIndex1'}
      >
        {validPronos?.length > 0 && (
          <Grid
            item
            container
            xs={12}
            className={clsx(classes.singleCardContainer, 'noPaddingRight')}
          >
            {!isLoading ? (
              validPronos.map((prono, index) => (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className={clsx(
                    classes.singleCard,
                    index === validPronos.length - 1 &&
                      validPronos.length % 2 === 1 &&
                      validPronos.length !== 1 &&
                      classes.last,
                    validPronos.length === 1 && classes.fullWidth,
                  )}
                  key={index}
                >
                  <CardPredictionNHL blue prono={prono} />
                </Grid>
              ))
            ) : (
              <Box
                height="100%"
                minHeight="150px"
                width="80%"
                margin="auto"
                display="flex"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                color="primary.dark"
              >
                <Typography variant="h4">
                  {t('Chargement des analyses...')}
                </Typography>
              </Box>
            )}
          </Grid>
        )}
        {(futurePronos.canceled?.length > 0 ||
          futurePronos.available?.length > 0) && (
          <Grid item xs={12}>
            {futurePronos.available?.length > 0 && (
              <Grid item xs={12}>
                <PreviewTitle
                  title="Analyses en cours de calcul"
                  whiteColor
                  fontWeight="600"
                  marginBottom
                />
              </Grid>
            )}
            {futurePronos.available?.length > 0 && (
              <Grid item xs={12} className={classes.bigCard}>
                <Box
                  padding={mobile ? '20px' : '26px'}
                  paddingBottom={mobile ? '0px' : 'inherit'}
                  width="100%"
                  height="100%"
                  marginLeft="-.5rem"
                >
                  <Box width="100%">
                    <Grid container className={classes.rowContainer}>
                      {futurePronos.available.map(hiddenProno => (
                        <Grid item xs={12} key={hiddenProno.id}>
                          <RowNHLHiddenPrediction prono={hiddenProno} />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            )}
            {futurePronos.canceled?.length > 0 && (
              <Grid item xs={12} className={classes.bigCard}>
                <Box
                  padding={mobile ? '20px' : '26px'}
                  paddingBottom={mobile ? '0px' : 'inherit'}
                  width="100%"
                  height="100%"
                  marginLeft="-.5rem"
                >
                  <Box
                    color="#848ca5"
                    className="uppercase"
                    marginBottom=".5rem"
                  >
                    {/* date */}
                    <Typography variant="h4" component={'span'}>
                      <Box
                        fontWeight="500"
                        fontFamily="BigNoodleTitling"
                        paddingLeft="1rem"
                        color="red"
                        letterSpacing="1.5px"
                      >
                        {t("MATCHS NON CHOISIS PAR L'ALGORITHME")}
                      </Box>
                    </Typography>
                  </Box>
                  <Box width="100%" data-tour="canceled-pronos-step">
                    <Grid container className={classes.rowContainer}>
                      {futurePronos.canceled.map(hiddenProno => (
                        <Grid item xs={12} key={hiddenProno.id}>
                          <RowNHLHiddenPrediction prono={hiddenProno} />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        )}
        {isLoading ? (
          <Loading nobg />
        ) : (
          validPronos?.length === 0 &&
          futurePronos.canceled?.length === 0 &&
          futurePronos.available?.length === 0 && (
            <Grid
              item
              container
              xs={12}
              className={clsx(classes.singleCardContainer, 'noPaddingRight')}
            >
              <Box
                height="100%"
                minHeight="150px"
                width="80%"
                margin="auto"
                display="flex"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                color="white"
              >
                <Typography variant="h4">
                  {t(customText)}
                  {/* {t('La NHL reprend le 19 octobre')} */}
                </Typography>
              </Box>
            </Grid>
          )
        )}
      </Grid>
    </>
  );
};

export default NHLTab;
