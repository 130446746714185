import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Theme,
  useTheme,
  createStyles,
} from '@material-ui/core/styles';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import { Fire } from 'services';
import PlayerItemNFL from '../../../Templates/Items/PlayerItemNFL';
import TeamItemNFL from '../../../Templates/Items/TeamItemNFL';
import PreviewTitle from '../../../Templates/Misc/PreviewTitle';
import CardGeneral from '../../../Templates/Cards/CardGeneral';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import RefreshIcon from '@material-ui/icons/Refresh';
import PreviewHeaderTeams from './PreviewHeader/PreviewHeaderTeams';
import PreviewHeaderPlayers from './PreviewHeader/PreviewHeaderPlayers';
import SimpleButtonYellow from '../../../Templates/Buttons/SimpleButtonYellow';
import Loading from '../../../../Loading/Loading';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      margin: theme.spacing(4, 0, 2),
    },
    tabs: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      textTransform: 'none',
      fontWeight: 'bold',
      color:
        theme.palette.type === 'dark'
          ? '#fff !important'
          : '#3c4045 !important',
    },
    appBar: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    topPreview: {
      width: '100%',
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
    },
    swipeable: {
      padding: '20px 0',
    },
    card: {
      height: 'unset',
      minHeight: '23.2rem',
      padding: '10px 25px',
      [theme.breakpoints.down('sm')]: {
        padding: 5,
        width: 'calc(100% + 40px)',
        margin: 0,
      },
      [theme.breakpoints.down('xs')]: {
        minHeight: '27.1rem',
      },
    },
    ListItem: {
      margin: '0',
    },
    headerItems: {
      [theme.breakpoints.down('sm')]: {
        padding: '10px 2rem !important',
      },
    },
    refreshButton: {
      marginLeft: 'auto',
    },
    singleCard: {
      backgroundColor: '#f8f9fa',
      marginTop: 10,
      borderRadius: '15px',
      border: 'none',
      boxShadow: 'none',
      minHeight: 'unset',
      minWidth: '100%',

      [theme.breakpoints.down('sm')]: {
        padding: '1rem',
      },
      '&> *': {
        fontWeight: 500,
        textAlign: 'center',
      },
      '&:hover': {
        backgroundColor: 'white',
        boxShadow: '0 5px 10px 0 rgba(10, 22, 62, 0.08)',
      },
    },
  }),
);

export default function PreviewLibraryNFL() {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const [updateView, setUpdateView] = useState(0);
  const [value, setValue] = useState(0);
  const [allTeams, setAllTeams] = useState<any[]>([]);
  const [random3Teams, setRandom3Teams] = useState<any>(null);
  const [randomPlayers, setRandomPlayers] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const [matches, setMatches] = useState<any>([]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  useEffect(() => {
    setUpdateView(updateView => ++updateView);
    // console.log('updating view')
  }, [allTeams]);

  async function requestRandomLeague() {
    // let _league = await SMonks.previewRandomLeague();
    // setMatches(_league.season.data.fixtures.data);
    // setTeams(_league.season.data.teams.data);
  }

  const requestAllTeams = async () => {
    const teams = await Fire.cloud('nfl', { method: 'getTeams' });
    setAllTeams(teams);
  };

  React.useEffect(() => {
    allTeams?.length !== 0 && getRandomTeams();
  }, [allTeams]);

  const getRandomTeams = () => {
    setLoading(true);
    var randNumbers: Array<number> = [];
    while (randNumbers.length < 4) {
      var r = Math.floor(Math.random() * (allTeams.length - 1));
      if (randNumbers.indexOf(r) === -1) randNumbers.push(r);
    }

    var randomTeams = [
      allTeams[randNumbers[0]],
      allTeams[randNumbers[1]],
      allTeams[randNumbers[2]],
    ];
    randomTeams.sort((a, b) => a.conf_rank - b.conf_rank);
    setLoading(false);
    setRandom3Teams(randomTeams);
  };

  // const getRandomPlayers = async () => {
  //   setRandomPlayers(await Fire.cloud('get3RandomsNFLPlayers'));
  // };

  React.useEffect(() => {
    requestAllTeams();
    // getRandomPlayers();
  }, []);

  // React.useEffect(() => {
  //   console.log(random3Teams);
  // }, [random3Teams]);

  function TabPanel(props) {
    const { children, value, index, classes } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {value === index && <React.Fragment>{children}</React.Fragment>}
      </div>
    );
  }

  return (
    <React.Fragment>
      <span style={{ display: 'none' }}>{updateView}</span>
      <div className={classes.topPreview}>
        <PreviewTitle title={'Database'} marginBottom />
        <SimpleButtonYellow
          title={t('de données')}
          addIcon={true}
          path="library"
        />
      </div>
      <CardGeneral className={classes.card}>
        <AppBar position="static" className={classes.appBar}>
          <Tabs
            TabIndicatorProps={{
              style: {
                height: '4px',
                transform: 'translate(0, 75%)',
              },
            }}
            className="noOverflow"
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            aria-label="full width tabs"
          >
            <Tab label={t('Équipes')} className={classes.tabs} />
            {/* <Tab label={t('Joueurs')} className={classes.tabs} /> */}
            {/* <Tab label="Historique" className={classes.tabs} /> */}
            <Button
              className={classes.refreshButton}
              onClick={() => {
                if (value == 0) {
                  getRandomTeams();
                } else {
                  // getRandomPlayers();
                }
              }}
              startIcon={<RefreshIcon />}
              color={theme.palette.type === 'dark' ? 'inherit' : 'primary'}
            >
              <Typography variant="h6">{t('Refresh')}</Typography>
            </Button>
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
          className={classes.swipeable}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <List>
              <Grid
                item
                container
                xs={12}
                md={12}
                spacing={1}
                className={classes.ListItem}
              >
                <Grid item xs={12}>
                  <PreviewHeaderTeams mobile={mobile} t={t} />
                </Grid>
                {loading ? (
                  <Loading nobg />
                ) : (
                  <>
                    {random3Teams &&
                      random3Teams.map((team, index) => (
                        <Grid item xs={12} key={index}>
                          <TeamItemNFL {...team} />
                        </Grid>
                      ))}
                  </>
                )}
              </Grid>
            </List>
          </TabPanel>
          {/* <TabPanel value={value} index={1} dir={theme.direction}>
            <List style={{ height: '100%' }}>
              <Grid
                item
                container
                xs={12}
                spacing={1}
                className={classes.ListItem}
              >
                <Grid item xs={12}>
                  <PreviewHeaderPlayers mobile={mobile} t={t} />
                </Grid>
                {randomPlayers &&
                  randomPlayers.map((player, index) => (
                    <Grid item xs={12} key={index}>
                      <PlayerItemNFL {...player} />
                    </Grid>
                  ))}
              </Grid>
            </List>
          </TabPanel> */}
        </SwipeableViews>
        <Grid item container xs={12} md={12} spacing={1}></Grid>
      </CardGeneral>
    </React.Fragment>
  );
}
