import React from 'react';
import { connectHits } from 'react-instantsearch-dom';
import { Hit, HitsProvided } from 'react-instantsearch-core';
import { SoccerPlayer } from '../../../../../types/soccer/soccer_players';
import CardGeneral from '../../../Templates/Cards/CardGeneral';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, CardContent, Typography } from '@material-ui/core/';
import PlayerHitChip from './PlayerHitChip/PlayerHitChip';
import Logo from '../../../Templates/Logos/Logo';
import ButtonFilled from '../../../Templates/Buttons/ButtonFilled';
import { useTranslation } from 'react-i18next';
import backgroundFootball_Transparent from 'images/backgroundFootball_Transparent.svg';

type PlayerHit = {
  hit: Hit<SoccerPlayer>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      background: `no-repeat url(${backgroundFootball_Transparent}) top right, #17234f`,
      height: '330px',
      width: '100%',
      maxWidth: '23%',
      minWidth: '279px',
      [theme.breakpoints.down('md')]: {
        minWidth: '250px',
      },
      [theme.breakpoints.down('md')]: {
        minWidth: '250px',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '30%',
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: '310px',
        minWidth: '275px',
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },
    playerName: {
      fontSize: 22,
      fontWeight: 'bold',
      color: '#3c4045',
      fontFamily: 'AvenirNext',
    },
  }),
);

const PlayerHit: React.FC<PlayerHit> = ({ hit }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  // const playerStats = hit?.stats;

  // const stat = useMemo(() => {
  //   const won = playerStats.filter(({ won }) => won === true).length;
  //   const lose = playerStats.filter(({ won }) => won === false).length;
  //   const egalite = playerStats.filter(({ won }) => won === null).length;
  //   const goalsScored = playerStats.reduce(
  //     (total, { goalsScored }) => total + goalsScored,
  //     0,
  //   );
  //   const assists = playerStats.reduce(
  //     (total, { assists }) => total + assists,
  //     0,
  //   );
  //   const yellowCards = playerStats.reduce(
  //     (total, { yellowCards }) => total + yellowCards,
  //     0,
  //   );
  //   const redCards = playerStats.reduce(
  //     (total, { redCards }) => total + redCards,
  //     0,
  //   );
  //   return { won, lose, egalite, goalsScored, assists, yellowCards, redCards };
  // }, [playerStats]);
  return (
    <CardGeneral
      className={clsx(classes.card)}
      shadow
      cursorPointer
      onClick={() => {
        hit.objectID !== undefined && history.push(`/player/${hit.objectID}`);
      }}
      data-tour={
        hit.info.name === 'Kylian Mbappe' ? 'database-players-step' : ''
      }
      blue
    >
      <CardContent className={classes.content}>
        <Box display="flex" justifyContent="space-between">
          <Box />
          <Box display="flex" gridGap={5}>
            <PlayerHitChip label={hit.info.teamName} />
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Logo
            src={hit.info.logo}
            width={90}
            imgWidth="auto"
            noBg
            typeImgDefault="player"
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Typography variant="h4">{hit.info.name}</Typography>
          <Typography variant="h6" color="textSecondary" component={'span'}>
            <Box fontWeight={500}>{t(hit.info.position)}</Box>
          </Typography>
        </Box>
        {/* <Box display="flex" justifyContent="space-between">
          <PlayerHitStats
            goals={stat.goalsScored}
            assists={stat.assists}
            wins={stat.won}
          />
        </Box> */}
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          marginTop="5px"
        >
          <ButtonFilled icon small hoverOutlined />
        </Box>
      </CardContent>
    </CardGeneral>
  );
};

const PlayerHits: React.FC<HitsProvided<SoccerPlayer>> = ({ hits }) => {
  return (
    <div
      style={{
        display: 'flex',
        marginTop: 25,
        gap: 25,
        flexWrap: 'wrap',
        justifyContent: 'space-around',
      }}
    >
      {hits.map(hit => (
        <PlayerHit key={hit.objectID} hit={hit} />
      ))}
    </div>
  );
};

export default connectHits(PlayerHits);
