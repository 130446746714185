import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import 'video-react/dist/video-react.css'; // import css
import Typography from '@material-ui/core/Typography';
import { Player, BigPlayButton } from 'video-react';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import '../../../../theme/datasportTheme.css';
import clsx from 'clsx';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
    },
    teamName: {
      color: 'white',
      width: 'fit-content',
      margin: 'auto',
      marginTop: '5%',
      fontWeight: 'bold',
    },
    smallTeamName: {
      width: 'initial',
    },
    disablePointerEvent: {
      pointerEvents: 'none',
    },
    bg: {
      backgroundImage:
        'linear-gradient(to bottom, rgba(11, 24, 71, 0) -16%, #0b1847 104%)',

      '&:hover': {
        backgroundImage:
          'linear-gradient(to bottom, rgba(11, 24, 71, 0) -16%, #000a30 104%)',
      },
    },
    btn: {
      zIndex: 3,
      marginTop: '0 !important',
      marginLeft: '0 !important',
      transform: 'translate(-50%, -50%) !important',
      borderRadius: '50% !important',
      backgroundColor: 'rgba(255, 255, 255, 0.25) !important',
      transition: 'background-color .2s esae-in-out',

      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.5) !important',
      },
    },
    bigBtn: {
      width: '4em !important',
      height: '4em !important',
      [theme.breakpoints.down('sm')]: {
        width: '2em !important',
        height: '2em !important',
      },
    },
    smallBtn: {
      width: '2em !important',
      height: '2em !important',
      [theme.breakpoints.down('sm')]: {
        width: '1em !important',
        height: '1em !important',
      },
    },
    card: {
      padding: '1rem',
      height: '100%',
      backgroundColor: '#fff',
      boxShadow: '0 0 33px 0 rgb(93 93 93 / 11%)',

      '&:hover': {
        boxShadow: '0 0 33px 0 rgb(93 93 93 / 20%)',
      },
    },
    flag: {
      height: 40,
      width: 40,
      margin: 'auto',
      backgroundSize: 'contain',
      [theme.breakpoints.down('sm')]: {
        height: 40,
        width: 40,
      },
    },
    smallFlag: {
      width: 30,
      height: 30,
      [theme.breakpoints.down('sm')]: {
        height: 25,
        width: 25,
      },
    },
    date: {
      color: 'white',
      width: 'max-content',
      margin: 'auto',
      marginTop: '2%',
      fontWeight: 600,
    },
    video: {
      backgroundSize: 'contain',
    },
  }),
);

type BigCardHighlightProps = {
  url: string;
  team1Name: string;
  team2Name: string;
  timestamp: number;
  team1Logo: string;
  team2Logo: string;
  homeScore: number;
  awayScore: number;
  poster: string;
  little?: boolean;
  player: string;
  minute: number;
};

const BigCardHighlight: React.FC<BigCardHighlightProps> = ({
  url,
  team1Name,
  team2Name,
  timestamp,
  team1Logo,
  team2Logo,
  homeScore,
  awayScore,
  poster,
  little,
  player,
  minute,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showImage, setShowImage] = useState(true);

  const finalScore = `${homeScore} - ${awayScore}`;
  const winnerTeam = +homeScore > +awayScore ? team1Name : team2Name;

  return (
    <Card className={classes.card}>
      <Grid
        container
        item
        justify="center"
        alignItems="center"
        style={{ height: '100%' }}
      >
        <Grid item xs={12} sm={7} style={{ height: '100%' }}>
          <Box
            position="relative"
            width="100%"
            height="100%"
            p={0}
            borderRadius="1rem"
            overflow="hidden"
            onClick={() => setShowImage(false)}
          >
            {showImage && (
              <Box
                position="absolute"
                width="100%"
                height="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={clsx(classes.bg, classes.disablePointerEvent)}
                zIndex="2"
                p="10%"
              />
            )}
            <Box height="100%">
              <Player src={url} poster={poster} fluid={false} height="100%">
                <BigPlayButton
                  position="center"
                  className={clsx(
                    little ? classes.smallBtn : classes.bigBtn,
                    classes.btn,
                  )}
                />
              </Player>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={5} style={{ height: '100%' }}>
          <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            padding=".5rem .5rem 0 1.5rem"
          >
            <Box height="40%" width="100%">
              <Box
                width="100%"
                height="40%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body1" component={'span'}>
                  <Box color="#848ca5" fontWeight={600}>
                    {moment.unix(timestamp).calendar()}
                  </Box>
                </Typography>
                <Box
                  bgcolor="#e5e8ee"
                  borderRadius="1rem"
                  padding=".25rem .75rem"
                >
                  <Typography variant="body1" component={'span'}>
                    <Box color="#848ca5" fontWeight={600}>
                      {minute}'
                    </Box>
                  </Typography>
                </Box>
              </Box>
              <Box
                width="100%"
                height="60%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  width="max-content"
                  height="fit-content"
                  padding=".25rem 1rem"
                  borderRadius=".5rem"
                  border="solid 2px #67c3e6"
                >
                  <Typography variant="h4" component={'span'}>
                    <Box color="#3c4045" fontWeight={700}>
                      {t('Victoire')} {winnerTeam}
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              height="40%"
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                width="100%"
                height="50%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  height="100%"
                  width="30%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box
                    bgcolor="white"
                    borderRadius="50%"
                    padding="5%"
                    boxShadow="0 6px 25px -4px rgba(118, 148, 255, 0.42)"
                  >
                    <CardMedia
                      image={team1Logo}
                      title="Drapeau équipe 1"
                      className={clsx(
                        little && classes.smallFlag,
                        classes.flag,
                      )}
                    />
                  </Box>
                </Box>
                <Box
                  height="100%"
                  width="30%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="h5" component={'span'}>
                    <Box
                      width="100%"
                      bgcolor="#e5e8ee"
                      color="#3c4045"
                      borderRadius="1rem"
                      maxWidth="90px"
                      margin="auto"
                      padding=".1rem 1rem"
                      fontWeight={700}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {finalScore}
                    </Box>
                  </Typography>
                </Box>
                <Box
                  height="100%"
                  width="30%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box
                    bgcolor="white"
                    borderRadius="50%"
                    padding="5%"
                    boxShadow="0 6px 25px -4px rgba(118, 148, 255, 0.42)"
                  >
                    <CardMedia
                      image={team2Logo}
                      title="Drapeau équipe 1"
                      className={clsx(
                        little && classes.smallFlag,
                        classes.flag,
                      )}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                width="100%"
                height="50%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                paddingTop=".5rem"
              >
                <Box
                  height="100%"
                  width="30%"
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="center"
                  textAlign="center"
                >
                  <Typography variant="h6" component={'span'}>
                    <Box color="#3c4045" fontWeight="bold">
                      {team1Name}
                    </Box>
                  </Typography>
                </Box>
                <Box
                  height="100%"
                  width="30%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                />
                <Box
                  height="100%"
                  width="30%"
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="center"
                  textAlign="center"
                >
                  <Typography variant="h6" component={'span'}>
                    <Box color="#3c4045" fontWeight="bold">
                      {team2Name}
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box height="20%" width="100%" display="flex" alignItems="flex-end">
              <Box
                height="80%"
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="space-between"
                flexDirection="column"
                bgcolor="#f8f9fa"
                borderRadius="10px"
                padding=".5rem 1rem"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body1" component="span">
                    <Box color="#848ca5" fontWeight={600}>
                      {t('Buteur')}
                    </Box>
                  </Typography>
                  <Typography variant="body1" component="span">
                    <Box color="#3c4045" fontWeight={700}>
                      {player}
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default BigCardHighlight;
