import React from 'react';
import { Autocomplete } from './autocomplete.type';
import './Input.scss';

type Props = {
  title?: string;
  placeholder?: string;
  value: any;
  defaultValue?: any;
  type?: string;
  small?: boolean;
  disabled?: boolean;
  trimSpaces?: boolean;
  dark?: boolean;
  icon?: any;
  onChange?: (val: any) => void;
  onKeyPress?: (event: any) => void;
  onKeyUp?: (event: any) => void;
  autocomplete?: Autocomplete;
  id?: string;
  noMargin?: boolean;
  creditCard?: boolean;
};
function Input(props: Props) {
  const trimSpaces = value => {
    return value.split(' ').join('');
  };

  return (
    <div
      className={['input-wrapper'].join(' ')}
      onKeyPress={props.onKeyPress}
      style={{ width: '100%', margin: props.noMargin ? '0' : '' }}
    >
      {props.title && <div className="input-title">{props.title}</div>}
      <input
        style={{ width: '100%' }}
        className={[
          'input',
          props.small ? 'small' : '',
          props.disabled ? 'disabled' : '',
          props.dark ? 'dark-input' : '',
          props.creditCard ? 'credit-card' : '',
        ].join(' ')}
        disabled={props.disabled}
        type={props.type || 'text'}
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={e => props.onChange && props.onChange(e.target.value)}
        placeholder={props.placeholder || props.title}
        id={props.id}
        // onBlur={(e) =>
        //   props.trimSpaces ?
        //   onBlur={(e: any) => setValue(name, e.target.value.trim())}
        //     : null
        // }
        autoComplete={props.autocomplete}
      />
      {props.icon}
    </div>
  );
}

export default Input;
