import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import PlayoffsList from './PlayoffsList/PlayoffsList';
import { Fire } from 'services';
import { Playoffs } from '../../../../../../types/sportradar/NBA/playoffs';
import { useQuery } from 'react-query';
import CardGeneral from '../../../../Templates/Cards/CardGeneral';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bigCard: {
      height: 'unset',
      padding: '.9rem 1.9rem',
      [theme.breakpoints.down('sm')]: {
        padding: 5,
        width: 'calc(100% + 40px)',
        transform: 'translate(-20px)',
        margin: 0,
      },
      '&> *': {
        textAlign: 'center',
        alignItems: 'center',
      },
    },
  }),
);

const CalendarNBAPlayoffs: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data: playoffs } = useQuery(
    'nba:calendar:playoffs',
    () => Fire.cloud<Playoffs>('basket', { method: 'getPlayoffSchedules' }),
    { staleTime: 900000 /* 15 mn */ },
  );

  return (
    <Grid container item xs={12} justify="space-around">
      <Box width="100%" zIndex="1">
        <CardGeneral className={classes.bigCard}>
          <div style={{ marginBottom: '1.5rem', marginTop: '0' }}>
            <Grid container item>
              <Typography variant="h6" component={'span'}>
                <Box
                  fontFamily="AvenirNext"
                  color="#848ca5"
                  style={{ textTransform: 'uppercase' }}
                  margin=".5rem 0"
                />
              </Typography>
            </Grid>
            <div>
              {playoffs?.['Eastern Conference']?.['First Round']?.length ? (
                <PlayoffsList playoffs={playoffs as Playoffs} />
              ) : (
                <Typography variant="h6" component={'span'}>
                  <Box
                    fontFamily="AvenirNext"
                    color="#848ca5"
                    style={{ textTransform: 'uppercase' }}
                    margin=".5rem 0"
                  >
                    {t('Pas de matchs pour le moment')}
                  </Box>
                </Typography>
              )}
            </div>
          </div>
        </CardGeneral>
      </Box>
    </Grid>
  );
};

export default CalendarNBAPlayoffs;
