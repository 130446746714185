import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

export default function Score(props) {
  const { score, sidePadding, medium, big } = props;
  const theme = useTheme();
  const isDark = theme.palette.type === 'dark';

  return (
    <Typography
      variant={big ? 'h4' : medium ? 'h6' : 'body1'}
      component={'span'}
    >
      <Box
        color="#3c4045"
        bgcolor={isDark ? 'transparent' : '#e9edf6'}
        padding={sidePadding ? '.25rem 1rem' : '.25rem .5rem'}
        width="max-content"
        borderRadius="2rem"
        fontWeight={700}
        textAlign="center"
      >
        {score}
      </Box>
    </Typography>
  );
}
