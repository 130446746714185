import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CardGeneral from '../../../Templates/Cards/CardGeneral';
import MatchHistory from '../MatchHistory';
import { CompetitorSummary } from '../../../../../types/sportradar/soccer/history';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@material-ui/core';
import SinglePronoHistoryDesktop from './SinglePronoHistoryDesktop';
import SinglePronoHistoryMobile from './SinglePronoHistoryMobile';

const useStyles = makeStyles(() => ({
  card: {
    padding: '1rem',
  },
  item: {
    borderRadius: '15px',
    border: '1px solid #444f78',
    padding: '1.25rem',
  },
}));

type Props = {
  teamHistories1: CompetitorSummary[];
  teamHistories2: CompetitorSummary[];
  teamHistories3: CompetitorSummary[];
  teamName1: string;
  teamName2: string;
  isLoading: boolean;
};

const SinglePronoHistory: React.FC<Props> = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const mobile: boolean = useMediaQuery(theme.breakpoints.down('xs'));

  return mobile ? (
    <SinglePronoHistoryMobile {...props} />
  ) : (
    <SinglePronoHistoryDesktop {...props} />
  );
};

export default SinglePronoHistory;
