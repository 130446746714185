import React, { useCallback, useState, useEffect } from 'react';
import clsx from 'clsx';
import Navbar from '../Templates/Navbar/Navbar';
import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
  Grid,
  Box,
} from '@material-ui/core';
import { darkTheme } from '../../../theme/_datasportDarkTheme';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Fire } from 'services';
import { useDispatch, useSelector } from 'react-redux';
import Fade from '@material-ui/core/Fade';
import '../../../theme/datasportTheme.css';
import { PagePronos } from './PagePronos/PagePronos';
import SingleProno from './SingleProno';
import Loading from '../../Loading/Loading';
import ToggleSport, { Sport } from '../Home/ToggleSport';
import Particles from '../../Reusable/Particles/Particles';
import { getPronosDate } from '../utils/prono';
import Tour from 'reactour';
import moment from 'moment';
import '../../../theme/datasportTheme.css';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { updateUser } from 'actions/auth.action';
import ButtonFilled from '../Templates/Buttons/ButtonFilled';
import { useTranslation } from 'react-i18next';
import { getISOWeek, getYear } from 'date-fns';
import LeftNavbar from '../Templates/Navbar/LeftNavbar';
import { disableTour, activateNextTour } from '../../../utils/reactour.utils';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageBackgroundBlue: {
      zIndex: -1,
      background: '#0a1437',
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      top: 0,
      left: 0,
    },
    pageBackgroundBlueGradient: {
      backgroundImage:
        'linear-gradient(108deg, #6d7fff9e -1%, rgba(84, 114, 255, 0.05) 46%, rgba(84, 114, 255, 0.05) 46%)',
      zIndex: 5,
      opacity: 0.36,
      content: '""',
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      top: 0,
      left: 0,
      pointerEvents: 'none',
    },
    container: {
      maxWidth: 1300,
      margin: 'auto',
      width: '100%',
      zIndex: 10,
      padding: '2rem',
    },
    singleCard: {
      maxWidth: 400,
      minWidth: 350,
      width: '30%',
      [theme.breakpoints.down('md')]: {
        '&:nth-child(2n)': {
          marginRight: 'auto',
        },
        '&:nth-child(2n+1)': {
          marginLeft: 'auto',
        },
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: '2rem !important ',
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: 280,
      },
    },
    last: {
      [theme.breakpoints.down('xs')]: {
        margin: 'auto',
      },
    },
    bigCard: {
      background: 'rgba(25,38,83,0.85)',
      minHeight: '23rem',
      width: '100%',
      borderRadius: 25,
    },
    rowContainer: {
      '& > div': {
        margin: '10px 0',
      },
    },
    singleCardContainer: {
      flexFlow: 'row nowrap',
      overflowX: 'scroll',
      marginBottom: '1rem',
      scrollbarColor: theme.palette.text.secondary + ' transparent',
      scrollbarWidth: 'auto',
      '&::-webkit-scrollbar': {
        height: '7px',
        paddingBottom: '1rem',
      },

      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.text.secondary,
        transition: 'background-color .3s ease-in-out',
        borderRadius: '20px',
        '&:hover': {
          backgroundColor: theme.palette.text.primary,
        },
      },
    },
    particles: {
      position: 'fixed',
      width: '100%',
      height: '100%',
    },
    '&.zoom': {
      transform: 'scale(1.1)',
    },
    toggleSportContainer: {
      [theme.breakpoints.down('xs')]: {
        overflow: 'scroll',
      },
      padding: '1rem 2rem',
      background: 'rgba(25, 38, 83, .85)',
      position: 'sticky',
      top: 0,
      zIndex: 4,
      backdropFilter: 'blur(10px)',
    },
    appBarShift: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: `calc(100% - ${theme.spacing(7) + 1}px)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${theme.spacing(9) + 1}px)`,
      },
    },
    appBarShiftOpen: {
      width: `calc(100% - ${drawerWidth}px)`,
      '& > div': {
        width: '100%',
        margin: 0,
      },
    },
    appBarShiftMobile: {
      width: '100%',
    },
  }),
);

export const getCollectionPath = (selectedSport: Sport): string => {
  switch (selectedSport) {
    case 'FOOTBALL':
      return 'pronos';
    case 'TENNIS':
      return 'pronosTennis';
    case 'NBA':
      return 'pronosNBA';
    case 'NFL':
      return 'pronosNFL';
    case 'NHL':
      return 'pronosNHL';
    case 'BASEBALL':
      return 'pronosBASEBALL';
    default:
      return 'pronos';
  }
};

type Props = {
  match: any;
};

export default function Pronos(props: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [prono, setProno] = React.useState(null);
  const [nextPronos, setNextPronos] = React.useState<any>();
  const user = useSelector((state: any) => state.authReducer.user);
  const history = useHistory();
  const selectedSport: Sport = useSelector(
    (state: any) => state.sportsReducer.selectedSport,
  );
  const { t } = useTranslation();

  if (!user.vip) history.push('/dashboard');

  const [pronos, setPronos] = useState<any>(null);
  const spacing = mobile ? 3 : 6;
  const id = props.match.params.id || null;
  const [loader, setLoading] = useState(true);
  const [isPronosLoading, setIsPronosLoading] = useState(true);
  const [isTourPronosOpen, setIsTourPronosOpen] = useState(false);
  const [isTourPronoOpen, setIsTourPronoOpen] = useState(false);
  const dispatch = useDispatch();
  const [updateString, setUpdateString] = useState('a');
  const [examplePronos, setExamplePronos] = useState<any[]>([]);
  const [info, setInfo] = React.useState<any>({ ...user });
  const [open, setOpen] = React.useState(true);
  const reduxPronos = useSelector((state: any) => state.pronosReducer);

  const clearPronos = () => {
    setNextPronos([]);
  };

  // useEffect(() => {
  //   const testUpdate = async () => {
  //     // await Fire.cloud('verifyAndUpdatePronos', { sport: 'soccer' });

  //     ['soccer', 'NBA', 'tennis', 'NHL', 'NFL', 'baseball'].map(
  //       async (s, index) => {
  //         setTimeout(async () => {
  //           console.log(
  //             await Fire.cloud('verifyAndUpdatePronos', { sport: s }),
  //           );
  //         }, index * 1000);
  //       },
  //     );

  //     // const ref = Fire.store().collection('bilanPronos');
  //     // const bilans = await Fire.list(ref);
  //     // bilans.forEach(async bilan => {
  //     //   Fire.store().collection('bilanPronos').doc(bilan.id).delete();
  //     // });
  //   };
  //   testUpdate();
  // }, []);

  // useEffect(() => {
  //   const importPronoFromDatawin = async () => {
  //     await Fire.cloud('testUpdateSoccerTeams');
  //   };
  //   importPronoFromDatawin();
  // }, []);

  const isProUser = async (email: string) => {
    const ref = Fire.store().collection('pros').where('email', '==', email);
    const list = await Fire.list(ref);
    return list.length !== 0;
  };

  const getExamplePronos = async () => {
    const ref = Fire.store()
      .collection('pronos')
      .where('id', '==', 'xQtY3U7by6wwgJhSs83B');
    const list = await Fire.list(ref);
    const allExamplePronos = [0, 1, 2, 3, 4].map(i => {
      let p = { ...list[0] };
      if (i === 3 || i === 4) {
        delete p.team1;
        const date = new Date();
        date.setDate(date.getDate() - 2);
        if (p.date?.seconds) p.date.seconds = date.getTime() / 1000;
        else {
          p.date = { seconds: date.getTime() / 1000 };
        }
        p.hide_until = p.date;
      }
      return p;
    });
    setExamplePronos(allExamplePronos);
  };

  const getAllPronos = useCallback(async () => {
    setIsPronosLoading(true);
    setPronos(reduxPronos);
    setIsPronosLoading(false);
  }, [reduxPronos]);

  const getSingleProno = async _id => {
    const pronoRef = await Fire.store().collection('pronos').doc(_id);
    const prono: any = await Fire.doc(pronoRef);

    const nextPronoRef = await Fire.store()
      .collection('pronos')
      .orderBy('date', 'asc')
      .where('date', '>=', getPronosDate());
    let next = await Fire.list<any>(nextPronoRef);
    next = next
      .filter(prono => prono.id !== id)
      .filter(prono => prono.isPronoTest !== true)
      .sort((a, b) =>
        a.hide_until && !b.hide_until
          ? 1
          : !a.hide_until && b.hide_until
          ? -1
          : a.hide_until?.seconds - b.hide_until?.seconds,
      );
    next.splice(5, next.length);
    setNextPronos(next);
    setProno(prono);
  };

  useEffect(() => {
    setLoading(true);
    if (id) {
      getSingleProno(id);
    } else getAllPronos();
    setLoading(false);
  }, [getAllPronos, id, selectedSport, reduxPronos]);

  const testFirstConnexionPronos = async () => {
    setIsTourPronosOpen(true);
    info['isFirstConnexion']['pronos'] = false;
    const payload = {
      ...info,
    };
    setInfo({ ...info });
    await dispatch(updateUser(payload));
  };

  const testFirstConnexionProno = async () => {
    setIsTourPronoOpen(true);
    info['isFirstConnexion']['prono'] = false;
    const payload = {
      ...info,
    };
    setInfo({ ...info });
    return dispatch(updateUser(payload));
  };

  React.useEffect(() => {
    if (user.isFirstConnexion?.pronos === true && !prono) {
      testFirstConnexionPronos();
    } else if (user.isFirstConnexion?.prono === true && prono) {
      setIsTourPronosOpen(false);
      testFirstConnexionProno();
    }
  }, [testFirstConnexionPronos, testFirstConnexionProno, prono]);

  React.useEffect(() => {
    getExamplePronos();
  }, []);

  const disableBody = target => disableBodyScroll(target);
  const enableBody = target => enableBodyScroll(target);

  const stepsPagePronos = [
    {
      selector: '[data-tour="valid-pronos-step"]',
      content: () => (
        <div>
          {t(
            'Vous trouverez sur cette page les analyses validées par notre algorithme dans chacun des 4 sports que nous proposons (football, tennis, basketball et football américain).',
          )}
          <br />
          <br />
          {t(
            'Dans l’exemple ci-dessous, 3 paris de football ont été validés par l’algorithme.',
          )}
        </div>
      ),
      position: 'top',
      scrollDuration: 5,
      stepInteraction: false,
    },
    {
      selector: '[data-tour="canceled-pronos-step"]',
      content: t(
        "En revanche, ces deux matchs n'ont pas été validés par l'algorithme.",
      ),
      position: 'top',
      scrollDuration: 5,
      stepInteraction: false,
    },
    {
      selector: '[data-tour="click-prono-step"]',
      content: t(
        'Cliquez sur « voir les statistiques » afin d’accéder aux nombreuses statistiques calculées par l’algorithme.',
      ),
      position: 'right',
      scrollDuration: 5,
      stepInteraction: true,
    },
  ];

  const stepsPageProno = [
    {
      selector: '[data-tour="presentation-prono-step"]',
      content: t(
        'Le pronostic conseillé est ici « Les deux équipes marquent : oui ».',
      ),
      stepInteraction: false,
      position: 'center',
    },
    {
      selector: '[data-tour="presentation-algo-step"]',
      content: t(
        "Par ailleurs, l'algorithme a aussi calculé différentes probabilités sur le match afin de vous aider dans vos analyses.",
      ),
      scrollDuration: 5,
      position: 'top',
      updateDelay: 1,
      update: updateString,
      action: node => {
        node?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setTimeout(() => {
          setUpdateString(prev => prev + '&');
        }, 300);
      },
    },
    {
      selector: '[data-tour="presentation-cotes-step"]',
      content: t(
        'Enfin, les cotes live pour la victoire de chaque équipe et pour le match nul sont disponibles ici.',
      ),
      stepInteraction: false,
      position: 'top',
    },
    {
      selector: mobile
        ? '[data-tour="nav-database-step-mobile"]'
        : '[data-tour="nav-database-step"]',
      content: mobile
        ? t(
            'Rendons-nous sur la Database afin de découvrir un outil donnant accès à de nombreuses données sur les joueurs, les équipes et les rencontres.',
          )
        : t(
            'Cliquez sur Database afin de découvrir un outil donnant accès à de nombreuses données sur les joueurs, les équipes et les rencontres.',
          ),
      scrollDuration: 5,
    },
  ];

  return (
    <React.Fragment>
      <Fade in={true} timeout={500}>
        <>
          <Particles />
          <div className={classes.pageBackgroundBlue} />
          <div className={classes.pageBackgroundBlueGradient} />
          {mobile && <Navbar noRadius transparent collapsed />}

          <Box display="flex">
            {!mobile && <LeftNavbar open={open} setOpen={setOpen} />}
            <Box
              className={clsx(classes.appBarShift, {
                [classes.appBarShiftOpen]: open,
                [classes.appBarShiftMobile]: mobile,
              })}
            >
              {!prono && (
                <Grid item xs={12} className={classes.toggleSportContainer}>
                  <ToggleSport
                    selectedSport={selectedSport}
                    onChangeSport={(newSport: Sport) => (
                      clearPronos(),
                      dispatch({
                        type: 'CHANGE_SPORT',
                        payload: { newSport },
                      })
                    )}
                  />
                </Grid>
              )}
              <Grid container className={classes.container}>
                {loader ? (
                  <Loading nobg />
                ) : !prono ? (
                  pronos ? (
                    <>
                      <PagePronos
                        isLoading={isPronosLoading}
                        pronos={
                          isTourPronosOpen
                            ? { FOOTBALL: examplePronos }
                            : pronos
                        }
                        selectedSport={
                          isTourPronosOpen ? 'FOOTBALL' : selectedSport
                        }
                        setProno={p => setProno(p)}
                        clearPronos={clearPronos}
                      />
                      <Tour
                        steps={stepsPagePronos}
                        isOpen={isTourPronosOpen}
                        disableInteraction={true}
                        rounded={5}
                        onAfterOpen={disableBody}
                        onBeforeClose={enableBody}
                        onRequestClose={e =>
                          disableTour(
                            info,
                            setIsTourPronosOpen,
                            dispatch,
                            updateUser,
                            e,
                          )
                        }
                      />
                    </>
                  ) : (
                    <Loading nobg />
                  )
                ) : (
                  <>
                    <Tour
                      steps={stepsPageProno}
                      isOpen={isTourPronoOpen}
                      onRequestClose={e =>
                        disableTour(
                          info,
                          setIsTourPronoOpen,
                          dispatch,
                          updateUser,
                          e,
                        )
                      }
                      rounded={5}
                      onAfterOpen={disableBody}
                      onBeforeClose={enableBody}
                      lastStepNextButton={
                        <ButtonFilled
                          title="Suivant"
                          little
                          onPress={() => {
                            activateNextTour(
                              info,
                              setIsTourPronosOpen,
                              dispatch,
                              updateUser,
                              history,
                              {
                                home1: false,
                                home2: false,
                                pronos: false,
                                prono: false,
                              },
                              '/library',
                            );
                          }}
                        />
                      }
                    />
                    <SingleProno
                      prono={isTourPronoOpen ? examplePronos[0] : prono}
                      nextPronos={nextPronos}
                    />
                  </>
                )}
              </Grid>
            </Box>
          </Box>
        </>
      </Fade>
    </React.Fragment>
  );
}
