import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box, Typography } from '@material-ui/core';
import Logo from '../../Templates/Logos/Logo';
import { Competitor } from '../../../../types/soccer/team';

type TeamInfoProps = {
  competitor: Competitor;
};

const TeamInfos: React.FC<TeamInfoProps> = ({ competitor }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      flexDirection="column"
      alignItems="flex-start"
      position="relative"
      width={mobile ? '100%' : '50%'}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent={mobile ? 'space-between' : 'center'}
        marginTop="1rem"
      >
        <Logo
          width={mobile ? 100 : 150}
          src={competitor.logo}
          club
          typeImgDefault="club"
          imgDefautl="FOOTBALL"
        />
        <Typography variant="h1" component="span">
          <Box
            color="white"
            fontWeight={600}
            marginLeft={mobile ? '1rem' : '2.5rem'}
            fontSize={mobile ? 50 : 70}
            data-testid="box-competitorName"
          >
            {mobile ? competitor.abbreviation : competitor.name}
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};

export default TeamInfos;
