import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { capitalize, Typography } from '@material-ui/core';
import { Time } from 'services';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useTranslation } from 'react-i18next';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonPrevNext: {
      transition: 'all .2s ease-in-out',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
      },
    },
    buttonContainer: {
      padding: 0,
      margin: '5px 10px',
      height: 'fit-content',
      borderRadius: '2rem',
    },
  }),
);

type CalendarNavigation = {
  currentCalendar: number;
  calendarLength: number;
  setCurrentCalendar: (navNumber: number) => void;
  dateNowYYYYMMDD: string;
};

const CalendarNavigation: React.FC<CalendarNavigation> = ({
  currentCalendar,
  calendarLength,
  setCurrentCalendar,
  dateNowYYYYMMDD,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const dateTest = Time.getDateFromString_YYYYMMDD(dateNowYYYYMMDD);

  return (
    <>
      {currentCalendar > 1 ? (
        <Button
          onClick={() => {
            setCurrentCalendar(-1);
          }}
          className={classes.buttonContainer}
        >
          <Box
            bgcolor="#242f56"
            color="white"
            padding=".5rem 1rem"
            borderRadius="2rem"
            alignItems="center"
            display="flex"
            fontWeight="600"
            className={classes.buttonPrevNext}
          >
            <ArrowBackIcon fontSize="small" style={{ marginRight: '.5rem' }} />{' '}
            {!mobile && t('Précédente')}
          </Box>
        </Button>
      ) : (
        <div style={{ opacity: '0' }}>{!mobile && t('Précédente')}</div>
      )}
      <div>
        <Typography variant="h3" style={{ margin: '1.5rem 0' }}>
          {
            // capitalize(
            // language === 'fr'
            //   ? dateTest.fullDateWithYear
            //   : language === 'en'
            //   ? `${t(dateTest.dayString)}, ${t(dateTest.monthString)} ${
            //       dateTest.day
            //     }, ${dateTest.year}`
            //   : language === 'es'
            //   ? `${t(dateTest.dayString)} ${dateTest.day} de ${t(
            //       dateTest.monthString,
            //     )} de ${dateTest.year}`
            //   : language === 'it'
            //   ? `${t(dateTest.dayString)} ${dateTest.day} ${t(
            //       dateTest.monthString,
            //     )} ${dateTest.year}`
            //   : '',
            // )
            moment(dateNowYYYYMMDD, 'YYYY-MM-DD').format('dddd DD MMMM YYYY')
          }
        </Typography>
      </div>
      {currentCalendar < calendarLength - 1 ? (
        <Button
          onClick={() => {
            setCurrentCalendar(1);
          }}
          className={classes.buttonContainer}
        >
          <Box
            bgcolor="#242f56"
            color="white"
            padding=".5rem 1rem"
            borderRadius="2rem"
            alignItems="center"
            display="flex"
            fontWeight="600"
            className={classes.buttonPrevNext}
            fontSize={''}
          >
            {!mobile && t('Suivante')}{' '}
            <ArrowForwardIcon
              fontSize="small"
              style={{ marginLeft: '.5rem' }}
            />
          </Box>
        </Button>
      ) : (
        <div style={{ opacity: '0' }}>{!mobile && t('Suivante')}</div>
      )}
    </>
  );
};

export default CalendarNavigation;
