import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  useTheme,
  createStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Navbar from '../Templates/Navbar/Navbar';
import { Grid, Fade } from '@material-ui/core';
import ScorePanel from './ScorePanel/ScorePanel';
import StatsPanel from './StatsPanel/StatsPanel';
import usePageMatch from './usePageMatch';
import BackButton from '../Templates/BackButton/BackButton';
import { lightTheme } from '../../../theme/_datasportTheme';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      maxWidth: 1300,
      margin: 'auto',
      width: '100%',
    },
  }),
);

const PageMatch: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();
  const { match, isMatchLoading, standings, isStandingsLoading } =
    usePageMatch();

  return (
    <Fade in={!isMatchLoading} timeout={500}>
      <ThemeProvider theme={lightTheme}>
        <Grid container className={classes.container} spacing={mobile ? 3 : 6}>
          <Grid item container xs={12}>
            <Navbar extend />
            <BackButton />
          </Grid>
          {match && (
            <>
              <ScorePanel mobile={mobile} match={match} />
              <StatsPanel
                t={t}
                match={match}
                standings={standings}
                standingsLoading={isStandingsLoading}
              />
            </>
          )}
        </Grid>
      </ThemeProvider>
    </Fade>
  );
};

export default PageMatch;
