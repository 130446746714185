import React from 'react';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/it';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import './HeaderBar.scss';
import { useTranslation } from 'react-i18next';

import logo from 'images/logo_datasport.png';
import flag_fr from 'images/flag_fr.png';
import flag_en from 'images/flag_en.png';
import flag_es from 'images/flag_es.png';
import flag_it from 'images/flag_it.png';

import Button from '../Button/Button';
import ImageButton from '../ImageButton/ImageButton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core';

function HeaderBar() {
  const history = useHistory();
  const [shown, showMenu] = React.useState(false);
  const user = useSelector((state: any) => state.authReducer.user);
  const [headerbar, setheaderbar] = useState(false);
  const { t, i18n } = useTranslation();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    moment.locale(lng);
  };

  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setheaderbar(true);
    } else {
      setheaderbar(false);
    }
  };

  window.addEventListener('scroll', changeBackground);
  return (
    <React.Fragment>
      {mobile && (
        <Box
          width="100%"
          position="sticky"
          style={{
            background: 'linear-gradient(214deg, #1c3460, #0F2A51E8)',
            height: 'fit-content',
          }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="1.5rem"
          top="0"
        >
          <a href="/">
            <img
              src={logo}
              className="main-logo responsive-logo"
              alt="Data-Sport logo"
            />
          </a>
          <div className="hamburger" onClick={() => showMenu(true)}>
            <a onClick={() => showMenu(true)}>
              <i className="fa fa-list"></i>
            </a>
          </div>
        </Box>
      )}
      <header
        className={[
          'main-header-bar font',
          headerbar ? 'headerbar active' : '',
          shown ? 'shown' : '',
        ].join(' ')}
      >
        {mobile && shown && (
          <a className="logo-link" href="/">
            <img src={logo} className="main-logo" alt="Data-Sport logo" />
          </a>
        )}
        <Box className="menu">
          {mobile && shown ? (
            <React.Fragment>
              <div className="links">
                <Box className="link">
                  <Typography variant="h2">
                    <a onClick={() => showMenu(false)} href="/#intro">
                      {t('Accueil')}
                    </a>
                  </Typography>
                </Box>
                <Box className="link" onClick={() => history.push('/login')}>
                  <Typography variant="h2">{t('Analyses')}</Typography>
                </Box>
              </div>

              <Box className="row login-btns">
                {user ? (
                  <Button
                    title={t('Mon compte')}
                    classes={['teal-btn', 'link-btn']}
                    onPress={() => history.push('/account')}
                  />
                ) : (
                  <React.Fragment>
                    <Button
                      title={t("S'inscrire")}
                      classes={['linear-btn', 'link-btn', 'small']}
                      onPress={() => history.push('/paiement-offre-1-euro')}
                      icon
                      small
                    />
                    <Button
                      title={t('Me connecter')}
                      classes={['clear-btn', 'link-btn', 'small']}
                      onPress={() => history.push('/login')}
                      small
                      style={{ marginBottom: '100px' }}
                    />
                    <Box className="flags">
                      <ImageButton
                        height={32}
                        width={32}
                        onPress={() => changeLanguage('fr')}
                        image={flag_fr}
                      />
                      &nbsp;
                      <ImageButton
                        height={32}
                        width={32}
                        onPress={() => changeLanguage('en')}
                        image={flag_en}
                      />
                      &nbsp;
                      <ImageButton
                        height={32}
                        width={32}
                        onPress={() => changeLanguage('es')}
                        image={flag_es}
                      />
                      &nbsp;
                      <ImageButton
                        height={32}
                        width={32}
                        onPress={() => changeLanguage('it')}
                        image={flag_it}
                      />
                    </Box>
                  </React.Fragment>
                )}
              </Box>

              <div className="close" onClick={() => showMenu(false)}>
                <i className="fa fa-close"></i>
              </div>
            </React.Fragment>
          ) : mobile === false ? (
            <React.Fragment>
              <a className="logo-link" href="/">
                <img src={logo} className="main-logo" alt="Data-Sport logo" />
              </a>
              <Box className="link">
                <Typography>
                  <a onClick={() => showMenu(false)} href="/#intro">
                    {t('Accueil')}
                  </a>
                </Typography>
              </Box>
              <Box className="link">
                <Typography>
                  <a
                    onClick={() => {
                      showMenu(false);
                      history.push('/paiement-offre-1-euro');
                    }}
                  >
                    {t('Analyses')}
                  </a>
                </Typography>
              </Box>
              {/* <div className="link">
                <a onClick={() => showMenu(false)} href="/#avantages">
                  {t('Avantages')}
                </a>
              </div> */}
              {/* <div className="link" onClick={() => history.push('/bonus')}>
                {t('Bonus')}
              </div> */}

              <div className="close" onClick={() => showMenu(false)}>
                <i className="fa fa-close"></i>
              </div>
              <div className="row login-btns">
                {user ? (
                  <Button
                    title={t('Mon compte')}
                    classes={['teal-btn', 'link-btn']}
                    onPress={() => history.push('/account')}
                  />
                ) : (
                  <React.Fragment>
                    <Button
                      title={t("S'inscrire")}
                      classes={['linear-btn', 'link-btn', 'small']}
                      onPress={() => history.push('/paiement-offre-1-euro')}
                      icon
                      small
                    />
                    <Button
                      title={t('Me connecter')}
                      classes={['clear-btn', 'link-btn', 'small']}
                      onPress={() => history.push('/login')}
                      small
                    />
                    <div className="flags">
                      <ImageButton
                        height={32}
                        width={32}
                        onPress={() => changeLanguage('fr')}
                        image={flag_fr}
                      />
                      &nbsp;
                      <ImageButton
                        height={32}
                        width={32}
                        onPress={() => changeLanguage('en')}
                        image={flag_en}
                      />
                      &nbsp;
                      <ImageButton
                        height={32}
                        width={32}
                        onPress={() => changeLanguage('es')}
                        image={flag_es}
                      />
                      &nbsp;
                      <ImageButton
                        height={32}
                        width={32}
                        onPress={() => changeLanguage('it')}
                        image={flag_it}
                      />
                    </div>
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          ) : null}
        </Box>
      </header>
    </React.Fragment>
  );
}

export default HeaderBar;
