import React from 'react';
import Card from '@material-ui/core/Card';
import {
  Grid,
  ListItem,
  ListItemText,
  Theme,
  Typography,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Competitor,
  DaySummary,
  DoubleCompetitors,
} from '../../../../../../types/TennisSummaries';
import countryCodes from '../../../../../../utils/countryCodes.json';
import SinglesImages from '../../../Ranking/RankingTennis/SinglesImages/SinglesImages';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    singleCard: {
      backgroundColor: 'rgba(38, 52, 112, 1)',
      margin: '.625rem 0',
      padding: '.75rem 1rem',
      borderRadius: '15px',
      border: 'none',
      boxShadow: 'none',
      minHeight: 'unset',
      minWidth: '100%',
      '&> *': {
        fontWeight: 500,
        textAlign: 'center',
      },
      '&:hover': {
        backgroundColor: '#1d2a64',
      },
    },
    countryImg: {
      display: 'flex',
      margin: 'auto',
      maxWidth: 40,
      [theme.breakpoints.down('sm')]: {
        width: 25,
      },
    },
    doubleCountryImg: {
      display: 'flex',
      maxWidth: 40,
      [theme.breakpoints.down('sm')]: {
        width: 25,
      },
    },
  }),
);

const ListHeader: React.FC<{ name: string }> = ({ name }) => {
  return (
    <Grid
      container
      item
      xs={12}
      direction="row"
      alignContent="center"
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <ListItemText primary={name} className="bold" />
      </Grid>
    </Grid>
  );
};

type CalendarTennisListProps = {
  summaries: DaySummary;
};

const CalendarTennisList: React.FC<CalendarTennisListProps> = ({
  summaries,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getCompetitorLabel = (
    type: 'doubles' | 'singles',
    competitor: Competitor,
  ): string => {
    if (type === 'singles') {
      const [lastName, firstName] = competitor.name.split(', ');
      return `${firstName?.charAt(0) || ''}.${lastName}`;
    }
    return competitor.name;
  };

  const getCompetitorFlag = (
    type: 'doubles' | 'singles',
    competitor: Competitor,
  ): JSX.Element => {
    if (type === 'singles')
      return (
        <img
          src={`https://cdn.sportmonks.com/images/countries/png/short/${
            competitor.country === 'Neutral'
              ? 'ru'
              : competitor.country === 'Taipei chinois'
              ? 'tw'
              : countryCodes[competitor.country_code] || competitor.country_code
          }.png`}
          className={classes.countryImg}
          alt="Data-Sport country flag"
        />
      );
    if (competitor.players) {
      return (
        <>
          <img
            src={`https://cdn.sportmonks.com/images/countries/png/short/${
              competitor.players[0].country === 'Neutral'
                ? 'ru'
                : competitor.players[0].country === 'Taipei chinois'
                ? 'tw'
                : countryCodes[
                    (competitor.players as DoubleCompetitors)[0].country_code
                  ] || competitor.country_code
            }.png`}
            className={classes.doubleCountryImg}
            alt="Data-Sport country flag"
          />
          <img
            src={`https://cdn.sportmonks.com/images/countries/png/short/${
              competitor.players[1].country === 'Neutral'
                ? 'ru'
                : competitor.players[1].country === 'Taipei chinois'
                ? 'tw'
                : countryCodes[
                    (competitor.players as DoubleCompetitors)[1].country_code
                  ] || competitor.country_code
            }.png`}
            className={classes.doubleCountryImg}
            style={{ marginLeft: 25 }}
            alt="Data-Sport country flag"
          />
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      {summaries &&
        Object.entries(summaries).map(([name, events]) => (
          <div style={{ marginTop: 15, marginBottom: 50 }}>
            <ListHeader name={name} />
            {events.map(match => {
              const type: 'doubles' | 'singles' =
                match.sport_event.sport_event_context.competition.type;
              return (
                <ListItem component={Card} className={classes.singleCard}>
                  <Grid
                    container
                    item
                    xs={12}
                    alignContent="center"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid
                      container
                      item
                      alignContent="center"
                      justify={'center'}
                      alignItems="center"
                      xs={2}
                    >
                      <Typography
                        variant={'body1'}
                        style={{
                          fontWeight: 'bold',
                          color:
                            match.sport_event_status.status !== 'cancelled' &&
                            match.sport_event_status?.winner_id ===
                              match.sport_event.competitors[0].id
                              ? '#20a86c'
                              : 'inherit',
                        }}
                      >
                        {getCompetitorLabel(
                          type,
                          match.sport_event.competitors[0],
                        )}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      style={{
                        placeSelf: 'center',
                        display: 'flex',
                        justifyContent: type === 'doubles' ? 'center' : 'unset',
                      }}
                    >
                      {getCompetitorFlag(
                        type,
                        match.sport_event.competitors[0],
                      )}
                    </Grid>
                    <Grid
                      container
                      item
                      alignContent="center"
                      justify="center"
                      alignItems="center"
                      xs={1}
                    >
                      <SinglesImages
                        gender={
                          match.sport_event.sport_event_context.competition
                            .gender
                        }
                        playerId={match.sport_event.competitors[0].id}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      alignContent="center"
                      justify="center"
                      alignItems="center"
                      xs={2}
                    >
                      <Box
                        width="max-content"
                        height="100%"
                        borderRadius="1rem"
                        bgcolor="rgba(255, 255, 255, 10%)"
                        fontWeight="bold"
                        fontFamily="AvenirNext"
                        padding=".3rem .75rem"
                        fontSize="1rem"
                      >
                        {match.sport_event_status?.status === 'closed'
                          ? match.sport_event_status?.match_status === 'retired'
                            ? t('Abandon')
                            : match.sport_event_status?.match_status ===
                              'walkover'
                            ? t('Walk Over')
                            : `${match.sport_event_status?.home_score} - ${match.sport_event_status?.away_score}`
                          : match.sport_event_status?.status === 'cancelled'
                          ? t('Annulé')
                          : match.sport_event.start_time
                              ?.split('T')[1]
                              .split(':', 2)[0] +
                            'h' +
                            match.sport_event.start_time
                              ?.split('T')[1]
                              .split(':', 2)[1]}
                      </Box>
                    </Grid>
                    <Grid
                      container
                      item
                      alignContent="center"
                      justify="center"
                      alignItems="center"
                      xs={1}
                    >
                      <SinglesImages
                        gender={
                          match.sport_event.sport_event_context.competition
                            .gender
                        }
                        playerId={match.sport_event.competitors[1].id}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      style={{
                        placeSelf: 'center',
                        display: 'flex',
                        justifyContent: type === 'doubles' ? 'center' : 'unset',
                      }}
                    >
                      {getCompetitorFlag(
                        type,
                        match.sport_event.competitors[1],
                      )}
                    </Grid>
                    <Grid
                      container
                      item
                      alignContent="center"
                      justify={'center'}
                      alignItems="center"
                      xs={2}
                    >
                      <Typography
                        variant={'body1'}
                        style={{
                          fontWeight: 'bold',
                          color:
                            match.sport_event_status.status !== 'cancelled' &&
                            match.sport_event_status?.winner_id ===
                              match.sport_event.competitors[1].id
                              ? '#20a86c'
                              : 'inherit',
                        }}
                      >
                        {getCompetitorLabel(
                          type,
                          match.sport_event.competitors[1],
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              );
            })}
          </div>
        ))}
    </>
  );
};

export default CalendarTennisList;
