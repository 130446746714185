import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
  Theme,
  Typography,
  FormControl,
  styled,
  InputBase,
  alpha,
  NativeSelect,
  Button,
} from '@material-ui/core';
import './PaymentStripe.scss';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Particles from 'reusable/Particles/Particles';
import { getGeolocation } from '../../../utils/geolocation';
import ButtonFilled from '../../Dashboard/Templates/Buttons/ButtonFilled';
import Navbar from '../../Home/NavbarHome/NavbarHome';
import countryList from '../../../utils/countryList';
import { useHistory, useParams } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { refreshUser, saveInfo, updateUser } from 'actions/auth.action';
import { Fire } from 'services';
import { useDispatch, useSelector } from 'react-redux';
import { getParameterByName } from '../../../utils/logic';
import badge_stripe from '../../../images/badge_stripe.png';
import { loadStripe, StripeElementLocale } from '@stripe/stripe-js';
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import PaiementSecureImg from 'images/paiement_securise.png';
import Loading from '../../Loading/Loading';
import moment from 'moment';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const STEPS = ['Signup', 'Validation', 'Payment', 'More infos'];

const currencyIcon = {
  EUR: '€',
  USD: '$',
  GBP: '£',
};

const key = process.env.REACT_APP_STRIPE_API_KEY;
// process.env.NODE_ENV == 'production'
//   ?
//   : process.env.REACT_APP_STRIPE_API_KEY_TEST;
const stripePromise = loadStripe(key!);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      minHeight: '100vh',
      backgroundColor: theme.palette.background.default,
    },
    outBox: {
      background:
        'linear-gradient(to bottom, #68ead1 100%, #67c3e6 51%, #68bdf1 0%)',
      borderRadius: '15px',
      boxShadow: '0 0 18px 0 rgba(138, 225, 255, 0.25)',
      position: 'relative',
      padding: '3px',
    },
    innerBox: {
      backgroundColor: 'rgb(9, 28, 57)',
      margin: 0,
      borderRadius: '15px',
      border: 'none',
      maxWidth: '90vw',
      padding: '4rem 3rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    stepperCircle: {
      '& > span > :is(.MuiStepIcon-active, .MuiStepIcon-completed)': {
        color: '#5fd8d0',
      },
      '& > span > :is(.MuiStepIcon-active, .MuiStepIcon-completed) .MuiStepIcon-text':
        {
          fill: '#0b1847',
          fontWeight: 700,
        },
    },
    label: {
      color: 'white',
      fontSize: '1.2rem',

      '&.Mui-focused': {
        color: 'white',
      },
    },
    eye: {
      position: 'absolute',
      right: '8%',
      bottom: '39%',
      fontSize: '1.5rem !important',
      color: 'grey',
      cursor: 'pointer',
      transition:
        'color 0.1s ease-in-out, fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',

      '&:hover': {
        color: 'rgb(68, 68, 68)',
      },
    },
    oldPrice: {
      '&::after': {
        content: '" "',
        width: '100%',
        height: '5px',
        backgroundColor: 'red',
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translate(0, -50%) rotate(18deg)',
      },
    },
  }),
);

const CustomInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2),
  },

  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    border: '1px solid white',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    backgroundColor: 'transparent !important',
    color: 'white !important',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    margin: '0 0 1rem 0',
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    },
    '&::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)',
    },
  },

  '& .MuiNativeSelect-icon': {
    color: 'white',
    bottom: '30%',
    top: 'auto',
  },
}));

const CustomAlert = styled(Alert)(({ theme }) => ({
  '& .MuiAlert-message': {
    width: '100%',
  },
}));

const CheckoutForm = (referral: any) => {
  const user_already = useSelector((state: any) => state.authReducer.user);
  const [activeStep, setActiveStep] = React.useState(
    user_already ? (user_already.vip ? 3 : 1) : 0,
  );
  const [langLoaded, setLangLoaded] = React.useState(false);
  const [searchingLang, setSearchingLang] = React.useState(false);
  const [passwordType, setPasswordType] = React.useState('password');
  const [currency, setCurrency] = React.useState<'EUR' | 'GBP' | 'USD' | ''>(
    '',
  );
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [codePromo, setCodePromo] = React.useState('');
  const [isCouponValid, setIsCouponValid] = React.useState(false);
  const [isCouponInvalid, setIsCouponInvalid] = React.useState(false);
  const [coupon, setCoupon] = React.useState<any>({ id: '' });
  const [user, setUser] = React.useState<any>({
    first_name: user_already?.first_name || '',
    last_name: user_already?.last_name || '',
    email: user_already?.email || '',
    password: '',
    location: user_already?.location || {
      countryCode: '',
      state: '',
    },
    phone: user_already?.phone || '',
    birth: user_already?.birth || '',
  });
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { lang }: { lang: string } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const urlParams = document.location.search;

  const verifyCoupon = async () => {
    if (codePromo !== '') {
      const doc = await Fire.doc(
        Fire.store().collection('codesPromo').doc('reduc38euros'),
      );
      const res = doc.promo.indexOf(codePromo) !== -1;

      if (!res) {
        setIsCouponValid(false);
        showError("Votre code promo n'est pas valide");
        setIsCouponInvalid(true);
        return null;
      } else {
        setIsCouponInvalid(false);
        setCoupon({
          id: codePromo,
        });
        setIsCouponValid(true);
      }
    }
    showError('');
  };

  React.useEffect(() => {
    if (activeStep === 0) {
      if (user_already) setActiveStep(1);
    }
    if (activeStep === 1 || activeStep === 2) {
      if (user_already?.vip) setActiveStep(3);
    }
    if (activeStep === 3) {
      if (
        user.phone !== '' &&
        user.phone !== 'Non renseigé' &&
        user?.birth !== ''
      )
        history.push('/');
    }
  }, [activeStep]);

  React.useEffect(() => {
    // Get geolocatiom from Abstract API
    const getLoc = async (noRetry: boolean = false) => {
      // If language is not loaded or we are not already searching for a lang, we search for it
      if (!langLoaded && !searchingLang) {
        setSearchingLang(true);
        const geoLoc = await getGeolocation();
        // If we don't get a geolocation, we retry after 1 second
        if (!geoLoc) {
          setSearchingLang(false);
          //if we already retried, we set the currency to USD
          if (noRetry) {
            setCurrency('USD');
            return;
          }
          setTimeout(() => {
            getLoc(true);
          }, 1000);
          return;
        }
        // If we get a geolocation, we set the currency, the country and the state
        setValue('location', {
          countryCode: geoLoc.country_code,
          state: geoLoc.region,
        });
        const newCurrency =
          geoLoc.currency.currency_code === 'EUR' ||
          geoLoc.currency.currency_code === 'GBP'
            ? geoLoc.currency.currency_code
            : 'USD';
        setCurrency(newCurrency);
        setLangLoaded(true);
        setSearchingLang(false);
      }
    };
    getLoc();
  }, [getGeolocation]);

  React.useEffect(() => {
    // We force the url to be in the right language
    if (['fr', 'it', 'es', 'en'].some(el => el === lang))
      i18n.changeLanguage(lang);
    else history.push(`/secure/${i18n.language}`);
  }, [lang]);

  const setValue = (key: string, val: any) => {
    user[key] = val;
    setUser({ ...user });
  };

  const showError = err => {
    setError(err);
    window.scrollTo(0, 0);
  };

  const register = async () => {
    const { email, first_name, last_name, password, location } = user;
    const utm = {
      utm_source: getParameterByName('utm_source'),
      utm_medium: getParameterByName('utm_medium'),
      utm_campaign: getParameterByName('utm_campaign'),
      utm_term: getParameterByName('utm_term'),
      utm_content: getParameterByName('utm_content'),
    };

    if (first_name === '' || last_name === '') {
      showError(t('Veuillez saisir votre prénom et votre nom de famille'));
      return;
    } else if (email === '') {
      showError(t('Veuillez saisir votre email de connexion'));
      return;
    } else if (password === '') {
      showError(t('Veuillez saisir votre mot de passe'));
      return;
    } else if (!location.countryCode) {
      showError(t('Veuillez renseigner votre pays'));
      return;
    }

    setError('');

    setLoading(true);

    try {
      const payload = {
        first_name: first_name.trim(),
        last_name: last_name.trim(),
        email: email.trim(),
        phone: '',
        vip: false,
        source: utm,
        location,
      };
      await dispatch(saveInfo(payload));
      await Fire.auth()
        .createUserWithEmailAndPassword(email, password)
        .then(async userDoc => {
          // setActiveStep(1);
          if (!userDoc.user) return;
          await Fire.store()
            .collection('users')
            .doc(userDoc.user.uid)
            .set(payload);
        });
    } catch (err) {
      let error: any = err;
      switch (error.code) {
        case 'auth/email-already-exists':
        case 'auth/email-already-in-use':
          showError(t("L'email entrée est déjà utilisée"));
          break;

        case 'auth/invalid-password':
          showError(t('Identifiants invalides'));
          break;

        case 'auth/user-not-found':
          showError(t('Identifiants invalides'));
          break;

        case 'auth/invalid-email':
          showError(t("L'email entré est invalide"));
          break;

        case 'auth/weak-password':
          showError(t('Le mot de passe doit contenir au moins 6 caractères'));
          break;

        default:
          showError(
            "Une erreur est survenue, veuillez rafraîchir la page ou contacter le support à l'adresse mail suivante : contact@data-sport.io",
          );
      }
    }
    setLoading(false);
  };

  const pay = async () => {
    if (!stripe || !elements) return;
    setLoading(true);
    setError('');
    try {
      const cardElement = elements!.getElement(CardNumberElement);
      if (!cardElement) return;
      const { error, paymentMethod } = await stripe!.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      if (error) {
        showError(t('Une erreur est survenue : ' + error.message));
      } else if (paymentMethod && paymentMethod.card) {
        try {
          // We add card to stripe account and to the user, in this cloud function we also set the user cus_id in stripe_customer table in Firestore
          await Fire.cloud('addCard', {
            cardId: paymentMethod.id,
            referral: referral || undefined,
          });
          // PAY
          try {
            const payload = {
              cardId: paymentMethod.id,
              subId: `month${currency}`,
              feeId: `fee${currency}`,
            };
            if (isCouponValid) {
              payload['coupon'] = {
                id: codePromo,
              };
            }
            // We create a subscription in stripe and in the user
            const res = await Fire.cloud('subscribe', payload);

            if (!res.error) {
              const sub = res.subscription;
              await dispatch(
                refreshUser({
                  cardId: paymentMethod.id,
                  choice: sub,
                  vip: true,
                  state: { trial: new Date() },
                }),
              );

              try {
                window.dataLayer.push({
                  event: 'subscription',
                });
                // const confirmTemplates = {
                //   FR: 16,
                //   IT: 18,
                //   ES: 17,
                //   EN: 3,
                // };
                // const idTemplate = 3;

                // Mail.send(user_already || user, idTemplate);
              } catch (error) {
                console.error(error);
              } finally {
                setError('');
                setActiveStep(3);
              }
            } else {
              switch (res.error) {
                case 'invalid_offer':
                  showError(
                    "L'offre sélectionnée n'existe pas ou plus, veuillez raffraichir la page et recommencer",
                  );
                  break;

                default:
                  showError(
                    "Une erreur est survenue, veuillez rafraîchir la page ou contacter le support à l'adresse mail suivante : contact@data-sport.io",
                  );
                  break;
              }
            }
          } catch (err) {
            showError('Paiement impossible. Veuillez vérifier votre connexion');
            return;
          }
        } catch (error) {
          showError(
            "Ajout de cb impossible. Veuillez vérifier les informations de votre carte bancaire ou nous contacter à l'adresse mail suivante : contact@data-sport.io",
          );
          return;
        }
      }
    } catch (err) {
      showError(
        "Ajout de cb impossible. Veuillez vérifier les informations de votre carte bancaire ou nous contacter à l'adresse mail suivante : contact@data-sport.io",
      );
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };

  const addInfos = async () => {
    let { phone, birth } = user;
    if (phone) {
      phone = phone.toString().replace(/ /g, '').split('.').join('');
    }
    if ((phone === '' || !phone) && (birth === '' || !birth)) {
      showError(
        t(
          'Veuillez saisir votre numéro de téléphone et votre date de naissance',
        ),
      );
      return;
    } else if (phone === '' || !phone) {
      showError(t('Veuillez saisir votre numéro de téléphone'));
      return;
    } else if (birth === '' || !birth) {
      showError(t('Veuillez saisir votre date de naissance'));
      return;
    } else if (
      !phone.match(
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/im,
      )
    ) {
      showError(t('Le numéro de téléphone est incorrect'));
      return;
    }
    setError('');

    // Reverse of the birth date to have dd-mm-yyyy instead of yyyy-mm-dd
    birth = birth.split('-').reverse().join('-');

    phone = phone.toString().replace(/ /g, '').split('.').join('');

    setLoading(true);
    try {
      dispatch(
        saveInfo({
          phone: phone,
          birth: birth,
        }),
      );

      await dispatch(
        updateUser({
          phone: phone,
          birth: birth,
        }),
      );

      history.push('/');
    } catch (err) {
      let error: any = err;
      switch (error.code) {
        case 'auth/user-not-found':
          showError(t('Identifiants invalides'));
          break;

        default:
          showError(
            "Une erreur est survenue, veuillez rafraîchir la page ou contacter le support à l'adresse mail suivante : contact@data-sport.io",
          );
          break;
      }
      // handleSubmit();
    }
    setLoading(false);
  };

  const handleChangeLocation = event => {
    user.location.countryCode = event.target.value;
    setUser({ ...user });
  };

  return (
    <Grid container className={classes.root}>
      <Navbar noBurger />
      <Particles />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          height: '100%',
          opacity: currency ? '1' : '0',
          transition: 'opacity .5s ease-in-out',
        }}
      >
        <Box className={classes.outBox}>
          <Box className={classes.innerBox}>
            <Typography
              component="h2"
              variant="h4"
              style={{
                color: 'white',
                marginBottom: '2rem',
                fontWeight: 500,
                maxWidth: '415px',
                textAlign: 'center',
              }}
            >
              {t(
                'Accédez à la puissance de l’intelligence artificielle pour les paris sportifs',
              )}
            </Typography>
            <Box sx={{ width: '70%', margin: '0 auto' }}>
              <Stepper
                activeStep={activeStep}
                style={{
                  background: 'transparent',
                  padding: '1rem 0',
                  margin: '0 0 1rem',
                  justifyContent: 'center',
                }}
              >
                {STEPS.map(label => {
                  return (
                    <Step key={label}>
                      <StepLabel className={classes.stepperCircle} />
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
            <Box style={{ width: '100%' }}>
              {activeStep === 0 ? (
                <form
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '90%',
                    maxWidth: '375px',
                    flexDirection: 'column',
                    margin: '0 auto',
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h4"
                    style={{ color: 'white', marginBottom: '2rem' }}
                  >
                    {t('Inscrivez-vous')} :
                  </Typography>
                  <div style={{ display: 'flex', gap: '1rem' }}>
                    <FormControl fullWidth>
                      <CustomInput
                        placeholder={t('Prénom')}
                        id="firstname-input"
                        value={user.first_name}
                        onChange={e => setValue('first_name', e.target.value)}
                        autoComplete="given-name"
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <CustomInput
                        placeholder={t('Nom')}
                        id="lastname-input"
                        value={user.last_name}
                        onChange={e => setValue('last_name', e.target.value)}
                        autoComplete="family-name"
                      />
                    </FormControl>
                  </div>

                  <FormControl fullWidth>
                    <CustomInput
                      placeholder={t('Email')}
                      id="email-input"
                      value={user.email}
                      onChange={e => setValue('email', e.target.value.trim())}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <CustomInput
                      placeholder={t('Mot de passe')}
                      id="password-input"
                      type={passwordType}
                      value={user.password}
                      onChange={e => setValue('password', e.target.value)}
                    />
                    {passwordType === 'password' ? (
                      <Visibility
                        fontSize="inherit"
                        color="inherit"
                        onClick={() => setPasswordType('text')}
                        className={classes.eye}
                      />
                    ) : (
                      <VisibilityOff
                        fontSize="inherit"
                        color="inherit"
                        onClick={() => setPasswordType('password')}
                        className={classes.eye}
                      />
                    )}
                  </FormControl>
                  <FormControl fullWidth style={{ marginBottom: '1.5rem' }}>
                    <NativeSelect
                      id="country-select"
                      value={user.location.countryCode}
                      onChange={handleChangeLocation}
                      input={<CustomInput />}
                    >
                      <option disabled value="">
                        {t('Sélectionnez votre pays de résidence')}
                      </option>
                      {Object.keys(countryList).map(key => (
                        <option value={key} key={key}>
                          {t(countryList[key])}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                  <ButtonFilled
                    title={loading ? t('Chargement...') : 'Continuer'}
                    large
                    disabled={loading}
                    onPress={register}
                  />
                  <Typography
                    variant="h6"
                    style={{
                      color: '#bbb',
                      marginTop: '1.5rem',
                      cursor: 'pointer',
                    }}
                    onClick={() => history.push('/login' + `${urlParams}`)}
                  >
                    {t('Déjà inscrit ? Connectez-vous')}
                  </Typography>
                  {error && (
                    <Alert
                      severity="error"
                      style={{ marginTop: '2rem', width: '100%' }}
                    >
                      {error}
                    </Alert>
                  )}
                </form>
              ) : activeStep === 1 ? (
                <Box>
                  <Typography
                    component="h2"
                    variant="h4"
                    style={{
                      color: 'white',
                      marginBottom: '2rem',
                      textAlign: 'center',
                    }}
                  >
                    {t('Validez votre offre')}
                  </Typography>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: '0 auto 2rem',
                    }}
                  >
                    {/* {isCouponValid ? (
                      <>
                        <Box position="relative" className={classes.oldPrice}>
                          {i18n.language === 'en' && (
                            <Typography
                              component="h2"
                              variant="h5"
                              style={{
                                color: 'white',
                                fontSize: '2.5rem',
                                textAlign: 'center',
                                display: 'inline-block',
                              }}
                            >
                              {currencyIcon[currency]}
                            </Typography>
                          )}
                          <Typography
                            component="h1"
                            variant="h5"
                            style={{
                              color: 'white',
                              fontSize: '3rem',
                              textAlign: 'center',
                              display: 'inline-block',
                              lineHeight: '1',
                            }}
                          >
                            39
                          </Typography>
                          {i18n.language !== 'en' && (
                            <Typography
                              component="h2"
                              variant="h5"
                              style={{
                                color: 'white',
                                fontSize: '2.5rem',
                                textAlign: 'center',
                                display: 'inline-block',
                              }}
                            >
                              {currencyIcon[currency]}
                            </Typography>
                          )}
                        </Box>
                        <ArrowForwardIcon
                          style={{
                            color: 'white',
                            fontSize: '2rem',
                            margin: '1rem',
                          }}
                        />
                        <Box>
                          {i18n.language === 'en' && (
                            <Typography
                              component="h2"
                              variant="h5"
                              style={{
                                color: 'white',
                                fontSize: '2.5rem',
                                textAlign: 'center',
                                display: 'inline-block',
                              }}
                            >
                              {currencyIcon[currency]}
                            </Typography>
                          )}
                          <Typography
                            component="h1"
                            variant="h5"
                            style={{
                              color: 'white',
                              fontSize: '3rem',
                              textAlign: 'center',
                              display: 'inline-block',
                              lineHeight: '1',
                            }}
                          >
                            1
                          </Typography>
                          {i18n.language !== 'en' && (
                            <Typography
                              component="h2"
                              variant="h5"
                              style={{
                                color: 'white',
                                fontSize: '2.5rem',
                                textAlign: 'center',
                                display: 'inline-block',
                              }}
                            >
                              {currencyIcon[currency]}
                            </Typography>
                          )}
                        </Box>
                      </>
                    ) : (
                      <>
                        {i18n.language === 'en' && (
                          <Typography
                            component="h2"
                            variant="h5"
                            style={{
                              color: 'white',
                              fontSize: '3rem',
                              textAlign: 'center',
                              display: 'inline-block',
                            }}
                          >
                            {currencyIcon[currency]}
                          </Typography>
                        )}
                        <Typography
                          component="h1"
                          variant="h5"
                          style={{
                            color: 'white',
                            fontSize: '4rem',
                            textAlign: 'center',
                            display: 'inline-block',
                            lineHeight: '1',
                          }}
                        >
                          39
                        </Typography>
                        {i18n.language !== 'en' && (
                          <Typography
                            component="h2"
                            variant="h5"
                            style={{
                              color: 'white',
                              fontSize: '3rem',
                              textAlign: 'center',
                              display: 'inline-block',
                            }}
                          >
                            {currencyIcon[currency]}
                          </Typography>
                        )}
                      </>
                    )} */}
                    {isCouponValid ? (
                      <>
                        <Box>
                          {i18n.language === 'en' && (
                            <Typography
                              component="h2"
                              variant="h5"
                              style={{
                                color: 'white',
                                fontSize: '3rem',
                                textAlign: 'center',
                                display: 'inline-block',
                              }}
                            >
                              {currencyIcon[currency]}
                            </Typography>
                          )}
                          <Typography
                            component="h1"
                            variant="h5"
                            style={{
                              color: 'white',
                              fontSize: '4rem',
                              textAlign: 'center',
                              display: 'inline-block',
                              lineHeight: '1',
                            }}
                          >
                            1
                          </Typography>
                          {i18n.language !== 'en' && (
                            <Typography
                              component="h2"
                              variant="h5"
                              style={{
                                color: 'white',
                                fontSize: '3rem',
                                textAlign: 'center',
                                display: 'inline-block',
                              }}
                            >
                              {currencyIcon[currency]}
                            </Typography>
                          )}
                        </Box>
                      </>
                    ) : (
                      <>
                        {i18n.language === 'en' && (
                          <Typography
                            component="h2"
                            variant="h5"
                            style={{
                              color: 'white',
                              fontSize: '3rem',
                              textAlign: 'center',
                              display: 'inline-block',
                            }}
                          >
                            {currencyIcon[currency]}
                          </Typography>
                        )}
                        <Typography
                          component="h1"
                          variant="h5"
                          style={{
                            color: 'white',
                            fontSize: '4rem',
                            textAlign: 'center',
                            display: 'inline-block',
                            lineHeight: '1',
                          }}
                        >
                          39
                        </Typography>
                        {i18n.language !== 'en' && (
                          <Typography
                            component="h2"
                            variant="h5"
                            style={{
                              color: 'white',
                              fontSize: '3rem',
                              textAlign: 'center',
                              display: 'inline-block',
                            }}
                          >
                            {currencyIcon[currency]}
                          </Typography>
                        )}
                      </>
                    )}
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <ButtonFilled
                      title={t('Étape suivante')}
                      disabled={loading}
                      large
                      onPress={() => setActiveStep(2)}
                    />
                  </Box>
                  {isCouponValid && (
                    <Typography
                      style={{
                        color: '#bbb',
                        textAlign: 'center',
                        marginTop: '1rem',
                        fontSize: '.65rem',
                      }}
                    >
                      *{' '}
                      {t(
                        `Valide pendant 7 jours puis 39.99${currencyIcon[currency]} par mois, résiliable à tout moment`,
                      )}
                    </Typography>
                  )}
                  <Box
                    textAlign="center"
                    marginTop="4rem"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <CustomInput
                      placeholder={t('CODE PROMO')}
                      id="promo_code-input"
                      style={{
                        width: '65%',
                      }}
                      inputProps={{
                        style: {
                          textAlign: 'center',
                          border: isCouponValid
                            ? '1px solid #3fe43f'
                            : isCouponInvalid
                            ? '1px solid #ab302a'
                            : '1px solid white',
                          boxShadow: isCouponValid
                            ? '0 0 1px 1px rgba(60, 172, 47, 0.5)'
                            : isCouponInvalid
                            ? '0 0 1px 1px rgba(255, 0, 0, 0.5)'
                            : '0 0 1px 1px rgba(255, 255, 255, 0.5)',
                        },
                      }}
                      onChange={e => setCodePromo(e.target.value)}
                    />
                    <Button
                      style={{
                        height: 'fit-content',
                        padding: '10px 21px',
                        fontFamily: 'AvenirNext',
                        borderRadius: '7px',
                        backgroundColor: ' #152e57',
                      }}
                      onClick={verifyCoupon}
                    >
                      <Typography variant="h5" component={'span'}>
                        {t('Appliquer')}
                      </Typography>
                    </Button>
                  </Box>
                  {error && (
                    <Alert
                      severity="error"
                      style={{ marginTop: '2rem', width: '100%' }}
                    >
                      {error}
                    </Alert>
                  )}
                  {/* {isCouponValid && (
                    <Alert
                      severity="success"
                      style={{ marginTop: '2rem', width: '100%' }}
                    >
                      {t('Votre code promo est valide !')}
                    </Alert>
                  )} */}
                  {isCouponValid && (
                    <CustomAlert
                      severity="success"
                      style={{
                        width: '100%',
                      }}
                    >
                      <Box width="100%">
                        <Grid container justifyContent="space-between">
                          <Grid item md={9}>
                            <Typography
                              style={{
                                fontSize: '.85rem',
                              }}
                            >
                              {t('Code promo valide')}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} style={{ textAlign: 'right' }}>
                            <Typography
                              style={{
                                fontSize: '.85rem',
                              }}
                            >
                              {t(`-38${currencyIcon[currency]}`)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </CustomAlert>
                  )}
                </Box>
              ) : activeStep === 2 ? (
                <Box display="flex" flexDirection="column">
                  <Typography
                    component="h2"
                    variant="h4"
                    style={{
                      color: 'white',
                      textAlign: 'center',
                    }}
                  >
                    {t('Paiement sécurisé')} :
                  </Typography>
                  {isCouponValid ? (
                    <Typography
                      component="span"
                      variant="h6"
                      style={{
                        color: 'white',
                        textAlign: 'center',
                        fontWeight: 500,
                        marginTop: '1rem',
                        marginBottom: '2rem',
                      }}
                    >
                      {t(`Montant`)} : 1.00 {currency.toUpperCase()}
                    </Typography>
                  ) : (
                    <Typography
                      component="span"
                      variant="h6"
                      style={{
                        color: 'white',
                        textAlign: 'center',
                        fontWeight: 500,
                        marginTop: '1rem',
                        marginBottom: '2rem',
                      }}
                    >
                      {t(`Montant`)} : 39 {currency.toUpperCase()}
                    </Typography>
                  )}
                  {loading ? <Loading nobg /> : null}
                  <Box
                    style={{
                      background: 'rgb(18 39 74)',
                      color: 'white',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '355px',
                      maxWidth: '100%',
                      margin: '0 auto 2rem',
                      padding: loading ? '0' : '1rem',
                      maxHeight: loading ? '0' : '100%',
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      style={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          maxWidth: '90%',
                          margin: '1rem auto 2rem',
                        }}
                      >
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Box
                            style={{
                              width: '100%',
                              height: '1px',
                              backgroundColor: '#54af53',
                            }}
                          ></Box>
                          <Typography
                            style={{
                              color: '#54af53',
                              fontSize: '.7rem',
                              width: '100%',
                              minWidth: 'fit-content',
                              margin: '0 2px',
                            }}
                          >
                            {t('Paiement')} <b>{t('sécurisé')}</b>{' '}
                            {t('garanti')}
                          </Typography>
                          <Box
                            style={{
                              width: '100%',
                              height: '1px',
                              backgroundColor: '#54af53',
                            }}
                          ></Box>
                        </Box>

                        <img
                          style={{
                            maxWidth: '100%',
                          }}
                          src={PaiementSecureImg}
                          alt="Data-Sport badge-stripe"
                        />
                      </Box>
                      <CardNumberElement
                        options={{
                          placeholder: '**** **** **** ****',
                          style: {
                            base: {
                              padding: '16px',
                              // borderRadius: '4px',
                              color: 'black',
                              backgroundColor: 'white',
                              fontSize: '16px',
                              '::placeholder': {
                                color: '#aab7c4',
                              },
                            },
                            invalid: {
                              color: '#9e2146',
                            },
                          },
                        }}
                      />
                      <Box
                        display="flex"
                        style={{ gap: '1rem', marginTop: '1rem' }}
                      >
                        <CardExpiryElement
                          options={{
                            style: {
                              base: {
                                padding: '16px',
                                // borderRadius: '4px',
                                color: 'black',
                                backgroundColor: 'white',
                                fontSize: '16px',
                                '::placeholder': {
                                  color: '#aab7c4',
                                },
                              },
                              invalid: {
                                color: '#9e2146',
                              },
                            },
                          }}
                        />
                        <CardCvcElement
                          options={{
                            style: {
                              base: {
                                padding: '16px',
                                // borderRadius: '4px',
                                color: 'black',
                                backgroundColor: 'white',
                                fontSize: '16px',
                                '::placeholder': {
                                  color: '#aab7c4',
                                },
                              },
                              invalid: {
                                color: '#9e2146',
                              },
                            },
                          }}
                        />
                      </Box>
                      {/* <img
                        style={{
                          maxHeight: '30px',
                          maxWidth: '30%',
                          margin: '1.5rem auto .5rem',
                        }}
                        src={badge_stripe}
                        alt="Data-Sport badge-stripe"
                      /> */}
                    </Box>
                  </Box>
                  {error && (
                    <Alert
                      severity="error"
                      style={{ marginBottom: '2rem', width: '100%' }}
                    >
                      {error}
                    </Alert>
                  )}
                  <Box display="flex" justifyContent="center">
                    <ButtonFilled
                      title={loading ? t('Chargement...') : t('Étape suivante')}
                      disabled={loading}
                      onPress={pay}
                      // onPress={() => setActiveStep(3)}
                    />
                  </Box>
                  {isCouponValid && (
                    <Alert
                      severity="success"
                      style={{ marginTop: '2rem', width: '100%' }}
                    >
                      {t('Votre code promo a bien été appliqué !')}
                    </Alert>
                  )}
                </Box>
              ) : (
                activeStep === 3 && (
                  <form>
                    <FormControl fullWidth>
                      <CustomInput
                        placeholder={t('Date de naissance') + ' :'}
                        type="date"
                        defaultValue={
                          user.birth
                            ? moment(user.birth, 'DD-MM-YYYY').format(
                                'YYYY-MM-DD',
                              )
                            : ''
                        }
                        // value={user.birth}
                        onChange={e => setValue('birth', e.target.value)}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <CustomInput
                        placeholder={t('Numéro de téléphone') + ' :'}
                        value={user.phone}
                        onChange={e => setValue('phone', e.target.value)}
                      />
                    </FormControl>
                    {error && (
                      <div
                        className="error-box"
                        style={{ textAlign: 'center' }}
                      >
                        <div className="error">{error}</div>
                      </div>
                    )}
                    <div className="row-center" style={{ marginTop: 25 }}>
                      <ButtonFilled
                        disabled={loading}
                        title={
                          loading
                            ? t('Chargement...')
                            : t('Accédez aux analyses !')
                        }
                        onPress={addInfos}
                      />
                    </div>
                  </form>
                )
              )}
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const PaymentStripe = () => {
  const { i18n } = useTranslation();
  const [referral, setReferral] = React.useState('');

  React.useEffect(() => {
    (window as any).rewardful('ready', () => {
      if ((window as any).Rewardful.referral) {
        setReferral((window as any).Rewardful.referral);
      }
    });
  }, []);

  return (
    <Elements
      stripe={stripePromise}
      options={{
        locale: i18n.language as StripeElementLocale | undefined,
      }}
    >
      <CheckoutForm referral={referral} />
    </Elements>
  );
};

export default PaymentStripe;
