import React from 'react';
import { Grid, ListItem, Theme, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import { GroupedDayRegularGames } from '../../../../../../../../../cloud/functions/src/types/sportradar/NBA/SchedulesGamesNBA';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Logo from '../../../../../Templates/Logos/Logo';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ButtonFilled from '../../../../../Templates/Buttons/ButtonFilled';
import { getNFLLogoURL } from '../../../../../../../utils/nfl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    singleCard: {
      backgroundColor: 'rgba(38, 52, 112, 1)',
      margin: '.625rem 0',
      padding: '.75rem 1rem',
      borderRadius: '15px',
      border: 'none',
      boxShadow: 'none',
      minHeight: 'unset',
      minWidth: '100%',
      '&> *': {
        fontWeight: 500,
        textAlign: 'center',
      },
      '&:hover': {
        backgroundColor: '#1d2a64',
      },
    },
    countryImg: {
      display: 'flex',
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        height: 30,
        width: 30,
      },
    },
  }),
);

type CalendarListProps = {
  games: GroupedDayRegularGames[];
};

const CalendarList: React.FC<CalendarListProps> = ({ games }) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <>
      {games &&
        games.map(game => (
          <ListItem
            component={Card}
            key={game.id}
            className={classes.singleCard}
          >
            <Grid
              container
              item
              xs={12}
              alignContent="center"
              justify="center"
              alignItems="center"
            >
              <Grid
                container
                item
                alignContent="center"
                justify={'center'}
                alignItems="center"
                xs={2}
              >
                <Typography
                  variant={'body1'}
                  style={{
                    fontWeight: 'bold',
                    color:
                      game.scoring?.home_points && game.scoring?.away_points
                        ? game.scoring.home_points > game.scoring.away_points
                          ? '#20a86c'
                          : 'white'
                        : 'white',
                  }}
                >
                  {game.home.name}
                </Typography>
              </Grid>
              <Grid
                container
                item
                alignContent="center"
                justify="center"
                alignItems="center"
                xs={2}
              >
                <Logo
                  src={getNFLLogoURL(game.home.id)}
                  width={mobile ? 40 : 60}
                  noBg
                  imgWidth="85%"
                  imgHeight="inehrit"
                  typeImgDefault="club"
                  imgDefautl="NFL"
                />
              </Grid>
              <Grid
                container
                item
                alignContent="center"
                justify="center"
                alignItems="center"
                xs={4}
              >
                <Box
                  width="max-content"
                  height="100%"
                  borderRadius="1rem"
                  bgcolor="rgba(255, 255, 255, 0.1)"
                  fontWeight="bold"
                  fontFamily="AvenirNext"
                  padding=".3rem 1rem"
                  margin="0 .3rem"
                  fontSize="1.10rem"
                >
                  {game.scoring?.home_points} - {game.scoring?.away_points}
                </Box>
              </Grid>
              <Grid
                container
                item
                alignContent="center"
                justify="center"
                alignItems="center"
                xs={2}
              >
                <Logo
                  src={game.away.id ? getNFLLogoURL(game.away.id) : ''}
                  width={mobile ? 40 : 60}
                  noBg
                  imgWidth="85%"
                  imgHeight="inehrit"
                  typeImgDefault="club"
                  imgDefautl="NFL"
                />
              </Grid>
              <Grid
                container
                item
                alignContent="center"
                justify={'center'}
                alignItems="center"
                xs={2}
              >
                <Typography
                  variant={'body1'}
                  style={{
                    fontWeight: 'bold',
                    color:
                      game.scoring?.home_points && game.scoring?.away_points
                        ? game.scoring.away_points > game.scoring.home_points
                          ? '#20a86c'
                          : 'white'
                        : 'white',
                  }}
                >
                  {game.away.name}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        ))}
    </>
  );
};

export default CalendarList;
