import React from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { Fire } from 'services';
import { Grid, ListItem, ListItemText, Typography } from '@material-ui/core';
import Loading from '../../../../Loading/Loading';
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import '../../../../../theme/datasportTheme.css';
import NFLLogo from '../../../Templates/NFLLogo/NFLLogo';
import { useQuery } from 'react-query';
import { getNFLLogoURL } from '../../../../../utils/nfl';
import CardGeneral from '../../../Templates/Cards/CardGeneral';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      margin: theme.spacing(4, 0, 2),
    },
    tabs: {
      backgroundColor: 'transparent',
      borderBottom: 'none',
      [theme.breakpoints.down('xs')]: {
        overflow: 'scroll',
      },
    },
    tab: {
      boxShadow: 'none',
      textTransform: 'none',
      fontWeight: 'bold',
      minWidth: '100px',

      '&>span': {
        '&.MuiTab-wrapper': {
          backgroundColor: '#242f56',
          borderRadius: '50px',
          color: 'white !important',
          height: '100%',
          padding: '.8rem 2.5rem',
          width: 'max-content',
          transition: 'all .2s ease-in-out',
        },
      },
      '&.Mui-selected': {
        '&> span': {
          '&.MuiTab-wrapper': {
            backgroundColor: theme.palette.secondary.main,
            color: `${theme.palette.primary.main} !important`,
          },
        },
      },
      '&:hover> span': {
        '&.MuiTab-wrapper': {
          backgroundColor: theme.palette.secondary.main,
          color: `${theme.palette.primary.main} !important`,
        },
      },
    },
    card: {
      height: 'unset',
      padding: '1rem',
      [theme.breakpoints.down('sm')]: {
        padding: 5,
        width: 'calc(100% + 40px)',
        transform: 'translate(-20px)',
        margin: 0,
      },
      '&> *': {
        textAlign: 'center',
      },

      '& > div > div': {
        [theme.breakpoints.down('sm')]: {
          padding: '5px !important',
        },
      },
    },
    singleCard: {
      backgroundColor: 'rgba(38, 52, 112, 1)',
      margin: '.625rem 0',
      padding: '1rem',
      borderRadius: '15px',
      border: 'none',
      boxShadow: 'none',
      minHeight: 'unset',
      minWidth: '100%',
      [theme.breakpoints.down('sm')]: {
        padding: '1rem',
      },
      '&> *': {
        fontWeight: 500,
        textAlign: 'center',
      },
      '&:hover': {
        backgroundColor: '#1d2a64',
      },
    },
    headerItems: {
      backgroundColor: 'transparent',
      [theme.breakpoints.down('sm')]: {
        padding: '10px 2rem !important',
      },
    },
    img: {
      borderRadius: '50px',
      [theme.breakpoints.down('sm')]: {
        width: 20,
        height: 20,
      },
    },
    countryImg: {
      display: 'flex',
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        height: 30,
        width: 30,
      },
    },
    appBar: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      borderBottom: 'solid 2px #dbdfe8',

      '&,& > div, &>div>div': {
        overflow: 'scroll !important',
      },
    },
    button: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '13px',
      borderBottomRightRadius: '18px',

      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
        boxShadow: '0 12px 23px 0 rgb(10 22 62 / 16 %)',
      },
    },
  }),
);

type RankingNFLProps = {
  league: string;
};

const RankingNFL: React.FC<RankingNFLProps> = props => {
  const classes = useStyles();
  const theme = useTheme();
  const { league } = props;

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const { data: teams, isLoading } = useQuery(
    'NFL:standings',
    () => Fire.cloud('nfl', { method: 'getRegularStandings' }),
    { staleTime: 900000 /* 15 mn */ },
  );

  const mapTeams = () =>
    Object.entries(teams[league]).map(([key, div]: [string, any]) => {
      return (
        <Grid key={key}>
          <Box margin="2rem 0 1rem 1rem" textAlign="left">
            <Typography variant="h5">{t(key)}</Typography>
          </Box>
          {div.map(team => {
            return (
              <ListItem
                component={Card}
                className={clsx(classes.singleCard)}
                key={team.rank.conference}
              >
                <Grid
                  container
                  item
                  xs={12}
                  alignContent="center"
                  justify="center"
                  alignItems="center"
                >
                  <Grid
                    container
                    item
                    alignContent="center"
                    justify="center"
                    alignItems="center"
                    xs={mobile ? 7 : 3}
                  >
                    <Grid item xs={1}>
                      <ListItemText
                        primary={team.rank.division}
                        className="bold"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <NFLLogo src={getNFLLogoURL(team.id)} />
                    </Grid>
                    <Grid item xs={7}>
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ gap: 5 }}
                      >
                        <ListItemText primary={team.name} className="bold" />
                      </Box>
                    </Grid>
                  </Grid>
                  {mobile ? (
                    <Grid container item xs={5}>
                      <Grid item xs={3}>
                        <ListItemText primary={team.wins} />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={3}
                        alignItems="center"
                        justify="center"
                      >
                        <ListItemText primary={team.losses} />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={3}
                        alignItems="center"
                        justify="center"
                      >
                        <ListItemText
                          primary={Math.round(team.win_pct * 10000) / 100}
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={3}
                        alignItems="center"
                        justify="center"
                      >
                        {/* <ListItemText primary={team.infos.matches_won} /> */}
                      </Grid>
                      <Grid item xs={3}>
                        {/* {positionChange(team.movement)} */}
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container item xs={9}>
                      <Grid item xs={2}>
                        <ListItemText
                          primary={team.market}
                          style={{ fontWeight: 'bold' }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <ListItemText
                          style={{ fontWeight: 'bold' }}
                          primary={Math.round(team.win_pct * 10000) / 100 + '%'}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Box>
                          <ListItemText
                            primary={team.wins + team.losses + team.ties}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <ListItemText primary={team.wins} />
                      </Grid>
                      <Grid item xs={2}>
                        <Box>
                          <ListItemText primary={team.losses} />
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box>
                          <ListItemText primary={team.ties} />
                        </Box>
                      </Grid>
                      <Grid item xs={1} />
                      <Grid item xs={1}>
                        {/* {positionChange(team.movement)} */}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </ListItem>
            );
          })}
        </Grid>
      );
    });

  return (
    <React.Fragment>
      <Grid container item xs={10} sm={12} justify="space-around">
        <Box width="100%" position="relative">
          {isLoading ? (
            <Grid container item xs={12}>
              <Loading nobg />
            </Grid>
          ) : (
            <CardGeneral className={classes.card}>
              <ListItem
                component={Card}
                className={clsx(classes.card, classes.headerItems)}
              >
                <Grid
                  container
                  item
                  xs={12}
                  alignContent="center"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item container xs={mobile ? 7 : 3}>
                    {!mobile && (
                      <>
                        <Grid item xs={5} />
                        <Grid item xs={7}>
                          {/* <Box
                            display="flex"
                            alignItems="center"
                            style={{ gap: 5 }}
                          > */}
                          <ListItemText primary={t('Nom')} className="bold" />
                          {/* </Box> */}
                        </Grid>
                      </>
                    )}
                  </Grid>
                  {mobile ? (
                    <Grid
                      container
                      item
                      xs={5}
                      direction="row"
                      alignContent="center"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={3}>
                        <ListItemText primary={'V'} className="bold" />
                      </Grid>
                      <Grid item xs={3}>
                        <ListItemText
                          primary={'D'}
                          className="bold"
                          style={{ textAlign: 'center' }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <ListItemText
                          primary={'%'}
                          className="bold"
                          style={{ textAlign: 'center' }}
                        />
                      </Grid>
                      <Grid item xs={3}></Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      item
                      xs={9}
                      direction="row"
                      alignContent="center"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={2}>
                        <ListItemText primary={t('Ville')} className="bold" />
                      </Grid>
                      <Grid item xs={2}>
                        <ListItemText
                          primary={t('% de vict.')}
                          className="bold"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <ListItemText primary={t('Joués')} className="bold" />
                      </Grid>
                      <Grid item xs={2}>
                        <ListItemText
                          primary={t('Victoires')}
                          className="bold"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <ListItemText
                          primary={t('Défaites')}
                          className="bold"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <ListItemText primary={t('Nuls')} className="bold" />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </ListItem>
              {teams[league] && mapTeams()}
            </CardGeneral>
          )}
        </Box>
      </Grid>
    </React.Fragment>
  );
};

export default RankingNFL;
