import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import FilledInput from '@material-ui/core/FilledInput';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import SearchIcon from '@material-ui/icons/Search';
import { connectSearchBox } from 'react-instantsearch-dom';
import { SearchBoxProvided } from 'react-instantsearch-core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      color: 'red',
      '&>div': {
        borderRadius: '20px !important',
        backgroundColor: '#242f56 !important',
        color: 'rgba(255, 255, 255)',
        fontWeight: 'bold',
        '&::before': {
          display: 'none !important',
        },
        '&::after': {
          display: 'none !important',
        },

        '&>input': {
          padding: '15px 20px !important',
          '&::placeholder': {
            color: 'rgba(255, 255, 255, .33) !important',
          },
        },

        '&>svg': {
          fontSize: '30px',
        },
      },
    },
  }),
);

type MySearchBoxProps = SearchBoxProvided & {
  placeholder: string;
  isTourOpen?: boolean;
  soccer?: boolean;
};

const MySearchBox: React.FC<MySearchBoxProps> = ({
  currentRefinement,
  refine,
  placeholder,
  isTourOpen,
  soccer,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const isTeamPlayersPage = () =>
    history.location.pathname.startsWith('/library/teams_players/');

  const [valueExample, setValueExample] = React.useState('');

  React.useEffect(() => {
    if (isTourOpen)
      'Kylian Mbappe'.split('').forEach((letter, index) => {
        setTimeout(() => {
          setValueExample(prev => {
            const newSearch = prev + letter;
            onChange(newSearch);
            return newSearch;
          });
        }, index * 80);
      });
  }, [isTourOpen]);

  const onChange = value => {
    if (soccer && !isTeamPlayersPage()) history.push('/library/teams_players/');
    if (!soccer) history.push('/blog/');
    refine(value);
  };

  return (
    <Box
      width="80%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      maxWidth="600px"
      margin="auto"
      marginTop="15px"
      marginBottom="15px"
    >
      <FormControl
        fullWidth
        className={classes.formControl}
        variant="outlined"
        data-tour="database-input-step"
      >
        <FilledInput
          type="search"
          id="Filled-adornment-mysearchbox"
          value={isTourOpen ? valueExample : currentRefinement}
          onChange={event => {
            onChange(event.target.value);
          }}
          // startAdornment={<SearchIcon color="secondary" fontSize="default" />}
          placeholder={placeholder}
        />
      </FormControl>
    </Box>
  );
};

export default connectSearchBox(MySearchBox);
