import React from 'react';
import './Loading.scss';
import datasport_logo from '../../images/logo_datasport.png';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  makeStyles,
  Theme,
  useTheme,
  createStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    small: {
      width: '150px',
      height: '150px',
      margin: '5%',
    },
    small_logo: {
      width: '120px',
      height: 'auto',
    },
  }),
);

type Props = {
  nobg?: boolean;
  small?: boolean;
};

function Loading(props: Props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();

  return (
    <div
      className={!props.nobg ? 'loader_background' : 'loader_no-background '}
    >
      <img
        src={datasport_logo}
        alt="Data-Sport logo"
        className={
          props.small ? `logo_loading ${classes.small_logo}` : 'logo_loading'
        }
      ></img>
      <div className={props.small ? `loader ${classes.small}` : 'loader'}></div>
    </div>
  );
}

export default Loading;
