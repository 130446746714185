import React from 'react';
import { Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const TeamHitPoint: React.FC<{ pts: number }> = ({ pts }) => {
  const { t } = useTranslation();
  return (
    <Chip
      style={{ backgroundColor: '#243058' }}
      label={
        <div
          style={{
            fontSize: 14,
            fontWeight: 600,
            color: 'whtie',
            padding: 4,
          }}
        >
          <span style={{ marginRight: 6 }}>{t('Pts')}:</span>
          <span>{pts}</span>
        </div>
      }
    />
  );
};

export default TeamHitPoint;
