import {
  MatchSummary,
  StandingsEntity,
} from '../../../types/sportradar/soccer/sport-event';
import { Fire } from 'services';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

type PageMatch = {
  match?: MatchSummary;
  isMatchLoading: boolean;
  standings: StandingsEntity[];
  isStandingsLoading: boolean;
};

const usePageMatch = (): PageMatch => {
  const { srEventId } = useParams<{ srEventId: string }>();
  const { data: match, isLoading: isMatchLoading } = useQuery(
    `soccer:match${srEventId}`,
    () =>
      Fire.cloud<MatchSummary>('soccer', {
        method: 'getMatchSummary',
        srEventId,
      }),
    { staleTime: 900000 /* 15 mn */ },
  );

  const competitionId = match?.sport_event.sport_event_context.competition.id;
  const groupId = match?.sport_event.sport_event_context.groups[0].id;
  const {
    data: standings = [],
    isLoading: isStandingsLoading,
    isIdle: isStandingsIdle,
  } = useQuery(
    `soccer:standings/${competitionId}/${groupId}`,
    () =>
      Fire.cloud<StandingsEntity[]>('soccer', {
        method: 'getGroupStandings',
        competitionId,
        groupId,
      }),
    { enabled: !!match, staleTime: 900000 /* 15 mn */ },
  );

  return {
    isMatchLoading,
    match,
    standings,
    isStandingsLoading: isStandingsLoading || isStandingsIdle,
  };
};

export default usePageMatch;
