import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';

import Football from '../../../../images/football.png';
import Tennis from '../../../../images/tennis.png';
import NBA from '../../../../images/basketball.png';
import Hockey from '../../../../images/hockey.png';
import NFL from '../../../../images/nfl.png';
import Baseball from '../../../../images/baseball.png';

const Logo: React.FC<{
  src: string;
  width?: number;
  imgWidth?: string;
  imgHeight?: string;
  alt?: string;
  noBg?: boolean;
  club?: boolean;
  grey?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  imgDefautl?: 'FOOTBALL' | 'TENNIS' | 'NBA' | 'NFL' | 'NHL' | 'BASEBALL';
  typeImgDefault?: 'player' | 'club';
}> = ({
  src,
  width,
  alt,
  imgWidth,
  imgHeight,
  noBg,
  club,
  grey,
  disabled,
  style,
  imgDefautl,
  typeImgDefault,
}) => {
  const [imgUrl, setImgUrl] = useState<string | null>(null);

  useEffect(() => {
    setImgUrl(src);
  }, [src]);

  const getDefaultImg = () => {
    if (typeImgDefault === 'club') {
      if (imgDefautl === 'FOOTBALL') {
        return setImgUrl(Football || '');
      } else if (imgDefautl === 'TENNIS') {
        return setImgUrl(Tennis || '');
      } else if (imgDefautl === 'NBA') {
        return setImgUrl(NBA || '');
      } else if (imgDefautl === 'NFL') {
        return setImgUrl(NFL || '');
      } else if (imgDefautl === 'NHL') {
        return setImgUrl(Hockey || '');
      } else if (imgDefautl === 'BASEBALL') {
        return setImgUrl(Baseball || '');
      }
    } else if (typeImgDefault === 'player') {
      return setImgUrl(
        'https://firebasestorage.googleapis.com/v0/b/data-tech-bb3f7.appspot.com/o/tennis%2FAvatar_homme.png?alt=media&token=62248208-5821-4848-8029-b9b743996ce9' ||
          '',
      );
    }
  };

  return (
    <Box
      borderRadius="50%"
      bgcolor={noBg ? 'transparent' : 'white'}
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={width || 'inherit'}
      height={width || 'inherit'}
      minWidth={width}
      boxShadow={noBg ? '' : '0 6px 25px -4px rgba(118, 148, 255, 0.42)'}
      overflow="hidden"
      style={{
        filter: grey ? 'grayscale(1)' : '',
        opacity: disabled ? 0.48 : 1,
        ...(style || {}),
      }}
    >
      <img
        height={imgHeight || '85%'}
        width={imgWidth || (club ? 'auto' : '85%')}
        alt={alt || 'Data-Sport Logo'}
        style={{ borderRadius: '50%' }}
        src={imgUrl || undefined}
        onError={() => getDefaultImg()}
      />
    </Box>
  );
};

export default Logo;
