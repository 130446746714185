import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  useTheme,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CardGeneral from '../CardGeneral';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ButtonFilled from '../../Buttons/ButtonFilled';
import clsx from 'clsx';
import '../../../../../theme/datasportTheme.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import backgroundFootball from 'images/backgroundFootball.svg';
import backgroundFootball_Dark from 'images/backgroundFootball_Dark.svg';
import backgroundTennis from 'images/backgroundTennis.svg';
import BorderLinearProgress from '../../Progress/BorderLinearProgress';
import Logo from '../../Logos/Logo';
import { isTooLong } from '../../../../../utils/logic';
import moment from 'moment';
import { getInfosPronoLibre } from '../../../../../utils/pronoLibreSoccer';
import { capitalize } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: '20rem',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        height: '26rem',
      },
    },
    content: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'space-between',
      height: '100%',
      minHeight: '15rem',
      position: 'relative',
    },
    backgroundFootball: {
      background: `url(${backgroundFootball}) no-repeat top right`,
      backgroundColor: '#dadde5',
    },
    backgroundFootball_Dark: {
      background: `url(${backgroundFootball_Dark}) no-repeat top right`,
      backgroundColor: 'rgba(91, 94, 105, .85)',
    },
    backgroundFootballInvalid: {
      background: `url(${backgroundFootball}) no-repeat top right`,
      backgroundColor: '#dadde5',
    },
    backgroundFootballInvalid_Dark: {
      background: `url(${backgroundFootball_Dark}) no-repeat top right`,
      backgroundColor: 'rgba(164, 77, 77, .85)',
    },
    backgroundTennis: {
      background: `url(${backgroundTennis}) no-repeat top right`,
      backgroundColor: '#dadde5',
    },
    backgroundGrey: {
      background: '#d2d2d2',
    },
    greenShadow: {
      boxShadow: '0px 5px 17px 1px #04ff0063 !important',
    },
    orangeShadow: {
      boxShadow: '0px 5px 17px 1px #ffbc006b !important',
    },
    greenText: {
      color: '#10ff00',
    },
    orangeText: {
      color: '#ffb000b5',
    },
  }),
);

export default function CardPredictionFootball(props) {
  // const { fiability, date, league, team1, team2, team1Score, team2Score, team1Flag, team2Flag } = props;
  const { ...prono } = props;
  const {
    tournament,
    domicile,
    ext,
    date,
    blue,
    future,
    hide_until,
    isPronoTest,
    name_inactive,
    invalid,
  } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const isDark = theme.palette.type === 'dark';
  const language = i18n.language.toUpperCase();
  const [mainPronoName, setMainPronoName] = React.useState<string>('');
  const [percentage, setPercentage] = React.useState<number>(0);

  const getPronoInfos = () => {
    const pronoNames = getInfosPronoLibre(props, t);
    setMainPronoName(pronoNames.pronoName);
    setPercentage(pronoNames.pronoPercentage);
  };
  React.useEffect(() => {
    getPronoInfos();
  }, [language, props]);

  return (
    <CardGeneral
      className={clsx(
        classes.card,
        !future && !name_inactive && percentage >= 56 && classes.greenShadow,
        !future &&
          !name_inactive &&
          percentage > 0 &&
          percentage < 56 &&
          classes.orangeShadow,
        future &&
          (isDark
            ? classes.backgroundFootball_Dark
            : classes.backgroundFootball),
        invalid &&
          (isDark
            ? classes.backgroundFootballInvalid_Dark
            : classes.backgroundFootballInvalid),
      )}
      blue={blue}
      cursorPointer={!future && !name_inactive && !invalid}
      shadow={!future}
      onClick={
        !future && !name_inactive && !invalid
          ? () => {
              history.push('/pronos/' + prono.id);
            }
          : undefined
      }
    >
      <CardContent className={classes.content}>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          position="relative"
        >
          {isPronoTest && (
            <Box
              position="absolute"
              top="0"
              right="0"
              fontSize="12px"
              color="#ff3f3f"
              fontWeight={600}
              style={{ transform: 'translate(0, -100%)' }}
            >
              <span>Prono {name_inactive ? 'f' : 'test'}</span>
            </Box>
          )}
          <Box height="10%" width="100%">
            <Box
              width="100%"
              height="100%"
              display="flex"
              flexDirection="row"
              flexWrap="nowrap"
              fontWeight={600}
              justifyContent="space-between"
            >
              <Box
                color={future || (invalid && isDark) ? '#fff' : '#838ca6'}
                textAlign="left"
                fontSize=".7rem"
              >
                <Typography variant={mobile ? 'body1' : 'body2'}>
                  {moment(new Date(date.seconds * 1000)).format('DD/MM/YYYY')}
                </Typography>
              </Box>
              <Box color="#838ca6" textAlign="right" fontWeight="600">
                <Typography
                  variant={mobile ? 'body1' : 'body2'}
                  component="span"
                >
                  {/* {!future && percentage >= 50 && (
                    <Box className={classes.greenText} fontWeight={600}>
                      {t('Risque faible')}
                    </Box>
                  )} */}
                  {!future && !invalid && percentage < 56 && percentage > 0 && (
                    <Box className={classes.orangeText} fontWeight={600}>
                      {t('Risque élevé')}
                    </Box>
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
          {future ? (
            <Box height="20%" width="100%">
              <Box
                height="100%"
                width="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Box
                  color={
                    blue
                      ? isDark
                        ? '#fff'
                        : theme.palette.text.primary
                      : theme.palette.text.primary
                  }
                  textAlign="center"
                  width="100%"
                  height="50%"
                >
                  <Typography
                    variant={mobile ? 'h3' : 'h4'}
                    style={mobile ? {} : { fontSize: '1.24rem' }}
                  >
                    {t('Prochaine analyse')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : invalid ? (
            <Box height="20%" width="100%">
              <Box
                height="100%"
                width="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Box
                  color={
                    blue
                      ? isDark
                        ? '#fff'
                        : theme.palette.text.primary
                      : theme.palette.text.primary
                  }
                  textAlign="center"
                  width="100%"
                  height="100%"
                >
                  <Typography
                    variant={mobile ? 'h3' : 'h4'}
                    style={mobile ? {} : { fontSize: '1.24rem' }}
                  >
                    {t("Match non sélectionné par l'algorithme")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box height="20%" width="100%">
              <Box
                height="100%"
                width="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Box
                  color={blue ? '#fff' : theme.palette.text.primary}
                  textAlign="center"
                  width="100%"
                >
                  <Typography
                    variant={mobile ? 'h3' : 'h4'}
                    style={mobile ? {} : { fontSize: '1.24rem' }}
                  >
                    {capitalize(t(mainPronoName.trim()))}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          <Box height="45%" width="100%">
            <Box width="100%" height="60%" display="flex">
              <Box
                width="33%"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  borderRadius="50%"
                  height="50px"
                  width="50px"
                  bgcolor="white"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  boxShadow={
                    future || name_inactive ? '' : '0 0 33px 0 rgb(0 0 0 / 11%)'
                  }
                  className={future ? classes.backgroundGrey : ''}
                >
                  <Logo
                    src={domicile?.logo}
                    grey={future || invalid}
                    club={domicile.name !== domicile.country}
                    typeImgDefault="club"
                    imgDefautl="FOOTBALL"
                  />
                </Box>
              </Box>
              <Box
                width="33%"
                height="100%"
                color={future || (invalid && isDark) ? '#fff' : '#838ca6'}
                textAlign="center"
                alignItems="center"
                display="flex"
                justifyContent="center"
                fontWeight="600"
              >
                <Typography variant={mobile ? 'body1' : 'body2'}>
                  {tournament?.name?.[language] || tournament?.name}
                </Typography>
              </Box>
              <Box
                width="33%"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  borderRadius="50%"
                  height="50px"
                  width="50px"
                  bgcolor="white"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  boxShadow={
                    future || name_inactive ? '' : '0 0 33px 0 rgb(0 0 0 / 11%)'
                  }
                  className={future ? classes.backgroundGrey : ''}
                >
                  <Logo
                    src={ext?.logo}
                    grey={future || invalid}
                    club={ext.name !== ext.country}
                    typeImgDefault="club"
                    imgDefautl="FOOTBALL"
                  />
                </Box>
              </Box>
            </Box>
            <Box width="100%" height="40%" display="flex">
              <Box
                width="33%"
                height="100%"
                color={
                  isDark ? '#fff' : future ? theme.palette.text.primary : '#fff'
                }
                display="flex"
                textAlign="center"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  variant={mobile ? 'h6' : 'body1'}
                  component={'span'}
                >
                  <Box fontWeight="600">
                    {isTooLong(domicile?.name, 20, 3)
                      ? t(domicile.abbreviation)
                      : t(domicile?.name)}
                  </Box>
                </Typography>
              </Box>
              <Box width="33%" height="100%"></Box>
              <Box
                width="33%"
                height="100%"
                color={
                  isDark ? '#fff' : future ? theme.palette.text.primary : '#fff'
                }
                display="flex"
                textAlign="center"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  variant={mobile ? 'h6' : 'body1'}
                  component={'span'}
                >
                  <Box fontWeight="600">
                    {isTooLong(ext?.name, 20, 3)
                      ? t(ext.abbreviation)
                      : t(ext?.name)}
                  </Box>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            height="20%"
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {future ? (
              <BorderLinearProgress hide_until={hide_until} />
            ) : invalid ? (
              <BorderLinearProgress red={true} />
            ) : (
              <ButtonFilled title="Voir les statistiques" blue={blue} medium />
            )}
          </Box>
        </Box>
      </CardContent>
    </CardGeneral>
  );
}
