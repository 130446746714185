import moment from 'moment';
import 'moment/locale/fr';

export default class Time {
  static async freeze(duration: number = 1500) {
    return new Promise(resolve => {
      setTimeout(resolve, duration);
    });
  }

  static moment(val) {
    return moment(val);
  }

  static getDateFromSeconds(sec) {
    // This function return the date with the format dd/mm/yyyy from seconds

    // Get the date with the JS date format
    var date = new Date(1970, 0, 1);
    date.setSeconds(sec);

    // Get the day, month and Year

    var day = Math.abs(date.getDate());
    var month = date.getMonth() + 1;
    var year = date.getFullYear();

    // Return the date with the format dd/mm/yyyy
    return (
      (day < 10 ? `0${day}` : day) +
      '/' +
      (month < 10 ? `0${month}` : month) +
      '/' +
      year
    );
  }

  static calculateDifferenceInSecond(
    dateBeforeInSeconds,
    dateNowInSeconds = Math.floor(Date.now() / 1000),
  ) {
    // This function return the difference between 2 dates in seconds
    // The parameters have to be in seconds
    // If the second paramter is not define, it is by default the date at the moment the function is used, in seconds
    return dateBeforeInSeconds - dateNowInSeconds;
  }

  static calculateCountdown(dateInSeconds) {
    // This function return the time between now and the date we are waiting for
    // It ise used in the countdown
    return Date.now() + dateInSeconds / 100;
  }

  static getDateFromString_YYYYMMDD(dateInString: string = '') {
    // This function take as paramter a date in string at the format yyyy-mm-dd h
    // It returns an object with the complete date and the month, the day and the month
    // Exemple :
    // getDateFromString_YYYYMMDD(2021-04-15) returns
    // {
    // fullDate : "Jeudi 15 avril",
    // fullDateWithYear : "Jeudi 15 avril 2021",
    // day : "15",
    // dayString: "jeudi",
    // month: "avril",
    // year: "2021"
    // }

    let date = dateInString != '' ? new Date(dateInString) : new Date();

    const optionsFullDateWithYear = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    const optionsFullDate = { weekday: 'long', month: 'long', day: 'numeric' };
    const optionsDayString = { weekday: 'long' };
    const optionsMonthString = { month: 'long' };

    return {
      fullDate: date.toLocaleDateString('fr-Fr', optionsFullDate),
      fullDateWithYear: date.toLocaleDateString(
        'fr-Fr',
        optionsFullDateWithYear,
      ),
      day: dateInString.slice(8, 10),
      dayString: date.toLocaleDateString('fr-Fr', optionsDayString),
      month: dateInString.slice(5, 7),
      monthString: date.toLocaleDateString('fr-Fr', optionsMonthString),
      year: dateInString.slice(0, 4),
    };
  }
}
