import React from 'react';
import {
  createStyles,
  Fade,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Theme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      backgroundColor: 'transparent',
      borderBottom: 'none',
      paddingTop: '.5rem',
      paddingBottom: '.5rem',
      [theme.breakpoints.down('xs')]: {
        overflow: 'scroll',
      },
    },
    tab: {
      boxShadow: 'none',
      textTransform: 'none',
      fontWeight: 'bold',
      minWidth: '100px',

      '& > span:first-child': {
        borderRadius: '50px',
        height: '100%',
        padding: '.5rem 2.5rem',
        [theme.breakpoints.down('xs')]: {
          padding: '.5rem 1.5rem',
        },
        width: 'max-content',
        transition: 'all .2s ease-in-out',
        backgroundColor: '#242F56',
      },
      '&:hover > span:first-child': {
        color: 'white',
      },
      '&.Mui-selected': {
        '& > span:first-child': {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.secondary.main,
        },
        '&:hover > span:first-child': {
          color: theme.palette.primary.main,
        },
      },
    },
  }),
);

function a11yProps(index: number) {
  return {
    id: `scrollable - auto - tab - filter${index}`,
    'aria-controls': `scrollable - auto - tabpanel - filter${index}`,
  };
}

type TypeTabProps = {
  value: number;
  mediumComputer: boolean;
  onClick: (tabIndex: number) => void;
};

const SoccerTypeTab: React.FC<TypeTabProps> = ({
  value,
  mediumComputer,
  onClick,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Fade in={true} timeout={500}>
      <Grid
        container
        item
        xs={12}
        justify="center"
        className={mediumComputer ? 'noPadding' : ''}
        style={{ width: '90%', margin: 'auto' }}
      >
        <Tabs
          value={value}
          onChange={(e, v) => onClick(v)}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs filter"
          className={classes.tabs}
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
        >
          <Tab
            component="button"
            className={classes.tab}
            label={t('Classement')}
            {...a11yProps(0)}
          />
          <Tab
            component="button"
            className={classes.tab}
            label={t('Calendrier')}
            {...a11yProps(1)}
          />
          <Tab
            component="button"
            className={classes.tab}
            label={`${t('Equipes')} / ${t('Joueurs')}`}
            {...a11yProps(2)}
          />
        </Tabs>
      </Grid>
    </Fade>
  );
};

export default SoccerTypeTab;
