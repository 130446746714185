import React from 'react';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import Loading from '../../../../Loading/Loading';
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import '../../../../../theme/datasportTheme.css';
import Fire from 'services/fire.service';
import { Tournament } from '../../../../../types/soccer/tournament';
import { useQuery } from 'react-query';
import RowTeam from './RowTeam';
import CardGeneral from '../../../Templates/Cards/CardGeneral';

type Props = {
  league: Tournament;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: '10px 25px',
      [theme.breakpoints.down('sm')]: {
        padding: 5,
        width: 'calc(100% + 40px)',
        transform: 'translate(-20px)',
        margin: 0,
      },
      '&> *': {
        textAlign: 'center',
      },

      '& > div > div': {
        [theme.breakpoints.down('sm')]: {
          padding: '5px !important',
        },
      },
    },
  }),
);

const RankingSoccer: React.FC<Props> = ({ league }) => {
  const competitionId = league.competition.value;
  const classes = useStyles();
  const { data: groups, isLoading } = useQuery(
    `soccer:standings:${competitionId}`,
    () => Fire.cloud('soccer', { method: 'getStandings', competitionId }),
    { staleTime: 900000 /* 15 mn */ },
  );

  return (
    <Grid container justify="center" alignItems="center">
      <Grid container item xs={10} sm={12} justify="space-around">
        <Box width="100%" position="relative">
          {isLoading ? (
            <Grid container item xs={12}>
              <Loading nobg />
            </Grid>
          ) : (
            <CardGeneral className={classes.card}>
              <RowTeam isHeader />
              {groups &&
                groups.map(team => {
                  return <RowTeam team={team} key={team.id} />;
                })}
            </CardGeneral>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default RankingSoccer;
