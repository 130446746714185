import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CardGeneral from '../../../Templates/Cards/CardGeneral';
import MatchHistoryMobile from '../MatchHistoryMobile';
import { CompetitorSummary } from '../../../../../types/sportradar/soccer/history';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  card: {
    padding: '1rem',
  },
}));

type Props = {
  teamHistories1: CompetitorSummary[];
  teamHistories2: CompetitorSummary[];
  teamHistories3: CompetitorSummary[];
  teamName1: string;
  teamName2: string;
  isLoading: boolean;
};

const SinglePronoHistory: React.FC<Props> = ({
  teamHistories1,
  teamHistories2,
  teamHistories3,
  teamName1,
  teamName2,
  isLoading,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Box>
          <MatchHistoryMobile
            title={`${t('Derniers matchs')} ${teamName1}`}
            teamHistories={teamHistories1}
            teamName={teamName1}
            isLoading={isLoading}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box>
          <MatchHistoryMobile
            title={`${t('Derniers matchs')} ${teamName2}`}
            teamHistories={teamHistories2}
            teamName={teamName2}
            isLoading={isLoading}
          />
        </Box>
      </Grid>
      {!!teamHistories3.length && (
        <Grid item xs={12}>
          <Box>
            <MatchHistoryMobile
              title={`${t(
                'Dernières confrontations',
              )} ${teamName1}-${teamName2}`}
              teamHistories={teamHistories3}
              isLoading={isLoading}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default SinglePronoHistory;
