import React, { useState } from 'react';
import {
  createStyles,
  Fade,
  makeStyles,
  Tab,
  Tabs,
  Theme,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TypeTab from '../TypeTab/TypeTab';
import clsx from 'clsx';
import RankingNFL from '../Ranking/RankingNFL/RankingNFL';
import CalendarNFL from '../Calendar/CalendarNFL/CalendarNFL';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabsLeagues: {
      margin: '1rem auto !important',
      '&>.MuiTabs-scroller>.MuiTabs-flexContainer': {
        height: 115,
        display: 'flex',
        alignItems: 'center',

        [theme.breakpoints.down('xs')]: {
          height: 90,
        },
      },
    },
    tournamentTab: {
      filter: 'grayscale(90%)',
      transition: 'filter .2s ease-in-out',
      opacity: '.48',
      boxShadow: '0 6px 25px -4px rgba(118, 148, 255, 0.42)',
      '&.Mui-selected, &:hover': {
        filter: 'grayscale(0%)',
        border: `6px solid ${theme.palette.secondary.main}`,
        opacity: '1',
        '& > span': {
          '& > div': {
            width: '100%',
            height: '100%',
          },
        },

        [theme.breakpoints.down('xs')]: {
          border: `3px solid ${theme.palette.secondary.main}`,
        },
      },
      '& > span': {
        height: '100%',
        width: '100%',
        '& > div': {
          width: 'calc(100% - 12px)',
          height: 'calc(100% - 12px)',

          [theme.breakpoints.down('xs')]: {
            width: 'calc(100% - 6px)',
            height: 'calc(100% - 6px)',
          },
        },
      },
    },
    tournamentTabTennisNFL: {
      transition: 'all .2s ease-in-out',
      minHeight: '2.5rem',
      minWidth: '10% !important',
      maxWidth: 'none !important',
      width: 'fit-content !important',
      zIndex: 10,
      margin: '0px 15px',
      background: 'white',
      padding: '0 1rem',
      borderRadius: '2rem',
      border: `3px solid transparent`,
      '& > span': {
        fontSize: '.8rem',
      },
      '&.Mui-selected, &:hover': {
        filter: 'grayscale(0%)',
        border: `3px solid ${theme.palette.secondary.main}`,
        opacity: '1',
        '& > span': {
          color: '#0b1847',
        },
      },
    },
  }),
);

type LibraryNFLProps = {
  mediumComputer: boolean;
};

const LibraryNFL: React.FC<LibraryNFLProps> = ({ mediumComputer }) => {
  const classes = useStyles();
  const [selectedTypeTab, setSelectedTypeTab] = useState<number>(0);
  const [selectedTypeNFL, setSelectedTypeNFL] = useState<number>(0);
  const { t } = useTranslation();

  const NFLTypes = [
    [
      {
        key: 'NFC',
        label: t('NFC'),
      },
      {
        key: 'AFC',
        label: t('AFC'),
      },
    ],
    [
      {
        key: 'regular',
        label: t('Saison régulière'),
      },
      // {
      //   key: 'playoffs',
      //   label: t('Playoffs'),
      // },
    ],
  ];

  return (
    <Grid
      item
      container
      xs={12}
      className={mediumComputer ? 'noPadding' : ''}
      justify="center"
    >
      <TypeTab
        value={selectedTypeTab}
        mediumComputer={mediumComputer}
        onClick={i => setSelectedTypeTab(i)}
      />
      {selectedTypeTab === 0 && (
        <Fade in={selectedTypeTab === 0} timeout={500}>
          <Tabs
            value={selectedTypeNFL}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs library"
            className={classes.tabsLeagues}
            style={{
              borderBottom: 'none',
              fontSize: '3rem',
              color: 'white',
              width: '80%',
              minWidth: '300px',
              margin: 'auto',
            }}
          >
            {NFLTypes[selectedTypeTab].map((tournament, index) => (
              <Tab
                key={tournament.key}
                label={tournament.label}
                onClick={() => {
                  setSelectedTypeNFL(index);
                }}
                className={clsx(
                  classes.tournamentTabTennisNFL,
                  classes.tournamentTab,
                )}
              />
            ))}
          </Tabs>
        </Fade>
      )}
      {selectedTypeTab === 0 && (
        <RankingNFL league={NFLTypes[selectedTypeTab][selectedTypeNFL].key} />
      )}
      {selectedTypeTab === 1 && (
        <CalendarNFL
          league={NFLTypes[selectedTypeTab][0].key as 'playoffs' | 'regular'}
        />
      )}
    </Grid>
  );
};

export default LibraryNFL;
