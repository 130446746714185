import React from 'react';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Tabs, Theme } from '@material-ui/core';
import LeagueTab from './LeagueTab/LeagueTab';
import RankingSoccer from '../Ranking/RankingSoccer/RankingSoccer';
import CalendarSoccer from '../Calendar/CalendarSoccer/CalendarSoccer';
import { Tournament } from '../../../../types/soccer/tournament';
import { useHistory, useParams } from 'react-router-dom';
import TeamsPlayers from '../TeamsPlayers/TeamsPlayers';
import SoccerTypeTab from './SoccerTypeTab/SoccerTypeTab';
import MySearchBox from '../../Templates/algolia/MySearchBox/MySearchBox';
import { InstantSearch } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabsLeagues: {
      margin: '2rem auto !important',
      '&>.MuiTabs-scroller>.MuiTabs-flexContainer': {
        height: 115,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.down('xs')]: {
          height: 90,
        },
      },
    },
  }),
);

type LibraryFootProps = {
  mediumComputer: boolean;
  mobile: boolean;
  language: string;
  tournaments?: Tournament[];
  isTourOpen?: boolean;
};

const possibleTypes = {
  ranking: 0,
  calendar: 1,
  teams_players: 2,
};

const LibrarySoccer: React.FC<LibraryFootProps> = ({
  mediumComputer,
  mobile,
  language,
  tournaments,
  isTourOpen,
}) => {
  const classes = useStyles();
  const params = useParams<{ type: string; league: string }>();
  const history = useHistory();
  const { t } = useTranslation();
  const selectedTypeTab: number = possibleTypes[params.type] || 0;
  const selectedLeagueTab = +params.league || 0;

  const searchClient = algoliasearch(
    'JYT36W9CI0',
    '68806a66c6f9d979d936fb877b6486a8',
  );

  return (
    <Grid
      item
      xs={12}
      className={mediumComputer ? 'noPadding' : ''}
      data-tour="database-players-parent-step"
    >
      <InstantSearch indexName="soccer_teams" searchClient={searchClient}>
        {selectedTypeTab !== possibleTypes.teams_players && (
          <Tabs
            value={selectedLeagueTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs library"
            className={classes.tabsLeagues}
            style={{
              borderBottom: 'none',
              fontSize: '3rem',
              color: 'white',
              width: '90%',
              minWidth: '300px',
              margin: 'auto',
            }}
            TabIndicatorProps={{
              style: {
                display: 'none',
              },
            }}
          >
            {tournaments?.map((tournament, i) => (
              <LeagueTab
                key={tournament.id}
                tournament={tournament}
                mobile={mobile}
                language={language}
                selected={selectedLeagueTab === i}
                onClick={() => {
                  const keys = Object.keys(possibleTypes);
                  history.push(`/library/${keys[selectedTypeTab]}/${i}`);
                }}
              />
            ))}
          </Tabs>
        )}
        <Grid container style={{ marginBottom: '1rem' }}>
          <Grid item xs={12} sm={7}>
            <SoccerTypeTab
              value={selectedTypeTab}
              mediumComputer={mediumComputer}
              onClick={i => {
                const keys = Object.keys(possibleTypes);
                history.push(`/library/${keys[i]}/${selectedLeagueTab}`);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <MySearchBox
              placeholder={t('Rechercher un joueur, une équipe...')}
              isTourOpen={isTourOpen}
              soccer={true}
            />
          </Grid>
        </Grid>
        {tournaments && selectedTypeTab === 0 && (
          <RankingSoccer league={tournaments[selectedLeagueTab]} />
        )}
        {tournaments && selectedTypeTab === 1 && (
          <CalendarSoccer league={tournaments[selectedLeagueTab]} />
        )}
        <TeamsPlayers visible={selectedTypeTab === 2} />
      </InstantSearch>
    </Grid>
  );
};

export default React.memo(LibrarySoccer);
