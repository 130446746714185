import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './RegisterScreen.scss';
import { useLayoutEffect } from 'react';
import { Fire } from 'services';
import { saveInfo, saveOffer } from 'actions/auth.action';
import prices from 'data/prices.data';
import logo from '../../images/logo_datasport.png';
import { Button, Input } from '../Reusable';
import { useTranslation } from 'react-i18next';

function RegisterScreen(props) {
  const { t } = useTranslation();

  const offer = props.match.params.offer;

  const [user, setUser] = useState<any>({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    password: '',
    confirm: '',
  });
  // const [user, setUser] = useState<any>({
  //   first_name: 'Julien',
  //   last_name: 'Brunet',
  //   phone: '0642986844',
  //   email: 'julien.brunet.' + Math.random() + '@gmail.com',
  //   password: 'coucou123',
  //   confirm: 'coucou123'
  // })
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  const showError = err => {
    setError(err);
    window.scrollTo(0, 0);
  };

  const register = async () => {
    const { email, first_name, last_name, phone, password, confirm } = user;
    if (first_name === '' || last_name === '' || phone === '') {
      showError(t('Veuillez saisir votre nom, prénom et numéro de téléphone'));
      return;
    } else if (email === '') {
      showError(t('Veuillez saisir votre email de connexion'));
      return;
    } else if (password !== confirm) {
      showError(t('Les mots de passes ne correspondent pas'));
      return;
    }

    setLoading(true);
    try {
      if (offer) {
        for (const o of prices) {
          if (o.id === offer) {
            dispatch(saveOffer(o));
          }
        }
      }
      dispatch(
        saveInfo({
          first_name: first_name,
          last_name: last_name,
          phone: phone,
        }),
      );
      await Fire.auth().createUserWithEmailAndPassword(email, password);
      history.push('/account');
    } catch (err) {
      switch (err.code) {
        case 'auth/email-already-exists':
          showError(t("L'email entré est déjà utilisé"));
          break;

        case 'auth/invalid-password':
          showError(t('Identifiants invalides'));
          break;

        case 'auth/user-not-found':
          showError(t('Identifiants invalides'));
          break;

        case 'auth/invalid-email':
          showError(t("L'email entré est invalide"));
          break;

        default:
          showError(t('Une erreur est survenue'));
      }
    }
    setLoading(false);
  };

  const setValue = (key: string, val: any) => {
    user[key] = val;
    setUser({ ...user });
  };
  const ScrollToTop = () => {
    useLayoutEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return null;
  };
  return (
    <div className="register-screen">
      {/* <HeaderBar /> */}
      {/* <ScrollToTop /> */}
      <div className="background top-padding">
        <div className="col-center">
          <div className="col-center">
            <img
              className="logo"
              onClick={() => history.push('/')}
              src={logo}
              width="30%"
              alt="Data-Sport logo"
            />
          </div>
          {/* <div className="page-title">{t("Créez votre compte")}</div> */}

          {error && (
            <div className="error-box">
              <div className="error">{error}</div>
            </div>
          )}

          <div className="info-form red-box">
            <div className="row-center">
              <Input
                title={t('Prénom')}
                value={user.first_name}
                onChange={val => setValue('first_name', val)}
              />
              <Input
                title={t('Nom')}
                value={user.last_name}
                onChange={val => setValue('last_name', val)}
              />
              <Input
                title={t('Email')}
                value={user.email}
                onChange={val => setValue('email', val)}
              />
              <Input
                title={t('Téléphone')}
                value={user.phone}
                onChange={val => setValue('phone', val)}
              />
              <Input
                title={t('Mot de passe')}
                type="password"
                value={user.password}
                onChange={val => setValue('password', val)}
              />
              <Input
                title={t('Confirmer le mot de passe')}
                type="password"
                value={user.confirm}
                onChange={val => setValue('confirm', val)}
              />
              <p className="terms">
                {t(
                  'En cliquant sur Confirmer vous reconnaissez accepter et avoir pris connaissance de nos ',
                )}
                <span className="link" onClick={() => history.push('/terms')}>
                  {t('Conditions générales de vente')}
                </span>
                {t(' et de notre ')}
                <span className="link" onClick={() => history.push('/privacy')}>
                  {t('Politique de confidentialité')}
                </span>
              </p>
            </div>

            <div className="row-center" style={{ marginTop: 25 }}>
              <Button
                title={t('Confirmer')}
                onPress={register}
                classes={['linear-btn', 'big-btn']}
              />
            </div>

            <div className="row-center" style={{ marginTop: 30 }}>
              <div className="page-link" onClick={() => history.push('/login')}>
                {t('Déja membre ? Connectez vous')}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default RegisterScreen;
