import React, { useEffect, useMemo, useState } from 'react';
import { Fire } from 'services';
import { Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CalendarList from './CalendarList/CalendarList';
import CalendarNavigation from '../../CalendarTennis/CalendarNavigation/CalendarNavigation';
import Loading from '../../../../../Loading/Loading';
import { useQuery } from 'react-query';
import CardGeneral from '../../../../Templates/Cards/CardGeneral';
import moment from 'moment';
import { connectSortBy } from 'react-instantsearch-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bigCard: {
      height: 'unset',
      padding: '.9rem 1.9rem',
      [theme.breakpoints.down('sm')]: {
        padding: 5,
        width: 'calc(100% + 40px)',
        transform: 'translate(-20px)',
        margin: 0,
      },
      '&> *': {
        textAlign: 'center',
        alignItems: 'center',
      },
    },
  }),
);

const CalendarNBARegular: React.FC = () => {
  const classes = useStyles();
  const [currentCalendar, setCurrentCalendar] = useState<number>(0);
  const [calendarLength, setCalendarLength] = useState<number | null>(null);
  const { data: groupedGames, isLoading } = useQuery(
    'nba:calendar:regular',
    () => Fire.cloud('basket', { method: 'getRegularSchedules' }),
    { staleTime: 900000 /* 15 mn */ },
  );

  const dateNowYYYYMMDD = useMemo(() => {
    return Object.keys(groupedGames)[currentCalendar];
  }, [currentCalendar, groupedGames]);

  useEffect(() => {
    if (groupedGames) {
      const dates = Object.keys(groupedGames);
      const now = moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      if (now.isSameOrBefore(moment(dates[0]))) {
        setCurrentCalendar(0);
      } else if (now.isSameOrAfter(moment(dates[dates.length - 1]))) {
        setCurrentCalendar(dates.length - 1);
      } else {
        const newIndex = dates.findIndex(date =>
          now.isSameOrBefore(moment(date)),
        );
        setCurrentCalendar(newIndex);
      }
      const calendarLength = Object.keys(groupedGames).length;
      setCalendarLength(calendarLength);
    }
  }, [groupedGames]);

  return (
    <>
      {isLoading && (
        <Grid container item xs={12}>
          <Loading nobg />
        </Grid>
      )}
      <Grid container item xs={12} justify="space-around">
        <Box width="100%" zIndex="1">
          <CardGeneral className={classes.bigCard}>
            <Grid container item xs={12} justify="space-between">
              {dateNowYYYYMMDD && (
                <CalendarNavigation
                  currentCalendar={currentCalendar as number}
                  calendarLength={calendarLength as number}
                  setCurrentCalendar={(next): void =>
                    setCurrentCalendar(prev => (prev as number) + next)
                  }
                  dateNowYYYYMMDD={dateNowYYYYMMDD}
                />
              )}
            </Grid>
            <div style={{ marginBottom: '1.5rem', marginTop: '0' }}>
              <Grid container item>
                <Typography variant="h6" component={'span'}>
                  <Box
                    fontFamily="AvenirNext"
                    color="#848ca5"
                    style={{ textTransform: 'uppercase' }}
                    margin=".5rem 0"
                  />
                </Typography>
              </Grid>
              <div>
                {groupedGames && dateNowYYYYMMDD && (
                  <CalendarList games={groupedGames[dateNowYYYYMMDD]} />
                )}
              </div>
            </div>
          </CardGeneral>
        </Box>
      </Grid>
    </>
  );
};

export default CalendarNBARegular;
