import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid } from '@material-ui/core';
import PlayersTab from './PlayersTab/PlayersTab';
import FutureMatch from './FutureMatch/FutureMatch';
import { LastAndNextTeamMatch, Player } from '../../../../types/soccer/team';
import moment from 'moment';
import { Competitor } from '../../../../types/soccer/summary';

const getLastAndNextMatches = (matches: LastAndNextTeamMatch) => {
  const [competitorL1, competitorL2] = matches.last.sport_event.competitors;
  const [competitorN1, competitorN2] = matches.next.sport_event.competitors;
  const getName = (competitor: Competitor) => {
    return competitor?.name.length > 15
      ? competitor?.abbreviation
      : competitor?.name;
  };

  const getLastGameWinner = () => {
    const lastGameWinnerId = matches.last.sport_event_status.winner_id;
    const lastGameWinner = matches.last.sport_event.competitors.find(
      ({ id }) => id === lastGameWinnerId,
    );
    if (!lastGameWinner) return;
    return getName(lastGameWinner);
  };

  const getLastGameScore = () => {
    const { home_score, away_score } = matches.last.sport_event_status;
    return `${home_score} : ${away_score}`;
  };

  return (
    <>
      <Grid
        item
        container
        xs={12}
        style={{ marginTop: '2rem', height: 'fit-content' }}
      >
        <FutureMatch
          matchId={matches.last.sport_event.id}
          previous
          team1Name={getName(competitorL1)}
          team2Name={getName(competitorL2)}
          team1Logo={competitorL1.icon}
          team2Logo={competitorL2.icon}
          winner={getLastGameWinner()}
          score={getLastGameScore()}
          date={moment(matches.last.sport_event.start_time)}
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        style={{ marginTop: '2rem', height: 'fit-content' }}
      >
        <FutureMatch
          matchId={matches.next.sport_event.id}
          team1Name={getName(competitorN1)}
          team2Name={getName(competitorN2)}
          team1Logo={competitorN1.icon}
          team2Logo={competitorN2.icon}
          score={'-'}
          date={moment(matches.next.sport_event.start_time)}
        />
      </Grid>
    </>
  );
};

type BottomSectionProps = {
  players: Player[];
  matches?: LastAndNextTeamMatch;
};

const BottomSection: React.FC<BottomSectionProps> = ({ players, matches }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const spacing = mobile ? 2 : 4;

  return (
    <Grid
      item
      container
      xs={12}
      sm={11}
      style={{ margin: 'auto', position: 'relative' }}
      justify="center"
      spacing={spacing}
    >
      <Grid
        container
        item
        xs={12}
        md={9}
        style={{
          paddingLeft: 0,
          height: 'fit-content',
        }}
        justify="center"
      >
        {/* <Grid item container xs={12} style={{ height: 'fit-content' }}>
          <GraphLeaguePosition />
        </Grid> */}
        <Grid
          item
          container
          xs={12}
          style={{ marginTop: '2rem', height: 'fit-content' }}
        >
          <PlayersTab players={players} />
        </Grid>
        {/*<Grid*/}
        {/*  item*/}
        {/*  container*/}
        {/*  xs={11}*/}
        {/*  style={{ marginTop: '2rem', height: 'fit-content' }}*/}
        {/*>*/}
        {/*  <TrophyTab />*/}
        {/*</Grid>*/}
      </Grid>
      <Grid
        container
        item
        xs={11}
        md={3}
        style={{
          paddingRight: 0,
          height: 'fit-content',
        }}
        justify="flex-start"
        alignItems="flex-start"
      >
        {/*<Grid*/}
        {/*  item*/}
        {/*  container*/}
        {/*  xs={12}*/}
        {/*  style={{ marginTop: mobile ? '2rem' : '0', height: 'fit-content' }}*/}
        {/*>*/}
        {/*  <ShortLeagueTab />*/}
        {/*</Grid>*/}
        {matches && getLastAndNextMatches(matches)}
      </Grid>
    </Grid>
  );
};

export default BottomSection;
