import React from 'react';
import { ListItemText } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { CompetitorRanking } from '../../../../../../types/ranking';
import medal1 from 'images/medal1.png';
import medal2 from 'images/medal2.png';
import medal3 from 'images/medal3.png';
import countryCodes from '../../../../../../utils/countryCodes.json';
import { Gender } from '../../../../../../types/pronoTennis';
import SinglesImages from './SinglesImages/SinglesImages';

type Props = {
  mobile: boolean;
  player: CompetitorRanking;
  gender: Gender;
};

const ListItemPreviewLibraryTennis: React.FC<Props> = ({
  mobile,
  player,
  gender,
}) => {
  return (
    <Grid
      container
      alignContent="center"
      style={{ justifyContent: 'center' }}
      alignItems="center"
    >
      <Grid
        container
        item
        alignContent="center"
        style={{ justifyContent: 'center' }}
        alignItems="center"
        xs={6}
      >
        <Grid item xs={1}>
          <Grid item xs={1} style={{ paddingTop: 4 }}>
            {player.rank === 1 ? (
              <img src={medal1} alt="" style={{ width: mobile ? 20 : 30 }} />
            ) : player.rank === 2 ? (
              <img src={medal2} alt="" style={{ width: mobile ? 20 : 30 }} />
            ) : player.rank === 3 ? (
              <img src={medal3} alt="" style={{ width: mobile ? 20 : 30 }} />
            ) : (
              <ListItemText primary={player.rank} className="bold" />
            )}
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <SinglesImages gender={gender} playerId={player.competitor.id} />
        </Grid>
        <Grid item xs={7}>
          <ListItemText
            style={{ marginLeft: 10 }}
            primary={`${player.competitor.name.split(',')[1]} ${
              player.competitor.name.split(',')[0]
            }`}
            className="bold"
          />
        </Grid>
      </Grid>
      {mobile ? (
        <Grid container item xs={6}>
          <Grid item xs={6}>
            <ListItemText primary={player.points} />
          </Grid>
          <Grid item xs={6}>
            <img
              width={40}
              src={`https://cdn.sportmonks.com/images/countries/png/short/${
                player.competitor.country === 'Neutral'
                  ? 'ru'
                  : countryCodes[player.competitor.country_code] ||
                    player.competitor.country_code
              }.png`}
              alt={''}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container item xs={6}>
          <Grid item xs={5}>
            <ListItemText
              primary={player.points}
              style={{ fontWeight: 'bold' }}
            />
          </Grid>
          <Grid item xs={5}>
            <img
              width={40}
              style={{ marginRight: 10 }}
              src={`https://cdn.sportmonks.com/images/countries/png/short/${
                player.competitor.country === 'Neutral'
                  ? 'ru'
                  : countryCodes[player.competitor.country_code] ||
                    player.competitor.country_code
              }.png`}
              alt={player.competitor.country}
            />
          </Grid>
          <Grid item xs={2} />
        </Grid>
      )}
    </Grid>
  );
};

export default ListItemPreviewLibraryTennis;
