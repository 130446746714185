import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box, Typography } from '@material-ui/core';
import { PlayerTeamStat } from '../../../../../types/soccer/player-team-stats';

const StatsBox = props => {
  const { value, name } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box
      maxWidth="160px"
      width="45%"
      minHeight={mobile ? 80 : 100}
      bgcolor="#f8f9fa"
      padding="1rem 1rem .7rem"
      display="flex"
      borderRadius="15px"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Typography variant="h1" component="span">
        <Box
          color={theme.palette.text.primary}
          textAlign="center"
          fontSize={35}
        >
          {value}
        </Box>
      </Typography>
      <Typography variant="body2" component="span">
        <Box
          fontWeight={700}
          color={theme.palette.text.secondary}
          style={{ textTransform: 'uppercase' }}
          textAlign="center"
        >
          {name}
        </Box>
      </Typography>
    </Box>
  );
};

const StatsRow = props => {
  const { value, name, yellowCard, redCard, noBorder, matchShow, matchHide } =
    props;
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      width="100%"
      display="flex"
      flexWrap="nowrap"
      borderBottom={!noBorder ? '1px solid #dbdfe8' : ''}
      padding={!noBorder ? '.5rem 0' : '.25rem 0 0'}
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography variant="body2" component="span">
        <Box
          fontWeight={700}
          style={{ textTransform: 'uppercase' }}
          color={theme.palette.text.secondary}
          textAlign="center"
          display="flex"
          alignItems="center"
        >
          {matchHide && (
            <Box style={{ opacity: '0' }} component="span" marginRight=".6rem">
              {t('Matchs')}
            </Box>
          )}
          {matchShow && (
            <Box
              style={{ opacity: '0.53' }}
              component="span"
              marginRight=".6rem"
            >
              {t('Matchs')}
            </Box>
          )}

          {name}

          {(yellowCard || redCard) && (
            <Box
              width="12px"
              height="16px"
              borderRadius="3px"
              bgcolor={yellowCard ? '#fffa7e' : '#e0153f'}
              marginLeft=".6rem"
              display="inline-block"
            />
          )}
        </Box>
      </Typography>
      <Typography variant="h6" component="span">
        <Box
          fontWeight={700}
          color={theme.palette.text.primary}
          textAlign="center"
        >
          {value}
        </Box>
      </Typography>
    </Box>
  );
};

type PlayerStatisticsProps = {
  playerStats: PlayerTeamStat[];
};

const PlayerStatistics: React.FC<PlayerStatisticsProps> = ({ playerStats }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const stat = useMemo(() => {
    const won = playerStats.filter(({ won }) => won === true).length;
    const lose = playerStats.filter(({ won }) => won === false).length;
    const egalite = playerStats.filter(({ won }) => won === null).length;
    const goalsScored = playerStats.reduce(
      (total, { goalsScored }) => total + goalsScored,
      0,
    );
    const assists = playerStats.reduce(
      (total, { assists }) => total + assists,
      0,
    );
    const yellowCards = playerStats.reduce(
      (total, { yellowCards }) => total + yellowCards,
      0,
    );
    const redCards = playerStats.reduce(
      (total, { redCards }) => total + redCards,
      0,
    );
    return { won, lose, egalite, goalsScored, assists, yellowCards, redCards };
  }, [playerStats]);

  return (
    <Box
      position="relative"
      width="100%"
      height="fit-content"
      display="flex"
      flexDirection="column"
      borderRadius="15px"
      overflow="hidden"
      boxShadow="0 0 23px 0 rgba(10, 22, 62, 0.1)"
      data-tour="player-stats-step"
    >
      <Box
        width="100%"
        bgcolor="#f1f3f6"
        padding={'1.25rem 1.5rem .8rem'}
        borderBottom="1px solid #dbdfe8"
      >
        <Typography variant="h6" component={'span'}>
          <Box fontWeight={600} color={theme.palette.text.secondary}>
            {t('Statistiques')} 2021-2022
          </Box>
        </Typography>
      </Box>
      <Box
        width="100%"
        bgcolor="white"
        padding="1rem"
        display="flex"
        flexWrap="nowrap"
        justifyContent="space-between"
        alignItems="center"
      >
        <StatsBox value={stat.goalsScored} name={t('Buts')} />
        <StatsBox value={stat.assists} name={t('Passes décisives')} />
      </Box>
      <Box
        width="100%"
        padding="1rem"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <StatsRow value={stat.won} matchShow noBorder name={t('Gagnés')} />
        <StatsRow value={stat.egalite} matchHide noBorder name={t('Nuls')} />
        <StatsRow value={stat.lose} matchHide name={t('Perdus')} />
        <StatsRow
          value={stat.yellowCards}
          yellowCard
          noBorder
          name={t('Cartons')}
        />
        <StatsRow value={stat.redCards} redCard noBorder name={t('Cartons')} />
      </Box>
    </Box>
  );
};

export default PlayerStatistics;
