import React from 'react';
import clsx from 'clsx';
import Navbar from '../Templates/Navbar/Navbar';
import {
  makeStyles,
  createStyles,
  useTheme,
  Typography,
  Theme,
  Box,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import '../../../theme/datasportTheme.css';
import { useTranslation } from 'react-i18next';
import Fade from '@material-ui/core/Fade';
import { useDispatch, useSelector } from 'react-redux';
import ToggleSport, { Sport } from '../Home/ToggleSport';
import LibrarySoccer from './LibrarySoccer/LibrarySoccer';
import LibraryTennis from './LibraryTennis/LibraryTennis';
import LibraryNBA from './LibraryNBA/LibraryNBA';
import LibraryNFL from './LibraryNFL/LibraryNFL';
import useLibrary from './useLibrary';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { updateUser } from 'actions/auth.action';
import ButtonFilled from '../Templates/Buttons/ButtonFilled';
import LeftNavbar from '../Templates/Navbar/LeftNavbar';
import { disableTour } from '../../../utils/reactour.utils';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: 1300,
      margin: 'auto',
      width: '100%',
      padding: '2rem',
    },
    marginBottomNegative: {
      marginBottom: '-2.5rem',
    },
    dashboard: {
      maxWidth: '100vw',
      minHeight: '100vh',
    },
    bgDark: {
      background: '#0a1437',
    },
    bgLight: {
      background: '#fcfcfa',
    },
    toggleSportContainer: {
      [theme.breakpoints.down('xs')]: {
        overflow: 'scroll',
      },
      padding: '1rem 2rem',
      background: 'rgba(25, 38, 83, .85)',
      position: 'sticky',
      top: 0,
      zIndex: 11,
      backdropFilter: 'blur(10px)',
      marginBottom: '2rem',
    },
    pageBackgroundBlue: {
      zIndex: -1,
      background: '#0a1437',
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      top: 0,
      left: 0,
    },
    pageBackgroundBlueGradient: {
      backgroundImage:
        'linear-gradient(108deg, #6d7fff -1%, rgba(84, 114, 255, 0.05) 46%, rgba(84, 114, 255, 0.05) 46%)',
      zIndex: 5,
      opacity: 0.36,
      content: '""',
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      top: 0,
      left: 0,
      pointerEvents: 'none',
    },
    appBarShift: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: `calc(100% - ${theme.spacing(7) + 1}px)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${theme.spacing(9) + 1}px)`,
      },
    },
    appBarShiftOpen: {
      width: `calc(100% - ${drawerWidth}px)`,
      '& > div': {
        width: '100%',
        margin: 0,
      },
    },
    appBarShiftMobile: {
      width: '100%',
    },
  }),
);

export default function Library() {
  const classes = useStyles();
  const theme = useTheme();
  const [isTourOpen, setIsTourOpen] = React.useState(false);
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const mediumComputer = useMediaQuery('(max-width:1300px)');
  const spacing = mobile ? 2 : 6;
  const { t, i18n } = useTranslation();
  const selectedSport: Sport = useSelector(
    (state: any) => state.sportsReducer.selectedSport,
  );
  const user = useSelector((state: any) => state.authReducer.user);
  const [info, setInfo] = React.useState<any>({ ...user });
  const dispatch = useDispatch();
  const { soccerTournaments } = useLibrary();
  const language = i18n.language.toUpperCase();
  const [open, setOpen] = React.useState(true);

  const disableBody = target => disableBodyScroll(target);
  const enableBody = target => enableBodyScroll(target);

  const stepsDatabase = [
    {
      selector: '[data-tour="database-input-step"]',
      highlightedSelectors: ['[data-tour="database-players-step"]'],
      resizeObservables: ['[data-tour="database-players-parent-step"]'],
      observe: ['[data-tour="database-players-parent-step"]'],
      content: t(
        "Renseignez le nom d'une équipe ou d'un joueur et cliquez sur le bouton '+' pour voir toutes ses statistiques.",
      ),
      scrollDuration: 5,
      stepInteraction: true,
    },
  ];

  const testFirstConnexion = async () => {
    setIsTourOpen(true);
    info['isFirstConnexion']['database'] = false;
    const payload = {
      ...info,
    };
    setInfo({ ...info });
    await dispatch(updateUser(payload));
  };

  React.useEffect(() => {
    if (user.isFirstConnexion?.database === true) {
      testFirstConnexion();
    }
  }, [testFirstConnexion]);

  return (
    <React.Fragment>
      <Box className={clsx(classes.dashboard, classes.bgDark)}>
        <Fade in timeout={500}>
          <>
            <div className={classes.pageBackgroundBlue} />
            <div className={classes.pageBackgroundBlueGradient} />
            {mobile && <Navbar noRadius transparent collapsed />}
            <Box display="flex">
              {!mobile && <LeftNavbar open={open} setOpen={setOpen} />}
              <Box
                className={clsx(classes.appBarShift, {
                  [classes.appBarShiftOpen]: open,
                  [classes.appBarShiftMobile]: mobile,
                })}
              >
                <Grid container>
                  <Grid item xs={12} className={classes.toggleSportContainer}>
                    <ToggleSport
                      selectedSport={selectedSport}
                      onChangeSport={(newSport: Sport) =>
                        dispatch({
                          type: 'CHANGE_SPORT',
                          payload: { newSport },
                        })
                      }
                      sportsToHide={['NHL', 'BASEBALL']}
                    />
                  </Grid>
                  {isTourOpen || selectedSport === 'FOOTBALL' ? (
                    <>
                      <LibrarySoccer
                        mediumComputer={mediumComputer}
                        mobile={mobile}
                        language={language}
                        tournaments={soccerTournaments}
                        isTourOpen={isTourOpen}
                      />
                      <Tour
                        steps={stepsDatabase}
                        isOpen={isTourOpen}
                        onRequestClose={e =>
                          disableTour(
                            info,
                            setIsTourOpen,
                            dispatch,
                            updateUser,
                            e,
                          )
                        }
                        rounded={5}
                        maskSpace={15}
                        onAfterOpen={disableBody}
                        onBeforeClose={enableBody}
                      />
                    </>
                  ) : selectedSport === 'TENNIS' ? (
                    <LibraryTennis mediumComputer={mediumComputer} />
                  ) : selectedSport === 'NBA' ? (
                    <LibraryNBA mediumComputer={mediumComputer} />
                  ) : (
                    selectedSport === 'NFL' && (
                      <LibraryNFL mediumComputer={mediumComputer} />
                    )
                  )}
                </Grid>
              </Box>
            </Box>
          </>
        </Fade>
      </Box>
    </React.Fragment>
  );
}
