import React, { useCallback } from 'react';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { Fire } from 'services';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import soccerImage from 'images/football.png';
import tennisImage from 'images/tennis.png';
import basketballImage from 'images/basketball.png';
import nflImage from 'images/nfl.png';
import hockeyImage from 'images/hockey.png';
import baseballImage from 'images/baseball.png';
import {
  getHideUntilPronosFiltered,
  getValidPronosFiltered,
} from '../../../utils/pronos';
export type Sport = 'FOOTBALL' | 'TENNIS' | 'NBA' | 'NFL' | 'NHL' | 'BASEBALL';

export type ToggleSportProps = {
  selectedSport: Sport;
  onChangeSport: (newSport: Sport) => void;
  blue?: boolean;
  center?: boolean;
  sportsToHide?: Sport[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    switchContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'space-between',
        minWidth: 500,
      },
    },
    sportButton: {
      marginRight: '1rem',
      padding: '.5rem 1rem',
      border: 'none',
      borderRadius: '10px !important',
      transition: 'all .2s ease-in-out',

      '&.Mui-selected, &:hover, &.Mui-selected:hover': {
        background: 'rgb(0 10 44 / 85%)',
      },
    },
    buttonHover: {
      transition: 'opacity 0.2s ease-in-out',
    },
    bgBlue: {
      background: '#0a1437',
    },
  }),
);

const SportCircle = ({
  sport,
  image,
  containsPronos,
  containsPronosHideUntil,
}) => {
  const { t } = useTranslation();
  const mobile = useMediaQuery(useTheme().breakpoints.down('xs'));
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box className={clsx(classes.buttonHover)}>
        <Box
          borderRadius="50%"
          width={50}
          height={50}
          style={{ border: '2px solid #FFFA7E' }}
          padding=".25rem"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            style={{ background: 'rgba(255, 255, 255, .2)' }}
            borderRadius="50%"
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding=".5rem"
          >
            <img src={image} style={{ width: '100%', height: '100%' }} />
          </Box>
        </Box>
        {containsPronosHideUntil > 0 && (
          <Box
            style={{
              backgroundColor: 'rgb(154, 154, 154)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
              aspectRatio: '1/1',
              width: mobile ? '15px' : '17px',
              height: mobile ? '15px' : '17px',
              position: 'absolute',
              top: mobile ? '40px' : '42px',
              right: mobile ? '10px' : '12px',
            }}
          >
            <Typography
              style={{
                color: theme.palette.primary.main,
                borderRadius: '50%',
                fontWeight: 'bold',
                lineHeight: 0,
                fontSize: '.7rem',
              }}
              variant="body1"
            >
              {containsPronosHideUntil}
            </Typography>
          </Box>
        )}
        {containsPronos > 0 && (
          <Box
            style={{
              backgroundColor: theme.palette.secondary.main,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
              aspectRatio: '1/1',
              width: mobile ? '18px' : '20px',
              height: mobile ? '18px' : '20px',
              position: 'absolute',
              top: mobile ? '3px' : '5px',
              right: mobile ? '8px' : '10px',
            }}
          >
            <Typography
              style={{
                color: theme.palette.primary.main,
                borderRadius: '50%',
                fontWeight: 'bold',
                lineHeight: 0,
                fontSize: '.8rem',
              }}
              variant="body1"
            >
              {containsPronos}
            </Typography>
          </Box>
        )}
      </Box>
      <Typography
        variant="body1"
        style={{
          color: 'white',
          marginTop: '.5rem',
          fontWeight: 500,
          textTransform: 'capitalize',
        }}
      >
        {t(sport)}
      </Typography>
    </Box>
  );
};

const ToggleSport: React.FC<ToggleSportProps> = ({
  selectedSport,
  onChangeSport,
  blue,
  center,
  sportsToHide = [],
}) => {
  const classes = useStyles();
  const listedPronos = useSelector((state: any) => state.pronosReducer);
  const user = useSelector((state: any) => state.authReducer.user);
  const [isProUser, setIsProUser] = React.useState<boolean>(false);

  const verifyIsProUser = async () => {
    const ref = Fire.store()
      .collection('pros')
      .where('email', '==', user.email);
    const list = await Fire.list(ref);
    setIsProUser(!!list.length);
  };

  React.useEffect(() => {
    verifyIsProUser();
  }, []);

  const sports = [
    {
      value: 'FOOTBALL',
      name: 'Football',
      image: soccerImage,
    },
    {
      value: 'TENNIS',
      name: 'Tennis',
      image: tennisImage,
    },
    {
      value: 'NBA',
      name: 'NBA',
      image: basketballImage,
    },
    {
      value: 'NFL',
      name: 'NFL',
      image: nflImage,
    },
    {
      value: 'NHL',
      name: 'NHL',
      image: hockeyImage,
    },
    // {
    //   value: 'BASEBALL',
    //   name: 'Baseball',
    //   image: baseballImage,
    // },
  ];

  return (
    <ToggleButtonGroup
      value={selectedSport}
      exclusive
      onChange={(_, v) => v && onChangeSport(v)}
      size="small"
      style={{
        display: 'flex',
        justifyContent: center ? 'center' : 'flex-start',
      }}
      className={clsx(blue && classes.bgBlue, classes.switchContainer)}
      data-tour="toggle-sport-element"
    >
      {sports.map(
        sport =>
          !sportsToHide.includes(sport.value as Sport) && (
            <ToggleButton
              value={sport.value}
              aria-label={sport.name}
              className={classes.sportButton}
              key={sport.value}
            >
              <SportCircle
                sport={sport.name}
                image={sport.image}
                containsPronos={
                  getValidPronosFiltered(
                    listedPronos[sport.value],
                    sport.value === 'FOOTBALL',
                    isProUser,
                  ).length
                }
                containsPronosHideUntil={
                  getHideUntilPronosFiltered(
                    listedPronos[sport.value],
                    sport.value === 'FOOTBALL',
                    isProUser,
                  ).length
                }
              />
            </ToggleButton>
          ),
      )}
    </ToggleButtonGroup>
  );
};

export default ToggleSport;
