import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import DoublesImages from '../DoublesImages/DoublesImages';
import React from 'react';
import { Match } from '../../../../../types/pronoNHL';
import { getNHLLogoURL } from '../../../../../utils/nhl';
import Logo from '../../../Templates/Logos/Logo';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rowMatch: {
      borderRadius: 10,
      cursor: 'pointer',
      transition: 'all .2s ease-out',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  }),
);

const OtherPronoTeamRow: React.FC<{ match: Match }> = ({ match }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container item xs={12}>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        color="white"
        className={classes.rowMatch}
      >
        <Grid
          container
          item
          xs={12}
          justifyContent="space-around"
          style={{ marginBottom: '.75rem' }}
        >
          <Grid container item xs={5} alignItems="center" justify="center">
            <Logo
              src={getNHLLogoURL(match.value.home?.id)}
              width={40}
              typeImgDefault="club"
              imgDefautl="NHL"
            />
          </Grid>
          <Grid container item xs={5} alignItems="center" justify="center">
            <Logo
              src={getNHLLogoURL(match.value.away?.id)}
              width={40}
              typeImgDefault="club"
              imgDefautl="NHL"
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} justify="space-around">
          <Grid container item xs={5} alignItems="flex-start" justify="center">
            <Box fontWeight={600} textAlign="center">
              <Typography variant="body2">
                {t(match.value.home.name)}
              </Typography>
            </Box>
          </Grid>
          <Grid container item xs={5} alignItems="flex-start" justify="center">
            <Box fontWeight={600} textAlign="center">
              <Typography variant="body2">
                {t(match.value.away.name)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* <Grid container item xs={4} alignItems="center" justify="flex-start">
        <Box fontWeight={600}>
          <Typography variant="body2">{match.value.home.name}</Typography>
        </Box>
      </Grid>
       <Grid item xs={4}>
        <DoublesImages
          srcHome={getNHLLogoURL(match.value.home?.id)}
          srcAway={getNHLLogoURL(match.value.away?.id)}
          typeImgDefault="club"
          imgDefautl="NHL"
        />
      </Grid>
      <Grid container item xs={4} alignItems="center" justify="flex-end">
        <Box fontWeight={600} textAlign="end">
          <Typography variant="body2">{match.value.away.name}</Typography>
        </Box>
      </Grid> */}
    </Grid>
  );
};

export default OtherPronoTeamRow;
