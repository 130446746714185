import { isTooLong } from './logic';
import {
  matchTypeValueType,
  matchTypesType,
  matchTypes,
  matchTypesWithNames,
} from '../types/pronoLibre';

export const getInfosPronoLibre = (props, t) => {
  const matchType: matchTypesType = props.matchType;
  const matchTypeValue: matchTypeValueType = props.matchTypeValue;
  const player =
    matchType === 'byScorer' || matchType === 'byPerf'
      ? props.players
        ? props.players.filter(
            player => player.value.sr_id === matchTypeValue?.name,
          )[0]?.label
        : matchTypeValue?.name
      : null;

  const pronoPercentage: number = parseInt(
    matchType === 'byWin' || matchType === 'byResult'
      ? props.teamResults?.winner
      : matchTypeValue?.percentage,
  );

  const teamName =
    (matchType === 'byWin' || matchType === 'byResult') && props.winner
      ? isTooLong(props.winner.value.name)
        ? props.winner.value.alias
        : props.winner.value.name
      : matchType === 'byScorer'
      ? player
      : matchType === 'byPerf'
      ? `${t('Performance de')} ${player} : ${t('plus de')}`
      : matchType === 'byDifference'
      ? props.match.value.home.sr_id === matchTypeValue?.name
        ? props.match.value.home.name
        : props.match.value.away.name
      : '';

  const pronoName: string =
    matchType === 'byWin' || matchType === 'byResult'
      ? t(matchTypes[matchType]) + t(teamName)
      : t(teamName || '') +
        ' ' +
        (matchType === 'byDifference'
          ? matchTypeValue?.pts && matchTypeValue.pts < 0
            ? t('gagne de')
            : t('gagne le match ou perd de')
          : t(matchTypes[matchType])) +
        (matchType === 'byMorePts' ||
        matchType === 'byLessPts' ||
        matchType === 'byScorer' ||
        matchType === 'byPerf' ||
        matchType === 'byDifference'
          ? ` ${
              matchType === 'byDifference'
                ? matchTypeValue &&
                  matchTypeValue.pts &&
                  matchTypeValue.pts <= 0
                  ? -matchTypeValue.pts
                  : matchTypeValue?.pts
                : matchTypeValue?.pts
            } ${t('points')} ${
              matchType === 'byPerf'
                ? t('+ rebonds + passes')
                : matchType === 'byDifference'
                ? matchTypeValue?.pts && matchTypeValue?.pts < 0
                  ? t('ou +')
                  : t("d'écart ou -")
                : ''
            }`
          : '');
  const pronoNameWithPercentage: string =
    pronoName + ' ' + pronoPercentage + '%';

  return {
    pronoName,
    pronoNameWithPercentage,
    pronoPercentage,
  };
};
