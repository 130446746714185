import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import RowMatch from './RowMatch';
import { PlayerTeamStat } from '../../../../../types/soccer/player-team-stats';
import moment from 'moment';

type MatchsHistoryProps = {
  playerStats: PlayerTeamStat[];
};

const MatchsHistory: React.FC<MatchsHistoryProps> = ({ playerStats }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      position="relative"
      width="100%"
      display="flex"
      flexDirection="column"
      borderRadius="15px"
      overflow="hidden"
      boxShadow="0 0 23px 0 rgba(10, 22, 62, 0.1)"
      data-tour="player-historique-step"
    >
      <Box
        width="100%"
        bgcolor="#f1f3f6"
        padding="1.25rem 1.5rem .8rem"
        borderBottom="1px solid #dbdfe8"
      >
        <Typography variant="h6" component={'span'}>
          <Box fontWeight={600} color={theme.palette.text.secondary}>
            {t('Historique des matchs')}
          </Box>
        </Typography>
      </Box>
      <Box width="100%" height="100%" bgcolor="white" padding="1rem">
        <RowMatch isHeader />
        {playerStats.length > 0 ? (
          playerStats.map((stat, i) => (
            <RowMatch
              matchId={stat.id}
              date={moment(stat.date)}
              domicile={stat.dom}
              ext={stat.ext}
              domicileLogo={stat.domLogo}
              extLogo={stat.extLogo}
              score={stat.score}
              goal={stat.goalsScored}
              yellowCard={stat.yellowCards}
              redCard={stat.redCards}
              bgGrey={i % 2 === 0}
            />
          ))
        ) : (
          <Box
            height="100%"
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h5" color="textSecondary">
              {t("Ce joueur n'a joué aucun match durant cette saison")}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MatchsHistory;
