import React from 'react';
import './ExternPaymentLoading.scss';
import datasport_logo from '../../../../images/logo_datasport.png';
import { useTranslation } from 'react-i18next';

type Props = {
  nobg?: boolean;
};

function ExternPaymentLoading(props: Props) {
  const { t } = useTranslation();

  return (
    <div className={!props.nobg ? 'loader_background' : 'loader_no-background'}>
      <img
        src={datasport_logo}
        alt="Data-Sport logo"
        className="logo_loading"
      />
      <div className="loader" />
      <div
        style={{
          position: 'absolute',
          marginTop: '300px',
          color: 'antiquewhite',
          fontSize: '17px',
        }}
      >
        {t('Inscription en cours... Ne fermez pas cette fenêtre.')}
      </div>
    </div>
  );
}

export default ExternPaymentLoading;
