import React, { useState, useEffect, useCallback } from 'react';
import Review from './Review';
import { Grid } from '@material-ui/core';
import { makeStyles, useTheme, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Navbar from '../Dashboard/Templates/Navbar/Navbar';
import NavbarHome from '../Home/NavbarHome/NavbarHome';
import Fade from '@material-ui/core/Fade';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import PreviewTitle from '../Dashboard/Templates/Misc/PreviewTitle';
import { Fire } from 'services';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles({
  reviews: {
    background: '#fcfcfa',
    maxWidth: '100vw',
    minHeight: '100vh',
  },
  container: {
    padding: '0 10px',
    maxWidth: 1300,
    margin: 'auto',
    width: '100%',
  },
  reviewsContainer: {
    marginRight: '0 !important',
    marginLeft: '0 !important',
    marginTop: '.5rem',
    padding: '0 !important',
  },
  paragraph: {
    fontSize: '1.3rem',
    fontWeight: 500,
    margin: '1rem 0 1.3rem',
    color: '#3c4045',
  },
});

export default function ReviewScreen() {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const user = useSelector((state: any) => state.authReducer.user);
  const spacing = mobile ? 3 : 5;
  const { t, i18n } = useTranslation();
  const [reviews, setReviews] = useState<any>(null);

  const language = i18n.language;

  const getReviews = useCallback(async () => {
    const ref = Fire.store()
      .collection('press')
      .where('language', '==', language);
    const newReviews = await Fire.list(ref);
    newReviews.sort((a, b) => {
      if (!a.order && !b.order) return 0;
      if (!a.order) return 1;
      if (!b.order) return -1;
      return a.order - b.order;
    });
    setReviews(newReviews);
  }, [language]);

  useEffect(() => {
    getReviews();
  }, [getReviews]);

  return (
    <div className={classes.reviews}>
      <Helmet
        script={[
          helmetJsonLdProp({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            name: 'data-sport',
            url: 'https://data-sport.com/',
            sameAs: [
              'https://twitter.com/DatawinP',
              'https://www.facebook.com/Datawin-pronos-110499897757925',
            ],
          }),
        ]}
      />
      <Fade in timeout={500}>
        <>
          {!user && <NavbarHome blue />}
          <Grid container spacing={spacing} className={classes.container}>
            {user && (
              <Grid item xs={12}>
                <Navbar />
              </Grid>
            )}
            <Grid
              item
              container
              xs={12}
              spacing={spacing}
              className={classes.reviewsContainer}
            >
              <Grid item xs={12}>
                <PreviewTitle
                  typography="h1"
                  title={t('On parle de nous dans la presse')}
                />
                <p className={classes.paragraph}>
                  {t(
                    'Data-Sport est dans la presse, de Forbes à Fintech News, en passant par Le Point et Génération NT. Les médias s’intéressent de près à notre algorithme de prédiction de paris sportifs et les résultats qu’il permet d’obtenir.',
                  )}
                </p>
                <PreviewTitle
                  typography="h2"
                  title={t(
                    'La combinaison de l’IA et du machine learning au service des paris sportifs',
                  )}
                  fontWeight={600}
                />
                <p className={classes.paragraph}>
                  {t(
                    'Data-Sport, c’est un an et demi de développement par une équipe de data scientists triée sur le volet, et des tests exhaustifs de prédiction. Avant d’arriver à la version commercialisable de l’algorithme, de nombreux tests ont été menés. Plus concrètement, plusieurs tests ont été réalisés sur une série de 1 500 rencontres de football, ce qui a permis d’obtenir un impressionnant taux de prédictions exactes de78% ! Pour des raisons évidentes de confidentialité, nous ne pouvons révéler la « recette » exacte de notre algorithme. Ce que nous pouvons vous dire, c’est que nous sommes le seul site qui propose une solution de pronostics sportifs basés sur l’intelligence artificielle et le machine learning, capable d’atteindre un taux de 78% de bons pronostics, ou de prédictions comme on préfère les appeler, sur 1 500 matchs de football. ',
                  )}
                </p>
                <PreviewTitle
                  typography="h2"
                  title={t('Faire gagner l’utilisateur sur le long terme')}
                  fontWeight={600}
                />
                <p className={classes.paragraph}>
                  {t(
                    'Notre objectif est de faire gagner l’utilisateur sur le long terme, en allant chercher des cotes peu extravagantes. Data-Sport propose des prédictions sûres et sécurisées, en excluant les paris combinés pour se concentrer davantage sur le résultat simple d’un match (victoire ou match nul), pour des cotes allant de 1.50 à 1.70. Nous ne vous promettons pas que vous allez devenir riche, mais plutôt d’avoir un complément de salaire intéressant, dont le montant dépend de la mise engagée. Onestime que  vous pouvez gagner entre 300 et 400 euros par mois, si vous suivez lesprédictions de l’algorithme qui, pour chaque rencontre, vous propose les meilleures cotes du marché. Rappelons enfin que Data-Sport a signé une charte avec l’autorité derégulation des jeux en ligne, l’ARJEL, pour proposer uniquement des bookmakers agrémentés.',
                  )}
                </p>
                <PreviewTitle
                  typography="h2"
                  title={t('La presse s’intéresse à Data-Sport')}
                  fontWeight={600}
                />
                <p className={classes.paragraph}>
                  {t(
                    '« L’IA défie l’industrie des paris sportifs » (Forbes), « Quand l’IA et le machine learning s’attaquent aux paris sportifs » (Le Point), « Cette nouvelle intelligence artificielle révolutionne les paris sportifs » (Fintech News), « L’intelligence artificielleremplacera-t-elle bientôt l’homme pour réaliser les paris sportifs ? »(Génération NT)... Aujourd’hui Data-Sport est plutôt bien accueilli dans la presse, et pour cause ! Nous sommes aujourd’hui le seul site français qui propose une solution de paris sportifs basée sur l’IA et le machine learning, tirant plein profit de ces nouvelles technologies pour garantir des résultats. Découvrez les articles de presse sur Data-Sport ci-dessous. Bonne lecture !',
                  )}
                </p>
              </Grid>
              {reviews?.map(review => {
                return (
                  <Grid item xs={12} sm={6} md={4} key={review.id}>
                    <Review {...review} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </>
      </Fade>
    </div>
  );
}
