import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box, Typography } from '@material-ui/core';
import { Moment } from 'moment';
import Score from '../../../Templates/Misc/Score';
import Logo from '../../../Templates/Logos/Logo';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  card: {
    transition: 'all .2s ease-in-out',

    '&:hover': {
      boxShadow: '5px 5px 23px 0 rgba(10, 22, 62, 0.25)',
    },
  },
  outBox: {
    background: 'linear-gradient(256deg, #68ead1, #67c3e6 51%, #68bdf1 0%)',
    borderRadius: 10,
    height: '100%',
    width: 'fit-content',
  },
}));

type FutureMatchProps = {
  previous?: boolean;
  matchId: string;
  team1Name: string;
  team2Name: string;
  team1Logo: string;
  team2Logo: string;
  winner?: string;
  score: string;
  date: Moment;
};

const FutureMatch: React.FC<FutureMatchProps> = ({
  previous = false,
  matchId,
  team1Name,
  team2Name,
  team1Logo,
  team2Logo,
  winner,
  score,
  date,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Box
      position="relative"
      width="100%"
      display="flex"
      flexDirection="column"
      borderRadius="15px"
      overflow="hidden"
      boxShadow="0 0 23px 0 rgba(10, 22, 62, 0.1)"
      className={classes.card}
      style={{ cursor: previous ? 'pointer' : 'default' }}
      data-testid={previous ? 'previousMatch' : 'nextMatch'}
      onClick={() => {
        previous && history.push(`/match/${matchId}`);
      }}
    >
      <Box
        width="100%"
        bgcolor="#f1f3f6"
        padding="1.25rem 1.5rem .8rem"
        borderBottom="1px solid #dbdfe8"
      >
        <Typography variant="h6" component={'span'}>
          <Box
            data-testid="typeMatch"
            fontWeight={600}
            color={theme.palette.text.secondary}
          >
            {previous ? t('Dernier match') : t('Prochain match')}
          </Box>
        </Typography>
      </Box>
      <Box width="100%" bgcolor="white" padding="1rem 1rem 2rem">
        <Box
          display="flex"
          justifyContent="space-between"
          marginBottom={previous ? '0.5rem' : '1.25rem'}
        >
          <Typography variant="body1" component={'span'}>
            <Box
              data-testid="dateMatch"
              color={theme.palette.text.secondary}
              fontWeight={600}
            >
              {date.calendar('LL')}
            </Box>
          </Typography>
          <Typography variant="body1" component={'span'}>
            <Box
              data-testid="hourMatch"
              color={theme.palette.text.secondary}
              fontWeight={600}
            >
              {date.format('LT')}
            </Box>
          </Typography>
        </Box>
        {winner && (
          <Box
            width="100%"
            padding={'0.75rem 0'}
            display="flex"
            alignItems="center"
            justifyContent={'center'}
            marginBottom={'0.5rem'}
            data-testid="winnerMatch"
          >
            <div className={classes.outBox}>
              <Box
                width="fit-content"
                fontWeight="bold"
                style={{
                  backgroundColor: 'white',
                  backgroundClip: 'padding-box',
                }}
                borderRadius="10px"
                border="2px solid transparent"
                display="flex"
                justifyContent="center"
                alignItems="center"
                color={theme.palette.text.primary}
                padding={'.25rem 1rem'}
                boxShadow="0 0 18px 0 rgb(138 225 255 / 20%), inset 0 0 18px 0 rgb(138 225 255 / 15%)"
                textAlign="center"
              >
                <Typography variant={mobile ? 'h4' : 'h6'}>
                  {t('Victoire')} {winner}
                </Typography>
              </Box>
            </div>
          </Box>
        )}
        <Box
          width="100%"
          display="flex"
          justifyContent="space-around"
          textAlign="center"
        >
          <Box
            width="30%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Logo
              src={team1Logo}
              width={mobile ? 45 : 55}
              club
              typeImgDefault="club"
              imgDefautl="FOOTBALL"
            />
            <Typography
              variant="body1"
              component={'span'}
              style={{ marginTop: 10 }}
            >
              <Box
                color={theme.palette.text.secondary}
                fontWeight={600}
                data-testid="team1Name"
              >
                {team1Name}
              </Box>
            </Typography>
          </Box>
          <Box
            width="30%"
            display="flex"
            justifyContent="center"
            marginTop={'15px'}
            data-testid="scoreMatch"
          >
            <Score score={score} sidePadding medium={!mobile} big={mobile} />
          </Box>
          <Box
            width="30%"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Logo
              src={team2Logo}
              width={mobile ? 45 : 55}
              club
              typeImgDefault="club"
              imgDefautl="FOOTBALL"
            />
            <Typography
              variant="body1"
              component={'span'}
              style={{ marginTop: 10 }}
            >
              <Box
                color={theme.palette.text.secondary}
                fontWeight={600}
                data-testid="team2Name"
              >
                {team2Name}
              </Box>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FutureMatch;
