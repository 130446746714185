import React from 'react';
import {
  Grid,
  ListItem,
  ListItemText,
  Theme,
  Typography,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Conference,
  Playoffs,
  SerieTeam,
} from '../../../../../../../types/sportradar/NBA/playoffs';
import NBALogo from '../../../../../Templates/NBALogo/NBALogo';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    singleCard: {
      backgroundColor: 'rgba(38, 52, 112, 1)',
      margin: '.625rem 0',
      padding: '.75rem 1rem',
      borderRadius: '15px',
      border: 'none',
      boxShadow: 'none',
      minHeight: 'unset',
      minWidth: '100%',
      '&> *': {
        fontWeight: 500,
        textAlign: 'center',
      },
      '&:hover': {
        backgroundColor: '#1d2a64',
      },
    },
    countryImg: {
      display: 'flex',
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        height: 30,
        width: 30,
      },
    },
  }),
);

const ListHeader: React.FC<{ name: string; bigTitle?: boolean }> = ({
  name,
  bigTitle = false,
}) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      item
      xs={12}
      direction="row"
      alignContent="center"
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <ListItemText
          disableTypography
          primary={
            // @ts-ignore
            <Typography style={{ fontSize: bigTitle ? '2rem' : '1rem' }}>
              {t(name)}
            </Typography>
          }
          className="bold"
        />
      </Grid>
    </Grid>
  );
};

type PlayoffsListProps = {
  playoffs: Playoffs;
};

const PlayoffsList: React.FC<PlayoffsListProps> = ({ playoffs }) => {
  const classes = useStyles();
  return (
    <>
      {Object.entries(playoffs).map(
        ([conference, rounds]: [string, Conference]) => (
          <div style={{ marginTop: 15, marginBottom: 50 }}>
            <ListHeader name={conference} bigTitle />
            {Object.entries(rounds).map(
              ([round, series]: [string, [SerieTeam, SerieTeam][]]) => (
                <>
                  <ListHeader name={round} />
                  {series.map(serie => (
                    <ListItem component={Card} className={classes.singleCard}>
                      <Grid
                        container
                        item
                        xs={12}
                        alignContent="center"
                        justify="center"
                        alignItems="center"
                      >
                        <Grid
                          container
                          item
                          alignContent="center"
                          justify={'center'}
                          alignItems="center"
                          xs={2}
                        >
                          <Typography variant={'h6'}>
                            {serie[0].team.name}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          alignContent="center"
                          justify="center"
                          alignItems="center"
                          xs={2}
                        >
                          <NBALogo src={serie[0].team.icon} />
                        </Grid>
                        <Grid
                          container
                          item
                          alignContent="center"
                          justify="center"
                          alignItems="center"
                          xs={4}
                        >
                          <Box
                            width="max-content"
                            height="100%"
                            borderRadius="1rem"
                            bgcolor="#e9edf6"
                            fontWeight="bold"
                            fontFamily="AvenirNext"
                            padding=".3rem 1rem"
                            fontSize="1.25rem"
                          >
                            {serie[0].record} - {serie[1].record}
                          </Box>
                        </Grid>
                        <Grid
                          container
                          item
                          alignContent="center"
                          justify="center"
                          alignItems="center"
                          xs={2}
                        >
                          <NBALogo src={serie[1].team.icon} />
                        </Grid>
                        <Grid
                          container
                          item
                          alignContent="center"
                          justify={'center'}
                          alignItems="center"
                          xs={2}
                        >
                          <Typography variant={'h6'}>
                            {serie[1].team.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                  ))}
                </>
              ),
            )}
          </div>
        ),
      )}
    </>
  );
};

export default PlayoffsList;
