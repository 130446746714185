import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { Grid, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import SportsSoccerSharp from '@material-ui/icons/SportsSoccerSharp';
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import '../../../../../theme/datasportTheme.css';
import medal1 from 'images/medal1.png';
import medal2 from 'images/medal2.png';
import medal3 from 'images/medal3.png';
import { useHistory } from 'react-router-dom';
import Logo from '../../../Templates/Logos/Logo';
import ButtonFilled from '../../../Templates/Buttons/ButtonFilled';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: 'unset',
      padding: '10px 25px',
      [theme.breakpoints.down('sm')]: {
        padding: 5,
        width: 'calc(100% + 40px)',
        transform: 'translate(-20px)',
        margin: 0,
      },
      '&> *': {
        textAlign: 'center',
      },

      '& > div > div': {
        [theme.breakpoints.down('sm')]: {
          padding: '5px !important',
        },
      },
    },
    singleCard: {
      backgroundColor: 'rgba(38, 52, 112, 1)',
      margin: '.625rem 0',
      padding: '1rem',
      borderRadius: '15px',
      border: 'none',
      boxShadow: 'none',
      minHeight: 'unset',
      minWidth: '100%',
      [theme.breakpoints.down('sm')]: {
        padding: '1rem',
      },
      '&> *': {
        fontWeight: 500,
        textAlign: 'center',
      },
      '&:hover': {
        backgroundColor: '#1d2a64',
      },
    },
    headerItems: {
      backgroundColor: 'transparent',
      [theme.breakpoints.down('sm')]: {
        padding: '10px 2rem !important',
      },
    },
    img: {
      borderRadius: '50px',
      [theme.breakpoints.down('sm')]: {
        width: 20,
        height: 20,
      },
    },
    button: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '13px',
      borderBottomRightRadius: '18px',

      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
        boxShadow: '0 12px 23px 0 rgb(10 22 62 / 16 %)',
      },
    },
  }),
);

interface Props {
  isHeader?: boolean;
  team?: any;
}

export default function RowTeam(props: Props) {
  const { team, isHeader } = props;
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <ListItem
      component={Card}
      className={clsx(
        isHeader ? (classes.card, classes.headerItems) : classes.singleCard,
      )}
      style={{ cursor: isHeader ? 'default' : 'pointer' }}
      onClick={() => {
        !isHeader && history.push(`/team/${team.teamId}`);
      }}
    >
      <Grid
        container
        item
        xs={12}
        alignContent="center"
        justify="center"
        alignItems="center"
      >
        <Grid
          container
          item
          alignContent="center"
          justify="center"
          alignItems="center"
          xs={mobile ? 6 : 3}
        >
          {isHeader ? (
            ''
          ) : (
            <>
              <Grid item xs={3}>
                {team.position === 1 ? (
                  <img alt="" src={medal1} />
                ) : team.position === 2 ? (
                  <img alt="" src={medal2} />
                ) : team.position === 3 ? (
                  <img alt="" src={medal3} />
                ) : (
                  <Typography variant="h4" component={'span'}>
                    <Box color={theme.palette.text.primary} fontWeight={600}>
                      {team.position}
                    </Box>
                  </Typography>
                )}
              </Grid>
              <Grid item xs={4}>
                <Logo
                  src={team.logoPath}
                  width={45}
                  alt={team.teamName}
                  noBg
                  imgHeight="90%"
                  club
                  typeImgDefault="club"
                  imgDefautl="FOOTBALL"
                />
              </Grid>
              <Grid item xs={5}>
                <Box textAlign="left">
                  <ListItemText primary={t(team.teamName)} className="bold" />
                </Box>
              </Grid>
            </>
          )}
        </Grid>
        {mobile ? (
          <Grid container item xs={6} justify="center" alignItems="center">
            <Grid item xs={5}>
              <Typography variant="h5" component={'span'}>
                <Box
                  color={
                    isHeader
                      ? theme.palette.text.primary
                      : theme.palette.text.secondary
                  }
                  fontWeight={isHeader ? 700 : 600}
                >
                  {isHeader ? t('Points') : team.totalPoints}
                </Box>
              </Typography>
            </Grid>
            <Grid container item xs={7} alignItems="center" justify="center">
              {isHeader ? (
                <Box width="100%" justifyContent="center" textAlign="center">
                  <Typography variant="h5" component={'span'}>
                    <Box
                      display="flex"
                      textAlign="center"
                      justifyContent="center"
                      fontSize="1.1rem"
                    >
                      <Box color="#68daa8" width="25%">
                        {t('G.')}
                      </Box>
                      <Box color="#848ca5" width="25%">
                        {t('N.')}
                      </Box>
                      <Box color="#e0153f" width="25%">
                        {t('P.')}
                      </Box>
                    </Box>
                  </Typography>
                </Box>
              ) : (
                team.latestData.map(
                  (winnerTeamId, index) =>
                    index < 5 && (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {winnerTeamId === null ? (
                          <FiberManualRecordIcon
                            fontSize="small"
                            style={{ color: '#848ca5' }}
                          />
                        ) : winnerTeamId === team.actualTeamId ? (
                          <FiberManualRecordIcon
                            fontSize="small"
                            style={{ color: '#68daa8' }}
                          />
                        ) : (
                          <FiberManualRecordIcon
                            fontSize="small"
                            style={{ color: '#e0153f' }}
                          />
                        )}
                      </Box>
                    ),
                )
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid container item xs={9} alignItems="center">
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
              {isHeader ? (
                <ListItemText
                  primary={t('Points')}
                  style={{ fontWeight: 'bold' }}
                />
              ) : (
                <ListItemText
                  primary={team.totalPoints}
                  style={{ fontWeight: 'bold' }}
                />
              )}
            </Grid>
            <Grid item xs={1}>
              {isHeader ? (
                <ListItemText
                  primary={t('Joués')}
                  style={{ fontWeight: 'bold' }}
                />
              ) : (
                <ListItemText primary={team.overallGamesPlayed} />
              )}
            </Grid>
            <Grid item xs={1}>
              {isHeader ? (
                <ListItemText
                  primary={t('Gagnés')}
                  style={{ fontWeight: 'bold' }}
                />
              ) : (
                <ListItemText primary={team.overallWon} />
              )}
            </Grid>
            <Grid item xs={1}>
              {isHeader ? (
                <ListItemText
                  primary={t('Nuls')}
                  style={{ fontWeight: 'bold' }}
                />
              ) : (
                <ListItemText primary={team.overallDraw} />
              )}
            </Grid>
            <Grid item xs={1}>
              {isHeader ? (
                <ListItemText
                  primary={t('Perdus')}
                  style={{ fontWeight: 'bold' }}
                />
              ) : (
                <ListItemText primary={team.overallLost} />
              )}
            </Grid>
            <Grid item xs={1}>
              {isHeader ? (
                <ListItemIcon>
                  <Button style={{ margin: 'auto' }}>
                    <SportsSoccerSharp
                      style={{ fontSize: '1.9rem', color: '#f5ef69' }}
                    />
                  </Button>
                </ListItemIcon>
              ) : (
                <ListItemText primary={team.overallGoalsScored} />
              )}
            </Grid>
            <Grid item xs={1}>
              {isHeader ? (
                <ListItemIcon>
                  <Button style={{ margin: 'auto' }}>
                    <SportsSoccerSharp
                      style={{ fontSize: '1.9rem', color: '#848ca5' }}
                    />
                  </Button>
                </ListItemIcon>
              ) : (
                <ListItemText primary={team.overallGoalsAgainst} />
              )}
            </Grid>
            <Grid item xs={1}>
              {isHeader ? (
                <ListItemText primary={'+/-'} className="bold" />
              ) : (
                <ListItemText
                  primary={team.overallGoalsScored - team.overallGoalsAgainst}
                />
              )}
            </Grid>
            <Grid container item xs={2} alignItems="center" justify="center">
              {isHeader ? (
                <Box width="100%" justifyContent="center" textAlign="center">
                  <Typography variant="h5" component={'span'}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box color="#68daa8" width="25%">
                        {t('G.')}
                      </Box>
                      <Box color="#848ca5" width="25%">
                        {t('N.')}
                      </Box>
                      <Box color="#e0153f" width="25%">
                        {t('P.')}
                      </Box>
                    </Box>
                  </Typography>
                </Box>
              ) : (
                team.latestData &&
                team.latestData.map(
                  (winnerTeamId, index) =>
                    index < 5 && (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        key={index}
                      >
                        {winnerTeamId === null ? (
                          <FiberManualRecordIcon
                            fontSize="small"
                            style={{ color: '#848ca5' }}
                          />
                        ) : winnerTeamId === team.actualTeamId ? (
                          <FiberManualRecordIcon
                            fontSize="small"
                            style={{ color: '#68daa8' }}
                          />
                        ) : (
                          <FiberManualRecordIcon
                            fontSize="small"
                            style={{ color: '#e0153f' }}
                          />
                        )}
                      </Box>
                    ),
                )
              )}
            </Grid>
            <Grid item xs={1}>
              {!isHeader && <ButtonFilled icon small hoverOutlined />}
            </Grid>
          </Grid>
        )}
      </Grid>
    </ListItem>
  );
}
