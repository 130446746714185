import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Box, Typography } from '@material-ui/core';
import { TeamData } from '../../../../types/soccer/team';
import TeamInfos from './TeamInfos';
import { getPlayerName } from '../../../../utils/soccer';

const useStyles = makeStyles(() =>
  createStyles({
    infoBox: {
      boxShadow: 'rgb(85 89 102 / 0%) 0px 0px 23px 0px',
      transition: 'all .2s ease-in-out',
      cursor: 'default',

      '&:hover': {
        boxShadow: 'rgb(85 89 102 / 33%) 0px 0px 23px 0px',
        backgroundColor: '#36426b99',
      },
    },
  }),
);

const InfoBox = props => {
  const { title, value } = props;
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();

  return (
    <Box
      padding="1rem 1.5rem"
      display="flex"
      margin="1rem 0"
      width={'90%'}
      height={mobile ? 65 : 80}
      flexDirection="column"
      justifyContent="space-between"
      alignItems="flex-start"
      bgcolor="#242f56"
      borderRadius="15px"
      className={classes.infoBox}
    >
      <Typography variant="body2" component={'span'}>
        <Box
          color={theme.palette.text.secondary}
          style={{ textTransform: 'uppercase' }}
          fontWeight={600}
        >
          {t(title)}
        </Box>
      </Typography>
      <Typography variant="body2" component={'span'}>
        <Box display="flex" flexWrap="nowrap" alignItems="baseline">
          <Box
            color="white"
            fontWeight={700}
            fontSize={mobile ? 14 : 16}
            data-testid={`box-${title}`}
          >
            {value}
          </Box>
        </Box>
      </Typography>
    </Box>
  );
};

type TopSectionProps = {
  teamData: TeamData;
};

const TopSection: React.FC<TopSectionProps> = ({ teamData }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();

  const averagePlayersAge: number = useMemo(() => {
    return Math.round(
      teamData.players
        .map(({ age }) => age)
        .reduce((prev, curr) => prev + curr) / teamData.players.length,
    );
  }, [teamData]);

  return (
    <Grid
      item
      container
      xs={11}
      style={{ margin: 'auto', position: 'relative', overflow: 'hidden' }}
      justify="space-between"
      alignItems="flex-start"
    >
      <TeamInfos competitor={teamData.competitor} />
      <Box
        position="relative"
        marginTop={mobile ? '1rem' : 0}
        width={mobile ? '100%' : '50%'}
      >
        <Grid
          container
          justify="space-between"
          alignItems="center"
          style={{
            maxWidth: '550px',
            marginLeft: 'auto',
          }}
        >
          <Grid
            item
            container
            xs={6}
            justify={mobile ? 'flex-start' : 'center'}
            alignItems="center"
          >
            <InfoBox title={t('Pays')} value={teamData.competitor.country} />
          </Grid>
          <Grid
            item
            container
            xs={6}
            justify={mobile ? 'flex-end' : 'center'}
            alignItems="center"
          >
            <InfoBox title={t('Stade')} value={teamData.venue?.name || '-'} />
          </Grid>
          <Grid
            item
            container
            xs={6}
            justify={mobile ? 'flex-start' : 'center'}
            alignItems="center"
          >
            <InfoBox
              title={t('Entraineur')}
              value={getPlayerName(teamData.manager?.name)}
            />
          </Grid>
          <Grid
            item
            container
            xs={6}
            justify={mobile ? 'flex-end' : 'center'}
            alignItems="center"
          >
            <InfoBox title={t('Age average')} value={averagePlayersAge} />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default TopSection;
