import {
  Card,
  CardContent,
  CardMedia,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Highlight } from 'react-instantsearch-dom';
import './ArticleCard.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      borderRadius: '25px',
      padding: 0,
      background: 'rgba(24, 36, 79, 1)',
      paddingBottom: '1rem',
      cursor: 'pointer',
      marginBottom: '2rem',
      width: '100%',
    },
    image: {
      width: '90%',
      margin: 'auto',
      borderRadius: '25px',
      marginTop: '5%',
    },
  }),
);

const Description = ({ answer }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Typography
      variant="body1"
      style={{
        margin: '.5rem 0',
        fontSize: '1rem',
      }}
    >
      {t(answer)
        .substring(0, mobile ? 130 : 260)
        .replace('\\u0027', "'")
        .replace('"', '')}
      ...
    </Typography>
  );
};

const ArticleCard = ({ cardContent, index }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Card key={index} className={classes.card}>
      <CardMedia
        className={classes.image}
        image={cardContent.image}
        title={cardContent.title}
        style={{ height: mobile ? 200 : 320 }}
      />
      <CardContent style={{ width: '95%', margin: 'auto' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h4" style={{ margin: '.5rem 0' }}>
            <Highlight attribute="title" hit={cardContent} />
            {/* {cardContent.title.substring(0, 80)} */}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {moment(cardContent.date).format('DD/MM/YYYY')}
          </Typography>
        </div>
        <Description
          key={`answer-${cardContent.id}-${index}`}
          answer={cardContent.firstDescription}
        />
      </CardContent>
    </Card>
  );
};

export default ArticleCard;
