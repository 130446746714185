import React, { useState } from 'react';
import {
  makeStyles,
  Theme,
  useTheme,
  createStyles,
} from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import { SportsSoccerSharp } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import { Container, ListItemIcon, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import '../../../../theme/_colors.scss';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fade from '@material-ui/core/Fade';
import { getNFLLogoURL } from '../../../../utils/nfl';
import Logo from '../Logos/Logo';

type rank = {
  conference: number;
  division: number;
};

interface PlayerItemProps {
  name: string;
  market: string;
  wins: string;
  losses: string;
  win_pct: string;
  rank: rank;
  id: string;
  ties: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      backgroundColor:
        theme.palette.type === 'dark' ? 'rgba(24,36,79,0.85)' : '#f8f9fa',
      borderRadius: '15px',
      border: 'none',
      boxShadow: 'none',
      minHeight: 'unset',
      padding: 12,
      transition: 'all .15s ease-in-out !important',

      '&:hover': {
        backgroundColor: theme.palette.type === 'dark' ? '' : 'white',
        boxShadow: '0 5px 10px 0 rgba(10, 22, 62, 0.08)',
      },
    },
    img: {
      borderRadius: '50px',
      [theme.breakpoints.down('sm')]: {
        width: 20,
        height: 20,
      },
    },
    countryImg: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        width: 20,
        height: 11,
      },
    },
    typo: {
      height: '100%',
      alignItems: 'center',
    },
  }),
);

export default function TeamItem(props: PlayerItemProps) {
  const { name, market, wins, losses, win_pct, rank, id, ties } = props;
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Fade in={true} timeout={500}>
      <ListItem component={Card} className={classes.card}>
        <Grid
          container
          item
          xs
          justify="center"
          alignItems="center"
          className="textAlignCenter"
        >
          <Grid
            container
            item
            alignItems="center"
            justify="center"
            xs={7}
            sm={4}
          >
            <Grid
              container
              item
              xs={3}
              sm={4}
              alignItems="center"
              justify="center"
            >
              <Logo
                src={getNFLLogoURL(id)}
                width={mobile ? 30 : 40}
                typeImgDefault="club"
                imgDefautl="NFL"
              />
            </Grid>
            <Grid
              item
              container
              xs={9}
              sm={8}
              alignItems="center"
              style={{
                padding: '0 10px',
                justifyContent: mobile ? 'center' : 'flex-start',
              }}
            >
              <Typography className="typo bold">
                {market + ' ' + name}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={5}
            sm={4}
            justify="center"
            alignItems="center"
          >
            <Grid item xs={5}>
              <ListItemText primary={win_pct} />
            </Grid>
            <Grid item xs={7}>
              <ListItemText primary={wins + '-' + ties + '-' + losses} />
            </Grid>
          </Grid>
          {!mobile && (
            <Grid container item sm={4} justify="center" alignItems="center">
              <Grid item xs={6}>
                <ListItemText primary={wins + ties + losses} />
              </Grid>
              <Grid item xs={6}>
                <ListItemText primary={rank.conference} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </ListItem>
    </Fade>
  );
}
