import React, { useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import '../../../../theme/datasportTheme.css';
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import { CompetitorSummary } from '../../../../types/sportradar/soccer/history';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Logo from '../../Templates/Logos/Logo';
import Loading from '../../../Loading/Loading';

const useStyles = makeStyles(() => ({
  arrow: {
    color: '#7e89b2',
    cursor: 'pointer',
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  pin: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  matchItem: {
    padding: '1.5rem',
    background: '#263470',
    borderRadius: '12px',
    marginBottom: '2rem',
  },
}));

type RowMatchMobileProps = {
  summary: CompetitorSummary;
  last: boolean;
  teamName?: string;
  goToMatchPage: () => void;
};

const MatchIssuePin: React.FC<{ winnerId: string; teamName?: string }> = ({
  winnerId,
  teamName,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  if (!teamName) return <></>;

  const getData = (
    winnerId: string,
    teamName: string,
  ): { backgroundImage: string; value: string } => {
    if (!winnerId)
      return {
        backgroundImage: 'linear-gradient(45deg, #e09c6a, #eddd99)',
        value: t('N'),
      };
    if (winnerId === teamName)
      return {
        backgroundImage: 'linear-gradient(#7ecdcb, #7ecdcb)',
        value: t('V'),
      };
    return {
      backgroundImage: 'linear-gradient(45deg, #dc5c7d, #ad8dd9)',
      value: t('D'),
    };
  };

  const { value, backgroundImage } = getData(winnerId, teamName);

  return (
    <Box className={classes.pin} style={{ backgroundImage }}>
      {value}
    </Box>
  );
};

const RowMatchMobile: React.FC<RowMatchMobileProps> = ({
  summary,
  last,
  teamName,
  goToMatchPage,
}) => {
  const {
    date,
    domId,
    extId,
    winnerId,
    score,
    domLogo,
    extLogo,
    domScore,
    extScore,
  } = summary;
  const classes = useStyles();

  return (
    <Grid container className={classes.matchItem}>
      <Grid
        container
        direction="column"
        xs={10}
        // onClick={goToMatchPage}
      >
        <Grid container justify="flex-start" style={{ marginBottom: '1rem' }}>
          <Typography variant="h5" component={'span'}>
            <Box color="#FFFFFFB2" fontWeight={700} textAlign="center">
              {moment(date).format('DD/MM/YYYY')}
            </Box>
          </Typography>
        </Grid>
        <Grid container direction="column">
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{ marginBottom: '1rem' }}
          >
            <Grid item container xs={10}>
              <Grid item xs={3}>
                <Logo
                  src={domLogo || ''}
                  club
                  width={32}
                  typeImgDefault="club"
                  imgDefautl="FOOTBALL"
                />
              </Grid>
              <Grid
                item
                container
                xs={9}
                alignItems="center"
                style={{ justifyContent: 'flex-start' }}
              >
                <Typography
                  variant="h5"
                  component={'span'}
                  style={{ marginLeft: '1.5rem' }}
                >
                  <Box
                    color={domId === winnerId ? 'white' : '#838ca6'}
                    fontWeight={600}
                    textAlign="left"
                  >
                    {domId}
                  </Box>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6" component={'span'}>
                {domScore}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{ marginBottom: '1rem' }}
          >
            <Grid item container xs={10}>
              <Grid item xs={3}>
                <Logo
                  src={extLogo || ''}
                  club
                  width={32}
                  typeImgDefault="club"
                  imgDefautl="FOOTBALL"
                />
              </Grid>
              <Grid
                item
                container
                xs={9}
                alignItems="center"
                style={{ justifyContent: 'flex-start' }}
              >
                <Typography
                  variant="h5"
                  component={'span'}
                  style={{ marginLeft: '1.5rem' }}
                >
                  <Box
                    color={extId === winnerId ? 'white' : '#838ca6'}
                    fontWeight={600}
                    textAlign="left"
                  >
                    {extId}
                  </Box>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1" component={'span'}>
                {extScore}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={2}
        style={{ justifyContent: 'center' }}
        alignItems="center"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <MatchIssuePin teamName={teamName} winnerId={winnerId} />
        </Box>
      </Grid>
    </Grid>
  );
};

const arrangeHistories = (
  teamHistories: CompetitorSummary[],
): CompetitorSummary[][] => {
  const parts: CompetitorSummary[][] = [];
  if (teamHistories.length)
    teamHistories.forEach((number, i) => {
      if (i % 6 === 0) parts.push([]);
      parts[parts.length - 1].push(number);
    });
  return parts;
};

type Props = {
  teamHistories: CompetitorSummary[];
  title: string;
  teamName?: string;
  isLoading: boolean;
};

const MatchHistory: React.FC<Props> = ({
  teamHistories,
  title,
  teamName,
  isLoading,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [page, setPage] = useState(0);

  const histories = useMemo(
    () => arrangeHistories(teamHistories),
    [teamHistories],
  );

  return (
    <Grid container style={{ marginTop: '1rem' }}>
      <Grid item container xs={12}>
        <Grid item container justify="space-between" alignItems="center">
          <Typography variant="h5" component={'span'}>
            <Box color="white" fontWeight={600}>
              {t(title)}
            </Box>
          </Typography>
          <Box>
            <NavigateBeforeRoundedIcon
              onClick={() => setPage(page + 1)}
              className={clsx({
                [classes.arrow]: true,
                [classes.disabled]: !histories[page + 1],
              })}
            />
            <NavigateNextRoundedIcon
              onClick={() => setPage(page - 1)}
              className={clsx({
                [classes.arrow]: true,
                [classes.disabled]: page === 0,
              })}
            />
          </Box>
        </Grid>
      </Grid>
      {isLoading ? (
        <Loading nobg small />
      ) : (
        <Grid item container xs={12} style={{ marginTop: '1rem' }}>
          {histories[page]?.map((match, i) => (
            <RowMatchMobile
              key={`matchHistory-${match.id}`}
              summary={match}
              last={histories[page].length - 1 === i}
              teamName={teamName}
              goToMatchPage={() => history.push(`/match/${match.id}`)}
            />
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default MatchHistory;
