import React from 'react';
import {
  makeStyles,
  Theme,
  useTheme,
  createStyles,
} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CardGeneral from './CardGeneral';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '../Progress/CircularProgress';
import '../../../../theme/datasportTheme.css';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: '100%',
      width: '100%',
      border: '1px solid #444f78',
    },
    content: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
  }),
);

export default function CardPronoPourcentage(props) {
  const {
    team1,
    value,
    value2,
    title,
    subtitle,
    legend1,
    legend2,
    legend3,
    singleText,
  } = props;
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const values = {
    firstValue: {
      val: parseInt(value),
    },
    secondValue: {
      val: value2
        ? 100 - parseInt(value) - parseInt(value2)
        : 100 - parseInt(value),
    },
    lastValue: {
      val: parseInt(value2) || null,
    },
  };

  const valuesArray = values && [values.firstValue.val, values.secondValue.val];
  values.lastValue.val && valuesArray.push(values.lastValue.val);

  return (
    <CardGeneral className={classes.card} blue>
      <CardContent className={classes.content}>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            marginBottom={mobile ? '.2rem' : '0'}
            height="5rem"
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="space-between"
            justifyContent="flex-start"
          >
            <Typography variant="h4" component={'span'}>
              <Box
                color="white"
                fontWeight="600"
                marginBottom=".5rem"
                fontSize={
                  i18n.language === 'it'
                    ? '1.275rem'
                    : mobile
                    ? '1.5rem'
                    : '1.275rem'
                }
              >
                {t(title)}
              </Box>
            </Typography>
            <Typography
              variant={mobile ? 'h4' : 'h6'}
              component={'span'}
              style={{
                fontWeight: 500,
                fontSize: '.9rem',
                lineHeight: '.9rem',
              }}
            >
              <Box color="#848ca5">{t(subtitle)}</Box>
            </Typography>
          </Box>
          <Box height={160} width="100%" margin="1rem 0">
            <CircularProgress
              value={value}
              value2={value2}
              text1Bold
              text1={!singleText && `${t('Victoire')} ${team1}`}
              text2={`${value}%`}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="start"
            paddingBottom={mobile ? '1rem' : 0}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              width="100%"
            >
              <Box
                marginRight=".5rem"
                style={{
                  background: '#69dad9',
                }}
                minWidth="13px"
                height="13px"
                borderRadius="50%"
              />
              <Box color="#69dad9">
                <Typography
                  variant={mobile ? 'h5' : 'h6'}
                  style={{ fontWeight: 500 }}
                >
                  <span>{values.firstValue.val}%</span> {t(legend1)}
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              width="100%"
              marginTop={mobile ? '.5rem' : 0}
            >
              <Box
                marginRight=".5rem"
                style={{ backgroundColor: value2 ? '#e4e4e4' : '#848ca5' }}
                minWidth="13px"
                height="13px"
                borderRadius="50%"
              />
              <Box color={value2 ? '#e4e4e4' : '#848ca5'}>
                <Typography
                  variant={mobile ? 'h5' : 'h6'}
                  style={{ fontWeight: 500 }}
                >
                  <span>{values.secondValue.val}%</span> {t(legend2)}
                </Typography>
              </Box>
            </Box>
            {legend3 && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
                width="100%"
                marginTop={mobile ? '.5rem' : 0}
              >
                <Box
                  marginRight=".5rem"
                  style={{ backgroundColor: '#848ca5' }}
                  minWidth="13px"
                  height="13px"
                  borderRadius="50%"
                />
                <Box color="#848ca5">
                  <Typography
                    variant={mobile ? 'h5' : 'h6'}
                    style={{ fontWeight: 500 }}
                  >
                    <span>{values.lastValue.val}%</span> {t(legend3)}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </CardContent>
    </CardGeneral>
  );
}
