import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Box, Typography } from '@material-ui/core';
import Logo from '../../../Templates/Logos/Logo';
import ButtonFilled from '../../../Templates/Buttons/ButtonFilled';

const useStyles = makeStyles(() =>
  createStyles({
    bgNumber: {
      position: 'absolute',
      right: '-60%',
      fontSize: 300,
      top: 0,
      color: 'rgba(255, 255, 255, 0.03)',
      '-webkit-text-stroke': '10.2px rgba(132, 140, 165, 0.45)',
      objectFit: 'contain',
      opacity: 0.39,
      fontWeight: 'bold',
    },
    shadow: {
      cursor: 'pointer',
      marginTop: '.5rem',
      boxShadow: '0 12px 23px 0 rgba(10, 22, 62, 0)',
      position: 'relative',
      transition: 'box-shadow .2s ease-in-out',
      '&:hover': {
        boxShadow: '0 12px 23px 0 rgba(10, 22, 62, 0.10)',
        zIndex: 2,
      },
    },
  }),
);

export default function RowPlayer(props) {
  const {
    isHeader,
    countryFlag,
    bgGrey,
    jerseyNumber,
    height,
    player,
    weight,
    position,
    age,
    goToPlayerPage,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();

  return (
    <Box
      width="100%"
      bgcolor={bgGrey ? '#f8f9fa' : 'white'}
      borderRadius="8px"
      padding=".5rem .8rem"
      className={!isHeader ? classes.shadow : ''}
      onClick={goToPlayerPage}
      data-testid="box-rowPlayer"
    >
      <Grid container alignItems="center">
        <Grid item xs={1} alignItems="center">
          <Typography variant={mobile ? 'body2' : 'body1'} component={'span'}>
            <Box
              color={theme.palette.text.primary}
              fontWeight={700}
              data-testid="box-number"
            >
              {isHeader ? (mobile ? t('N.') : t('Numéro')) : jerseyNumber}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={2} alignItems="center" justify="center">
          <Typography variant={mobile ? 'body2' : 'body1'} component={'span'}>
            <Box
              color={isHeader ? theme.palette.text.primary : '#1a2844'}
              fontWeight={700}
              textAlign="center"
              display="flex"
              justifyContent="center"
              alignItems="center"
              data-testid="box-country"
            >
              {isHeader ? (
                t('Pays')
              ) : (
                <Logo
                  src={countryFlag}
                  width={mobile ? 30 : 40}
                  typeImgDefault="club"
                  imgDefautl="FOOTBALL"
                />
              )}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={2} alignItems="center">
          <Typography variant={mobile ? 'body2' : 'body1'} component={'span'}>
            <Box
              color={isHeader ? theme.palette.text.primary : '#1a2844'}
              fontWeight={700}
              data-testid="box-joueur"
            >
              {isHeader ? t('Joueur') : player}
            </Box>
          </Typography>
        </Grid>
        <Grid item container xs={4} justify="space-between">
          <Grid item xs={4} alignItems="center">
            <Typography variant={mobile ? 'body2' : 'body1'} component={'span'}>
              <Box
                color={
                  isHeader
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary
                }
                fontWeight={700}
                textAlign="center"
                data-testid="box-position"
              >
                {isHeader ? (mobile ? t('Pos.') : t('Position')) : position}
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={4} alignItems="center">
            <Typography variant={mobile ? 'body2' : 'body1'} component={'span'}>
              <Box
                color={
                  isHeader
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary
                }
                fontWeight={700}
                textAlign="center"
                data-testid="box-age"
              >
                {isHeader ? t('Age') : age}
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={4} alignItems="center">
            <Typography variant={mobile ? 'body2' : 'body1'} component={'span'}>
              <Box
                color={
                  isHeader
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary
                }
                fontWeight={700}
                textAlign="center"
                data-testid="box-height"
              >
                {isHeader ? t('Taille (cm)') : height}
              </Box>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={2} alignItems="center">
          <Typography variant={mobile ? 'body2' : 'body1'} component={'span'}>
            <Box
              color={
                isHeader
                  ? theme.palette.text.primary
                  : theme.palette.text.secondary
              }
              fontWeight={700}
              textAlign="center"
              data-testid="box-weight"
            >
              {isHeader ? `${t('Poids')} (kg)` : weight}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={1}>
          {!isHeader && <ButtonFilled icon small hoverOutlined />}
        </Grid>
      </Grid>
    </Box>
  );
}
