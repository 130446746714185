import React from 'react';
import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid } from '@material-ui/core';
import '../../theme/datasportTheme.css';
import { Article } from '../../types/article';
import { connectHits } from 'react-instantsearch-dom';
import { HitsProvided } from 'react-instantsearch-core';
import { ArticleHitProps } from './Blog.type';
import ArticleCard from './ArticleCard';
import { useHistory } from 'react-router-dom';

const ArticleHit: React.FC<ArticleHitProps> = ({ hit }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();

  return (
    <>
      <Grid
        item
        xs={12}
        sm={10}
        style={{
          padding: mobile ? '0' : '0 3rem',
          margin: 'auto',
          display: 'flex',
          justifyContent: 'center',
        }}
        key={hit.id}
        onClick={() => {
          history.push(`/blog/${hit.objectID}`);
        }}
      >
        <Grid item xs={10} key={hit.objectID}>
          <ArticleCard
            key={`answer-${hit.id}-${hit.objectID}`}
            cardContent={hit}
            index={hit.objectID}
          />
        </Grid>
      </Grid>
    </>
  );
};

const ArticleHits: React.FC<HitsProvided<Article>> = ({ hits }) => {
  hits.sort((a, b) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });

  return (
    <div
      style={{
        display: 'flex',
        marginTop: 25,
        gap: 25,
        flexWrap: 'wrap',
        justifyContent: 'space-around',
      }}
    >
      {hits.map(hit => (
        <ArticleHit key={hit.objectID} hit={hit} />
      ))}
    </div>
  );
};

export default connectHits(ArticleHits);
