import React, { useEffect, useState } from 'react';
import { CalendarMatches } from '../../../../../../types/soccer';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Fire } from 'services';
import { capitalize, Grid, Typography } from '@material-ui/core';
import Loading from '../../../../../Loading/Loading';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CalendarNavigation from './CalendarNavigation/CalendarNavigation';
import CalendarList from './CalendarList/CalendarList';
import { GroupedDayRegularGames } from '../../../../../../../../cloud/functions/src/types/sportradar/NBA/SchedulesGamesNBA';
import moment from 'moment';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import CardGeneral from '../../../../Templates/Cards/CardGeneral';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bigCard: {
      height: 'unset',
      padding: '.9rem 1.9rem',
      [theme.breakpoints.down('sm')]: {
        padding: 5,
        width: 'calc(100% + 40px)',
        transform: 'translate(-20px)',
        margin: 0,
      },
      '&> *': {
        alignItems: 'center',
      },
    },
  }),
);

const ListHeader: React.FC<{ date: string }> = ({ date }) => {
  return (
    <Box margin="0 0 1rem .15rem">
      <Typography variant="h5">{date}</Typography>
    </Box>
  );
};

const CalendarNFL: React.FC = () => {
  const classes = useStyles();
  const [calendarMatches, setCalendarMatches] =
    useState<CalendarMatches | null>(null);
  const [rounds, setRounds] = useState<string[]>([]);
  const [currentRound, setCurrentRound] = useState<string | null>(null);
  const history = useHistory();
  const { data: matches, isLoading } = useQuery(
    `nfl:calendar:reg`,
    () => Fire.cloud<CalendarMatches>('nfl', { method: 'getRegularCalendar' }),
    { staleTime: 900000 /* 15 mn */ },
  );
  let initialPage: any = new URLSearchParams(history.location.search).get(
    'page',
  );
  initialPage = initialPage ? parseInt(initialPage) : 1;

  useEffect(() => {
    const getLeagueCalendar = async (): Promise<void> => {
      if (!matches) {
        setCurrentRound(null);
        setRounds([]);
        setCalendarMatches(null);
      }
      if (matches) {
        const rounds = Object.keys(matches);
        const lastRound = parseInt(rounds[rounds.length - 1]);
        const firstDateOfMatches = Object.keys(matches[rounds[0]]);
        let currentRoundFounded = false;
        let dateInRound: null | string = null;
        const now = moment().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        if (now.isBefore(moment(firstDateOfMatches[0]))) {
          dateInRound = rounds[0];
          currentRoundFounded = true;
        } else {
          const lastDatesOfMatches = Object.keys(
            matches[rounds[rounds.length - 1]],
          );
          if (
            now.isAfter(
              moment(lastDatesOfMatches[lastDatesOfMatches.length - 1]),
            )
          ) {
            dateInRound = rounds[rounds.length - 1];
            currentRoundFounded = true;
          }
        }
        let previousLastDate = moment('1970-01-01');
        if (!currentRoundFounded) {
          Object.keys(matches).map(round => {
            if (currentRoundFounded) return;
            const dates = Object.keys(matches[round]);
            const lastDate = moment(dates[dates.length - 1]);
            if (now.isBetween(previousLastDate, lastDate, undefined, '(]')) {
              dateInRound = round;
              currentRoundFounded = true;
            } else {
              previousLastDate = lastDate;
            }
          });
        }
        const round = (
          dateInRound || (initialPage > lastRound ? lastRound : initialPage)
        ).toString();
        setCurrentRound(round);
        setRounds(rounds);
        setCalendarMatches(matches);
        history.replace(`${history.location.pathname}?page=${round}`);
      }
    };
    getLeagueCalendar();
  }, [history, matches]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading && (
        <Grid container item xs={12}>
          <Loading nobg />
        </Grid>
      )}
      <Grid container justify="center">
        <Grid container item xs={10} sm={12} justify="space-around">
          <Box width="100%" zIndex="1">
            <CardGeneral className={classes.bigCard}>
              <Grid container item xs={12} justify="space-between">
                {currentRound && (
                  <CalendarNavigation
                    currentRound={currentRound}
                    rounds={rounds}
                    setCurrentRound={next => {
                      const roundIndex = rounds.findIndex(
                        r => currentRound === r,
                      );
                      const nextRound = rounds[roundIndex + next];
                      history.replace(
                        `${history.location.pathname}?page=${nextRound}`,
                      );
                      setCurrentRound(nextRound);
                    }}
                  />
                )}
              </Grid>
              {calendarMatches &&
                currentRound &&
                Object.entries(calendarMatches[currentRound]).map(
                  ([date, matches]) => (
                    <Box key={date} margin="2rem 1rem">
                      <ListHeader
                        date={capitalize(
                          moment(date).format('dddd DD MMMM YYYY'),
                        )}
                      />
                      <CalendarList
                        games={matches as GroupedDayRegularGames[]}
                      />
                    </Box>
                  ),
                )}
            </CardGeneral>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(CalendarNFL);
