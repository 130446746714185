import React, { useState } from 'react';
import { AppBar, Box, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import MatchStatistics from './MatchStatistics/MatchStatistics';
import { TFunction } from 'i18next';
import { useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import {
  MatchSummary,
  StandingsEntity,
} from '../../../../types/sportradar/soccer/sport-event';
import RankingSoccer from '../RankingSoccer/RankingSoccer';

function a11yProps(index: number): { 'aria-controls': string; id: string } {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = props => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

type StatsPanelProps = {
  t: TFunction;
  match: MatchSummary;
  standings: StandingsEntity[];
  standingsLoading: boolean;
};

const StatsPanel: React.FC<StatsPanelProps> = ({
  t,
  match,
  standings,
  standingsLoading,
}) => {
  const theme = useTheme();
  const [tabValue, setTabValue] = useState<number>(0);

  return (
    <Grid item container justify="center">
      <Box
        width={'95%'}
        bgcolor="white"
        borderRadius="15px"
        boxShadow="0 0 23px 0 rgba(10, 22, 62, 0.1)"
        position="relative"
        padding=".5rem .1rem 1rem"
      >
        <AppBar position="static" color="default">
          <Tabs
            value={tabValue}
            onChange={(e, v) => setTabValue(v)}
            TabIndicatorProps={{
              style: {
                height: '4px',
                transform: 'translate(0, 75%)',
              },
            }}
            textColor="inherit"
            centered
            aria-label="Tabs"
            indicatorColor="secondary"
            className="noOverflow"
          >
            <Tab label={t('Statistiques')} {...a11yProps(0)} />
            <Tab label={t('Classement')} {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={tabValue}
          onChangeIndex={index => setTabValue(index)}
        >
          <TabPanel value={tabValue} index={0} dir={theme.direction}>
            <MatchStatistics
              competitors={match.sport_event.competitors}
              competitorsStats={match.statistics.totals}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1} dir={theme.direction}>
            <RankingSoccer
              standings={standings}
              loading={standingsLoading}
              competitors={match.sport_event.competitors}
              competition={match.sport_event.sport_event_context.competition}
            />
          </TabPanel>
        </SwipeableViews>
      </Box>
    </Grid>
  );
};

export default StatsPanel;
