import { Fire } from 'services';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  FullPlayerTeam,
  PlayerInfo,
  PlayerTeamStat,
} from '../../../types/soccer/player-team-stats';

type PagePlayer = {
  playerInfo?: PlayerInfo;
  playerStats?: PlayerTeamStat[];
};

const usePagePlayer = (): PagePlayer => {
  const { playerId } = useParams<{ playerId: string }>();
  const { data } = useQuery(
    `soccer:player:${playerId}`,
    () => {
      const ref = Fire.store().collection('soccer_players').doc(playerId);
      return Fire.doc<FullPlayerTeam>(ref);
    },
    { staleTime: 900000 /* 15 mn */, retry: false },
  );

  return {
    playerInfo: data?.info,
    playerStats: data?.stats,
  };
};

export default usePagePlayer;
