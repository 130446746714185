import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Fire, Mail } from 'services';
import ExternPaymentLoading from './ExternPaymentLoading/ExternPaymentLoading';
import { User } from '../../../types/user';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type ExternPaymentParams = { email: string };

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ExternPayment: React.FC = () => {
  const [error, setError] = React.useState('');
  const { i18n } = useTranslation();
  const { email } = useParams<ExternPaymentParams>();
  const history = useHistory();
  const query = useQuery();
  const lang = query.get('lang') || 'en';
  const first_name = query.get('first_name') || '';

  const sources = {
    utm_source: query.get('utm_source'),
    utm_medium: query.get('utm_medium'),
    utm_campaign: query.get('utm_campaign'),
    utm_term: query.get('utm_term'),
    utm_content: query.get('utm_content'),
  };

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang.toLowerCase());
    }
  }, [lang]);

  useEffect(() => {
    const handleToken = async () => {
      if (email) {
        const welcomeTemplates = {
          fr: 1,
          it: 8,
          es: 7,
          en: 2,
        };
        const idTemplate = welcomeTemplates[lang] ? welcomeTemplates[lang] : 2;
        await Fire.auth().signOut();
        let token;
        try {
          const response = await Fire.cloud('createExternTokenFromEmail', {
            email,
            sources,
          });
          console.log('response', response);
          if (response.success) {
            token = response.token;
          } else {
            let error = response.error.errorInfo;
            if (error.code === 'auth/user-not-found') {
              console.log('User not found');
              setError(
                "Malheureusement, nous n'avons pas trouvé de compte associé à cette adresse email. Veuillez vous contacter à l'adresse mail suivante : support@data-sport.io.",
              );
            } else {
              setError(
                "Malheureusement, il y a eu une erreur lors de votre inscription. Veuillez vous contacter à l'adresse mail suivante : support@data-sport.io.",
              );
            }
            return;
          }
        } catch (err) {
          setError(
            "Malheureusement, il y a eu une erreur lors de votre inscription. Veuillez vous contacter à l'adresse mail suivante : support@data-sport.io.",
          );
          return;
        }
        if (token) {
          window.dataLayer.push({ event: 'abonnement' });
          try {
            Mail.send({ email, first_name } as User, idTemplate);
          } catch (err) {
            console.log('err', err);
          }
          Fire.auth().signInWithCustomToken(token);
        }
        history.push('/extern-payment');
      }
    };
    handleToken();
  }, [email]);

  if (error !== '') {
    return (
      <Box
        className="loader_background"
        sx={{
          color: 'red',
          fontSize: '1.5rem',
          padding: '2rem',
        }}
        bgcolor="background.default"
      >
        {error}
      </Box>
    );
  }

  return <ExternPaymentLoading />;
};

export default ExternPayment;
