import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import CardPredictionFootball from '../../../Templates/Cards/CardPredictionFootball/CardPredictionFootball';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PreviewTitle from '../../../Templates/Misc/PreviewTitle';
import RowPrediction from '../../../Templates/Rows/RowPrediction';
import { FuturePronos, ValidPronos } from '../../../../../types/pronoNBA';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { getInfosPronoLibre as getInfosPronoLibreSoccer } from '../../../../../utils/pronoLibreSoccer';
import { getInfosPronoLibre as getInfosPronoLibreNBA } from '../../../../../utils/pronoLibreNBA';
import { TFunction } from 'i18next';
import firebase from 'firebase';
import { Fire } from 'services';
import arrowRightSolid from 'images/arrowRightSolid.svg';
import Loading from '../../../../Loading/Loading';
import { getValidPronosFiltered } from '../../../../../utils/pronos';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    singleCard: {
      maxWidth: 400,
      minWidth: 280,
      width: '30%',
      marginRight: '2rem',
      '&:last-child': {
        marginRight: '0',
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: '2rem',
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: 280,
      },
    },
    last: {
      [theme.breakpoints.down('xs')]: {
        margin: 'auto',
      },
    },
    fullWidth: {
      [theme.breakpoints.down('xs')]: {
        marginRight: '0px !important',
      },
    },
    bigCard: {
      background: 'rgba(25,38,83,0.85)',
      width: '100%',
      borderRadius: 25,
      paddingBottom: '.75rem',
      marginBottom: '2rem',
    },
    rowContainer: {
      '& > div': {
        margin: '10px 0',
      },
    },
    singleCardContainer: {
      flexFlow: 'row nowrap',
      overflowX: 'scroll',
      marginBottom: '2rem',
      scrollbarColor: theme.palette.text.secondary + ' transparent',
      scrollbarWidth: 'thin',
      paddingTop: '1rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingBottom: '2rem',
      position: 'relative',

      scrollBehavior: 'smooth',
      '&::-webkit-scrollbar': {
        height: '7px',
        paddingBottom: '1rem',
      },

      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.text.secondary,
        transition: 'background-color .3s ease-in-out',
        borderRadius: '20px',
        '&:hover': {
          backgroundColor: theme.palette.text.primary,
        },
      },
    },
    gridContainer: {
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
      },
      [theme.breakpoints.up('sm')]: {
        paddingRight: '3rem',
      },
    },
  }),
);

type FootballPageProps = {
  mobile: boolean;
  tablet: boolean;
  smallComputer: boolean;
  mediumComputer: boolean;
  isLoading: boolean;
  pronos: any;
  setProno: (p: any) => void;
  t: TFunction;
};

const FootballTab: React.FC<FootballPageProps> = ({
  mobile,
  tablet,
  smallComputer,
  mediumComputer,
  pronos,
  isLoading,
  setProno,
  t,
}) => {
  const classes = useStyles();
  const now = new Date().getTime() / 1000;
  const [customText, setCustomText] = useState('');
  const user = useSelector((state: any) => state.authReducer.user);
  const [isProUser, setIsProUser] = React.useState<boolean>(false);

  const getCustomText = async () => {
    try {
      const ref = Fire.store().collection('custom_text').doc('preview_pronos');
      const text = await Fire.doc(ref);
      if (text.football) setCustomText(text.football);
    } catch (err) {
      console.log(err);
    }
  };

  const isPronoCanceled = (
    winner: any,
    dateHideUntil: firebase.firestore.Timestamp,
  ) => !winner && (!dateHideUntil || dateHideUntil.seconds < now);

  const [validPronos, setValidPronos] = useState<ValidPronos>({
    all: [],
    lowRisks: [],
    highRisks: [],
    pronosLength: 0,
  });
  const [futurePronos, setFuturePronos] = useState<FuturePronos>({
    canceled: [],
    available: [],
  });

  useEffect(() => {
    if (pronos) {
      setFuturePronos(getHiddenPronos());
      setValidPronos(getValidPronos());
    }
  }, [pronos, isProUser]);

  React.useEffect(() => {
    getCustomText();
  }, []);

  const getValidPronos = () => {
    const validPronosFiltered = getValidPronosFiltered(pronos, true, isProUser);

    const lowRisks = validPronosFiltered.filter(
      prono => getInfosPronoLibreSoccer(prono, t).pronoPercentage >= 56,
    );

    const highRisks = validPronosFiltered.filter(
      prono => getInfosPronoLibreSoccer(prono, t).pronoPercentage < 56,
    );

    return {
      all: validPronosFiltered,
      lowRisks,
      highRisks,
      pronosLength: validPronosFiltered.length,
    };
  };

  const getHiddenPronos = () => {
    const dateNow = Date.now();
    const hiddenPronos = pronos.filter(
      prono =>
        !prono.team1 || (prono.hide_until && prono.hide_until.seconds > now),
    );

    const hiddenPronosFiltered = {
      available: [
        ...hiddenPronos
          .filter(prono => !isPronoCanceled(prono.team1, prono.hide_until))
          .sort((a, b) => a.hide_until.seconds - b.hide_until.seconds),
      ],
      canceled: [
        ...hiddenPronos.filter(prono =>
          isPronoCanceled(prono.team1, prono.hide_until),
        ),
      ],
    };
    return hiddenPronosFiltered;
  };

  const verifyIsProUser = React.useCallback(async () => {
    const ref = Fire.store()
      .collection('pros')
      .where('email', '==', user.email);
    const list = await Fire.list(ref);
    setIsProUser(!!list.length);
  }, [user.email]);

  React.useEffect(() => {
    verifyIsProUser();
  }, []);

  function checkRef() {
    const cardContainer = document.querySelector('.cardContainer');
    const singleCard = cardContainer?.querySelector('.singleCard');

    cardContainer &&
      singleCard &&
      (cardContainer.scrollLeft += singleCard.clientWidth + 32);
  }

  return (
    <>
      {validPronos.pronosLength !== 0 && (
        <Grid item xs={12}>
          <PreviewTitle
            title="Analyses"
            whiteColor
            fontWeight="600"
            marginBottom
          />
        </Grid>
      )}
      <Grid
        item
        container
        xs={12}
        className={clsx(mobile ? 'marginAuto zIndex1' : 'zIndex1')}
      >
        <Grid item container xs={12}>
          {validPronos.pronosLength > 0 && (
            <Grid container className={classes.gridContainer}>
              {validPronos.lowRisks.length > 3 && (
                <Box
                  position="absolute"
                  onClick={checkRef}
                  width={30}
                  height="auto"
                  top="50%"
                  sx={{
                    display: { xs: 'none', sm: 'block' },
                  }}
                  style={{
                    transform: 'translate(100%, -75%)',
                    right: 20,
                    cursor: 'pointer',
                  }}
                >
                  <img
                    src={arrowRightSolid}
                    alt="Data-Sport arrow-right"
                    id="lowPronoCard"
                  />
                </Box>
              )}
              <Grid
                item
                container
                xs={12}
                className={clsx('cardContainer', classes.singleCardContainer)}
                data-tour="valid-pronos-step"
              >
                {!isLoading ? (
                  validPronos.pronosLength > 3 ? (
                    validPronos.lowRisks.map((prono, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        className={clsx(
                          'singleCard',
                          classes.singleCard,
                          index === validPronos.pronosLength - 1 &&
                            validPronos.pronosLength % 2 === 1 &&
                            validPronos.pronosLength !== 1 &&
                            classes.last,
                          validPronos.pronosLength === 1 && classes.fullWidth,
                        )}
                        key={index}
                      >
                        <CardPredictionFootball
                          {...prono}
                          blue
                          setProno={setProno}
                        />
                      </Grid>
                    ))
                  ) : (
                    validPronos.all.map((prono, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        data-tour={index === 0 ? 'click-prono-step' : ''}
                        className={clsx(
                          classes.singleCard,
                          index === validPronos.pronosLength - 1 &&
                            validPronos.pronosLength % 2 === 1 &&
                            validPronos.pronosLength !== 1 &&
                            classes.last,
                          validPronos.pronosLength === 1 && classes.fullWidth,
                        )}
                        key={index}
                      >
                        <CardPredictionFootball
                          {...prono}
                          blue
                          setProno={setProno}
                        />
                      </Grid>
                    ))
                  )
                ) : (
                  <Box
                    height="100%"
                    minHeight="150px"
                    width="80%"
                    margin="auto"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    color="primary.dark"
                  >
                    <Typography variant="h4">
                      {t('Chargement des analyses...')}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          )}
          {validPronos.pronosLength > 3 && (
            <Grid container className={classes.gridContainer}>
              {validPronos.highRisks.length > 3 && (
                <Box
                  position="absolute"
                  onClick={checkRef}
                  width={40}
                  height="auto"
                  top="50%"
                  sx={{
                    display: { xs: 'none', sm: 'block' },
                  }}
                  style={{ transform: 'translate(100%, -75%)', right: 20 }}
                >
                  <img
                    src={arrowRightSolid}
                    alt="Data-Sport arrow-right"
                    id="lowPronoCard"
                  />
                </Box>
              )}
              <Grid
                item
                container
                xs={12}
                className={classes.singleCardContainer}
              >
                {!isLoading ? (
                  validPronos.highRisks.map((prono, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      className={clsx(
                        classes.singleCard,
                        index === validPronos.pronosLength - 1 &&
                          validPronos.pronosLength % 2 === 1 &&
                          validPronos.pronosLength !== 1 &&
                          classes.last,
                        validPronos.pronosLength === 1 && classes.fullWidth,
                      )}
                      key={index}
                    >
                      <CardPredictionFootball
                        {...prono}
                        blue
                        setProno={setProno}
                      />
                    </Grid>
                  ))
                ) : (
                  <Box
                    height="100%"
                    minHeight="150px"
                    width="80%"
                    margin="auto"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    color="primary.dark"
                  >
                    <Typography variant="h4">
                      {t('Chargement des analyses...')}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
        {(futurePronos.canceled?.length > 0 ||
          futurePronos.available?.length > 0) && (
          <Grid item xs={12}>
            {futurePronos.available?.length > 0 && (
              <Grid item xs={12}>
                <PreviewTitle
                  title="Analyses en cours de calcul"
                  whiteColor
                  fontWeight="600"
                  marginBottom
                />
              </Grid>
            )}
            {futurePronos.available?.length > 0 && (
              <Grid item xs={12} className={classes.bigCard}>
                <Box
                  padding={mobile ? '20px' : '26px'}
                  paddingBottom={mobile ? '0px' : 'inherit'}
                  width="100%"
                  height="100%"
                  marginLeft="-.5rem"
                >
                  <Box width="100%">
                    <Grid container className={classes.rowContainer}>
                      {futurePronos.available.map(hiddenProno => (
                        <Grid item xs={12} key={hiddenProno.id}>
                          <RowPrediction {...hiddenProno} />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            )}
            {futurePronos.canceled?.length > 0 && (
              <Grid item xs={12} className={classes.bigCard}>
                <Box
                  padding={mobile ? '20px' : '26px'}
                  paddingBottom={mobile ? '0px' : 'inherit'}
                  width="100%"
                  height="100%"
                  marginLeft="-.5rem"
                >
                  <Box
                    color="#848ca5"
                    className="uppercase"
                    marginBottom=".5rem"
                  >
                    {/* date */}
                    <Typography variant="h4" component={'span'}>
                      <Box
                        fontWeight="500"
                        fontFamily="BigNoodleTitling"
                        paddingLeft="1rem"
                        color="red"
                        letterSpacing="1.5px"
                      >
                        {t("MATCHS NON CHOISIS PAR L'ALGORITHME")}
                      </Box>
                    </Typography>
                  </Box>
                  <Box width="100%" data-tour="canceled-pronos-step">
                    <Grid container className={classes.rowContainer}>
                      {futurePronos.canceled.map(hiddenProno => (
                        <Grid item xs={12} key={hiddenProno.id}>
                          <RowPrediction {...hiddenProno} />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        )}
        {isLoading ? (
          <Loading nobg />
        ) : (
          validPronos.pronosLength === 0 &&
          (!futurePronos ||
            (futurePronos.canceled?.length === 0 &&
              futurePronos.available?.length === 0)) && (
            <Grid
              item
              container
              xs={12}
              className={clsx(classes.singleCardContainer, 'noPaddingRight')}
            >
              <Box
                height="100%"
                minHeight="150px"
                width="80%"
                margin="auto"
                display="flex"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                color="white"
              >
                <Typography variant="h4">{t(customText)}</Typography>
              </Box>
            </Grid>
          )
        )}
      </Grid>
    </>
  );
};

export default FootballTab;
