import React, { useState } from 'react';
import {
  Card,
  CardActions,
  Typography,
  Button,
  Box,
  Link,
} from '@material-ui/core/';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Theme,
  useTheme,
  createStyles,
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import HomeStars1 from 'images/homePage/HomeStars1.svg';
import HomeStars2 from 'images/homePage/HomeStars2.svg';
import HomeStars3 from 'images/homePage/HomeStars3.svg';
import HomeStars4 from 'images/homePage/HomeStars4.svg';
import HomeStars5 from 'images/homePage/HomeStars5.svg';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange, deepPurple, green, pink } from '@material-ui/core/colors';
import moment from 'moment';

type reviewProps = {
  id: number;
  pseudo: string;
  note: number;
  date: Date;
  place: string;
  comment: string;
  photoUrl: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
    },
    purple: {
      color: theme.palette.getContrastText(deepPurple[500]),
      backgroundColor: deepPurple[500],
    },
    pink: {
      color: theme.palette.getContrastText(pink[500]),
      backgroundColor: pink[500],
    },
    green: {
      color: '#fff',
      backgroundColor: green[500],
    },

    red: {
      backgroundColor: '#FCECE3',
      color: '#A14326',
    },
    blue: {
      backgroundColor: '#E1F0EE',
      color: '#125960',
    },
    yellow: {
      backgroundColor: '#FAF7D9',
      color: '#DA9640',
    },
  }),
);

const Review = props => {
  const { pseudo, note, date, comment, photoUrl, title, ...other } = props;
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const smallComputer = useMediaQuery(theme.breakpoints.only('sm'));
  const [readMore, setReadMore] = useState(false);
  const { t } = useTranslation();

  const randomColor = () => {
    const rand = Math.floor(Math.random() * 7) + 1;

    switch (rand) {
      case 1:
        return classes.orange;
      case 2:
        return classes.purple;
      case 3:
        return classes.pink;
      case 4:
        return classes.green;
      case 5:
        return classes.blue;
      case 6:
        return classes.red;
      case 7:
        return classes.yellow;
    }
  };

  const starsUrl = note => {
    switch (note) {
      case 5:
        return HomeStars5;
      case 4:
        return HomeStars4;
      case 3:
        return HomeStars3;
      case 2:
        return HomeStars2;
      case 1:
        return HomeStars1;
    }
  };

  return (
    <Box
      boxShadow="4px 4px 9px 1px rgba(0, 0, 0, 0.1);"
      borderRadius="11px"
      padding="1.5rem"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      width="100%"
      minHeight={mobile ? '' : '260px'}
    >
      <Box display="flex" flexDirection="row" flexWrap="nowrap">
        <Box display="flex" alignItems="center" justifyContent="center">
          <Avatar
            src={photoUrl || ''}
            alt="pseudo"
            className={randomColor()}
            style={{ fontWeight: 600, width: 50, height: 50, fontSize: 22 }}
          >
            {!photoUrl &&
              pseudo.split(' ').map((word, index) => {
                return index <= 2 && word.charAt(0);
              })}
          </Avatar>
        </Box>
        <Box
          width={'auto'}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          marginLeft={'1rem'}
          textAlign="left"
          height="100%"
        >
          <Typography variant={mobile ? 'h3' : 'h4'} component={'span'}>
            <Box color="#3C4045" fontWeight={600}>
              {pseudo}
            </Box>
          </Typography>
          <Typography variant={mobile ? 'h6' : 'body1'} component={'span'}>
            <Box
              color="#BBBBBB"
              fontWeight={500}
              marginTop={mobile ? '.25rem' : 0}
            >
              {moment.unix(date.seconds).calendar()}
            </Box>
          </Typography>
        </Box>
      </Box>
      <Box color="#6a6f75" marginTop={'.5rem'} textAlign="left">
        <Typography variant={mobile ? 'h4' : 'h6'} component={'span'}>
          <Box fontWeight={600} lineHeight="30px">
            {t(title)}
          </Box>
        </Typography>
        <Typography variant={mobile ? 'h4' : 'h6'} component={'span'}>
          <Box fontWeight={500} lineHeight="24px">
            {comment.length < 140 ? (
              comment
            ) : readMore ? (
              <>
                {comment}
                <Typography variant="h6" component={'span'}>
                  <Box
                    fontWeight={500}
                    lineHeight="24px"
                    color="#28c5fb"
                    width="max-content"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setReadMore(false)}
                  >
                    {t('Lire moins')}
                  </Box>
                </Typography>
              </>
            ) : (
              <>
                {comment.substring(0, 140) + ' ... '}
                <Typography variant="h6" component={'span'}>
                  <Box
                    fontWeight={500}
                    lineHeight="24px"
                    color="#28c5fb"
                    display="inline"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setReadMore(true)}
                  >
                    {t('Lire plus')}
                  </Box>
                </Typography>
              </>
            )}
          </Box>
        </Typography>
      </Box>
      <Box
        marginTop="1rem"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
      >
        <img
          src={starsUrl(note)}
          alt="Data-Sport stars-review"
          style={{ height: mobile ? '13px' : '15px' }}
        />
      </Box>
    </Box>
  );
};

export default Review;
