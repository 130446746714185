import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme, createStyles, Theme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fade from '@material-ui/core/Fade';
import { Grid } from '@material-ui/core';
import '../../theme/datasportTheme.css';
import Navbar from '../Dashboard/Templates/Navbar/Navbar';
import LeftNavbar from '../Dashboard/Templates/Navbar/LeftNavbar';
import {
  Configure,
  Index,
  InstantSearch,
  Pagination,
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import MySearchBox from '../Dashboard/Templates/algolia/MySearchBox/MySearchBox';
import ArticleHits from './ArticleHits';
import CustomRefinementList from './CustomRefinementList';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dashboard: {
      maxWidth: '100vw',
      minHeight: '100vh',
    },
    bgDark: {
      background: '#0a1437',
      overflowX: 'hidden',
    },
    bgLight: {
      background: '#fcfcfa',
    },
    container: {
      maxWidth: 1300,
      margin: 'auto',
      width: '100%',
    },
    cardContainer: {
      maxWidth: 1500,
      margin: '3rem auto 0',
      width: '100%',
      padding: '2rem',
      // background: '#0B0D1E66',
      borderRadius: '30px',
    },
    pageBackgroundBlue: {
      zIndex: -1,
      background: '#0a1437',
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      top: 0,
      left: 0,
    },
    pageBackgroundBlueGradient: {
      backgroundImage:
        'linear-gradient(108deg, #6d7fff9e -1%, rgba(84, 114, 255, 0.05) 46%, rgba(84, 114, 255, 0.05) 46%)',
      zIndex: 2,
      opacity: 0.36,
      content: '""',
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      top: 0,
      left: 0,
      pointerEvents: 'none',
    },
    appBarShift: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: `calc(100% - ${theme.spacing(7) + 1}px)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${theme.spacing(9) + 1}px)`,
      },
    },
    appBarShiftOpen: {
      width: `calc(100% - ${drawerWidth}px)`,
      '& > div': {
        width: '100%',
        margin: 0,
      },
    },
    appBarShiftMobile: {
      width: '100%',
    },
    card: {
      borderRadius: '25px',
      padding: 0,
      background: 'rgba(24, 36, 79, 1)',
      paddingBottom: '1rem',
      cursor: 'pointer',
      marginBottom: '2rem',
      width: '100%',
    },
    image: {
      height: 320,
      width: '90%',
      margin: 'auto',
      borderRadius: '25px',
      marginTop: '5%',
    },
    textFieldWhite: {
      '& .MuiInputLabel-outlined': {
        color: 'white',
      },
      '&  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':
        {
          color: 'white',
        },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'solid 2px white !important',
      },
      [theme.breakpoints.down('sm')]: {
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'solid 1px white !important',
        },
      },
    },
    autocompleteWhite: {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        width: '200px',
      },
      '& .MuiSvgIcon-root': {
        color: 'white',
      },
    },
  }),
);

const Blog = () => {
  const classes = useStyles();
  const theme = useTheme();
  const user = useSelector((state: any) => state.authReducer.user);
  const [isDarkTheme, setIsDarkTheme] = React.useState(true);
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const spacing = mobile ? 3 : 6;
  const [open, setOpen] = React.useState(true);

  const searchClient = algoliasearch(
    'JYT36W9CI0',
    '68806a66c6f9d979d936fb877b6486a8',
  );

  return (
    <React.Fragment>
      <Box
        className={clsx(
          user ? classes.dashboard : '',
          isDarkTheme ? classes.bgDark : classes.bgLight,
        )}
      >
        <Fade in timeout={500}>
          <>
            {isDarkTheme && (
              <>
                <div className={classes.pageBackgroundBlue} />
                <div className={classes.pageBackgroundBlueGradient} />
              </>
            )}
            {mobile && <Navbar noRadius transparent collapsed />}
            <Box display="flex">
              {!mobile && <LeftNavbar open={open} setOpen={setOpen} />}
              <Box
                className={clsx(classes.appBarShift, {
                  [classes.appBarShiftOpen]: open,
                  [classes.appBarShiftMobile]: mobile,
                })}
              >
                <Grid container>
                  <InstantSearch
                    indexName="articlesDatasport"
                    searchClient={searchClient}
                  >
                    <Grid
                      container
                      spacing={spacing}
                      className={user ? classes.container : ''}
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <div className={classes.cardContainer}>
                        <MySearchBox
                          placeholder={t('Rechercher un article...')}
                        />
                      </div>
                      <Box sx={{ width: '100%' }}>
                        <Index indexName="articlesDatasport">
                          <CustomRefinementList attribute="theme.label" />
                          <ArticleHits />
                          <Configure hitsPerPage={8} />
                          {/* <Pagination /> */}
                        </Index>
                      </Box>
                    </Grid>
                  </InstantSearch>
                </Grid>
              </Box>
            </Box>
          </>
        </Fade>
      </Box>
    </React.Fragment>
  );
};

export default Blog;
