import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { connectPagination } from 'react-instantsearch-dom';
import React from 'react';

const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => {
  if (!nbPages) return <></>;
  return (
    <ul
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '2rem',
      }}
    >
      <li
        style={{
          width: 35,
          height: 35,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: currentRefinement !== 1 ? 'pointer' : 'default',
          listStyle: "''",
        }}
      >
        <a
          onClick={() => {
            refine(
              currentRefinement === 1
                ? currentRefinement
                : currentRefinement - 1,
            );
          }}
          style={{ display: 'flex' }}
        >
          <ArrowBackIosRoundedIcon
            fontSize="small"
            color={currentRefinement !== 1 ? 'disabled' : 'inherit'}
            style={{
              color:
                currentRefinement === 1
                  ? 'rgba(132, 140, 165, .51)'
                  : 'rgb(132, 140, 165)',
            }}
          />
        </a>
      </li>
      {new Array(nbPages).fill(null).map((_, index) => {
        const page = index + 1;
        const nbItemsToShow = 5;
        // Script pour qu'il y ait toujours le même nombre d'index affichés (ici 5)
        if (
          currentRefinement === 1 || currentRefinement === 2
            ? page > nbItemsToShow
            : currentRefinement === nbPages || currentRefinement === nbPages - 1
            ? page < nbPages - nbItemsToShow + 1
            : page < currentRefinement - Math.floor(nbItemsToShow / 2) ||
              page > currentRefinement + Math.floor(nbItemsToShow / 2)
        )
          return null;
        return (
          <li
            key={index}
            style={{
              margin: '0 .5rem',
              width: 35,
              height: 35,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              listStyle: "''",
              borderRadius: '50%',
              fontWeight: 'bold',
              border: currentRefinement === page ? 'solid 1px #d2dbed' : 'none',
              background: currentRefinement === page ? 'white' : 'transparent',
            }}
          >
            <a
              href={createURL(page)}
              style={{
                fontWeight: currentRefinement === page ? 'bold' : 'inherit',
                color: '#848ca5',
                textDecoration: 'none',
                textTransform: 'none',
                fontSize: '.85rem',
              }}
              onClick={event => {
                event.preventDefault();
                refine(page);
              }}
            >
              {page}
            </a>
          </li>
        );
      })}
      <li
        style={{
          width: 35,
          height: 35,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: currentRefinement !== nbPages ? 'pointer' : 'default',
          listStyle: "''",
        }}
      >
        <a
          onClick={() => {
            refine(
              currentRefinement === nbPages
                ? currentRefinement
                : currentRefinement + 1,
            );
          }}
          style={{ display: 'flex' }}
        >
          <ArrowForwardIosRoundedIcon
            fontSize="small"
            color={currentRefinement === nbPages ? 'disabled' : 'inherit'}
            style={{
              color:
                currentRefinement === nbPages
                  ? 'rgba(132, 140, 165, .51)'
                  : 'rgb(132, 140, 165)',
            }}
          />
        </a>
      </li>
    </ul>
  );
};

export default connectPagination(Pagination);
