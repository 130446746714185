import React from 'react';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { Tournament } from '../../../../../types/soccer/tournament';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

type LeagueStyles = {
  mobile: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tournamentTab: {
      filter: 'grayscale(90%)',
      transition: 'filter .2s ease-in-out',
      opacity: '.48',
      boxShadow: '0 6px 25px -4px rgba(118, 148, 255, 0.42)',
      '&.Mui-selected, &:hover': {
        filter: 'grayscale(0%)',
        border: `6px solid ${theme.palette.secondary.main}`,
        opacity: '1',
        '& > span': {
          '& > div': {
            width: '100%',
            height: '100%',
          },
        },

        [theme.breakpoints.down('xs')]: {
          border: `3px solid ${theme.palette.secondary.main}`,
        },
      },
      '& > span': {
        height: '100%',
        width: '100%',
        '& > div': {
          width: 'calc(100% - 12px)',
          height: 'calc(100% - 12px)',
          [theme.breakpoints.down('xs')]: {
            width: 'calc(100% - 6px)',
            height: 'calc(100% - 6px)',
          },
        },
      },
      width: ({ mobile }: LeagueStyles) => (mobile ? 50 : 77),
      height: ({ mobile }: LeagueStyles) => (mobile ? 50 : 77),
      zIndex: 10,
      margin: ({ mobile }: LeagueStyles) => (mobile ? '0 10px' : '0px 30px'),
      padding: 0,
      background: 'white',
      borderRadius: '50%',
      minWidth: '0px',
    },
  }),
);

type LeagueProps = {
  readonly tournament: Tournament;
  readonly mobile: boolean;
  readonly language: string;
  readonly selected: boolean;
  readonly onClick: () => void;
};

const LeagueTab: React.FC<LeagueProps> = ({
  tournament,
  mobile,
  language,
  selected,
  onClick,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ mobile });
  return (
    <Box textAlign="center" fontWeight="500">
      <Tab
        label={<Avatar src={tournament.logo} />}
        className={clsx({
          [classes.tournamentTab]: true,
          'Mui-selected': selected,
        })}
        onClick={onClick}
      />
      <Typography style={{ marginTop: '.5rem' }}>
        {t(tournament.name[language] || tournament.name)}
      </Typography>
    </Box>
  );
};

export default LeagueTab;
