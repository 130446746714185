import React from 'react';
import {
  makeStyles,
  Theme,
  useTheme,
  createStyles,
} from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import { Grid, Typography } from '@material-ui/core';
import '../../../../theme/_colors.scss';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fade from '@material-ui/core/Fade';
import { SoccerTeam } from '../../../../types/soccer/soccer_teams';
import { getCountryLogo } from '../../../../utils/country';
import { getPlayerName } from '../../../../utils/soccer';
import TeamHitWins from '../../Library/TeamsPlayers/TeamHits/TeamHitWins/TeamHitWins';
import { useHistory } from 'react-router-dom';
import Logo from '../Logos/Logo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      backgroundColor:
        theme.palette.type === 'dark' ? 'rgba(24,36,79,0.85)' : '#f8f9fa',
      borderRadius: '15px',
      border: 'none',
      boxShadow: 'none',
      minHeight: 'unset',
      padding: 12,

      '&:hover': {
        boxShadow: '0 5px 10px 0 rgba(10, 22, 62, 0.08)',
      },
    },
    img: {
      borderRadius: '50px',
      [theme.breakpoints.down('sm')]: {
        width: 20,
        height: 20,
      },
    },
    countryImg: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        width: 20,
        height: 11,
      },
    },
    typo: {
      height: '100%',
      alignItems: 'center',
    },
  }),
);

type TeamItemProps = {
  team: SoccerTeam;
};

const TeamItem: React.FC<TeamItemProps> = ({ team }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Fade in={true} timeout={500}>
      <ListItem
        style={{ cursor: 'pointer' }}
        component={Card}
        className={classes.card}
        onClick={() => history.push(`/team/${team.id}`)}
      >
        <Grid
          container
          item
          xs
          alignContent="center"
          justify="center"
          alignItems="center"
        >
          <Grid
            container
            item
            alignItems="center"
            justify="space-around"
            xs={mobile ? 5 : 4}
          >
            <Grid
              container
              item
              xs={3}
              alignItems="center"
              alignContent="center"
            >
              <Logo
                src={team?.logo}
                width={mobile ? 30 : 50}
                typeImgDefault="club"
                imgDefautl="FOOTBALL"
                noBg
                style={{ height: 'auto' }}
              />
            </Grid>
            <Grid item container xs={6} alignItems="center">
              <Typography className="typo bold">{team?.name}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Logo
                src={getCountryLogo(team?.data?.competitor.country_code)}
                width={mobile ? 30 : 40}
                typeImgDefault="club"
                imgDefautl="FOOTBALL"
                noBg
              />
            </Grid>
          </Grid>
          {!mobile ? (
            <Grid
              container
              item
              xs={6}
              alignContent="center"
              justify="space-around"
              alignItems="center"
            >
              <Grid item xs={6}>
                <ListItemText
                  primary={getPlayerName(team?.data?.manager?.name)}
                />
              </Grid>
              <Grid item xs={6}>
                <ListItemText primary={team?.data?.venue?.name} />
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              item
              xs={3}
              alignContent="center"
              justify="space-around"
              alignItems="center"
            >
              <Grid item xs={12}>
                <ListItemText
                  primary={getPlayerName(team?.data?.manager?.name)}
                />
              </Grid>
            </Grid>
          )}
          <Grid
            container
            item
            xs={4}
            sm={2}
            alignContent="center"
            justify="space-around"
            alignItems="center"
          >
            <Grid item container xs={12} justify="center">
              <TeamHitWins wnl={team?.wnl} />
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
    </Fade>
  );
};

export default TeamItem;
