import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import Logo from '../../../Templates/Logos/Logo';
import small_ball from 'images/small_ball.svg';
import {
  Competitor,
  Player,
  StatCompetitor,
} from '../../../../../types/sportradar/soccer/sport-event';

type TeamInfoProps = {
  mobile: boolean;
  competitor: Competitor;
  statCompetitor: StatCompetitor;
  goToTeamPage: () => void;
};

type GoalRowProps = { mobile: boolean; players: Player[] };

type CardsRowProps = { mobile: boolean; color: string; players: Player[] };

const GoalsRow: React.FC<GoalRowProps> = ({ mobile, players }) => {
  if (!players?.length) return <></>;
  return (
    <Typography variant="h6" component={'span'}>
      <Box
        color="white"
        fontWeight={500}
        marginTop=".75rem"
        display="flex"
        flexDirection={mobile ? 'column' : 'row'}
        alignItems="center"
      >
        <img
          src={small_ball}
          alt="Data-Sport goals"
          width="17.5px"
          height="17.5px"
          style={{ marginRight: '1rem' }}
        />
        {players
          .map(({ name }) => name.split(',').reverse().join(' '))
          .join(', ')}
      </Box>
    </Typography>
  );
};

const CardsRow: React.FC<CardsRowProps> = ({ mobile, color, players }) => {
  if (!players?.length) return <></>;
  return (
    <Typography variant="h6" component={'span'}>
      <Box
        color="white"
        fontWeight={500}
        marginTop=".75rem"
        display="flex"
        flexDirection={mobile ? 'column' : 'row'}
        alignItems="center"
      >
        <Box
          width="12px"
          height="16px"
          bgcolor={color}
          borderRadius="1px"
          style={{ marginRight: '1rem' }}
        />
        {players
          .map(({ name }) => name.split(',').reverse().join(' '))
          .join(', ')}
      </Box>
    </Typography>
  );
};

const TeamInfo: React.FC<TeamInfoProps> = ({
  mobile,
  competitor,
  statCompetitor,
  goToTeamPage,
}) => {
  return (
    <Grid
      item
      container
      justify="center"
      alignItems="center"
      xs={4}
      style={{ cursor: 'pointer' }}
      onClick={goToTeamPage}
    >
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        position="relative"
      >
        <Box
          height="50%"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Logo
            src={competitor.icon}
            width={mobile ? 60 : 120}
            club={competitor.country !== competitor.name}
            typeImgDefault="club"
            imgDefautl="FOOTBALL"
          />
        </Box>
        <Box
          height="50%"
          display="flex"
          width="100%"
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="column"
          textAlign="center"
          marginTop="1rem"
        >
          <Typography variant={mobile ? 'h4' : 'h3'} component={'span'}>
            <Box color="white" fontWeight={600}>
              {mobile ? competitor.abbreviation : competitor.name}
            </Box>
          </Typography>
          <GoalsRow
            mobile={mobile}
            players={statCompetitor.players?.filter(
              ({ statistics }) => statistics.goals_scored > 0,
            )}
          />
          <CardsRow
            mobile={mobile}
            color={'#e0153f'}
            players={statCompetitor.players?.filter(
              ({ statistics }) => statistics.red_cards > 0,
            )}
          />
          <CardsRow
            mobile={mobile}
            color={'#f5ef69'}
            players={statCompetitor.players?.filter(
              ({ statistics }) => statistics.yellow_cards > 0,
            )}
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default TeamInfo;
