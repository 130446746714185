import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './App.scss';
import ScrollToTop from 'react-router-scroll-top';
import { Fire } from 'services';
import {
  autologin,
  finishLogin,
  saveInfo,
  updateUser,
} from './actions/auth.action';
import routes from './routes';
import { ThemeProvider } from '@material-ui/styles';
import { Sport } from './components/Dashboard/Home/ToggleSport';
import { getCollectionPath } from './components/Dashboard/Pronos/Pronos';
import { getPronosDate } from './components/Dashboard/utils/prono';
import { Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/it';
import { getGeolocation } from 'actions/geolocation.action';
import { Helmet } from 'react-helmet';
import { getUserLocation } from './utils/countryCodeAPI';
import { darkTheme } from './theme/_datasportDarkTheme';
import Loading from 'components/Loading/Loading';
import lienPayement from './utils/linkPayments';

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    ml: true;
    lg: true;
    xl: true;
  }
}

const verifyUserPaymentStripe = async id => {
  let breakF = false;
  let isVIP = false;
  try {
    const prosRef = await Fire.store().collection('pros').doc(id);
    await prosRef.get().then(pro => {
      if (pro.exists) {
        isVIP = true;
      } else {
        isVIP = false;
      }
    });

    if (isVIP) {
      return true;
    }

    const customerRef = await Fire.store()
      .collection('stripe_customers')
      .doc(id);
    const customer = await Fire.doc(customerRef);
    if (customer) {
      let customer_infos;
      try {
        customer_infos = await Fire.cloud('listSubsByCustomer', {
          customerId: customer.customer_id,
        });
      } catch (error) {
        console.log('error listSubsByCustomer', error);
      }

      customer_infos?.data?.forEach(sub => {
        if (
          (sub.status === 'active' ||
            sub.status === 'trialing' ||
            sub.status === 'past_due') &&
          !breakF
        ) {
          isVIP = true;
          breakF = true;
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
  return isVIP;
};

const verifyUserPaymentMarketpay = async user => {
  console.log('verifyUserPaymentMarketpay', user);
  let isVIP = false;
  const { paymentMarketPay } = user;

  try {
    const scheduleId =
      paymentMarketPay?.[paymentMarketPay.length - 1]?.schedules[0]?.scheduleId;
    const response = await Fire.cloud('getScheduleStatus', scheduleId);
    if (response.success === false) {
      isVIP = false;
    } else if (response.newStatus === 'ACTIVE') {
      isVIP = true;
    } else {
      isVIP = false;
    }
  } catch (error) {
    console.log(error);
  }
  return isVIP;
};

const verifyUserPro = async id => {
  let isVIP = false;
  const prosRef = await Fire.store().collection('pros').doc(id);
  await prosRef.get().then(pro => {
    if (pro.exists) {
      isVIP = true;
    } else {
      isVIP = false;
    }
  });
  return isVIP;
};

const VerifyUserVIP = async (user, history) => {
  if (!user?.id) return false;

  let isUserPro = await verifyUserPro(user.id);
  if (isUserPro) return true;

  if (user.paymentType === 'marketpay') {
    if (!user.state?.isExternRegisteringMarketPay) {
      return await verifyUserPaymentMarketpay(user);
    } else {
      return false;
    }
  } else {
    return await verifyUserPaymentStripe(user.id);
  }
};

const VerifyUserLoc = async (customer, dispatch) => {
  try {
    if (customer && !customer.location?.countryCode) {
      const data = await getUserLocation();
      dispatch(
        updateUser({
          location: {
            state: data?.regionName || '',
            countryCode: data?.countryCode || 0,
          },
        }),
      );
    }
    return;
  } catch (error) {
    console.log(error);
  }
};

const isUserInfoValid = user => {
  if (!user) return true;
  return (
    !!user.email?.trim().length &&
    !!user.last_name?.trim().length &&
    !!user.first_name?.trim().length
  );
};

const isThereValidPronos = prono => {
  const now = new Date().getTime() / 1000;
  return (
    !prono.hide_until ||
    (prono.team1 && prono.hide_until?.seconds <= now && prono.team1?.name)
  );
};
const isThereFuturePronos = prono => {
  const now = new Date().getTime() / 1000;
  return !prono.team1 || (prono.hide_until && prono.hide_until.seconds) > now;
};

const ALL_SPORT: Sport[] = [
  'FOOTBALL',
  'TENNIS',
  'NBA',
  'NFL',
  'NHL',
  'BASEBALL',
];

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.authReducer.user);
  const [loaded, setLoaded] = React.useState(false);
  const [title, setTitle] = React.useState('Data-Sport - The Sports news');
  const history = useHistory();
  const { i18n } = useTranslation();
  const selectedSport: Sport = useSelector(
    (state: any) => state.sportsReducer.selectedSport,
  );

  const initializeLanguage = (): void => {
    const query = new URLSearchParams(history.location.search);
    const language = query.get('lang');
    moment.locale(i18n.language);
    if (language) {
      i18n.changeLanguage(language);
      moment.locale(language);
      if (user) {
        dispatch(
          updateUser({
            language,
          }),
        );
      }
    }
  };

  const changeSport = (newSport: Sport) => {
    dispatch({ type: 'CHANGE_SPORT', payload: { newSport } });
  };

  const getAllPronos = async collection => {
    const collectionPath = getCollectionPath(collection);
    const ref = await Fire.store()
      .collection(collectionPath)
      .where('date', '>=', getPronosDate());
    return Fire.list(ref);
  };

  const isProUser = async (email: string) => {
    const ref = Fire.store().collection('pros').where('email', '==', email);
    const list = await Fire.list(ref);
    return list.length !== 0;
  };

  const verifyPronos = async () => {
    let indexSelectedSport = ALL_SPORT.indexOf(selectedSport);
    ALL_SPORT.splice(indexSelectedSport, 1);
    ALL_SPORT.unshift(selectedSport);
    const promises = ALL_SPORT.map(async (sport: Sport) => getAllPronos(sport));
    const allPronos = await Promise.all(promises);
    const isPro = await isProUser(user?.email || '');

    const filteredPronos = allPronos.map(pronos =>
      pronos
        .filter(prono =>
          prono.isPronoTest || prono.name_inactive ? isPro : true,
        )
        .filter(prono =>
          selectedSport === 'NBA' ||
          selectedSport === 'NFL' ||
          selectedSport === 'NHL'
            ? user?.location?.countryCode === 'US'
              ? prono.matchOddsUSActive && prono.matchOddsUS
              : true
            : true,
        ),
    );

    let isValidPronoAvailable = false;
    let collection;
    let isFuturePronoAvailable = false;
    let futureCollection;

    filteredPronos.forEach((collectionProno, i) => {
      dispatch({
        type: 'ADD_PRONOS',
        payload: { sport: ALL_SPORT[i], pronos: collectionProno },
      });
      if (!isValidPronoAvailable && collectionProno.length !== 0) {
        if (collectionProno.some(isThereValidPronos)) {
          isValidPronoAvailable = true;
          collection = ALL_SPORT[i];
        }
        if (
          !isFuturePronoAvailable &&
          collectionProno.some(isThereFuturePronos)
        ) {
          isFuturePronoAvailable = true;
          futureCollection = ALL_SPORT[i];
        }
      }
    });

    collection
      ? changeSport(collection)
      : futureCollection && changeSport(futureCollection);
  };

  const refreshPronos = async () => {
    const promises = ALL_SPORT.map(async (sport: Sport) => getAllPronos(sport));
    const allPronos = await Promise.all(promises);
    const isPro = await isProUser(user?.email || '');

    const filteredPronos = allPronos.map(pronos =>
      pronos
        .filter(prono =>
          prono.isPronoTest || prono.name_inactive ? isPro : true,
        )
        .filter(prono =>
          selectedSport === 'NBA' ||
          selectedSport === 'NFL' ||
          selectedSport === 'NHL'
            ? user?.location?.countryCode === 'US'
              ? prono.matchOddsUSActive && prono.matchOddsUS
              : true
            : true,
        ),
    );

    filteredPronos.forEach((collectionProno, i) => {
      if (collectionProno.length !== 0) {
        dispatch({
          type: 'ADD_PRONOS',
          payload: { sport: ALL_SPORT[i], pronos: collectionProno },
        });
      }
    });
  };

  const verifyFirstConnexion = user => {
    let userAgent = navigator.userAgent;
    if (
      !userAgent.match(/chrome|chromium|crios/i) &&
      userAgent.match(/safari/i)
    ) {
      const isFirstConnexion = {
        database: false,
        home1: false,
        home2: false,
        player: false,
        prono: false,
        pronos: false,
      };
      user.isFirstConnexion = isFirstConnexion;
      Fire.update('users', user.id, {
        isFirstConnexion,
      });
    } else if (user.isFirstConnexion === undefined) {
      const isFirstConnexion = {
        database: true,
        home1: true,
        home2: true,
        player: true,
        prono: true,
        pronos: true,
      };
      user.isFirstConnexion = isFirstConnexion;
      Fire.update('users', user.id, {
        isFirstConnexion,
      });
    }
  };

  React.useEffect(() => {
    if (
      !isUserInfoValid(user) &&
      !history.location.pathname.endsWith('oauth')
    ) {
      //TODO Remettre le redirect
      document.location.href = lienPayement[i18n.language];
    }
  }, [history, user]);

  React.useEffect(() => {
    initializeLanguage();
    Fire.init();

    Fire.auth().onAuthStateChanged(async user => {
      if (user) {
        const userDoc = Fire.store().collection('users').doc(user.uid);
        const userData = (await userDoc.get()).data();
        if (userData) userData.id = user.uid;

        const query = new URLSearchParams(history.location.search);
        const language = query.get('lang');
        let newLanguage = i18n.language;

        try {
          if (language) {
            newLanguage = language;
          } else {
            if (userData?.language) {
              newLanguage = userData.language;
            }
          }
        } catch (err) {
          console.log(err);
        }

        i18n.changeLanguage(newLanguage);
        moment.locale(newLanguage);

        if (userData) {
          let isVip = await VerifyUserVIP(userData, history);
          await userDoc.update({ vip: isVip, language: newLanguage });
          VerifyUserLoc(userData, dispatch);
          dispatch(autologin(user));
          await dispatch(finishLogin(i18n.language));
          await verifyPronos();

          i18n.on('languageChanged', lang => {
            Fire.update('users', user.uid, { language: lang });
          });
          if (!!userData.state?.isExternRegisteringMarketPay) {
            history.push('/payment');
          }
        }
      }
      setLoaded(true);
    });
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setInterval(() => {
      refreshPronos();
    }, 1000 * 60 * 2);
  }, []);

  React.useEffect(() => {
    if (user) verifyFirstConnexion(user);
  }, [user]);

  React.useEffect(() => {
    switch (i18n.language) {
      case 'en':
        setTitle('Data-Sport - The Sports news');
        break;
      case 'it':
        setTitle('Data-Sport - Il primo algoritmo sportive');
        break;
      case 'es':
        setTitle('Data-Sport - El primer algoritmo deportivas');
        break;
      case 'fr':
      default:
        setTitle('Data-Sport - The Sports news');
    }
  }, [i18n.language]);

  if (!loaded) return <Loading />;

  const userRoutes = routes.filter(route => {
    if (route.both === true) return true;
    if (!route.userLogged && !user) return true;
    if (route.userLogged && user) return true;
    return false;
  });
  return (
    <ThemeProvider theme={darkTheme}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {/* <ScrollToTop> */}
      <Switch>
        {userRoutes.map((route, index) => (
          <Route
            key={index}
            exact
            path={route.path}
            component={route.component}
          />
        ))}
      </Switch>
      {/* </ScrollToTop> */}
    </ThemeProvider>
  );
}

export default App;
