import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Grid,
  Box,
  Button,
  useTheme,
} from '@material-ui/core';
import { Fire, Time } from 'services';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      borderRadius: '25px',
      padding: 0,
      background: 'rgba(24, 36, 79, 1)',
      paddingBottom: '1rem',
      cursor: 'pointer',
      marginBottom: '2rem',
      width: '100%',
    },
    image: {
      width: '100%',
      margin: 'auto',
      borderRadius: '25px',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
  }),
);

export default function PageArticleTemplate({ article }) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const date = article?.date ? Fire.getDateFor(article?.date) : new Date();
  const full_date = Time.moment(date).format('DD / MM / YYYY');

  return (
    <Box className={classes.container}>
      <Grid container style={{ color: 'white', padding: '2rem 5rem' }}>
        <Button
          onClick={() => history.push('/blog')}
          color="inherit"
          startIcon={<ArrowBackIcon />}
        >
          {t('Retour')}
        </Button>
        <Typography
          variant="h3"
          style={{
            color: 'white',
            paddingBottom: '2rem',
            fontSize: '1rem',
            width: '100%',
          }}
        >
          {full_date}
        </Typography>
        <img
          src={article?.image}
          className={classes.image}
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
            height: mobile ? 200 : 400,
          }}
        />
        <Grid item xs={12}>
          <Typography
            variant="h2"
            style={{ color: 'white', padding: '2rem 0', fontSize: '3rem' }}
          >
            {article?.title}
          </Typography>
          <Box sx={{ textAlign: 'left' }}>
            <Typography
              variant="h3"
              style={{
                color: 'white',
                paddingBottom: '2rem',
                margin: `2% ${mobile ? '15%' : '26%'}`,
                fontSize: '1.3rem',
              }}
            >
              {article?.firstDescription}
            </Typography>
            {article?.description?.fr?.map((content, index) =>
              content?.type === 'file' ? (
                <img
                  src={content?.image}
                  className={classes.image}
                  style={{
                    margin: '2rem',
                    width: '50%',
                    borderRadius: '25px',
                    height: mobile ? '100px' : 'auto',
                    maxHeight: '500px',
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                />
              ) : content?.type === 'text' ? (
                <Typography
                  variant="h4"
                  style={{
                    margin: `2% ${mobile ? '15%' : '26%'}`,
                    fontSize: '1.5rem',
                  }}
                >
                  {content.value}
                </Typography>
              ) : (
                <Typography
                  variant="body1"
                  style={{
                    margin: `2% ${mobile ? '15%' : '26%'}`,
                    fontSize: '1rem',
                  }}
                >
                  {content.value}
                </Typography>
              ),
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
