import React, { useState } from 'react';
import {
  createStyles,
  Fade,
  makeStyles,
  Tab,
  Tabs,
  Theme,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TypeTab from '../TypeTab/TypeTab';
import clsx from 'clsx';
import RankingNBA from '../Ranking/RankingNBA/RankingNBA';
import CalendarNBA from '../Calendar/CalendarNBA/CalendarNBA';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabsLeagues: {
      margin: '1rem auto !important',
      '&>.MuiTabs-scroller>.MuiTabs-flexContainer': {
        height: 115,
        display: 'flex',
        alignItems: 'center',

        [theme.breakpoints.down('xs')]: {
          height: 90,
        },
      },
    },
    tournamentTab: {
      filter: 'grayscale(90%)',
      transition: 'filter .2s ease-in-out',
      opacity: '.48',
      boxShadow: '0 6px 25px -4px rgba(118, 148, 255, 0.42)',
      '&.Mui-selected, &:hover': {
        filter: 'grayscale(0%)',
        border: `6px solid ${theme.palette.secondary.main}`,
        opacity: '1',
        '& > span': {
          '& > div': {
            width: '100%',
            height: '100%',
          },
        },

        [theme.breakpoints.down('xs')]: {
          border: `3px solid ${theme.palette.secondary.main}`,
        },
      },
      '& > span': {
        height: '100%',
        width: '100%',
        '& > div': {
          width: 'calc(100% - 12px)',
          height: 'calc(100% - 12px)',

          [theme.breakpoints.down('xs')]: {
            width: 'calc(100% - 6px)',
            height: 'calc(100% - 6px)',
          },
        },
      },
    },
    tournamentTabTennisNBA: {
      transition: 'all .2s ease-in-out',
      minHeight: '2.5rem',
      minWidth: '10% !important',
      maxWidth: 'none !important',
      width: 'fit-content !important',
      zIndex: 10,
      margin: '0px 15px',
      padding: '0 1rem',
      background: 'white',
      borderRadius: '2rem',
      border: `3px solid transparent`,
      '& > span': {
        fontSize: '.8rem',
      },
      '&.Mui-selected, &:hover': {
        filter: 'grayscale(0%)',
        border: `3px solid ${theme.palette.secondary.main}`,
        opacity: '1',
        '& > span': {
          color: '#0b1847',
        },
      },
    },
  }),
);

type LibraryNBAProps = {
  mediumComputer: boolean;
};

const LibraryNBA: React.FC<LibraryNBAProps> = ({ mediumComputer }) => {
  const classes = useStyles();
  const [selectedTypeTab, setSelectedTypeTab] = useState<number>(0);
  const [selectedTypeNBA, setSelectedTypeNBA] = useState<number>(0);
  const { t } = useTranslation();

  const NBATypes = [
    [
      {
        key: 'rankingsEastern',
        label: t('Est'),
      },
      {
        key: 'rankingsWestern',
        label: t('Ouest'),
      },
    ],
    [
      {
        key: 'regular',
        label: t('Saison régulière'),
      },
      {
        key: 'playoffs',
        label: t('Playoffs'),
      },
    ],
  ];

  return (
    <Grid item xs={12} className={mediumComputer ? 'noPadding' : ''}>
      <TypeTab
        value={selectedTypeTab}
        mediumComputer={mediumComputer}
        onClick={i => setSelectedTypeTab(i)}
      />
      <Fade in={true} timeout={500}>
        <Tabs
          value={selectedTypeNBA}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs library"
          className={classes.tabsLeagues}
          style={{
            borderBottom: 'none',
            fontSize: '3rem',
            color: 'white',
            width: '80%',
            minWidth: '300px',
            margin: 'auto',
          }}
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
        >
          {NBATypes[selectedTypeTab].map((tournament, index) => (
            <Tab
              key={tournament.key}
              label={tournament.label}
              onClick={() => {
                setSelectedTypeNBA(index);
              }}
              className={clsx(
                classes.tournamentTabTennisNBA,
                classes.tournamentTab,
              )}
            />
          ))}
        </Tabs>
      </Fade>
      {selectedTypeTab === 0 && (
        <RankingNBA league={NBATypes[selectedTypeTab][selectedTypeNBA].key} />
      )}
      {selectedTypeTab === 1 && (
        <CalendarNBA
          league={
            NBATypes[selectedTypeTab][selectedTypeNBA].key as
              | 'playoffs'
              | 'regular'
          }
        />
      )}
    </Grid>
  );
};

export default LibraryNBA;
