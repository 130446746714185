import React from 'react';
import {
  makeStyles,
  useTheme,
  createStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Navbar from '../Templates/Navbar/Navbar';
import { Grid, Fade } from '@material-ui/core';
import TopSection from './TopSection/TopSection';
import BottomSection from './BottomSection/BottomSection';
import { useQuery } from 'react-query';
import { Fire } from 'services';
import { useHistory, useParams } from 'react-router-dom';
import BackButton from '../Templates/BackButton/BackButton';
import { FullTeamData } from '../../../types/soccer/team';
import { lightTheme } from '../../../theme/_datasportTheme';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      maxWidth: 1300,
      margin: 'auto',
      width: '100%',
    },
  }),
);

export default function PageTeam() {
  const classes = useStyles();
  const { competitorId } = useParams<{ competitorId: string }>();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();
  const spacing = 3;
  const { data, isError } = useQuery(
    `soccer:team:${competitorId}`,
    () => {
      const ref = Fire.store().collection('soccer_teams').doc(competitorId);
      return Fire.doc<FullTeamData>(ref);
    },
    { staleTime: 900000 /* 15 mn */, retry: false },
  );

  if (isError) history.replace('/');

  return (
    <Fade in={true} timeout={500}>
      <ThemeProvider theme={lightTheme}>
        <Grid container className={classes.container} spacing={spacing}>
          <Grid item container xs={12}>
            <Navbar bigExtend={mobile} extend={!mobile} />
            <BackButton />
          </Grid>
          {data && <TopSection teamData={data.data} />}
          {data && (
            <BottomSection
              players={data.data.players}
              matches={data.lastAndNext}
            />
          )}
        </Grid>
      </ThemeProvider>
    </Fade>
  );
}
