import React from 'react';
import Box from '@material-ui/core/Box';
import { Gender } from '../../../../../../../types/pronoTennis';
import PlayerPhoto from '../../../../../Templates/Tennis/PlayerPhoto';
import { useMediaQuery, useTheme } from '@material-ui/core';

const SinglesImages: React.FC<{ gender: Gender; playerId: string }> = ({
  gender,
  playerId,
}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        borderRadius="50%"
        bgcolor="white"
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginLeft="35px"
        boxShadow="0 0 33px 0 rgb(255 255 255 / 11%)"
      >
        <PlayerPhoto
          playerId={playerId}
          gender={gender}
          height="85%"
          style={{
            width: mobile ? 30 : 40,
            height: mobile ? 30 : 40,
            borderRadius: '50%',
          }}
        />
      </Box>
    </Box>
  );
};

export default SinglesImages;
