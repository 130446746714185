import React, { useState } from 'react';
import {
  makeStyles,
  Theme,
  useTheme,
  createStyles,
} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import PlayerItem from '../../../Templates/Items/PlayerItem';
import TeamItem from '../../../Templates/Items/TeamItem';
import PreviewTitle from '../../../Templates/Misc/PreviewTitle';
import CardGeneral from '../../../Templates/Cards/CardGeneral';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useTranslation } from 'react-i18next';
import SimpleButtonYellow from '../../../Templates/Buttons/SimpleButtonYellow';
import { useQuery } from 'react-query';
import { Fire } from 'services';
import { PlayerSoccerWithPhoto } from '../../../../../types/soccer/soccer_players';
import { SoccerTeam } from '../../../../../types/soccer/soccer_teams';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      margin: theme.spacing(4, 0, 2),
    },
    tabs: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      textTransform: 'none',
      fontWeight: 'bold',
      color:
        theme.palette.type === 'dark'
          ? '#fff !important'
          : '#3c4045 !important',
    },
    appBar: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    topPreview: {
      width: '100%',
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
    },
    swipeable: {
      padding: '20px 0',
    },
    card: {
      height: 'unset',
      minHeight: '23.2rem',
      padding: '10px 25px',
      [theme.breakpoints.down('sm')]: {
        padding: 5,
        width: 'calc(100% + 40px)',
        margin: 0,
      },
      [theme.breakpoints.down('xs')]: {
        minHeight: '27.1rem',
      },
    },
    ListItem: {
      margin: '0',
    },
    refreshButton: {
      marginLeft: 'auto',
    },
  }),
);

export default function PreviewLibrary() {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const { data: players, refetch: refetchPlayers } = useQuery(
    'soccer:preview-players:3random',
    () =>
      Fire.cloud<PlayerSoccerWithPhoto[]>('soccer', {
        method: 'get3RandomPlayers',
      }),
    { staleTime: 900000 /* 15 mn */ },
  );
  const { data: teams, refetch: refetchTeams } = useQuery(
    'soccer:preview-teams:3random',
    () => Fire.cloud<SoccerTeam[]>('soccer', { method: 'get3RandomTeams' }),
    { staleTime: 900000 /* 15 mn */ },
  );

  function TabPanel(props) {
    const { children, value, index } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {value === index && <React.Fragment>{children}</React.Fragment>}
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className={classes.topPreview}>
        <PreviewTitle title={'Database'} marginBottom />
        <SimpleButtonYellow
          title={t('de données')}
          addIcon={true}
          path="library"
        />
      </div>
      <CardGeneral className={classes.card}>
        <AppBar position="static" className={classes.appBar}>
          <Tabs
            TabIndicatorProps={{
              style: {
                height: '4px',
                transform: 'translate(0, 75%)',
              },
            }}
            className="noOverflow"
            value={value}
            onChange={(e, v) => setValue(v)}
            indicatorColor="secondary"
            aria-label="full width tabs"
          >
            <Tab label={t('Joueurs')} className={classes.tabs} />
            <Tab label={t('Clubs')} className={classes.tabs} />
            <Button
              className={classes.refreshButton}
              onClick={() => {
                if (value === 0) refetchPlayers();
                if (value === 1) refetchTeams();
              }}
              startIcon={<RefreshIcon />}
              color={theme.palette.type === 'dark' ? 'inherit' : 'primary'}
            >
              <Typography variant="h6">{t('Refresh')}</Typography>
            </Button>
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          className={classes.swipeable}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <List style={{ height: '100%' }}>
              <Grid
                item
                container
                xs={12}
                spacing={1}
                className={classes.ListItem}
              >
                <Grid item xs={12} key={0}>
                  <PlayerItem player={players?.[0]} />
                </Grid>
                <Grid item xs={12} key={1}>
                  <PlayerItem player={players?.[1]} />
                </Grid>
                <Grid item xs={12} key={2}>
                  <PlayerItem player={players?.[2]} />
                </Grid>
              </Grid>
            </List>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <List>
              <Grid
                item
                container
                xs={12}
                md={12}
                spacing={1}
                className={classes.ListItem}
              >
                {teams?.map(
                  (team, index) =>
                    index < 3 && (
                      <Grid item xs={12} key={index}>
                        <TeamItem team={team} />
                      </Grid>
                    ),
                )}
              </Grid>
            </List>
          </TabPanel>
        </SwipeableViews>
        <Grid item container xs={12} md={12} spacing={1} />
      </CardGeneral>
    </React.Fragment>
  );
}
