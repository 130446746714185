import React from 'react';
import TsParticles from 'react-tsparticles';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    pageBackgroundBlue: {
      zIndex: -1,
      background: '#0a1437',
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      top: 0,
      left: 0,
    },
    darkBackground: {},
    pageBackgroundBlueGradient: {
      backgroundImage:
        'linear-gradient(108deg, #6d7fff9e -1%, rgba(84, 114, 255, 0.05) 46%, rgba(84, 114, 255, 0.05) 46%)',
      zIndex: 5,
      opacity: 0.36,
      content: '""',
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      top: 0,
      left: 0,
      pointerEvents: 'none',
    },
    particles: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
    },
  }),
);

const Particles: React.FC = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <TsParticles
        id="tsparticles"
        className={classes.particles}
        options={{
          fpsLimit: 30,
          interactivity: {
            detectsOn: 'parent',
            events: {
              onClick: {
                enable: true,
                mode: 'repulse',
              },
              onHover: {
                enable: false,
                mode: 'repulse',
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 300,
                duration: 1.5,
                size: 0,
              },
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: '#ffffff',
            },
            links: {
              color: '#ffffff',
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: false,
            },
            move: {
              direction: 'none',
              enable: true,
              outMode: 'bounce',
              random: false,
              speed: 1,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                // eslint-disable-next-line @typescript-eslint/camelcase
                value_area: 400,
              },
              value: 15,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: 'circle',
            },
            size: {
              random: true,
              value: 3,
            },
          },
          detectRetina: true,
        }}
      />
      <div className={classes.pageBackgroundBlue} />
      <div className={classes.pageBackgroundBlueGradient} />
    </React.Fragment>
  );
};

export default Particles;
