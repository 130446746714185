import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  useTheme,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import '../../../../../theme/datasportTheme.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import ButtonFilled from '../../Buttons/ButtonFilled';
import CardGeneral from '../CardGeneral';
import backgroundNBA from 'images/backgroundNBA.svg';
import backgroundNBA_Dark from 'images/backgroundNBA_Dark.svg';
import BorderLinearProgress from '../../Progress/BorderLinearProgress';
import moment from 'moment';
import SinglesImages from './ClubLogo/ClubLogo';
import { PronoNBA } from '../../../../../types/pronoNBA';
import { getNBALogoURL } from '../../../../../utils/nba';
import { getInfosPronoLibre } from '../../../../../utils/pronoLibreNBA';
import { capitalize } from '@material-ui/core';
import Logo from '../../Logos/Logo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: '23rem',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        height: '26rem',
      },
    },
    content: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'space-between',
      height: '100%',
      minHeight: '15rem',
    },
    backgroundNBA: {
      background: `url(${backgroundNBA}) no-repeat top right`,
      backgroundColor: '#dadde5',
    },
    backgroundNBA_Dark: {
      background: `url(${backgroundNBA_Dark}) no-repeat top right`,
      backgroundColor: 'rgba(91, 94, 105, .85)',
    },
    backgroundNBAInvalid: {
      background: `url(${backgroundNBA}) no-repeat top right`,
      backgroundColor: '#dadde5',
    },
    backgroundNBAInvalid_Dark: {
      background: `url(${backgroundNBA_Dark}) no-repeat top right`,
      backgroundColor: 'rgba(164, 77, 77, .85)',
    },
    greenShadow: {
      boxShadow: '0px 5px 17px 1px #04ff0063 !important',
    },
    orangeShadow: {
      boxShadow: '0px 5px 17px 1px #ffbc006b !important',
    },
    greenText: {
      color: '#10ff00',
    },
    orangeText: {
      color: '#ffb000b5',
    },
    backgroundGrey: {
      background: '#d2d2d2',
    },
  }),
);

export type CardPredictionNBAProps = {
  prono: PronoNBA;
  future?: boolean;
  blue?: boolean;
  invalid?: boolean;
};

const CardPredictionNBA: React.FC<CardPredictionNBAProps> = ({
  prono,
  future = false,
  blue = false,
  invalid = false,
}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const language = i18n.language.toUpperCase();
  const isDark = theme.palette.type === 'dark';
  const [mainPronoName, setMainPronoName] = React.useState<string>('');
  const [percentage, setPercentage] = React.useState<number>(0);

  React.useEffect(() => {
    const getMainPronoName = () => {
      const pronoNames = getInfosPronoLibre(prono, t);
      setMainPronoName(pronoNames.pronoName);
      setPercentage(pronoNames.pronoPercentage);
    };
    getMainPronoName();
  }, [language, prono, language]);

  if (!prono?.match?.value?.home) return <></>;

  return (
    <CardGeneral
      className={clsx(
        classes.card,
        !future &&
          !invalid &&
          !prono.name_inactive &&
          percentage >= 50 &&
          classes.greenShadow,
        !future &&
          !invalid &&
          !prono.name_inactive &&
          percentage > 0 &&
          percentage < 50 &&
          classes.orangeShadow,

        future && (isDark ? classes.backgroundNBA_Dark : classes.backgroundNBA),
        invalid &&
          (isDark
            ? classes.backgroundNBAInvalid_Dark
            : classes.backgroundNBAInvalid),
      )}
      cursorPointer={!future || !invalid}
      shadow={!future}
      blue={blue}
      onClick={() => {
        if (!future || !invalid) history.push(`/pronos_nba/${prono.id}`);
      }}
    >
      <CardContent className={classes.content}>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          position="relative"
        >
          {prono.isPronoTest && (
            <Box
              position="absolute"
              top="0"
              right="0"
              fontSize="12px"
              color="#ff3f3f"
              fontWeight={600}
              style={{ transform: 'translate(0, -100%)' }}
            >
              <span>Prono {prono.name_inactive ? 'f' : 'test'}</span>
            </Box>
          )}
          <Box height="10%" width="100%">
            <Box
              width="100%"
              height="100%"
              display="flex"
              flexDirection="row"
              flexWrap="nowrap"
              fontWeight={600}
              justifyContent="space-between"
            >
              <Box
                color={future || (invalid && isDark) ? '#fff' : '#838ca6'}
                textAlign="left"
                fontSize=".7rem"
              >
                <Typography variant={mobile ? 'body1' : 'body2'}>
                  {moment(new Date(prono.date.seconds * 1000)).format(
                    'DD/MM/YYYY',
                  )}
                </Typography>
              </Box>
              <Box color="#838ca6" textAlign="right" fontWeight="600">
                <Typography
                  variant={mobile ? 'body1' : 'body2'}
                  component="span"
                >
                  {/* {!future && percentage >= 50 && (
                    <Box className={classes.greenText} fontWeight={600}>
                      {t('Risque faible')}
                    </Box>
                  )} */}
                  {!future && percentage < 56 && percentage > 0 && (
                    <Box className={classes.orangeText} fontWeight={600}>
                      {t('Risque élevé')}
                    </Box>
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
          {future ? (
            <Box height="20%" width="100%">
              <Box
                height="100%"
                width="100%"
                display="flex"
                flexDirection="column"
              >
                <Box
                  color="#838ca6"
                  textAlign="center"
                  fontWeight="600"
                  width="100%"
                  height="50%"
                >
                  <Typography variant={mobile ? 'body1' : 'body2'}>
                    {prono.round?.[language] ||
                      (typeof prono.round === 'string' ? prono.round : '-')}
                  </Typography>
                </Box>
                <Box
                  color={blue ? '#fff' : theme.palette.text.primary}
                  textAlign="center"
                  width="100%"
                  height="50%"
                >
                  <Typography
                    variant={mobile ? 'h3' : 'h4'}
                    style={mobile ? {} : { fontSize: '1.24rem' }}
                  >
                    {t('Prochaine analyse')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : invalid ? (
            <Box height="20%" width="100%">
              <Box
                height="100%"
                width="100%"
                display="flex"
                flexDirection="column"
              >
                <Box
                  color="#838ca6"
                  textAlign="center"
                  fontWeight="600"
                  width="100%"
                  height="50%"
                >
                  <Typography variant={mobile ? 'body1' : 'body2'}>
                    {prono.round?.[language] ||
                      (typeof prono.round === 'string' ? prono.round : '-')}
                  </Typography>
                </Box>
                <Box
                  color={blue ? '#fff' : theme.palette.text.primary}
                  textAlign="center"
                  width="100%"
                  height="100%"
                >
                  <Typography
                    variant={mobile ? 'h3' : 'h4'}
                    style={mobile ? {} : { fontSize: '1.24rem' }}
                  >
                    {t("Match non sélectionné par l'algorithme")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box height="20%" width="100%">
              <Box
                height="100%"
                width="100%"
                display="flex"
                flexDirection="column"
              >
                <Box
                  color="#838ca6"
                  textAlign="center"
                  fontWeight="600"
                  width="100%"
                >
                  <Typography variant={mobile ? 'body1' : 'body2'}>
                    {prono.round?.[language] || prono.round}
                  </Typography>
                </Box>
                <Box
                  color={blue ? '#fff' : theme.palette.text.primary}
                  textAlign="center"
                  width="100%"
                  marginTop={!mobile && '10px'}
                >
                  <Typography
                    variant={mobile ? 'h3' : 'h4'}
                    style={mobile ? {} : { fontSize: '1.24rem' }}
                  >
                    {capitalize(mainPronoName.trim())}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          <Box height="45%" width="100%">
            <Box width="100%" height="60%" display="flex">
              <Box
                width="33%"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  borderRadius="50%"
                  height="50px"
                  width="50px"
                  bgcolor="white"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className={future ? classes.backgroundGrey : ''}
                >
                  <Logo
                    src={getNBALogoURL(prono.match.value.home.sr_id)}
                    typeImgDefault="club"
                    imgDefautl="NHL"
                    club
                    grey={future || invalid}
                    width={mobile ? 50 : 60}
                  />
                </Box>
              </Box>
              {/* <SinglesImages
                future={future}
                mobile={mobile}
                src={getNBALogoURL(prono.match.value.home.sr_id)}
                typeImgDefault="club"
                imgDefautl="NBA"
              /> */}
              <Box
                width="33%"
                height="100%"
                color={future || (invalid && isDark) ? '#fff' : '#838ca6'}
                textAlign="center"
                alignItems="center"
                display="flex"
                justifyContent="center"
                fontWeight="600"
                marginTop={!mobile && '10px'}
              >
                <Typography variant={mobile ? 'body1' : 'body2'}>
                  {prono.competitionName?.[language] ||
                    (typeof prono.competitionName === 'string'
                      ? prono.competitionName
                      : '-')}
                </Typography>
              </Box>
              <Box
                width="33%"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  borderRadius="50%"
                  height="50px"
                  width="50px"
                  bgcolor="white"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className={future ? classes.backgroundGrey : ''}
                >
                  <Logo
                    src={getNBALogoURL(prono.match.value.away.sr_id)}
                    typeImgDefault="club"
                    imgDefautl="NHL"
                    club
                    grey={future || invalid}
                    width={mobile ? 50 : 60}
                  />
                </Box>
              </Box>
            </Box>
            <Box width="100%" height="40%" display="flex">
              <Box
                width="33%"
                height="100%"
                color={blue ? '#fff' : theme.palette.text.primary}
                display="flex"
                textAlign="center"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                fontSize={15}
              >
                <div>
                  <Typography
                    variant={mobile ? 'h6' : 'body1'}
                    component="span"
                  >
                    <Box fontWeight={600}>{prono.match.value.home.name}</Box>
                  </Typography>
                </div>
              </Box>
              <Box width="33%" height="100%" />
              <Box
                width="33%"
                height="100%"
                color={blue ? '#fff' : theme.palette.text.primary}
                display="flex"
                textAlign="center"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                fontSize={15}
              >
                <div>
                  <Typography
                    variant={mobile ? 'h6' : 'body1'}
                    component="span"
                  >
                    <Box fontWeight={600}>{prono.match.value.away.name}</Box>
                  </Typography>
                </div>
              </Box>
            </Box>
          </Box>
          <Box
            height="20%"
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {future ? (
              <BorderLinearProgress hide_until={prono.dateHideUntil} />
            ) : invalid ? (
              <BorderLinearProgress red={true} />
            ) : (
              <ButtonFilled title="Voir les statistiques" blue={true} medium />
            )}
          </Box>
        </Box>
      </CardContent>
    </CardGeneral>
  );
};

export default CardPredictionNBA;
