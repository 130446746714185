import React, { Dispatch, SetStateAction } from 'react';
import PreviewBonus from '../../Home/PreviewBonus';
import { useTheme, makeStyles, createStyles, Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import '../../../../theme/datasportTheme.css';
import ToggleSport, { Sport } from '../../Home/ToggleSport';
import { useDispatch } from 'react-redux';
import FootballTab from './FootballTab/FootballTab';
import NFLTab from './NFLTab/NFLTab';
import TennisTab from './TennisTab/TennisTab';
import Fade from '@material-ui/core/Fade';
import { PronoTennis } from '../../../../types/pronoTennis';
import NBATab from './NBATab/NBATab';
import NHLTab from './NHLTab/NHLTab';
import BASEBALLTab from './BASEBALLTab/BASEBALLTab';
import { PronoNBA } from '../../../../types/pronoNBA';
import { PronoNHL } from '../../../../types/pronoNHL';
import BilanPronos from '../../BilanPronos/BilanPronos';

type Props = {
  setProno: (p: any) => void;
  pronos: PronoTennis[] | PronoNBA[];
  selectedSport: Sport;
  isLoading: boolean;
  clearPronos: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: 1300,
      margin: 'auto',
      width: '100%',
    },
    marginBottomNegative: {
      marginBottom: '-2.5rem',
    },
    toggleSportContainer: {
      [theme.breakpoints.down('xs')]: {
        overflow: 'scroll',
      },
      position: 'sticky',
      top: 0,
      zIndex: 4,
      backdropFilter: 'blur(10px)',
    },
  }),
);

export const PagePronos = (props: Props): JSX.Element => {
  const { setProno, pronos, selectedSport, isLoading, clearPronos } = props;
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const tablet = useMediaQuery('max-width:900px');
  const smallComputer = useMediaQuery('max-width:1100px');
  const mediumComputer = useMediaQuery('max-width:1300px');
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <Fade in timeout={500}>
        <>
          {selectedSport === 'FOOTBALL' && (
            <FootballTab
              mobile={mobile}
              tablet={tablet}
              smallComputer={smallComputer}
              mediumComputer={mediumComputer}
              isLoading={isLoading}
              pronos={pronos['FOOTBALL']}
              setProno={setProno}
              t={t}
            />
          )}
          {selectedSport === 'TENNIS' && (
            <TennisTab
              mobile={mobile}
              tablet={tablet}
              smallComputer={smallComputer}
              mediumComputer={mediumComputer}
              isLoading={isLoading}
              pronos={pronos['TENNIS'] as PronoTennis[]}
              t={t}
            />
          )}
          {selectedSport === 'NBA' && (
            <NBATab
              mobile={mobile}
              isLoading={isLoading}
              pronos={pronos['NBA'] as PronoNBA[]}
              t={t}
            />
          )}
          {selectedSport === 'NFL' && (
            <NFLTab
              mobile={mobile}
              tablet={tablet}
              smallComputer={smallComputer}
              mediumComputer={mediumComputer}
              isLoading={isLoading}
              pronos={pronos['NFL']}
              setProno={setProno}
              t={t}
            />
          )}
          {selectedSport === 'NHL' && (
            <NHLTab
              mobile={mobile}
              isLoading={isLoading}
              pronos={pronos['NHL'] as PronoNHL[]}
              t={t}
            />
          )}
          {selectedSport === 'BASEBALL' && (
            <BASEBALLTab
              mobile={mobile}
              isLoading={isLoading}
              pronos={pronos['BASEBALL'] as PronoNBA[]}
              t={t}
            />
          )}
          {/* <Grid item xs={12} md={3}>
            <PreviewBonus maxHeight={550} blue />
          </Grid> */}
          <BilanPronos />
        </>
      </Fade>
    </React.Fragment>
  );
};
