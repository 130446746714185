import React, { useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Fire from 'services/fire.service';
import {
  autologin,
  finishLogin,
  saveInfo,
  updateUser,
} from 'actions/auth.action';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import OauthLogins from 'reusable/OauthLogins/OauthLogins';
import { useHistory } from 'react-router-dom';
import logo from '../../images/logo_datasport.png';
import { Button, Input } from '../Reusable';
import './PaymentMarketPay.scss';
import Particles from '../Reusable/Particles/Particles';
import { PaymentMarketPayProps } from './PaymentMarketPay.type';
import CardPronoTennisFille from '../Dashboard/SinglePronoTennis/CardPronoTennisFille/CardPronoTennisFille';
import { connectSortBy } from 'react-instantsearch-dom';
import { getFeeAndSubTypeAndTrialPrice } from '../../utils/countryCodeAPI';
import i18n from '../../i18n';
import ButtonFilled from '../Dashboard/Templates/Buttons/ButtonFilled';
import ButtonOutlined from '../Dashboard/Templates/Buttons/ButtonTransparent';
import ButtonOutlinedGrey from '../Dashboard/Templates/Buttons/ButtonOutlinedGrey';
import { Box } from '@material-ui/core';
import CreditCardForm from '../CreditCardForm/CreditCardForm';

interface AuthCodeError {
  code: string;
  message: string;
  name: string;
}

const hasAllValidInfos = user => {
  return (
    user.first_name &&
    user.last_name &&
    user.email &&
    user.phone &&
    user.phone !== 'Non renseigné'
  );
};

function getParameterByName(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
    results = regex.exec(document.location.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

const PaymentMarketPay = () => {
  const user_already = useSelector((state: any) => state.authReducer.user);
  const [cardHolder, setCardHolder] = React.useState<string>('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = React.useState('');
  const [step, setStep] = useState(user_already ? 1 : 0);
  const [locationObject, setLocationObject] = useState<any>({});
  const [formType, setFormType] = useState<'signup' | 'login'>('signup');
  const [user, setUser] = useState<any>(
    user_already || {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      password: '',
      locationObject: {},
    },
  );
  const [passwordType, setPasswordType] = useState('password');
  const [message, setMessage] = useState(
    'Vous allez être redirigé vers la page de paiement, un instant...',
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const setValue = (key: string, val: any) => {
    user[key] = val;
    setUser({ ...user });
  };

  const showError = err => {
    setError(t(err));
    // window.scrollTo(0, 0)
  };

  const login = async () => {
    const { email, password } = user;
    if (!email || !password) {
      showError('Veuillez remplir tous les champs');
      return;
    }
    setLoading(true);
    try {
      await Fire.auth().signInWithEmailAndPassword(email, password);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      const error = err as AuthCodeError;
      switch (error.code) {
        case 'auth/user-not-found':
        case 'auth/invalid-password':
        case 'auth/wrong-password':
          showError(t('Identifiants invalides'));
          break;

        case 'auth/invalid-email':
          showError(t("L'email entré est invalide"));
          break;

        default:
          showError(t('Une erreur est survenue'));
      }
    }
  };

  const register = async () => {
    setLoading(true);
    const { email, phone, first_name, last_name, password } = user;
    const utm = {
      utm_source: getParameterByName('utm_source'),
      utm_medium: getParameterByName('utm_medium'),
      utm_campaign: getParameterByName('utm_campaign'),
      utm_term: getParameterByName('utm_term'),
      utm_content: getParameterByName('utm_content'),
    };
    // const { source } = document.location
    if (!first_name?.trim().length || !last_name?.trim().length) {
      setLoading(false);
      showError(t('Veuillez saisir votre nom et votre prénom'));
      return;
    } else if (!email?.trim().length) {
      setLoading(false);
      showError(t('Veuillez saisir votre email de connexion'));
      return;
    } else if (!phone?.trim().length) {
      setLoading(false);
      showError(t('Veuillez saisir votre numéro de téléphone'));
      return;
    } else if (!user_already && password?.trim().length <= 5) {
      setLoading(false);
      showError(t('Veuillez saisir votre mot de passe'));
      return;
    }

    try {
      const payload: any = {
        first_name: first_name.trim(),
        last_name: last_name.trim(),
        email: email.trim(),
        phone: phone,
        source: utm,
        paymentType: 'marketpay',
        location: {
          state: locationObject.regionName || '',
          countryCode: locationObject.countryCode || '',
        },
        state: {
          isExternRegisteringMarketPay: false,
        },
        paymentMarketPay: [],
      };
      if (!user_already) {
        payload.vip = false;
      }

      if (!user_already) {
        await Fire.auth()
          .createUserWithEmailAndPassword(email, password)
          .then(async userDoc => {
            setLoading(false);
            if (!userDoc.user) return;
            await Fire.store()
              .collection('users')
              .doc(userDoc.user.uid)
              .set(payload);
            dispatch(saveInfo(payload));

            setStep(1);
          })
          .catch(err => {
            setLoading(false);
            const error = err as AuthCodeError;
            switch (error.code) {
              case 'auth/email-already-in-use':
                showError(t("L'email entré est déjà utilisé. Connectez-vous."));
                break;

              case 'auth/invalid-password':
                showError(t('Identifiants invalides'));
                break;

              case 'auth/user-not-found':
                showError(t('Identifiants invalides'));
                break;

              case 'auth/invalid-email':
                showError(t("L'email entré est invalide"));
                break;

              case 'auth/weak-password':
                showError(t('Mot de passe trop faible'));
                break;

              default:
                showError(t('Une erreur est survenue'));
                break;
            }
          });
      } else {
        dispatch(updateUser(payload));
        setLoading(false);
        setStep(1);
      }
    } catch (err) {
      setLoading(false);
      showError(t('Une erreur est survenue, veuillez réessayer'));
      // handleSubmit();
    }
  };

  const getAllPriceInfos = async () => {
    const [countryCodeObject] = await getFeeAndSubTypeAndTrialPrice();
    setLocationObject(countryCodeObject);
  };

  React.useEffect(() => {
    getAllPriceInfos();
  }, []);

  const verifyUserPayment = async urlEmail => {
    setLoading(true);
    setMessage(t('Nous vérifions votre paiement, merci de patienter'));

    const { email, paymentType, paymentMarketPay } = user_already;

    if (email !== urlEmail) {
      showError(t('Votre email ne correspond pas à celui de votre compte'));
      setLoading(false);
      return;
    }

    if (paymentType !== 'marketpay') {
      showError(t('Votre type de paiement ne correspond pas'));
      setLoading(false);
      return;
    }

    try {
      // We verify if the transaction has been a success
      const response = await Fire.cloud(
        'getTransactionStatus',
        paymentMarketPay[paymentMarketPay.length - 1].uuid,
      );
      if (response.transactionStatus === 'SUCCESS') {
        const lastSubs = paymentMarketPay;
        //Find in array lastSubs if there is a sub with the same uuid
        const index = lastSubs.findIndex(sub => sub.uuid === response.uuid);
        if (index !== -1) {
          //If there is a sub with the same uuid, we update the sub with the new status
          lastSubs[index] = response;
        }

        const payload = {
          vip: true,
          paymentType: 'marketpay',
          paymentMarketPay: lastSubs,
          state: {
            isExternRegisteringMarketPay: false,
          },
        };
        await dispatch(updateUser(payload));
        await dispatch(saveInfo(payload));
        setLoading(false);
        setStep(3);
      } else if (response.transactionStatus === 'REDIRECT') {
        // We redirect the user to the payment page
        setLoading(false);
        history.push('/payment');
      } else if (response.transactionStatus === 'ERROR') {
        setLoading(false);
        showError(
          t('Votre paiement a échoué pour la raison suivante :') +
            response.errors[0].message,
        );
      } else if (!response.success) {
        setLoading(false);
        showError(t('Une erreur est survenue :') + response.errorMessage);
      }
    } catch (err) {
      setLoading(false);
      showError(
        t(
          "Une erreur est survenue, essayez de rafraichir la page, ou contactez-nous à l'adresse mail contact@data-sport.io si l'erreur presiste",
        ),
      );
    }
  };

  const pay = async transactionToken => {
    const query = new URLSearchParams(history.location.search);
    const currency = query.get('currency');
    const lang = query.get('lang');

    setLoading(true);
    setMessage(
      'Vous allez être redirigé vers la page de paiement, un instant...',
    );

    try {
      // We create the transaction and get the transaction url
      const res: PaymentMarketPayProps = await Fire.cloud('getMarketPayUrl', {
        userInfos: {
          identification: user_already.id,
          firstName: user_already.first_name,
          lastName: user_already.last_name,
          email: user_already.email,
          billingPhone: user_already.phone,
        },
        language: lang || i18n.language || 'en',
        currency: currency || 'EUR',
        //TODO ajouter le token de l'utilisateur
        transactionToken,
      });

      // console.log(res);

      if (res !== undefined && res.success) {
        // The transaction has been created, we save the transaction infos in the user and we redirect the user to the payment page
        const payload = {
          paymentType: 'marketpay',
          paymentMarketPay: [
            ...(user_already.paymentMarketPay || []),
            {
              merchantTransactionId: res.merchantTransactionId || '',
              paymentMethod: res.paymentMethod || '',
              purchaseId: res.purchaseId || '',
              redirectUrl: res.redirectUrl || '',
              returnType: res.returnType || '',
              success: res.success,
              uuid: res.uuid || '',
            },
          ],
          state: {
            isExternRegisteringMarketPay: true,
          },
        };

        try {
          // console.log('save user, pay');
          await dispatch(saveInfo(payload));

          // console.log('save user, pay 1');
          // console.log(user_already.id);
          // console.log(payload);

          await Fire.store()
            .collection('users')
            .doc(user_already.id)
            .update(payload);

          // console.log('save user, pay 2');

          setLoading(false);
          if (res.returnType === 'REDIRECT') {
            window.open(res.redirectUrl, '_self');
          } else if (res.returnType === 'FINISHED') {
            setMessage(t('Nous vérifions votre paiement, merci de patienter'));
            history.push(
              '/payment?email=' + user_already.email + '&success=true',
            );
            setStep(2);
          } else if (res.returnType === 'ERROR') {
            showError(
              t(
                `Une erreur est survenue : ${res?.errors?.[0]?.errorMessage}, essayez de refraichir la page, ou contactez-nous à l'adresse mail contact@data-sport.io si l'erreur presiste`,
              ),
            );
          }
          // setStep(2);
        } catch (err) {
          console.log(err);
          setLoading(false);
          showError(
            t(
              "Une erreur est survenue lors de la mise à jour de vos informations, essayez de refraichir la page, ou contactez-nous à l'adresse mail contact@data-sport.io si l'erreur presiste ",
            ),
          );
        }
      } else {
        setLoading(false);
        showError(
          t(
            `Une erreur est survenue : ${res?.errors?.[0]?.errorCode}, essayez de refraichir la page, ou contactez-nous à l'adresse mail contact@data-sport.io si l'erreur presiste`,
          ),
        );
      }
    } catch (err) {
      setLoading(false);
      showError(
        t(
          "Une erreur est survenue, essayez de refraichir la page, ou contactez-nous à l'adresse mail contact@data-sport.io si l'erreur presiste ",
        ),
      );
    }

    // window.open(url, '_self');
  };

  React.useEffect(() => {
    // We check what step the user is at :
    // Step = 0 : The user is not connected (if formtype === 'signup', then we show the signup form, else we show the login form)
    if (step === 0) {
      if (user_already && hasAllValidInfos(user_already)) {
        if (
          user_already.vip &&
          user_already.state?.isExternRegisteringMarketPay === false
        ) {
          setStep(3);
        } else {
          setStep(1);
        }
      }
    }
    if (step === 1) {
      const urlParams = new URLSearchParams(history.location.search);
      const success = urlParams.get('success');
      const email = urlParams.get('email');

      // If the user doesn't have all the infos needed (first_name, last_name, email & phone), we show the form
      if (!user_already || !hasAllValidInfos(user_already)) {
        setStep(0);
        return;
      }

      if (!!user_already.state?.isExternRegisteringMarketPay) {
        verifyUserPayment(user_already.email);
      }

      // If the user is already a vip and is not paying from marketPay, we show the success page
      if (success && email) {
        setStep(2);
      } else if (
        user_already.vip &&
        !user_already.state?.isExternRegisteringMarketPay
      ) {
        setStep(3);
        return;
      }
    }
    // Step = 2 : The user is connected, but not a vip
    if (step === 2) {
      setLoading(true);

      const urlParams = new URLSearchParams(history.location.search);
      const success = urlParams.get('success');
      const email = urlParams.get('email');

      // console.log(success);

      // If there is no success or email paramters in the url, it means that he didn't come from the marketPay page, so we checked
      if (!success || !email) {
        // If the user is paying from marketPay, we redirect him to the payment page
        if (
          user_already.state?.isExternRegisteringMarketPay === true &&
          user_already?.paymentMarketPay
        ) {
          // We search for the last paymentMarketPay object
          const marketPayUrl =
            user_already?.paymentMarketPay[
              user_already.paymentMarketPay?.length - 1
            ]?.redirectUrl;

          if (marketPayUrl) {
            setMessage(
              'Vous allez être redirigé vers la page de paiement, un instant...',
            );

            setLoading(false);
            // window.open(marketPayUrl, '_self');
            setStep(2);
          }
          // } else pay();
        }
      } else {
        // If there is success and email parameters in the url, it means that he came from the marketPay page, so we checked if the payment was successful
        setMessage(t('Nous vérifions votre paiement, merci de patienter'));
        verifyUserPayment(email);
      }
      setLoading(false);
    }
    // Step = 3 : The user is connected and a vip, we redirect him to the home page
    if (step === 3) {
      if (!user_already) {
        setStep(0);
        return;
      }
      if (!user_already.vip) {
        setStep(1);
      }
      setMessage(
        t(
          'Vous allez être redirigé vers le site de Data-Sport, merci de patienter',
        ),
      );

      history.push('/');
    }
  }, [step]);

  return (
    <div className="login-screen">
      <Particles />

      {/* <HeaderBar /> */}
      <div className="login-container background top-padding">
        <div className="row-center">
          <div className="login-outbox">
            <div className="info-login-form red-box">
              <div className="col-center login-logo-container">
                <img
                  className="logo"
                  onClick={() => history.push('/')}
                  src={logo}
                  width="100px"
                />
              </div>
              {step === 0 ? (
                <>
                  <div className="page-title">
                    {t(
                      user_already
                        ? ''
                        : formType === 'login'
                        ? 'Connexion'
                        : 'Inscription',
                    )}
                  </div>
                  {(user_already || formType === 'signup') && (
                    <>
                      <div className="name">
                        <Input
                          autocomplete="given-name"
                          placeholder={t('First name')}
                          value={user.first_name}
                          onChange={val => setValue('first_name', val)}
                          trimSpaces
                        />
                        <Input
                          autocomplete="family-name"
                          placeholder={t('Last name')}
                          value={user.last_name}
                          onChange={val => setValue('last_name', val)}
                        />
                      </div>
                      <div
                        style={{
                          position: 'relative',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Input
                          type="tel"
                          autocomplete="tel"
                          placeholder={t('Téléphone')}
                          value={
                            user.phone !== 'Non renseigné' ? user.phone : ''
                          }
                          onChange={val => setValue('phone', val.trim())}
                        />
                      </div>
                    </>
                  )}{' '}
                  <div
                    style={{
                      position: 'relative',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Input
                      autocomplete="email"
                      type="email"
                      placeholder={t('Email')}
                      value={user.email}
                      onChange={val => setValue('email', val.trim())}
                    />
                  </div>
                  {!user_already && (
                    <div className="password_container">
                      <Input
                        placeholder={t('Mot de passe')}
                        type={passwordType}
                        value={user.password}
                        onChange={val => setValue('password', val)}
                      />
                      {passwordType === 'password' ? (
                        <Visibility
                          fontSize="inherit"
                          color="inherit"
                          onClick={() => setPasswordType('text')}
                          className="visibility-eye"
                        />
                      ) : (
                        <VisibilityOff
                          fontSize="inherit"
                          color="inherit"
                          onClick={() => setPasswordType('password')}
                          className="visibility-eye"
                        />
                      )}
                    </div>
                  )}
                  {formType === 'signup' ? (
                    <div className="row-center" style={{ marginTop: 25 }}>
                      <div
                        className="page-link login-page-link"
                        onClick={() => setFormType('login')}
                      >
                        {t('Déjà inscrit ? Connectez-vous')}
                      </div>
                    </div>
                  ) : (
                    <div className="row-center" style={{ marginTop: 25 }}>
                      <div
                        className="page-link login-page-link"
                        onClick={() => setFormType('signup')}
                      >
                        {t('Pas de compte ? Inscrivez-vous')}
                      </div>
                    </div>
                  )}
                  <div className="row-center" style={{ marginTop: 25 }}>
                    <Button
                      title={
                        loading
                          ? t('Chargement...')
                          : formType === 'login'
                          ? t('Se connecter')
                          : t("S'INSCRIRE")
                      }
                      onPress={
                        user_already
                          ? register
                          : formType === 'login'
                          ? login
                          : register
                      }
                      classes={['linear-btn', 'big-btn', 'login-btn']}
                      type="submit"
                      disabled={loading}
                    />
                  </div>
                </>
              ) : step === 2 ? (
                !error && t(message)
              ) : step === 1 ? (
                !error && <CreditCardForm pay={pay} />
              ) : (
                t(message)
              )}
              {error && (
                <div
                  className="error-box"
                  style={{
                    textAlign: 'center',
                    marginTop: step !== 1 ? '2rem' : 0,
                  }}
                >
                  <div className="error">{t(error)}</div>
                  {step !== 0 && (
                    <>
                      <br />
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <ButtonOutlined
                          title={t('Cliquez ici pour réessayer')}
                          onClick={() => setStep(0)}
                          blue
                        />
                      </Box>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <Footer /> */}
    </div>
  );
};

export default PaymentMarketPay;
