import React from 'react';
import { connectHits } from 'react-instantsearch-dom';
import { Hit, HitsProvided } from 'react-instantsearch-core';
import { SoccerTeam } from '../../../../../types/soccer/soccer_teams';
import CardGeneral from '../../../Templates/Cards/CardGeneral';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TeamHitPosition from './TeamHitPosition/TeamHitPosition';
import { Box, CardContent } from '@material-ui/core/';
import TeamHitChip from './TeamHitChip/TeamHitChip';
import Logo from '../../../Templates/Logos/Logo';
import TeamHitPoint from './TeamHitPoint/TeamHitPoint';
import TeamHitWins from './TeamHitWins/TeamHitWins';
import TeamHitPosArrow from './TeamHitPosArrow/TeamHitPosArrow';
import ButtonFilled from '../../../Templates/Buttons/ButtonFilled';
import { useHistory } from 'react-router-dom';

type TeamHit = {
  hit: Hit<SoccerTeam>;
  queries?: any;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: '352px',
      width: '100%',
      maxWidth: '23%',
      minWidth: '279px',
      [theme.breakpoints.down('md')]: {
        minWidth: '250px',
      },
      [theme.breakpoints.down('md')]: {
        minWidth: '250px',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '30%',
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: '310px',
        minWidth: '275px',
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },
    teamName: {
      fontSize: 22,
      fontWeight: 'bold',
      color: 'white',
      fontFamily: 'AvenirNext',
    },
  }),
);

const TeamHit: React.FC<TeamHit> = ({ hit, queries }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <CardGeneral
      className={clsx(classes.card)}
      shadow={hit.objectID !== undefined}
      cursorPointer={hit.objectID !== undefined}
      onClick={() => {
        hit.objectID !== undefined && history.push(`/team/${hit.objectID}`);
      }}
    >
      <CardContent
        className={classes.content}
        style={{
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {hit.logo && (
          <Box
            style={{
              opacity: '.065',
              position: 'absolute',
              top: '-5%',
              right: '-35%',
              width: '100%',
              zIndex: -1,
            }}
          >
            <img src={hit.logo} alt="Data-Sport team-hit-logo" width="100%" />
          </Box>
        )}
        <Box display="flex" justifyContent="space-between">
          <TeamHitPosition position={hit.position} />
          <Box display="flex" gridGap={5} marginTop="-10px">
            {/* <TeamHitChip label="Equipe" /> */}
            <TeamHitChip label={hit.competition.name} />
          </Box>
        </Box>
        <Box>
          <Logo
            src={hit.logo}
            width={90}
            imgWidth="auto"
            noBg
            typeImgDefault="club"
            imgDefautl="FOOTBALL"
          />
        </Box>
        <Box>
          <p className={classes.teamName}>{hit.name}</p>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <TeamHitPoint pts={hit.pts} />
          <TeamHitWins wnl={hit.wnl} />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginTop="5px"
        >
          <TeamHitPosArrow variation={hit.movement} />
          <ButtonFilled icon small hoverOutlined />
        </Box>
      </CardContent>
    </CardGeneral>
  );
};

const TeamHits: React.FC<HitsProvided<SoccerTeam>> = ({ hits }) => {
  return (
    <div
      style={{
        display: 'flex',
        marginTop: 25,
        gap: 25,
        flexWrap: 'wrap',
        justifyContent: 'space-around',
      }}
    >
      {hits.map(hit => (
        <TeamHit key={hit.objectID} hit={hit} />
      ))}
    </div>
  );
};

export default connectHits(TeamHits);
