import React from 'react';
import {
  makeStyles,
  Theme,
  useTheme,
  createStyles,
} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { ListItemText } from '@material-ui/core';
import { TFunction } from 'i18next';
import ListItem from '@material-ui/core/ListItem';
import Fade from '@material-ui/core/Fade';
import Card from '@material-ui/core/Card';

type ListItemHeaderProps = {
  mobile: boolean;
  t: TFunction;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      backgroundColor:
        theme.palette.type === 'dark' ? 'rgba(24,36,79,0.85)' : '#f8f9fa',
      borderRadius: '15px',
      border: 'none',
      boxShadow: 'none',
      minHeight: 'unset',
      padding: 12,
      transition: 'all .15s ease-in-out !important',
    },
    img: {
      borderRadius: '50px',
      [theme.breakpoints.down('sm')]: {
        width: 20,
        height: 20,
      },
    },
    countryImg: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        width: 20,
        height: 11,
      },
    },
    typo: {
      height: '100%',
      alignItems: 'center',
    },
  }),
);

const PreviewHeader: React.FC<ListItemHeaderProps> = ({ mobile, t }) => {
  const classes = useStyles();

  return (
    <Fade in={true} timeout={500}>
      <ListItem component={Card} className={classes.card}>
        <Grid
          container
          item
          xs
          justify="center"
          alignItems="center"
          className="textAlignCenter"
        >
          <Grid container item xs={7} sm={4}>
            <Grid item xs={3} sm={4} />
            <Grid container item xs={9} sm={8} className="textAlignLeft">
              <ListItemText
                primary={t('Nom')}
                className="bold"
                style={{ textAlign: 'center' }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={5}
            sm={4}
            justify="center"
            alignItems="center"
          >
            <Grid item xs={5}>
              <ListItemText primary={t('% de vict.')} className="bold" />
            </Grid>
            <Grid item xs={7}>
              <ListItemText primary={t('G.- N.- P.')} className="bold" />
            </Grid>
          </Grid>
          {!mobile && (
            <Grid container item sm={4} justify="center" alignItems="center">
              <Grid item xs={6}>
                <ListItemText primary={t('Joués')} className="bold" />
              </Grid>
              <Grid item xs={6}>
                <ListItemText primary={t('Classement')} className="bold" />
              </Grid>
            </Grid>
          )}
        </Grid>
      </ListItem>
    </Fade>
  );
};

export default PreviewHeader;
