import React from 'react';
import { makeStyles, useTheme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Box, Typography, LinearProgress } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    bgGrey: {
      backgroundColor: '#f8f9fa',
      borderRadius: 15,
    },
  }),
);

const useStylesProgress = makeStyles({
  root: {
    height: 4,
    borderRadius: 15,
    width: '100%',
    background: '#dbdfe8',
  },
  bar: {
    borderRadius: 15,
    background: `linear-gradient(-90deg, #68ead1, #67c3e6, #68bdf1)`,
  },
});

const Progress: React.FC<{ value: number }> = ({ value }) => {
  const classes = useStylesProgress({ value });
  return (
    <LinearProgress
      classes={{
        root: clsx(classes.root),
        bar: classes.bar,
      }}
      variant="determinate"
      value={value}
    />
  );
};

type SingleStatisticsProps = {
  value1: number;
  value2: number;
  name: string;
  bgGrey: boolean;
  additionnal: string;
};

const SingleStatistic: React.FC<SingleStatisticsProps> = ({
  value1,
  value2,
  name,
  bgGrey,
  additionnal,
}) => {
  const ratio = (value1 / (value2 + value1)) * 100;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box
      width="100%"
      className={bgGrey ? classes.bgGrey : ''}
      display="flex"
      flexDirection="column"
      padding=".75rem 0 .5rem"
    >
      <Box
        width="100%"
        maxWidth="450px"
        margin="auto"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" component={'span'}>
          <Box color={theme.palette.primary.main}>{value1 + additionnal}</Box>
        </Typography>
        <Typography variant="body1" component={'span'}>
          <Box color={theme.palette.text.secondary}>{name}</Box>
        </Typography>
        <Typography variant="h5" component={'span'}>
          <Box color={theme.palette.primary.main}>{value2 + additionnal}</Box>
        </Typography>
      </Box>
      <Box
        width="100%"
        maxWidth="450px"
        margin="auto"
        padding=".5rem 0"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        {!!ratio && <Progress value={ratio} />}
      </Box>
    </Box>
  );
};

export default SingleStatistic;
