import React from 'react';
import {
  makeStyles,
  Theme,
  useTheme,
  createStyles,
} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import '../../../../theme/datasportTheme.css';
import { useTranslation } from 'react-i18next';
import BorderLinearProgress from '../Progress/BorderLinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Logo from '../Logos/Logo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    redBox: {
      padding: '.5rem 0',
      border: 'solid 2px rgba(255, 0, 0, .75)',
      borderRadius: '1.5rem',

      [theme.breakpoints.down('xs')]: {
        padding: '1rem',
      },
    },
  }),
);

export default function RowPrediction(props) {
  const { name, domicile, ext, hide_until, team1 } = props;
  const theme = useTheme();
  const smallComputer = useMediaQuery(theme.breakpoints.down('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const language = i18n.language.toUpperCase();

  if (!hide_until) return null;
  const canceled =
    !team1 &&
    (!hide_until || hide_until?.seconds <= new Date().getTime() / 1000);

  return (
    <Tooltip title={canceled ? "Match non choisi par l'algorithme" : ''} arrow>
      <Grid
        container
        style={{ color: 'white' }}
        className={canceled ? classes.redBox : ''}
      >
        <Grid
          container
          item
          xs={12}
          sm={6}
          md={6}
          alignItems="center"
          style={{ justifyContent: 'space-between' }}
        >
          <Grid item xs={4}>
            <Typography
              variant={smallComputer ? 'h6' : 'body1'}
              component={'span'}
            >
              <Box fontWeight={600} paddingRight="1rem" textAlign="end">
                {t(domicile?.name)}
              </Box>
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={4}
            alignItems="center"
            wrap="nowrap"
            style={{ justifyContent: 'space-around' }}
          >
            <Box
              style={{ transform: 'translate(-10%, 0)' }}
              borderRadius="50%"
              height={smallComputer ? (mobile ? '30px' : '35px') : '50px'}
              width={smallComputer ? (mobile ? '30px' : '35px') : '50px'}
              bgcolor="white"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {/* <img
                alt={t(domicile?.name)}
                width="85%"
                style={{ borderRadius: '50%' }}
              /> */}
              <Logo
                src={domicile?.logo}
                club={domicile.name !== domicile.country}
                typeImgDefault="club"
                imgDefautl="FOOTBALL"
              />
            </Box>
            <Box
              style={{ transform: 'translate(10%, 0)' }}
              borderRadius="50%"
              height={smallComputer ? (mobile ? '30px' : '35px') : '50px'}
              width={smallComputer ? (mobile ? '30px' : '35px') : '50px'}
              bgcolor="white"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Logo
                src={ext?.logo}
                club={domicile.name !== domicile.country}
                typeImgDefault="club"
                imgDefautl="FOOTBALL"
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant={smallComputer ? 'h6' : 'body1'}
              component={'span'}
            >
              <Box fontWeight={600} paddingLeft="1rem" textAlign="flex-start">
                {t(ext?.name)}
              </Box>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={5}
          sm={3}
          md={3}
          alignItems="center"
          style={{ justifyContent: 'center' }}
        >
          <Typography
            variant={smallComputer ? 'h6' : 'body1'}
            component={'span'}
          >
            <Box fontWeight={600} justifyContent="center" textAlign="center">
              {name[language] || t(name)}
            </Box>
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={7}
          sm={3}
          md={3}
          alignItems="center"
          style={{ margin: mobile ? '1rem auto' : 'auto' }}
        >
          <Box
            height="80%"
            maxWidth="285px"
            width="95%"
            margin="auto"
            borderRadius="7px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            padding={mobile ? '.75rem' : '.5rem 1rem'}
            position="relative"
          >
            <BorderLinearProgress hide_until={hide_until} red={canceled} />
          </Box>
        </Grid>
      </Grid>
    </Tooltip>
  );
}
