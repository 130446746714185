import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Chip, Box } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { WonNullLoss } from '../../../../../../types/soccer/soccer_teams';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';

enum COLORS {
  w = '#68daa8',
  n = '#828da8',
  l = '#e0153f',
}

const TeamHitWins: React.FC<{ wnl: WonNullLoss }> = ({ wnl }) => {
  const lastStatus = wnl.split('.') as readonly WonNullLoss[];
  const { t } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Chip
      style={{ backgroundColor: '#243058' }}
      label={
        <Box display="flex" gridGap={mobile ? 3 : 6}>
          <Box
            display="flex"
            gridGap={mobile ? 2 : 4}
            fontSize={mobile ? 10 : 14}
            fontWeight={500}
          >
            <span style={{ color: COLORS.w }}>{t('G.')}</span>
            <span style={{ color: COLORS.n }}>{t('N.')}</span>
            <span style={{ color: COLORS.l }}>{t('P.')}</span>
          </Box>
          <Box display="flex" alignItems="center">
            {lastStatus.map((status, i) => (
              <FiberManualRecordIcon
                key={i}
                style={{
                  color: COLORS[status],
                  fontSize: mobile ? '.8rem' : '.9rem',
                }}
              />
            ))}
          </Box>
        </Box>
      }
    />
  );
};

export default TeamHitWins;
