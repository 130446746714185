import { useEffect } from 'react';
import { Fire } from 'services';
import { Playoffs } from '../../../types/sportradar/NBA/playoffs';
import { useQuery, useQueryClient } from 'react-query';
import { Tournament } from '../../../types/soccer/tournament';

const getTournaments = async (): Promise<Tournament[]> => {
  const ref = Fire.store().collection('tournaments');
  const tournaments = await Fire.list<Tournament>(ref);
  return tournaments
    .filter(({ order }) => !!order)
    .sort((a, b) => a.order - b.order);
};

const useLibrary: () => {
  soccerTournaments: undefined | Tournament[];
} = () => {
  const queryClient = useQueryClient();
  const { data: soccerTournaments } = useQuery('tournaments', getTournaments);

  useEffect(() => {
    const prefetchSoccerStandings = async () => {
      if (soccerTournaments) {
        const promises = soccerTournaments.map(({ competition: { value } }) =>
          queryClient.prefetchQuery(
            `soccer:standings:${value}`,
            () =>
              Fire.cloud('soccer', {
                method: 'getStandings',
                competitionId: value,
              }),
            { staleTime: 900000 /* 15 mn */ },
          ),
        );
        await Promise.all(promises);
      }
    };
    const prefetchSoccerCalendar = async () => {
      if (soccerTournaments) {
        const promises = soccerTournaments.map(({ competition: { value } }) =>
          queryClient.prefetchQuery(
            `soccer:calendar:${value}`,
            () =>
              Fire.cloud('soccer', {
                method: 'getCalendars',
                competitionId: value,
              }),
            { staleTime: 900000 /* 15 mn */ },
          ),
        );
        await Promise.all(promises);
      }
    };
    prefetchSoccerStandings();
    prefetchSoccerCalendar();
  }, [queryClient, soccerTournaments]);

  useEffect(() => {
    const prefetchTennis = async () => {
      const promises = [
        queryClient.prefetchQuery(
          'tennis:standings',
          () => Fire.cloud('tennis', { method: 'getRankings' }),
          { staleTime: 900000 /* 15 mn */ },
        ),
        queryClient.prefetchQuery(
          'tennis:calendar',
          () => Fire.cloud('tennis', { method: 'getSummaries' }),
          { staleTime: 900000 /* 15 mn */ },
        ),
      ];
      await Promise.all(promises);
    };
    prefetchTennis();
  }, [queryClient]);

  useEffect(() => {
    const prefetchNBA = async () => {
      const promises = [
        queryClient.prefetchQuery(
          'nba:standings',
          () => Fire.cloud('basket', { method: 'getRankings' }),
          { staleTime: 900000 /* 15 mn */ },
        ),
        queryClient.prefetchQuery(
          'nba:calendar:playoffs',
          () =>
            Fire.cloud<Playoffs>('basket', { method: 'getPlayoffSchedules' }),
          { staleTime: 900000 /* 15 mn */ },
        ),
        queryClient.prefetchQuery(
          'nba:calendar:regular',
          () => Fire.cloud('basket', { method: 'getRegularSchedules' }),
          { staleTime: 900000 /* 15 mn */ },
        ),
      ];
      await Promise.all(promises);
    };
    prefetchNBA();
  }, [queryClient]);

  return { soccerTournaments };
};

export default useLibrary;
