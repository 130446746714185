import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import 'video-react/dist/video-react.css'; // import css
import { Grid, Box, Typography } from '@material-ui/core';
import BigCardHighlight from '../Templates/Cards/BigCardHighlight';
import Loading from '../../Loading/Loading';
import { useTranslation } from 'react-i18next';
import '../../../theme/datasportTheme.css';
import poster1 from 'images/highlights (1).jpg';
import poster2 from 'images/highlights (2).jpg';
import poster3 from 'images/highlights (3).jpg';
import poster4 from 'images/highlights (4).jpg';
import Navbar from '../Templates/Navbar/Navbar';
import Fade from '@material-ui/core/Fade';
import { Fire } from 'services';
import { PreviewHighlight } from '../../../types/PreviewHighlight';
import InfiniteScroll from 'react-infinite-scroll-component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
    },
    background: {
      background: '#fcfcfa',
    },
    container: {
      maxWidth: 1300,
      margin: 'auto',
      width: '100%',
    },
    highlightsContainer: {
      position: 'relative',
      '& .infinite-scroll-component__outerdiv': {
        width: '100%',
      },
    },
    teamName: {
      fontWeight: 700,
      color: 'white',
      width: 'max-content',
      margin: 'auto',
      marginTop: '10%',
    },
    smallTeamName: {
      width: 'initial',
    },
    bg: {
      backgroundImage:
        'linear-gradient(to bottom, rgba(11, 24, 71, 0) -16%, #0b1847 104%)',
      pointerEvents: 'none',
    },
    btn: {
      zIndex: 3,
      marginTop: '0 !important',
      marginLeft: '0 !important',
      transform: 'translate(-50%, -50%) !important',
      borderRadius: '50% !important',
      backgroundColor: 'rgba(255, 255, 255, 0.25) !important',

      ':before': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    bigBtn: {
      width: '4em !important',
      height: '4em !important',
    },
    smallBtn: {
      width: '2em !important',
      height: '2em !important',
    },
    card: {
      padding: 0,
      marginBottom: '1.5rem',
    },
    littleCard: {
      height: 125,
      [theme.breakpoints.down('sm')]: {
        '&:nth-child(2n)': {
          paddingRight: 0,
          paddingLeft: 10,
        },
        '&:nth-child(2n+1)': {
          paddingLeft: 0,
          paddingRight: 10,
        },
      },
    },
    flag: {
      height: 80,
      width: 80,
      margin: 'auto',
      backgroundSize: 'contain',
    },
    smallFlag: {
      width: 30,
      height: 30,
    },
  }),
);

const getPoster = (index: number) => {
  const v = (index / 4) % 1;
  switch (v) {
    case 0:
      return poster1;
    case 0.25:
      return poster2;
    case 0.5:
      return poster3;
    case 0.75:
      return poster4;
    default:
      return poster1;
  }
};

export default function PageHighlights() {
  const classes = useStyles();
  const spacing = 3;
  const [loading, setLoading] = useState(false);
  const [highlights, setHighlights] = useState<PreviewHighlight[]>([]);
  const { t } = useTranslation();

  const getHighlights = async () => {
    let ref = Fire.store()
      .collection('soccer_highlight')
      .orderBy('timestamp', 'desc')
      .limit(5);
    if (highlights.length) {
      ref = ref.startAfter(highlights[highlights.length - 1].timestamp);
    }
    const receivedHighlights = await Fire.list<PreviewHighlight>(ref);
    setHighlights([...highlights, ...receivedHighlights]);
  };

  useEffect(() => {
    setLoading(true);
    getHighlights();
    setLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!highlights || loading)
    return (
      <Grid container item xs={12} className={classes.card}>
        <Loading nobg />
      </Grid>
    );

  return (
    <Fade in timeout={500}>
      <Grid container className={classes.background}>
        <Grid container spacing={spacing} className={classes.container}>
          <Grid item xs={12}>
            <Navbar mediumExtend />
          </Grid>
          <Grid item xs={12} style={{ zIndex: 1 }}>
            <Box margin="auto" textAlign="center" padding="0 2rem" zIndex="1">
              <Typography variant="h1" component={'span'}>
                <Box
                  color="white"
                  textAlign="center"
                  width="100%"
                  marginBottom="2rem"
                >
                  {t('Highlights vidéos')}
                </Box>
              </Typography>
              <Typography variant="body1" component={'span'}>
                <Box
                  color="white"
                  textAlign="center"
                  width="100%"
                  marginBottom="2rem"
                  lineHeight="1.5rem"
                >
                  {t('Retrouver tous les buts de vos championnats préférés')}
                </Box>
              </Typography>
            </Box>
          </Grid>
          <Grid item container xs={12} justify="center">
            <Grid
              item
              container
              xs={10}
              className={classes.highlightsContainer}
              spacing={spacing}
              justify="center"
              alignItems="center"
            >
              <InfiniteScroll
                dataLength={highlights.length}
                next={() => getHighlights()}
                hasMore={true}
                loader={<h4>Loading...</h4>}
              >
                {highlights.map((highlight, i) => (
                  <Grid
                    container
                    item
                    justify="center"
                    style={{ minHeight: 350 }}
                    key={highlight.id}
                  >
                    <Grid item xs={11} className={classes.card}>
                      <BigCardHighlight
                        url={highlight.url}
                        team1Name={highlight.homeTeamName}
                        team1Logo={highlight.homeTeamLogo}
                        team2Name={highlight.awayTeamName}
                        team2Logo={highlight.awayTeamLogo}
                        homeScore={highlight.homeScore}
                        awayScore={highlight.awayScore}
                        timestamp={highlight.timestamp}
                        poster={getPoster(i)}
                        player={highlight.player}
                        minute={highlight.minute}
                      />
                    </Grid>
                  </Grid>
                ))}
              </InfiniteScroll>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fade>
  );
}
