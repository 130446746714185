import React from 'react';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Configure, Index } from 'react-instantsearch-dom';
import TeamHits from './TeamHits/TeamHits';
import PlayerHits from './PlayerHits/PlayerHits';
import Pagination from './Pagination/Pagination';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bigCard: {
      height: 'unset',
      backgroundColor: 'transparent',
      padding: '.9rem 1.9rem',
      [theme.breakpoints.down('sm')]: {
        padding: 5,
        width: 'calc(100% + 40px)',
        transform: 'translate(-20px)',
        margin: 0,
      },
      '&> *': {
        alignItems: 'center',
      },
    },
    hiddenTeamPlayers: {
      height: '0px',
      visibility: 'hidden',
    },
  }),
);

const TeamsPlayers: React.FC<{ visible: boolean }> = ({ visible }) => {
  const classes = useStyles();

  return (
    <Grid container justify="center">
      <Grid container item xs={10} sm={12} justify="space-around">
        <Box width="100%" zIndex="1">
          <Card
            className={clsx({
              [classes.bigCard]: true,
              [classes.hiddenTeamPlayers]: !visible,
            })}
          >
            <Index indexName="soccer_teams">
              <TeamHits />
              <Configure hitsPerPage={8} />
              <Pagination />
            </Index>
            <Index indexName="soccer_players">
              <PlayerHits />
              <Configure hitsPerPage={8} />
              <Pagination />
            </Index>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TeamsPlayers;
