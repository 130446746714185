import React from 'react';
import medal1 from 'images/medal1.png';
import medal2 from 'images/medal2.png';
import medal3 from 'images/medal3.png';
import { Typography } from '@material-ui/core';

const TeamHitPosition: React.FC<{ position: number }> = ({ position }) => {
  if (position === 1) return <img src={medal1} alt="" />;
  if (position === 2) return <img src={medal2} alt="" />;
  if (position === 3) return <img src={medal3} alt="" />;
  return <Typography variant="h6">{position}</Typography>;
};

export default TeamHitPosition;
