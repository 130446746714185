import React from 'react';
import { Grid } from '@material-ui/core';
import MatchsHistory from './MatchsHistory/MatchsHistory';
import PlayerStatistics from './PlayerStatistics/PlayerStatistics';
import { PlayerTeamStat } from '../../../../types/soccer/player-team-stats';

type BottomSectionProps = {
  playerStats: PlayerTeamStat[];
};

const BottomSection: React.FC<BottomSectionProps> = ({ playerStats }) => {
  return (
    <Grid
      item
      container
      xs={12}
      sm={11}
      style={{ margin: 'auto', position: 'relative' }}
      justify="center"
      spacing={2}
    >
      <Grid item container xs={12} md={9}>
        <MatchsHistory playerStats={playerStats} />
      </Grid>
      <Grid item container xs={9} md={3}>
        <PlayerStatistics playerStats={playerStats} />
      </Grid>
    </Grid>
  );
};

export default BottomSection;
