import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { Grid, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  DaySummaries,
  TennisSummaries,
} from '../../../../../types/TennisSummaries';
import CalendarNavigation from './CalendarNavigation/CalendarNavigation';
import CalendarTennisList from './CalendarTennisList/CalendarTennisList';
import { useQuery } from 'react-query';
import { Fire } from 'services';
import moment from 'moment';
import CardGeneral from '../../../Templates/Cards/CardGeneral';
import Loading from '../../../../Loading/Loading';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bigCard: {
      [theme.breakpoints.down('sm')]: {
        padding: 5,
        width: 'calc(100% + 40px)',
        transform: 'translate(-20px)',
        margin: 0,
      },
      '&> *': {
        textAlign: 'center',
        alignItems: 'center',
      },
    },
  }),
);

const getDaySummaries = (tennisSummaries: TennisSummaries): DaySummaries => {
  const daySummaries = {};
  const summaries = Object.values(tennisSummaries);
  summaries.forEach(({ name, summary }) => {
    summary.forEach(event => {
      const eventYYYYMMDD = event.sport_event.start_time.slice(0, 10);
      if (!daySummaries[eventYYYYMMDD]) daySummaries[eventYYYYMMDD] = {};
      if (
        !Object.keys(daySummaries[eventYYYYMMDD]).some(
          eventName => name === eventName,
        )
      ) {
        daySummaries[eventYYYYMMDD][name] = [];
      }
      daySummaries[eventYYYYMMDD][name].push(event);
    });
  });
  return daySummaries;
};

const CalendarTennis: React.FC = () => {
  const classes = useStyles();
  const [daySummaries, setDaySummaries] = React.useState<DaySummaries | null>(
    null,
  );
  const [currentCalendar, setCurrentCalendar] = useState<number | null>(null);
  const [calendarLength, setCalendarLength] = useState<number | null>(null);
  const { data: tennisSummaries, isLoading } = useQuery(
    'tennis:calendar',
    () => Fire.cloud('tennis', { method: 'getSummaries' }),
    { staleTime: 900000 /* 15 mn */ },
  );

  const dateNowYYYYMMDD = currentCalendar
    ? // @ts-ignore
      Object.keys(daySummaries).sort((a, b) => new Date(a) - new Date(b))[
        currentCalendar
      ]
    : null;

  useEffect(() => {
    if (tennisSummaries) {
      const daySummaries = getDaySummaries(tennisSummaries);
      setDaySummaries(daySummaries);
      const calendarLength = Object.keys(daySummaries).length;
      const now = moment()
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .format('YYYY-MM-DD');
      if (Object.keys(daySummaries).includes(now)) {
        setCurrentCalendar(
          Object.keys(daySummaries)
            // @ts-ignore
            .sort((a, b) => new Date(a) - new Date(b))
            .indexOf(now),
        );
      } else setCurrentCalendar(calendarLength - 1);
      setCalendarLength(calendarLength);
    }
  }, [tennisSummaries]);

  return (
    <>
      <Grid
        container
        item
        xs={12}
        justify="space-around"
        style={{ marginTop: '1rem' }}
      >
        <Box width="100%" zIndex="1">
          <CardGeneral className={classes.bigCard}>
            {isLoading ? (
              <Grid container style={{ padding: '2rem 0' }}>
                <Loading nobg />
              </Grid>
            ) : (
              <>
                <Grid container item xs={12} justify="space-between">
                  {dateNowYYYYMMDD && (
                    <CalendarNavigation
                      currentCalendar={currentCalendar as number}
                      calendarLength={calendarLength as number}
                      setCurrentCalendar={next =>
                        setCurrentCalendar(prev => (prev as number) + next)
                      }
                      dateNowYYYYMMDD={dateNowYYYYMMDD}
                    />
                  )}
                </Grid>
                <div style={{ marginBottom: '1.5rem', marginTop: '0' }}>
                  <Grid container item>
                    <Typography variant="h6" component={'span'}>
                      <Box
                        fontFamily="AvenirNext"
                        color="#848ca5"
                        style={{ textTransform: 'uppercase' }}
                        margin=".5rem 0"
                      />
                    </Typography>
                  </Grid>
                  <div>
                    {daySummaries && (
                      <CalendarTennisList
                        summaries={daySummaries[dateNowYYYYMMDD as string]}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </CardGeneral>
        </Box>
      </Grid>
    </>
  );
};

export default CalendarTennis;
