import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Box, Typography } from '@material-ui/core';
import Logo from '../../../Templates/Logos/Logo';
import SingleStatistic from './SingleStatistic/SingleStatistic';
import {
  Competitor,
  StatCompetitor,
  TotalStatistics,
} from '../../../../../types/sportradar/soccer/sport-event';

type MatchStatisticsProps = {
  competitors: Competitor[];
  competitorsStats: TotalStatistics;
};

type Stat = {
  label: string;
  value1: number;
  value2: number;
  additionnal: string;
};

const MatchStatistics: React.FC<MatchStatisticsProps> = ({
  competitors,
  competitorsStats,
}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();

  const types = useMemo(
    () =>
      ({
        ball_possession: {
          label: t('Possession'),
          percentage: true,
        },
        shots_total: {
          label: t('Tirs'),
          percentage: false,
        },
        shots_on_target: {
          label: t('Tirs cadrés'),
          percentage: false,
        },
        shots_off_target: {
          label: t('Tirs non cadrés'),
          percentage: false,
        },
        shots_blocked: {
          label: t('Tirs stoppés'),
          percentage: false,
        },
        corner_kicks: {
          label: t('Corners'),
          percentage: false,
        },
        free_kicks: {
          label: t('Coups francs'),
          percentage: false,
        },
        offsides: {
          label: t('Hors-jeux'),
          percentage: false,
        },
        substitutions: {
          label: t('Remplacements'),
          percentage: false,
        },
        fouls: {
          label: t('Fautes'),
          percentage: false,
        },
        yellow_cards: {
          label: t('Cartons jaunes'),
          percentage: false,
        },
        red_cards: {
          label: t('Cartons rouges'),
          percentage: false,
        },
      } as const),
    [t],
  );

  const getStats = useCallback(
    (competitors: StatCompetitor[]): Stat[] => {
      const competitor1Stats = competitors[0].statistics;
      const competitor2Stats = competitors[1].statistics;
      const stats: Stat[] = [];
      Object.entries(types).forEach(([type, { label, percentage }]) => {
        if (type in competitor1Stats && type in competitor2Stats) {
          stats.push({
            label: label,
            value1: competitor1Stats[type],
            value2: competitor2Stats[type],
            additionnal: percentage ? ' %' : '',
          });
        }
      });
      return stats;
    },
    [types],
  );

  const [competitor1, competitor2] = competitors;
  const stats = useMemo(
    () => getStats(competitorsStats.competitors),
    [competitorsStats.competitors, getStats],
  );

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      padding=".5rem 0 0 0"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        maxWidth="600px"
        width="100%"
        display="flex"
        flexWrap="nowrap"
        marginBottom="1.5rem"
      >
        <Grid container justify="center" alignItems="center">
          <Grid item container xs={4} justify="center">
            <Typography variant="h5" component={'span'}>
              <Box color={theme.palette.primary.main}>
                {mobile ? competitor1.abbreviation : competitor1.name}
              </Box>
            </Typography>
          </Grid>
          <Grid item container xs={1} justify="center">
            <Logo
              src={competitor1.icon}
              width={mobile ? 30 : 40}
              typeImgDefault="club"
              imgDefautl="FOOTBALL"
            />
          </Grid>
          <Grid item container xs={2} justify="center">
            <Typography variant="body1" component={'span'}>
              <Box color={theme.palette.text.secondary}>{t('Equipes')}</Box>
            </Typography>
          </Grid>
          <Grid item container xs={1} justify="center">
            <Logo
              src={competitor2.icon}
              width={mobile ? 30 : 40}
              typeImgDefault="club"
              imgDefautl="FOOTBALL"
            />
          </Grid>
          <Grid item container xs={4} justify="center">
            <Typography variant="h5" component={'span'}>
              <Box color={theme.palette.primary.main}>
                {mobile ? competitor2.abbreviation : competitor2.name}
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {stats.map(({ label, value1, value2, additionnal }, index) => {
        return (
          <SingleStatistic
            name={label}
            value1={value1}
            value2={value2}
            bgGrey={index % 2 === 0}
            additionnal={additionnal}
          />
        );
      })}
    </Box>
  );
};

export default MatchStatistics;
