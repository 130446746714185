import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  useTheme,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fade from '@material-ui/core/Fade';
import CardPronoFille from '../Templates/Cards/CardPronoFille';
import CardOtherPrediction from '../Templates/Cards/CardOtherPrediction';
import CardBookmakers from '../Templates/Cards/CardBookmakers';
import CardGeneral from '../Templates/Cards/CardGeneral';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useTranslation } from 'react-i18next';
import SinglePronoTabPanel from './SinglePronoTabPanel';
import '../../../theme/datasportTheme.css';
import { getProperName } from '../../../utils/sportradar';
import { getInfosPronoLibre } from '../../../utils/pronoLibreSoccer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    prono: {
      padding: '0 10px',
      // background: "#fcfcfa",
      zIndex: 1,
      width: '100vw',
      color: 'white',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        width: '100%',
      },
    },
    container: {
      maxWidth: 1500,
      margin: 'auto',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },
    },
    childPredLeft: {
      margin: 0,
      padding: '0px !important',
      height: 'fit-content',
    },
    cardGeneral: {
      textAlign: 'end',
      height: '15rem',
      // border: 'solid 2px #7fc4ff8c',
    },
    rightColumn: {
      height: 'fit-content',
    },
  }),
);
// const [spacing, setSpacing] = useState(8);

interface Props {
  prono: any;
  nextPronos: any;
}

export default function SingleProno(props: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const tablet = useMediaQuery(theme.breakpoints.down('sm'));
  const smallComputer = useMediaQuery(theme.breakpoints.down('md'));
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const spacing = mobile ? 3 : 6;
  const { prono, nextPronos } = props;

  const language = i18n.language;

  if (
    props.prono.hide_until &&
    new Date().getTime() / 1000 - props.prono.hide_until > 0
  )
    history.push('/pronos');

  const now = new Date().getTime() / 1000;
  const isPronoCanceled = (
    winner: any,
    dateHideUntil: firebase.firestore.Timestamp,
  ) => !winner && (!dateHideUntil || dateHideUntil.seconds < now);

  return (
    <div className={classes.prono}>
      <Fade in={true} timeout={500}>
        <React.Fragment>
          <Grid container spacing={spacing} className={classes.container}>
            <Grid
              item
              xs={12}
              justify="flex-start"
              className="noPaddingTop noPaddingBottom"
            >
              <Button
                onClick={() => history.push('/pronos')}
                color="inherit"
                startIcon={<ArrowBackIcon />}
              >
                {t('Retour')}
              </Button>
            </Grid>
            <Grid
              container
              item
              xs={12}
              lg={9}
              spacing={spacing}
              id="childPred-left"
              className={classes.childPredLeft}
            >
              <Grid item xs={12} sm={8}>
                <CardPronoFille {...prono} />
              </Grid>
              <Grid item container xs={12} sm={4}>
                {prono && !mobile && (
                  <CardGeneral blue className={classes.cardGeneral}>
                    <Box
                      width="100%"
                      height="100%"
                      display="flex"
                      flexDirection="column"
                      padding={
                        smallComputer
                          ? mobile
                            ? '0 2rem'
                            : '0 1rem'
                          : '0 1rem'
                      }
                    >
                      <Box
                        display="flex"
                        justifyContent="space-evenly"
                        height="50%"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Typography component={'span'} variant="h6">
                          <Box
                            color="#838ca6"
                            fontWeight={600}
                            textAlign="center"
                          >
                            {t('Stade')}
                          </Box>
                        </Typography>
                        <Typography component={'span'} variant="h5">
                          <Box
                            color="white"
                            fontWeight="bold"
                            textAlign="center"
                          >
                            {prono.schedule.value.sport_event.venue?.name
                              ? prono.schedule.value.sport_event.venue.name
                              : t('Stade inconnu')}
                          </Box>
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-evenly"
                        height="50%"
                        flexDirection="column"
                        borderTop="1px solid rgba(131, 140, 166, .3)"
                        alignItems="center"
                      >
                        <Typography component={'span'} variant="h6">
                          <Box
                            color="#838ca6"
                            fontWeight={600}
                            textAlign="center"
                          >
                            {t('Arbitre')}
                          </Box>
                        </Typography>
                        <Typography component={'span'} variant="h5">
                          <Box
                            color="white"
                            fontWeight="bold"
                            textAlign="center"
                          >
                            {prono.schedule.value.sport_event
                              .sport_event_conditions?.referees?.[0].name
                              ? getProperName(
                                  prono.schedule.value.sport_event
                                    .sport_event_conditions.referees[0].name,
                                )
                              : t('Non renseigné')}
                          </Box>
                        </Typography>
                      </Box>
                    </Box>
                  </CardGeneral>
                )}
              </Grid>
              <Grid item xs={12}>
                <SinglePronoTabPanel {...prono} />
              </Grid>
              {prono.cotes && language === 'fr' && (
                <Grid item xs={12}>
                  {/* <Box
                    marginBottom="1rem"
                    color="white"
                    width="100%"
                    fontSize="1.375rem"
                    fontWeight={600}
                  >
                    {t('Trouvez la meilleure cote en direct')}
                  </Box> */}
                  {/* {prono.matchType !== 'byWin' && (
                    <Box
                      marginBottom="1rem"
                      color="white"
                      width="95%"
                      fontSize=".8rem"
                      fontWeight={500}
                    >
                      {t(
                        'Attention, les cotes ci-dessous ne correspondent pas à la analyse de l’algorithme. Elles correspondent au pari',
                      ) +
                        ` “${prono.team1.name} vainqueur”. ` +
                        t(
                          "Pour trouver la cote de la analyse de l'algorithme, veuillez vous rendre sur le site d'un bookmaker.",
                        )}
                    </Box>
                  )} */}
                  <CardBookmakers prono={prono} />
                </Grid>
              )}
            </Grid>
            {!mobile && (
              <Grid
                container
                item
                xs={12}
                lg={3}
                id="childPredRight"
                justify={smallComputer ? 'space-around' : 'flex-start'}
                className={classes.rightColumn}
                spacing={!mobile && tablet ? 2 : 0}
              >
                {nextPronos.map(
                  prono =>
                    !isPronoCanceled(prono.team1, prono.hide_until) && (
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        lg={12}
                        className="margin-bottom2rem"
                        key={`next_prono_${prono.id}`}
                      >
                        <CardOtherPrediction prono={prono} />
                      </Grid>
                    ),
                )}
                {/* <Grid container item xs={12} spacing={smallComputer ? 1 : 0}>
                <Grid item xs={12}>
                  <Box
                    marginBottom="1rem"
                    color="white"
                    width="100%"
                    fontSize="1.375rem"
                    fontWeight={600}
                  >
                    {t('Actus du match')}
                  </Box>
                </Grid>
                <Grid item sm={4} md={12}>
                  <SingleMatchActu min={0} text="Actualités à venir" />
                </Grid>
                <Grid item sm={4} md={12}>
                                    <SingleMatchActu min={43} text="Carton jaune MBappe" />
                                </Grid>
                                <Grid item sm={4} md={12}>
                                    <SingleMatchActu min={81} text="But de Pavard" />
                                </Grid>
              </Grid> */}
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      </Fade>
    </div>
  );
}
