import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PreviewTitle from '../../../Templates/Misc/PreviewTitle';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { TFunction } from 'i18next';
import CardPredictionTennis from '../../../Templates/Cards/CardPredictionTennis/CardPredictionTennis';
import RowTennisHiddenPrediction from '../../../Templates/Rows/RowTennisHiddenPrediction';
import { Fire } from 'services';
import firebase from 'firebase';
import { PronoTennis, ValidPronos } from '../../../../../types/pronoTennis';
import arrowRightSolid from 'images/arrowRightSolid.svg';
import Loading from '../../../../Loading/Loading';
import { getInfosPronoLibre as getInfosPronoLibreTennis } from '../../../../../utils/pronoLibreTennis';
import { getValidPronosFiltered } from '../../../../../utils/pronos';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    singleCard: {
      maxWidth: 400,
      minWidth: 280,
      width: '30%',
      marginRight: '2rem',
      [theme.breakpoints.down('sm')]: {
        marginRight: '2rem',
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: 280,
      },
    },
    last: {
      [theme.breakpoints.down('md')]: {
        margin: 'auto',
      },
    },
    fullWidth: {
      [theme.breakpoints.down('xs')]: {
        marginRight: '0px !important',
      },
    },
    bigCard: {
      background: 'rgba(25,38,83,0.85)',
      width: '100%',
      borderRadius: 25,
      paddingBottom: '.75rem',
    },
    rowContainer: {
      '& > div': {
        margin: '10px 0',
      },
    },
    singleCardContainer: {
      flexFlow: 'row nowrap',
      overflowX: 'scroll',
      marginBottom: '2rem',
      scrollbarColor: theme.palette.text.secondary + ' transparent',
      scrollbarWidth: 'thin',
      paddingTop: '1rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingBottom: '2rem',
      '&::-webkit-scrollbar': {
        height: '7px',
        paddingBottom: '1rem',
      },

      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.text.secondary,
        transition: 'background-color .3s ease-in-out',
        borderRadius: '20px',
        '&:hover': {
          backgroundColor: theme.palette.text.primary,
        },
      },
    },
    middleArrowAfterLeft: {
      position: 'relative',
      '&::after': {
        content: "''",
        backgroundImage: `url(${arrowRightSolid})`,
        backgroundSize: 'cover',
        bakgroundPosition: 'center',
        color: 'white',
        position: 'absolute',
        right: '-10px',
        top: '45%',
        transform: 'translate(0, -50%)',
        width: 25,
        height: 27,
        zIndex: 10,
        [theme.breakpoints.down('md')]: {
          right: '0px',
        },
        [theme.breakpoints.down('xs')]: {
          width: 14,
          height: 15,
          right: '-6px',
        },
      },
    },
    topArrowAfterLeft: {
      position: 'relative',
      '&::after': {
        content: "''",
        backgroundImage: `url(${arrowRightSolid})`,
        backgroundSize: 'cover',
        bakgroundPosition: 'center',
        color: 'white',
        position: 'absolute',
        right: '-10px',
        top: '22%',
        transform: 'translate(0, -50%)',
        width: 25,
        height: 27,
        zIndex: 10,
        [theme.breakpoints.down('md')]: {
          right: '0px',
        },
        [theme.breakpoints.down('xs')]: {
          width: 14,
          height: 15,
          right: '-6px',
        },
      },
    },
    bottomArrowAfterLeft: {
      position: 'relative',
      '&::before': {
        content: "''",
        backgroundImage: `url(${arrowRightSolid})`,
        backgroundSize: 'cover',
        bakgroundPosition: 'center',
        color: 'white',
        position: 'absolute',
        right: '-10px',
        top: '72%',
        transform: 'translate(0, -50%)',
        width: 25,
        height: 27,
        zIndex: 10,
        [theme.breakpoints.down('md')]: {
          right: '0px',
        },
        [theme.breakpoints.down('xs')]: {
          width: 14,
          height: 15,
          right: '-6px',
        },
      },
    },
  }),
);

type TennisPageProps = {
  mobile: boolean;
  tablet: boolean;
  smallComputer: boolean;
  mediumComputer: boolean;
  isLoading: boolean;
  pronos: PronoTennis[];
  t: TFunction;
};

const TennisTab: React.FC<TennisPageProps> = ({
  mobile,
  tablet,
  smallComputer,
  mediumComputer,
  pronos,
  isLoading,
  t,
}) => {
  const classes = useStyles();
  const user = useSelector((state: any) => state.authReducer.user);
  const [isProUser, setIsProUser] = React.useState<boolean>(false);
  const [hiddenPronos, setHiddenPronos] = useState<any>({
    canceled: [],
    available: [],
  });
  const [validPronos, setValidPronos] = useState<ValidPronos>({
    all: [],
    lowRisks: [],
    highRisks: [],
    pronosLength: 0,
  });
  const [customText, setCustomText] = React.useState('');
  const now = new Date().getTime() / 1000;

  const verifyIsProUser = async () => {
    const ref = Fire.store()
      .collection('pros')
      .where('email', '==', user.email);
    const list = await Fire.list(ref);
    setIsProUser(!!list.length);
  };

  React.useEffect(() => {
    verifyIsProUser();
  }, []);

  const getCustomText = async () => {
    try {
      const ref = Fire.store().collection('custom_text').doc('preview_pronos');
      const text = await Fire.doc(ref);
      if (text.tennis) setCustomText(text.tennis);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    getCustomText();
  }, []);

  useEffect(() => {
    setHiddenPronos(getHiddenPronos());
    setValidPronos(getValidPronos());
  }, [pronos, isProUser]);

  const isPronoCanceled = (
    winner: any,
    dateHideUntil: firebase.firestore.Timestamp,
  ) => !winner && (!dateHideUntil || dateHideUntil.seconds < now);

  const getValidPronos = () => {
    const validPronosFiltered = getValidPronosFiltered(
      pronos,
      false,
      isProUser,
    );

    const lowRisks = validPronosFiltered.filter(
      prono => getInfosPronoLibreTennis(prono, t).pronoPercentage >= 56,
    );

    const highRisks = validPronosFiltered.filter(
      prono => getInfosPronoLibreTennis(prono, t).pronoPercentage < 56,
    );

    return {
      all: validPronosFiltered,
      lowRisks,
      highRisks,
      pronosLength: validPronosFiltered.length,
    };
  };

  const getHiddenPronos = () => {
    const dateNow = Date.now();
    const hiddenPronos = pronos.filter(
      prono =>
        !prono.winner ||
        (prono.dateHideUntil && prono.dateHideUntil.seconds > now),
    );
    hiddenPronos.sort((a, b) => {
      const aCanceled = isPronoCanceled(a.winner, a.dateHideUntil);
      const bCanceled = isPronoCanceled(b.winner, b.dateHideUntil);
      if (aCanceled && bCanceled) return 0;
      if (aCanceled) return 1;
      if (bCanceled) return -1;
      return a.dateHideUntil?.seconds - b.dateHideUntil?.seconds;
    });
    const hiddenPronosFiltered = {
      available: [
        ...hiddenPronos
          .filter(prono => !isPronoCanceled(prono.winner, prono.dateHideUntil))
          .sort((a, b) => a.dateHideUntil.seconds - b.dateHideUntil.seconds),
      ],
      canceled: [
        ...hiddenPronos.filter(prono =>
          isPronoCanceled(prono.winner, prono.dateHideUntil),
        ),
      ],
    };
    return hiddenPronosFiltered;
  };

  return (
    <>
      {validPronos.pronosLength !== 0 && (
        <Grid item xs={12}>
          <PreviewTitle
            title="Analyses"
            whiteColor
            fontWeight="600"
            marginBottom
          />
        </Grid>
      )}
      <Grid
        item
        container
        xs={12}
        className={clsx(mobile ? 'marginAuto zIndex1' : 'zIndex1')}
      >
        <Grid
          item
          container
          xs={12}
          className={clsx(
            validPronos.pronosLength > 3
              ? mobile
                ? validPronos.lowRisks.length > 1 && classes.topArrowAfterLeft
                : tablet
                ? validPronos.lowRisks.length > 2 && classes.topArrowAfterLeft
                : smallComputer
                ? validPronos.lowRisks.length > 3 && classes.topArrowAfterLeft
                : mediumComputer
                ? validPronos.lowRisks.length > 2 && classes.topArrowAfterLeft
                : validPronos.lowRisks.length > 3 && classes.topArrowAfterLeft
              : mobile
              ? validPronos.pronosLength > 1 && classes.middleArrowAfterLeft
              : tablet
              ? validPronos.pronosLength > 2 && classes.middleArrowAfterLeft
              : smallComputer
              ? validPronos.pronosLength > 3 && classes.middleArrowAfterLeft
              : mediumComputer
              ? validPronos.pronosLength > 2 && classes.middleArrowAfterLeft
              : validPronos.pronosLength > 3 && classes.middleArrowAfterLeft,
            validPronos.pronosLength > 3 && mobile
              ? validPronos.highRisks.length > 1 && classes.bottomArrowAfterLeft
              : tablet
              ? validPronos.highRisks.length > 2 && classes.bottomArrowAfterLeft
              : smallComputer
              ? validPronos.highRisks.length > 3 && classes.bottomArrowAfterLeft
              : mediumComputer
              ? validPronos.highRisks.length > 2 && classes.bottomArrowAfterLeft
              : validPronos.highRisks.length > 3 &&
                classes.bottomArrowAfterLeft,
          )}
        >
          {validPronos.pronosLength > 0 && (
            <Grid
              item
              container
              xs={12}
              className={classes.singleCardContainer}
              data-tour="valid-pronos-step"
            >
              {!isLoading ? (
                validPronos.pronosLength > 3 ? (
                  validPronos.lowRisks.map((prono, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      className={clsx(
                        classes.singleCard,
                        index === validPronos.pronosLength - 1 &&
                          validPronos.pronosLength % 2 === 1 &&
                          validPronos.pronosLength !== 1 &&
                          classes.last,
                        validPronos.pronosLength === 1 && classes.fullWidth,
                      )}
                      key={index}
                    >
                      <CardPredictionTennis prono={prono} blue />
                    </Grid>
                  ))
                ) : (
                  validPronos.all.map((prono, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      data-tour={index === 0 ? 'click-prono-step' : ''}
                      className={clsx(
                        classes.singleCard,
                        index === validPronos.pronosLength - 1 &&
                          validPronos.pronosLength % 2 === 1 &&
                          validPronos.pronosLength !== 1 &&
                          classes.last,
                        validPronos.pronosLength === 1 && classes.fullWidth,
                      )}
                      key={index}
                    >
                      <CardPredictionTennis prono={prono} blue />
                    </Grid>
                  ))
                )
              ) : (
                <Box
                  height="100%"
                  minHeight="150px"
                  width="80%"
                  margin="auto"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  color="primary.dark"
                >
                  <Typography variant="h4">
                    {t('Chargement des analyses...')}
                  </Typography>
                </Box>
              )}
            </Grid>
          )}
          {validPronos.pronosLength > 3 && (
            <Grid
              item
              container
              xs={12}
              className={classes.singleCardContainer}
            >
              {!isLoading ? (
                validPronos.highRisks.map((prono, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    className={clsx(
                      classes.singleCard,
                      index === validPronos.pronosLength - 1 &&
                        validPronos.pronosLength % 2 === 1 &&
                        validPronos.pronosLength !== 1 &&
                        classes.last,
                      validPronos.pronosLength === 1 && classes.fullWidth,
                    )}
                    key={index}
                  >
                    <CardPredictionTennis prono={prono} blue />
                  </Grid>
                ))
              ) : (
                <Box
                  height="100%"
                  minHeight="150px"
                  width="80%"
                  margin="auto"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  color="primary.dark"
                >
                  <Typography variant="h4">
                    {t('Chargement des analyses...')}
                  </Typography>
                </Box>
              )}
            </Grid>
          )}
        </Grid>
        {(hiddenPronos.canceled?.length > 0 ||
          hiddenPronos.available?.length > 0) && (
          <Grid item xs={12}>
            {hiddenPronos.available?.length > 0 && (
              <Grid item xs={12}>
                <PreviewTitle
                  title="Analyses en cours de calcul"
                  whiteColor
                  fontWeight="600"
                  marginBottom
                />
              </Grid>
            )}
            {hiddenPronos.available?.length > 0 && (
              <Grid item xs={12} className={classes.bigCard}>
                <Box
                  padding={mobile ? '20px' : '26px'}
                  paddingBottom={mobile ? '0px' : 'inherit'}
                  width="100%"
                  height="100%"
                  marginLeft="-.5rem"
                >
                  <Box width="100%">
                    <Grid container className={classes.rowContainer}>
                      {hiddenPronos.available.map(hiddenProno => (
                        <Grid item xs={12} key={hiddenProno.id}>
                          <RowTennisHiddenPrediction prono={hiddenProno} />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            )}
            {hiddenPronos.canceled?.length > 0 && (
              <Grid item xs={12} className={classes.bigCard}>
                <Box
                  padding={mobile ? '20px' : '26px'}
                  paddingBottom={mobile ? '0px' : 'inherit'}
                  width="100%"
                  height="100%"
                  marginLeft="-.5rem"
                >
                  <Box
                    color="#848ca5"
                    className="uppercase"
                    marginBottom=".5rem"
                  >
                    {/* date */}
                    <Typography variant="h4" component={'span'}>
                      <Box
                        fontWeight="500"
                        fontFamily="BigNoodleTitling"
                        paddingLeft="1rem"
                        color="red"
                        letterSpacing="1.5px"
                      >
                        {t("MATCHS NON CHOISIS PAR L'ALGORITHME")}
                      </Box>
                    </Typography>
                  </Box>
                  <Box width="100%" data-tour="canceled-pronos-step">
                    <Grid container className={classes.rowContainer}>
                      {hiddenPronos.canceled.map(hiddenProno => (
                        <Grid item xs={12} key={hiddenProno.id}>
                          <RowTennisHiddenPrediction prono={hiddenProno} />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        )}
        {isLoading ? (
          <Loading nobg />
        ) : (
          validPronos.pronosLength === 0 &&
          !hiddenPronos.canceled?.length &&
          !hiddenPronos.available?.length && (
            <Grid
              item
              container
              xs={12}
              className={clsx(classes.singleCardContainer, 'noPaddingRight')}
            >
              <Box
                height="100%"
                minHeight="150px"
                width="80%"
                margin="auto"
                display="flex"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                color="white"
              >
                <Typography variant="h4">{t(customText)}</Typography>
              </Box>
            </Grid>
          )
        )}
      </Grid>
    </>
  );
};

export default TennisTab;
