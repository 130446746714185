import React, { useState } from 'react';
import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/it';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import flag_fr from 'images/flag_fr.png';
import flag_en from 'images/flag_en.png';
import flag_es from 'images/flag_es.png';
import flag_it from 'images/flag_it.png';
import ImageButton from '../../../Home/Templates/ImageButton';
import { Box, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingRight: '0 !important',
      marginLeft: 0,
      '&>button': {
        margin: '.5rem 1rem !important',
        backgroundSize: 'cover !important',
        backgroundPosition: 'center !important',
      },
      '&::after': {
        content: 'none !important',
      },
      '&::before': {
        content: 'none !important',
      },
    },
    icon: {
      display: 'none !important',
    },
    menuList: {
      '&>li>button': {
        margin: '0 .5rem !important',
      },
    },
  }),
);

export default function LangSelect(props) {
  const classes = useStyles();
  const history = useHistory();

  const { i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);

  const { secure } = props;

  React.useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const changeLanguage = lng => {
    setLang(lng);
    i18n.changeLanguage(lng);
    moment.locale(lng);
    {
      secure && history.push(`/secure/${lng}`);
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <Typography style={{ textTransform: 'uppercase', marginRight: '.25rem' }}>
        {lang}
      </Typography>
      <Select
        value={lang}
        onChange={e => changeLanguage(e.target.value)}
        inputProps={{
          classes: {
            root: classes.root,
            icon: classes.icon,
          },
        }}
        MenuProps={{
          classes: {
            list: classes.menuList,
          },
        }}
      >
        <MenuItem value="en">
          <ImageButton
            height={30}
            width={30}
            image={flag_en}
            marginRight="1rem"
          />
        </MenuItem>
        <MenuItem value="fr">
          <ImageButton
            height={30}
            width={30}
            image={flag_fr}
            marginRight="1rem"
          />
        </MenuItem>
        <MenuItem value="es">
          <ImageButton
            height={30}
            width={30}
            image={flag_es}
            marginRight="1rem"
          />
        </MenuItem>
        <MenuItem value="it">
          <ImageButton
            height={30}
            width={30}
            image={flag_it}
            marginRight="1rem"
          />
        </MenuItem>
      </Select>
    </Box>
  );
}
