import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import RowPlayer from './RowPlayer';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { Player } from '../../../../../types/soccer/team';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type PlayersTabProps = {
  players: Player[];
};

const PlayersTab: React.FC<PlayersTabProps> = ({ players }) => {
  const theme = useTheme();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Box
      position="relative"
      width="100%"
      display="flex"
      borderRadius="15px"
      overflow="hidden"
      boxShadow="0 0 23px 0 rgba(10, 22, 62, 0.1)"
      flexDirection="column"
      alignItems="center"
    >
      <Box width="100%" bgcolor="white" padding="1rem">
        <RowPlayer isHeader />
        {players.map((player, i) => (
          <RowPlayer
            key={player.id}
            jerseyNumber={player.number}
            player={player.name}
            playerLabel={player.name}
            playerId={player.id}
            position={t(player.position)}
            countryFlag={player.logo}
            age={player.age}
            height={player.height}
            weight={player.weight}
            bgGrey={i % 2 === 0}
            goToPlayerPage={() => history.push(`/player/${player.id}`)}
          />
        ))}
      </Box>
      <Box>
        <KeyboardArrowDownRoundedIcon
          style={{ cursor: 'pointer', color: theme.palette.text.secondary }}
        />
      </Box>
    </Box>
  );
};

export default PlayersTab;
