import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
  makeStyles,
  Theme,
  useTheme,
  createStyles,
} from '@material-ui/core/styles';
import { useTranslation, initReactI18next } from 'react-i18next';

type Props = {
  title: string;
  light: string;
};

const PreviewTitle = props => {
  const {
    title,
    light,
    whiteColor,
    fontWeight = '700',
    marginBottom,
    typography = null,
  } = props;
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      title: {
        color: whiteColor ? 'white' : theme.palette.text.primary,
        fontWeight: fontWeight,
      },
    }),
  );
  const classes = useStyles();

  return (
    <Typography variant="h3" component={'span'}>
      <Box lineHeight="2rem" marginBottom={marginBottom && '2rem'}>
        {light && (
          <Box fontWeight="fontWeightRegular" display="inline" color="#989fb4">
            {light}{' '}
          </Box>
        )}
        {typography ? (
          <Typography
            variant={typography}
            display="inline"
            className={classes.title}
          >
            {t(title)}
          </Typography>
        ) : (
          <Box
            fontWeight={fontWeight}
            display="inline"
            color={whiteColor ? 'white' : theme.palette.text.primary}
          >
            {t(title)}
          </Box>
        )}
      </Box>
    </Typography>
  );
};

export default PreviewTitle;
