import React, { useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import Navbar from '../Templates/Navbar/Navbar';
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import '../../../theme/datasportTheme.css';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Fire } from 'services';
import { useTranslation, initReactI18next } from 'react-i18next';
import ButtonFilled from '../Templates/Buttons/ButtonFilled';
import Loading from '../../Loading/Loading';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: 1300,
      margin: 'auto',
      width: '100%',
    },
    row: {
      position: 'relative',
      backgroundColor: 'white',
      borderRadius: 15,
      padding: '1rem 2rem',
      marginBottom: '4rem',
      marginLeft: 'auto',
      marginRight: 'auto',
      boxShadow: '0 0 23px 0 rgba(10, 22, 62, 0.04)',
      zIndex: 1,
      [theme.breakpoints.down('xs')]: {
        padding: '3rem 1rem',
        '&>*': {
          margin: '1rem 3rem',
        },
      },
    },
    logo: {
      maxWidth: '80%',
    },
    rightDiv: {
      position: 'relative',
      padding: '1rem',
      border: `5px dotted ${theme.palette.secondary.main}`,
      borderRadius: '.3rem',
      [theme.breakpoints.down('xs')]: {
        padding: '1.5rem',
      },
    },
    rightBonus: {
      background: 'white',
      padding: '0 .5rem',
      position: 'absolute',
      top: '0',
      transform: 'translate(0,-55%)',
    },
    button: {
      position: 'absolute',
      bottom: '0',
      transform: 'translate(0,55%)',
      borderRadius: '1.1rem',
      background: theme.palette.secondary.main,
      color: theme.palette.primary.main,
      border: '2px solid white',
      padding: '.1rem .8rem',
      fontWeight: 'bold',

      '&:hover': {
        background: theme.palette.secondary.dark,
      },

      [theme.breakpoints.down('xs')]: {
        padding: '.1rem 1rem',
        fontSize: '.9rem',
        borderRadius: '1.5rem',
      },
    },
    buttonBelge: {
      position: 'relative',
      bottom: 'inherit',
      transform: 'inherit',
    },
    number: {
      position: 'absolute',
      width: '50px',
      height: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: '0',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: theme.palette.secondary.main,
      borderRadius: '50%',
      border: '4px solid white',
      margin: '0 !important',
    },
  }),
);

export default function SingleBonus(props) {
  const { logo, name, amount, description, link, number, note } = props;
  const isBelge = props.language === 'be';
  // const note = 8.9;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const spacing = mobile ? 3 : 6;

  return (
    <Fade in={true} timeout={500}>
      <Grid
        item
        container
        xs={isBelge ? (mobile ? 12 : 8) : 12}
        className={classes.row}
        data-tour={`bookmaker-${name}-step`}
      >
        {isBelge ? (
          <>
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              sm={12}
              md={6}
            >
              <Grid item container xs={10} justify="center" alignItems="center">
                <img src={logo} alt={'name'} className={classes.logo} />
              </Grid>
            </Grid>
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              sm={12}
              md={3}
            >
              <Box
                width="95px"
                height="95px"
                borderRadius="50%"
                border="2px solid #dbdfe8"
                fontWeight="bold"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box display="flex" alignItems="baseline">
                  <Typography component={'span'}>
                    <Box fontSize="1.9rem" color="#00d678" width="70%">
                      {note}
                    </Box>
                  </Typography>
                  <Typography
                    component={'span'}
                    style={{ transform: 'translate(0px, 5px)' }}
                  >
                    <Box fontSize=".9rem" color="#848ca5" width="30%">
                      /10
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              sm={12}
              md={3}
            >
              <Box
                width="90%"
                maxWidth="160px"
                height="55%"
                className={classes.rightDiv}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Typography className={classes.rightBonus} component={'span'}>
                  <Box
                    fontWeight="bold"
                    fontSize={mobile ? '1.3rem' : '1rem'}
                    color={theme.palette.primary.dark}
                  >
                    Avantages
                  </Box>
                </Typography>
                <Typography variant="h1" component={'span'}>
                  <Box color={theme.palette.primary.dark}>{amount}</Box>
                </Typography>
                <Button href={link} target="_blank" className={classes.button}>
                  {t("J'en profite")}
                </Button>
              </Box>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              item
              container
              sm={5}
              md={3}
              justify="center"
              alignItems="center"
            >
              <img src={logo} alt={'name'} className={classes.logo} />
            </Grid>
            <Grid item sm={7} md={4}>
              <Box
                width="100%"
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                paddingLeft={!mobile && '2rem'}
              >
                {!mobile && (
                  <Box width="100%">
                    <Box
                      width="max-content"
                      bgcolor="#dbdfe8"
                      borderRadius="5px"
                      padding={mobile ? '.2rem 1rem' : '0 .5rem'}
                      marginBottom={mobile ? '2rem' : '1rem'}
                      margin={mobile ? 'auto' : 'inherit'}
                    >
                      <Typography
                        variant={mobile ? 'h6' : 'body1'}
                        component={'span'}
                      >
                        <Box
                          color={theme.palette.text.secondary}
                          fontWeight="600"
                        >
                          {name}
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                )}
                <Box width="100%">
                  <Typography variant={mobile ? 'h4' : 'h6'} component={'span'}>
                    <Box
                      fontWeight="bold"
                      color={theme.palette.primary.dark}
                      textAlign={mobile ? 'center' : 'left'}
                    >
                      {t(description)}
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              container
              sm={5}
              md={2}
              justify="center"
              alignItems="flex-end"
            >
              <Box
                width="105px"
                height="105px"
                borderRadius="50%"
                border="2px solid #dbdfe8"
                fontWeight="bold"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box display="flex" alignItems="baseline">
                  <Typography component={'span'}>
                    <Box
                      fontSize="2rem"
                      color={note < 6 ? '#ffa86b' : '#68daa8'}
                      width="70%"
                    >
                      {note}
                    </Box>
                  </Typography>
                  <Typography component={'span'}>
                    <Box fontSize=".9rem" color="#848ca5" width="30%">
                      /10
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              container
              sm={7}
              md={3}
              justify="center"
              alignItems="center"
            >
              <Box
                width="90%"
                maxWidth="160px"
                className={classes.rightDiv}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                data-tour={`bookmaker-${name}-price-step`}
              >
                <Typography className={classes.rightBonus} component={'span'}>
                  <Box
                    fontWeight="bold"
                    fontSize={mobile ? '1.3rem' : '1rem'}
                    color={theme.palette.primary.dark}
                  >
                    BONUS
                  </Box>
                </Typography>
                <Typography variant="h1" component={'span'}>
                  <Box color={theme.palette.primary.dark}>{amount}</Box>
                </Typography>
                <Button href={link} target="_blank" className={classes.button}>
                  {t("J'en profite")}
                </Button>
              </Box>
            </Grid>
          </>
        )}
        <Box className={classes.number}>
          <Typography variant="h3" component={'span'}>
            <Box color={theme.palette.primary.dark}>{number + 1}</Box>
          </Typography>
        </Box>
      </Grid>
    </Fade>
  );
}
