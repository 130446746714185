import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import DoublesImages from '../DoublesImages/DoublesImages';
import React from 'react';
import { Match } from '../../../../../types/pronoNBA';
import { getNFLLogoURL } from '../../../../../utils/nfl';
import Logo from '../../../Templates/Logos/Logo';
import { useTranslation } from 'react-i18next';

// <Grid container item xs={12}>
//   <Grid container item xs={4} alignItems="center" justify="flex-start">
//     <Box fontWeight={600}>
//       <Typography variant="body2">{match.value.home.name}</Typography>
//     </Box>
//   </Grid>
//   <Grid item xs={4}>
//     <DoublesImages
//       srcHome={getNFLLogoURL(match.value.home?.id)}
//       srcAway={getNFLLogoURL(match.value.away?.id)}
//     />
//   </Grid>
//   <Grid container item xs={4} alignItems="center" justify="flex-end">
//     <Box fontWeight={600} textAlign="end">
//       <Typography variant="body2">{match.value.away.name}</Typography>
//     </Box>
//   </Grid>
// </Grid>
const OtherPronoTeamRow: React.FC<{ match: Match }> = ({ match }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      <Grid
        container
        item
        xs={12}
        justifyContent="space-around"
        style={{ marginBottom: '.75rem' }}
      >
        <Grid container item xs={5} alignItems="center" justify="center">
          <Box style={{ height: 'auto', width: mobile ? 50 : 90 }}>
            <img
              alt="Data-Sport NFL-logo"
              src={getNFLLogoURL(match.value.home.id)}
              height="100%"
              width={'100%'}
            />
          </Box>
        </Grid>
        <Grid container item xs={5} alignItems="center" justify="center">
          <Box style={{ height: 'auto', width: mobile ? 50 : 90 }}>
            <img
              alt="Data-Sport NFL-Logo"
              src={getNFLLogoURL(match.value.away.id)}
              height="100%"
              width={'100%'}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} justify="space-around">
        <Grid container item xs={5} alignItems="flex-start" justify="center">
          <Box fontWeight={600} textAlign="center">
            <Typography variant="body2">{t(match.value.home.name)}</Typography>
          </Box>
        </Grid>
        <Grid container item xs={5} alignItems="flex-start" justify="center">
          <Box fontWeight={600} textAlign="center">
            <Typography variant="body2">{t(match.value.away.name)}</Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default OtherPronoTeamRow;
