import React from 'react';
import {
  createStyles,
  Fade,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Theme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      backgroundColor: 'transparent',
      borderBottom: 'none',
      paddingTop: '.5rem',
      paddingBottom: '.5rem',
      [theme.breakpoints.down('xs')]: {
        overflow: 'scroll',
      },
    },
    tab: {
      boxShadow: 'none',
      textTransform: 'none',
      fontWeight: 'bold',
      minWidth: '100px',

      '&>span': {
        '&.MuiTab-wrapper': {
          backgroundColor: '#242f56',
          borderRadius: '50px',
          color: 'white !important',
          height: '100%',
          padding: '.8rem 2.5rem',
          width: 'max-content',
          transition: 'all .2s ease-in-out',
        },
      },
      '&.Mui-selected': {
        '&> span': {
          '&.MuiTab-wrapper': {
            backgroundColor: theme.palette.secondary.main,
            color: `${theme.palette.primary.main}!important`,
          },
        },
      },
      '&:hover> span': {
        '&.MuiTab-wrapper': {
          backgroundColor: theme.palette.secondary.main,
          color: `${theme.palette.primary.main}!important`,
        },
      },
    },
  }),
);

function a11yProps(index: number) {
  return {
    id: `scrollable - auto - tab - filter${index}`,
    'aria-controls': `scrollable - auto - tabpanel - filter${index}`,
  };
}

type TypeTabProps = {
  value: number;
  mediumComputer: boolean;
  onClick: (tabIndex: number) => void;
  noRanking?: boolean;
  noCalendar?: boolean;
};

const TypeTab: React.FC<TypeTabProps> = ({
  value,
  mediumComputer,
  onClick,
  noRanking,
  noCalendar,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Fade in={true} timeout={500}>
      <Grid
        container
        item
        xs={12}
        justify="center"
        className={mediumComputer ? 'noPadding' : ''}
      >
        <Tabs
          value={value}
          onChange={(e, v) => onClick(v)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs filter"
          className={classes.tabs}
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
        >
          {!noRanking && (
            <Tab
              component="button"
              className={classes.tab}
              label={t('Classement')}
              {...a11yProps(0)}
            />
          )}
          {!noCalendar && (
            <Tab
              component="button"
              className={classes.tab}
              label={t('Calendrier')}
              {...a11yProps(1)}
            />
          )}
        </Tabs>
      </Grid>
    </Fade>
  );
};

export default TypeTab;
