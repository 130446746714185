import React from 'react';
import { Typography } from '@material-ui/core';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@material-ui/icons/ArrowDropUpRounded';
import Box from '@material-ui/core/Box';

const TeamHitPosArrow: React.FC<{ variation: number }> = ({ variation }) => {
  if (!variation) return <Box></Box>;
  return (
    <Box fontWeight="bold">
      <Typography
        style={{
          color: variation < 0 ? '#e0153f' : '#68daa8',
          display: 'flex',
          borderRadius: '1rem',
          padding: '0 .2rem 0 .8rem',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: variation < 0 ? '#ffeff2' : '#e7f8f1',
        }}
      >
        {Math.abs(variation)}
        {variation < 0 && <ArrowDropDownRoundedIcon fontSize="large" />}
        {variation > 0 && <ArrowDropUpRoundedIcon fontSize="large" />}
      </Typography>
    </Box>
  );
};

export default TeamHitPosArrow;
