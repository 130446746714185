import React from 'react';
import { Grid } from '@material-ui/core';
import TeamInfo from './TeamInfo/TeamInfo';
import TeamScores from './TeamScores/TeamScores';
import {
  MatchSummary,
  Referee,
  Venue,
} from '../../../../types/sportradar/soccer/sport-event';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

export type ScorePanelProps = {
  match: MatchSummary;
  mobile: boolean;
};

const getMainReferee = (referees: Referee[]): string => {
  if (!referees) return '-';
  const mainReferee = referees.find(
    ({ type }) => type === 'main_referee',
  ) as Referee;
  return mainReferee.name.split(',').reverse().join(' ');
};

const getCountry = (venue: Venue) => {
  if (!venue) return '-';
  return `${venue.city_name}, ${venue.country_name}`;
};

const ScorePanel: React.FC<ScorePanelProps> = ({ match, mobile }) => {
  const { sport_event, sport_event_status, statistics } = match;
  const history = useHistory();
  return (
    <Grid item container xs={11} style={{ margin: 'auto', marginTop: -25 }}>
      <TeamInfo
        mobile={mobile}
        competitor={sport_event.competitors[0]}
        statCompetitor={statistics.totals.competitors[0]}
        goToTeamPage={() =>
          history.push(`/team/${sport_event.competitors[0].id}`)
        }
      />
      <TeamScores
        mobile={mobile}
        homeScore={sport_event_status.home_score}
        awayScore={sport_event_status.away_score}
        date={sport_event.start_time}
        venue={sport_event.venue?.name || '-'}
        country={getCountry(sport_event.venue)}
        referee={getMainReferee(sport_event.sport_event_conditions.referees)}
        competition={sport_event.sport_event_context.competition}
      />
      <TeamInfo
        mobile={mobile}
        competitor={sport_event.competitors[1]}
        statCompetitor={statistics.totals.competitors[1]}
        goToTeamPage={() =>
          history.push(`/team/${sport_event.competitors[1].id}`)
        }
      />
    </Grid>
  );
};

export default React.memo(ScorePanel);
