import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Box, Typography } from '@material-ui/core';
import { Fire } from 'services';
import { Input } from 'reusable';
import Particles from 'reusable/Particles/Particles';
import logo from '../../images/logo_datasport.png';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './CreditCardForm.scss';
import PaiementSecureImg from '../../images/paiement_securise.png';
import { useSelector } from 'react-redux';

const CreditCardForm = props => {
  const MARKET_PAY_API_KEY =
    process.env.NODE_ENV == 'development'
      ? 'RFt9fG6Yt5goH0DiGx6i'
      : '391ad26d72fe446fa0f2d882871445';

  const MARKET_PAY_URL =
    process.env.NODE_ENV == 'development'
      ? 'https://marketpay.paymentsandbox.cloud'
      : 'https://gateway.marketpay.eu';

  const user_already = useSelector((state: any) => state.authReducer.user);
  const history = useHistory();
  const { t } = useTranslation();
  const { pay } = props;
  const [loading, setLoading] = useState<any>(false);

  const [user, setUser] = useState<any>({
    card_holder: '',
    exp_month: '',
    exp_year: '',
  });

  const setValue = (key: string, val: any) => {
    user[key] = val;
    setUser({ ...user });
  };

  React.useEffect(() => {
    if (user) {
      let s = document.createElement('script');
      s.async = true;
      s.src = `${MARKET_PAY_URL}/js/integrated/payment.1.3.min.js`;
      s.setAttribute('data-main', 'payment-js');
      document.head.appendChild(s);

      s.onload = function () {
        const script = document.createElement('script');
        script.text = `
        var payment = new PaymentJs();
  
        payment.init('${MARKET_PAY_API_KEY}', 'card_number', 'cvv', (payment) => {
  
          payment.setNumberStyle({ 
            borderRadius: "4px",
            width: "100%",
            height: "40px",
            background: "#fff",
            marginBottom: "15px",
            color: "black",
            padding: "1rem",
          });
  
          payment.setNumberPlaceholder('${t('**** **** **** ****')}');
  
          payment.setCvvStyle({ 
            borderRadius: "4px",
            width: "100%",
            height: "40px",
            background: "#fff",
            marginBottom: "15px",
            color: "black",
            padding: "1rem",
          });
  
          payment.setCvvPlaceholder('${t('CVV')}');
      });
  
        document.getElementById("button_form")?.addEventListener("click", function(event) {
  
          var data = {
              card_holder: $('#card_holder').val(),
              month: $('#exp_month').val(),
              year: $('#exp_year').val(),
          };
  
          payment.tokenize(
              data, 
  
              function(token, cardData) { //success callback function
  
                  $('#transaction_token').val(token); //store the transaction token
                  $('#hidden_button').click(); //submit the form (hidden button)
  
              }, 
  
              function(errors) { //error callback function
                  // alert('Errors occurred');
                  //render error information here
              }
          );
  
        })`;
        document.body.appendChild(script);
      };
    }
  }, []);

  const submitForm = async () => {
    setLoading(true);

    const transactionToken = (
      document.querySelector('#transaction_token') as HTMLInputElement
    ).value;

    await pay(transactionToken);
  };

  return (
    <Box textAlign="center">
      <div
        style={{
          textAlign: 'center',
          fontSize: '30px',
          marginBottom: '2rem',
          fontWeight: 500,
          fontStyle: 'italic',
          fontFamily:
            "'AvenirNext-HeavyItalic', 'Montserrat', 'Helvetica Neue', 'sans-serif'",
        }}
      >
        {t('Paiement')}
      </div>
      <Box
        style={{
          background: 'rgb(18 39 74)',
          color: 'white',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          width: '355px',
          maxWidth: '100%',
          margin: '0 auto 2rem',
          padding: '1rem',
          overflow: 'hidden',
        }}
      >
        <Box
          style={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '90%',
              margin: '1rem auto 2rem',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#54af53',
                }}
              ></Box>
              <Typography
                style={{
                  color: '#54af53',
                  fontSize: '.7rem',
                  width: '100%',
                  minWidth: 'fit-content',
                  margin: '0 2px',
                }}
              >
                {t('Paiement')} <b>{t('sécurisé')}</b> {t('garanti')}
              </Typography>
              <Box
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#54af53',
                }}
              ></Box>
            </Box>

            <img
              style={{
                maxWidth: '100%',
              }}
              src={PaiementSecureImg}
              alt="Data-Sport badge-stripe"
            />
          </Box>
          <Box
            id="payment_form"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <input
              type="hidden"
              id="transaction_token"
              name="transaction_token"
            />

            <div>
              <Input
                id="card_holder"
                placeholder={t('Nom sur la carte')}
                value={user.card_holder}
                onChange={val => setValue('card_holder', val)}
                noMargin
                creditCard
              />
            </div>

            <div>
              <div
                id="card_number"
                style={{
                  width: '100%',
                  height: '55px',
                }}
              ></div>
            </div>

            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: '20px' }}>
                <div>
                  <Input
                    id="exp_month"
                    placeholder={t('MM')}
                    value={user.exp_month}
                    onChange={val => {
                      const trimmedVal = val.trim();
                      if (trimmedVal !== user.exp_month && !isNaN(trimmedVal)) {
                        setValue('exp_month', parseInt(trimmedVal));
                      }
                    }}
                    noMargin
                    creditCard
                  />
                </div>
              </div>

              <div>
                <Input
                  id="exp_year"
                  placeholder={t('AAAA')}
                  value={user.exp_year}
                  onChange={val => setValue('exp_year', parseInt(val))}
                  noMargin
                  creditCard
                />
              </div>
            </div>
            <div>
              <div
                id="cvv"
                style={{
                  width: '100%',
                  height: '55px',
                }}
              ></div>
            </div>

            {/* <div>
          <Input
            id="email"
            placeholder={t('Email')}
            value={user.email}
            onChange={val => setValue('email', val)}
            noMargin
          />
        </div> */}
          </Box>
        </Box>
      </Box>
      <Button
        id="button_form"
        className="global-btn linear-btn big-btn login-btn"
        style={{
          marginTop: '1rem',
          marginLeft: 'auto',
          marginRight: 'auto',
          fontSize: '14px',
          fontWeight: 600,
        }}
        disabled={loading ? true : false}
      >
        {loading ? t('CHARGEMENT...') : t('PAYER')}
      </Button>

      <Button
        id="hidden_button"
        onClick={submitForm}
        style={{ display: 'none' }}
      >
        Soumettre
      </Button>
    </Box>
  );
};

export default CreditCardForm;
