import React from 'react';
import { CssBaseline, makeStyles, Button } from '@material-ui/core';
import {
  withStyles,
  Theme,
  useTheme,
  createStyles,
} from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useTranslation, initReactI18next } from 'react-i18next';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: 'transparent',
      width: 'fit-content',
      color: theme.palette.text.secondary,
      display: 'flex',
      alignItems: 'center',

      '&:hover': {
        color: theme.palette.secondary.dark,
      },
    },
    blue: {
      color: 'white !important',
    },
    mediumPadding: {
      padding: '10px 20px !important',
    },
    smallPadding: {
      padding: '5px 15px !important',
    },
  }),
);

export default function ButtonOutlined(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { title, blue, path, little, medium, arrow, blank, onClick } = props;

  return (
    <Button
      size="large"
      className={clsx(
        classes.button,
        blue && classes.blue,
        little && classes.smallPadding,
        medium && classes.mediumPadding,
      )}
      href={path ? (blank ? path : `/${path}`) : ''}
      onClick={e => props.onClick && props.onClick()}
    >
      <Typography
        variant={little || medium ? 'h6' : 'h5'}
        style={{ marginRight: '.5rem' }}
        component={'span'}
      >
        {t(title)}
      </Typography>
      {arrow && <ArrowForwardIosIcon fontSize="small" />}
    </Button>
  );
}
