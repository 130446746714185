import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import { Theme, useTheme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '13px',
      borderBottomRightRadius: '25px',
      padding: '10px 30px',
      height: 'fit-content',
      fontFamily: 'AvenirNext',
      color:
        theme.palette.type === 'dark' ? '#3c4045' : theme.palette.text.primary,

      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
        boxShadow: '0 12px 23px 0 rgb(10 22 62 / 16 %)',
      },
    },
    hoverOutlined: {
      borderBottomRightRadius: '20px',
      border: '3px solid transparent',

      '&:hover': {
        backgroundColor: 'transparent',
        boxShadow: '0 12px 23px 0 rgb(10 22 62 / 16 %)',
        border: `3px solid ${theme.palette.secondary.main}`,
      },
    },
    blue: {
      backgroundColor: '#7589ff',
      color: 'white !important',
      '&:hover': {
        backgroundColor: '#5559ff',
      },
    },
    large: {
      padding: '10px 75px',
      maxWidth: '100%',
    },
    mediumPadding: {
      padding: '10px 20px',
      [theme.breakpoints.down('sm')]: {
        padding: '5px 16px ',
      },
    },
    littlePadding: {
      padding: '5px 15px !important',
    },
    smallPadding: {
      padding: '5px 10px !important',
    },
    extraSmallPadding: {
      padding: '2px !important',
    },
    disabled: {
      opacity: 0.85,
    },
  }),
);

export default function ButtonFilled(props) {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const {
    title,
    blue,
    path,
    little,
    medium,
    icon,
    hoverOutlined,
    small,
    extraSmall,
    disabled = false,
    height,
    fade,
    EndIcon,
    blank,
    onPress,
    large,
  } = props;

  return (
    <Button
      size="large"
      className={clsx(
        classes.button,
        blue && classes.blue,
        little && classes.littlePadding,
        small && classes.smallPadding,
        extraSmall && classes.extraSmallPadding,
        medium && classes.mediumPadding,
        hoverOutlined && classes.hoverOutlined,
        disabled && classes.disabled,
        large && classes.large,
      )}
      href={path ? (blank ? path : `/${path}`) : ''}
      onClick={e => onPress && onPress()}
      disabled={disabled}
    >
      <Typography
        variant={
          mobile
            ? little || medium
              ? 'h5'
              : 'h4'
            : little || medium
            ? 'h6'
            : 'h5'
        }
        style={{
          lineHeight: little || small ? '1rem' : extraSmall ? 0 : 'initial',
          marginRight: EndIcon ? '.75rem' : '',
        }}
        component={'span'}
      >
        {icon && <AddIcon fontSize={small || extraSmall ? 'small' : 'large'} />}{' '}
        {fade ? (
          <Fade in timeout={1500}>
            <span>{t(title)}</span>
          </Fade>
        ) : (
          t(title)
        )}
      </Typography>
      {EndIcon && EndIcon}
    </Button>
  );
}
