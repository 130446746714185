import React from 'react';
import CalendarNBARegular from './CalendarNBARegular/CalendarNBARegular';
import CalendarNBAPlayoffs from './CalendarNBAPlayoffs/CalendarNBAPlayoffs';

type CalendarNBAProps = {
  league: 'regular' | 'playoffs';
};

const CalendarNBA: React.FC<CalendarNBAProps> = ({ league }) => {
  if (league === 'playoffs') return <CalendarNBAPlayoffs />;
  if (league === 'regular') return <CalendarNBARegular />;
  return <></>;
};
export default CalendarNBA;
