import React, { useEffect, useState } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import PreviewTitle from '../../../Templates/Misc/PreviewTitle';
import CardGeneral from '../../../Templates/Cards/CardGeneral';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import List from '@material-ui/core/List';
import SwipeableViews from 'react-swipeable-views';
import Grid from '@material-ui/core/Grid';
import { CompetitorRanking } from '../../../../../types/ranking';
import { Fire } from 'services';
import { ListItem } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListItemPreviewLibraryTennis from './ListItemPreviewLibraryTennis/ListItemPreviewLibraryTennis';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import PreviewHeader from './PreviewHeader/PreviewHeader';
import Loading from '../../../../Loading/Loading';
import SimpleButtonYellow from '../../../Templates/Buttons/SimpleButtonYellow';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      margin: theme.spacing(4, 0, 2),
    },
    tabs: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      textTransform: 'none',
      fontWeight: 'bold',
      color:
        theme.palette.type === 'dark'
          ? '#fff !important'
          : '#3c4045 !important',
    },
    appBar: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    topPreview: {
      width: '100%',
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
    },
    swipeable: {
      padding: '20px 0',
    },
    card: {
      height: 'unset',
      minHeight: '23.2rem',
      padding: '10px 25px',
      [theme.breakpoints.down('sm')]: {
        padding: 5,
        width: 'calc(100% + 40px)',
        margin: 0,
      },
      [theme.breakpoints.down('xs')]: {
        minHeight: '27.1rem',
      },
    },
    ListItem: {
      margin: '0',
    },
    headerItems: {
      [theme.breakpoints.down('sm')]: {
        padding: '10px 2rem !important',
      },
    },
    singleCard: {
      backgroundColor:
        theme.palette.type === 'dark' ? 'rgba(24,36,79,0.85)' : '#f8f9fa',
      marginTop: 10,
      borderRadius: '15px',
      border: 'none',
      boxShadow: 'none',
      minHeight: 'unset',
      minWidth: '100%',
      [theme.breakpoints.down('sm')]: {
        padding: '1rem',
      },
      '&> *': {
        fontWeight: 500,
        textAlign: 'center',
      },
      '&:hover': {
        backgroundColor: theme.palette.type === 'dark' ? '' : 'white',
        boxShadow: '0 5px 10px 0 rgba(10, 22, 62, 0.08)',
      },
    },
  }),
);

const TabPanel: React.FC<{ value: number; index: number }> = props => {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

const PreviewLibraryTennis: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [rankings, setRankings] = useState<
    [CompetitorRanking[], CompetitorRanking[]] | null
  >(null);

  useEffect(() => {
    const getRankings = async () => {
      setRankings(await Fire.cloud('tennis', { method: 'getRankings' }));
    };
    getRankings();
  }, []);

  if (!rankings) return <Loading nobg={true} />;
  return (
    <React.Fragment>
      <span style={{ display: 'none' }}>test</span>
      <div className={classes.topPreview}>
        <PreviewTitle title={t('Classement Tennis')} marginBottom />
        <SimpleButtonYellow
          title={t('de données')}
          addIcon={true}
          path="library"
        />
      </div>
      <CardGeneral className={classes.card}>
        <AppBar position="static" className={classes.appBar}>
          <Tabs
            TabIndicatorProps={{
              style: {
                height: '4px',
                transform: 'translate(0, 75%)',
              },
            }}
            className="noOverflow"
            value={tabValue}
            onChange={(_, v) => setTabValue(v)}
            indicatorColor="secondary"
            textColor="primary"
            aria-label="full width tabs"
          >
            <Tab label="ATP" className={classes.tabs} />
            <Tab label="WTA" className={classes.tabs} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={tabValue}
          onChangeIndex={i => setTabValue(i)}
          className={classes.swipeable}
        >
          <TabPanel value={tabValue} index={0}>
            <List style={{ height: '100%' }}>
              <Grid
                item
                container
                xs={12}
                spacing={1}
                className={classes.ListItem}
              >
                <ListItem
                  component={Card}
                  className={clsx(classes.singleCard, classes.headerItems)}
                >
                  <PreviewHeader mobile={mobile} t={t} />
                </ListItem>
                {rankings[0].slice(0, 3).map(player => (
                  <ListItem
                    component={Card}
                    className={classes.singleCard}
                    key={player.rank}
                  >
                    <ListItemPreviewLibraryTennis
                      mobile={mobile}
                      player={player}
                      gender={tabValue === 0 ? 'men' : 'women'}
                    />
                  </ListItem>
                ))}
              </Grid>
            </List>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <List>
              <Grid
                item
                container
                xs={12}
                spacing={1}
                className={classes.ListItem}
              >
                <ListItem
                  component={Card}
                  className={clsx(classes.singleCard, classes.headerItems)}
                >
                  <PreviewHeader mobile={mobile} t={t} />
                </ListItem>
                {rankings[1].slice(0, 3).map(player => (
                  <ListItem
                    component={Card}
                    className={classes.singleCard}
                    key={tabValue}
                  >
                    <ListItemPreviewLibraryTennis
                      mobile={mobile}
                      player={player}
                      gender={tabValue === 0 ? 'men' : 'women'}
                    />
                  </ListItem>
                ))}
              </Grid>
            </List>
          </TabPanel>
        </SwipeableViews>
        <Grid item container xs={12} md={12} spacing={1} />
      </CardGeneral>
    </React.Fragment>
  );
};

export default PreviewLibraryTennis;
