import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Moment } from 'moment';
import { Grid, Box, Typography } from '@material-ui/core';
import ButtonFilled from '../../../Templates/Buttons/ButtonFilled';
import Logo from '../../../Templates/Logos/Logo';
import Score from '../../../Templates/Misc/Score';
import small_ball from 'images/small_ball.svg';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    bgNumber: {
      position: 'absolute',
      right: '-60%',
      fontSize: 300,
      top: 0,
      color: 'rgba(255, 255, 255, 0.03)',
      '-webkit-text-stroke': '10.2px rgba(132, 140, 165, 0.45)',
      objectFit: 'contain',
      opacity: 0.39,
      fontWeight: 'bold',
    },
    shadow: {
      cursor: 'pointer',
      marginTop: '.5rem',
      boxShadow: '0 12px 23px 0 rgba(10, 22, 62, 0)',
      position: 'relative',
      transition: 'box-shadow .2s ease-in-out',
      '&:hover': {
        boxShadow: '0 12px 23px 0 rgba(10, 22, 62, 0.10)',
        zIndex: 2,
      },
    },
  }),
);

type RowMatchProps = {
  isHeader?: boolean;
  date?: Moment;
  domicile?: string;
  domicileLogo?: string;
  score?: string;
  ext?: string;
  extLogo?: string;
  goal?: number;
  redCard?: number;
  yellowCard?: number;
  matchId?: string;
  bgGrey?: boolean;
};

const RowMatch: React.FC<RowMatchProps> = ({
  isHeader = false,
  date,
  domicile,
  domicileLogo,
  score,
  ext,
  extLogo,
  goal,
  redCard,
  yellowCard,
  matchId,
  bgGrey,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Box
      width="100%"
      bgcolor={bgGrey ? '#f8f9fa' : 'white'}
      borderRadius="8px"
      padding=".5rem .8rem"
      className={!isHeader ? classes.shadow : ''}
      onClick={() => history.push(`/match/${matchId}`)}
    >
      <Grid container alignItems="center">
        <Grid item xs={2} alignItems="center">
          <Typography variant={mobile ? 'body2' : 'body1'} component={'span'}>
            <Box color={theme.palette.text.primary} fontWeight={700}>
              {isHeader ? t('Date') : date?.format('DD/MM/YYYY')}
            </Box>
          </Typography>
        </Grid>
        <Grid container item xs={10} md={7} justify="center">
          {isHeader ? (
            <Typography variant="body1" component={'span'}>
              <Box color={theme.palette.text.primary} fontWeight={700}>
                {t('Match')}
              </Box>
            </Typography>
          ) : (
            <React.Fragment>
              <Grid item container xs={3} alignItems="center" justify="center">
                <Typography
                  variant={mobile ? 'body2' : 'body1'}
                  component={'span'}
                >
                  <Box
                    color={theme.palette.text.primary}
                    fontWeight={700}
                    textAlign="center"
                  >
                    {domicile}
                  </Box>
                </Typography>
              </Grid>
              <Grid item container xs={2} alignItems="center" justify="center">
                <Logo
                  width={mobile ? 30 : 40}
                  src={domicileLogo as string}
                  club
                  typeImgDefault="club"
                  imgDefautl="FOOTBALL"
                />
              </Grid>
              <Grid item container xs={2} alignItems="center" justify="center">
                <Score score={score} />
              </Grid>
              <Grid item container xs={2} alignItems="center" justify="center">
                <Logo
                  width={mobile ? 30 : 40}
                  src={extLogo as string}
                  club
                  typeImgDefault="club"
                  imgDefautl="FOOTBALL"
                />
              </Grid>
              <Grid item container xs={3} alignItems="center" justify="center">
                <Typography
                  variant={mobile ? 'body2' : 'body1'}
                  component={'span'}
                >
                  <Box
                    color={theme.palette.text.primary}
                    fontWeight={700}
                    textAlign="center"
                  >
                    {ext}
                  </Box>
                </Typography>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
        {!mobile && (
          <>
            <Grid item container xs={2} alignItems="center" justify="center">
              <Grid item container xs={4} justify="center" alignItems="center">
                {isHeader ? (
                  <img src={small_ball} alt="Data-Sport goals" />
                ) : (
                  <Typography variant="body1" component={'span'}>
                    <Box color={theme.palette.text.primary} fontWeight={700}>
                      {goal}
                    </Box>
                  </Typography>
                )}
              </Grid>
              <Grid item container xs={4} justify="center" alignItems="center">
                {isHeader ? (
                  <Box
                    width="12px"
                    height="16px"
                    bgcolor="#e0153f"
                    borderRadius="2px"
                  />
                ) : (
                  <Typography variant="body1" component={'span'}>
                    <Box color={theme.palette.text.primary} fontWeight={700}>
                      {redCard}
                    </Box>
                  </Typography>
                )}
              </Grid>
              <Grid item container xs={4} justify="center" alignItems="center">
                {isHeader ? (
                  <Box
                    width="12px"
                    height="16px"
                    bgcolor="#fffa7e"
                    borderRadius="2px"
                  />
                ) : (
                  <Typography variant="body1" component={'span'}>
                    <Box color={theme.palette.text.primary} fontWeight={700}>
                      {yellowCard}
                    </Box>
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item xs={1}>
              {!isHeader && (
                <ButtonFilled
                  // Si on laisse ce lien, alors il y a une double redirection, la redirection du clic sur la row, plus la redirection du clic sur le bouton
                  // path={`match/${matchId}`}
                  icon
                  small
                  hoverOutlined
                />
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default RowMatch;
