import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme, createStyles, Theme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fade from '@material-ui/core/Fade';
import Navbar from '../../Dashboard/Templates/Navbar/Navbar';
import LeftNavbar from '../../Dashboard/Templates/Navbar/LeftNavbar';
import '../../../theme/datasportTheme.css';
import { Fire } from 'services';
import PageArticleTemplate from './PageArticleTemplate';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dashboard: {
      maxWidth: '100vw',
      minHeight: '100vh',
    },
    bgDark: {
      background: '#0a1437',
      overflowX: 'hidden',
    },
    bgLight: {
      background: '#fcfcfa',
    },
    container: {
      maxWidth: 1300,
      margin: 'auto',
      width: '100%',
    },
    pageBackgroundBlue: {
      zIndex: -1,
      background: '#0a1437',
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      top: 0,
      left: 0,
    },
    pageBackgroundBlueGradient: {
      backgroundImage:
        'linear-gradient(108deg, #6d7fff9e -1%, rgba(84, 114, 255, 0.05) 46%, rgba(84, 114, 255, 0.05) 46%)',
      zIndex: 2,
      opacity: 0.36,
      content: '""',
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      top: 0,
      left: 0,
      pointerEvents: 'none',
    },
    appBarShift: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: `calc(100% - ${theme.spacing(7) + 1}px)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${theme.spacing(9) + 1}px)`,
      },
    },
    appBarShiftOpen: {
      width: `calc(100% - ${drawerWidth}px)`,
      '& > div': {
        width: '100%',
        margin: 0,
      },
    },
    appBarShiftMobile: {
      width: '100%',
    },
  }),
);

interface Props {
  match: any;
}

export default function PageArticle(props: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const user = useSelector((state: any) => state.authReducer.user);
  const [isDarkTheme, setIsDarkTheme] = React.useState(true);
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);
  const [article, setArticle] = React.useState();

  const id = props.match.params.id || null;

  React.useEffect(() => {
    if (id) {
      getSingleArticle(id);
    }
  }, [id]);

  const getSingleArticle = async _id => {
    const pronoRef = await Fire.store().collection('articles').doc(_id);
    const prono: any = await Fire.doc(pronoRef);
    setArticle(prono);
  };

  return (
    <React.Fragment>
      <Box
        className={clsx(
          user ? classes.dashboard : '',
          isDarkTheme ? classes.bgDark : classes.bgLight,
        )}
      >
        <Fade in timeout={500}>
          <>
            {isDarkTheme && (
              <>
                <div className={classes.pageBackgroundBlue} />
                <div className={classes.pageBackgroundBlueGradient} />
              </>
            )}
            {mobile && <Navbar noRadius transparent collapsed />}
            <Box display="flex">
              {!mobile && <LeftNavbar open={open} setOpen={setOpen} />}
              <Box
                className={clsx(classes.appBarShift, {
                  [classes.appBarShiftOpen]: open,
                  [classes.appBarShiftMobile]: mobile,
                })}
              >
                <PageArticleTemplate article={article} />
              </Box>
            </Box>
          </>
        </Fade>
      </Box>
    </React.Fragment>
  );
}
