import React from 'react';
import './TermsScreenFrench.scss';

import { HeaderBar, Footer } from '../Reusable';
import { useTranslation } from 'react-i18next';
import Navbar from '../Home/NavbarHome/NavbarHome';

function TermsScreenFrench() {
  // const ref = Fire.store().collection('terms')
  // const [terms, loading, error] = useAsync(Fire.list(ref), [])

  const { i18n } = useTranslation();

  if (i18n.language === 'it') {
    return (
      <div className="terms-screen">
        {/* <HeaderBar /> */}
        <Navbar />

        <div className="options col-center">
          <div className="content">
            <h1>Condizioni generali di vendita</h1>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <h2>1. OPPONIBILIT&Agrave; DELLE CONDIZIONI GENERALI DI VENDITA</h2>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              1.1. Le presenti condizioni generali di vendita (le
              &quot;CG&quot;) sono applicabili ai servizi e ai prodotti di
              prognostica e di previsione concepiti, elaborati e
              commercializzati da DATA-SPORT in particolare sotto il marchio
              &quot;DATA-SPORT&quot;.
            </p>
            <p>&nbsp;</p>
            <p>
              1.2. I T&amp;C regolano il rapporto contrattuale tra DATA-SPORT e
              il cliente (il &quot;Cliente&quot;). Esse prevalgono su qualsiasi
              altro documento di portata legale o commerciale. Il Cliente si
              impegna inoltre a rispettare i termini e le condizioni generali di
              utilizzo del Sito (le &quot;TOU&quot;).
            </p>
            <p>&nbsp;</p>
            <p>
              1.3. 1.3. DATA-SPORT si riserva il diritto di cambiare, adattare o
              modificare i T&amp;C in qualsiasi momento e senza preavviso; i
              nuovi T&amp;C saranno poi applicabili a qualsiasi nuovo ordine.
            </p>
            <p>&nbsp;</p>
            <p>
              1.4 Effettuando un ordine per i servizi e i prodotti di previsione
              e predizione, il Cliente riconosce di aver letto i T&amp;C e
              dichiara di accettarli senza limitazioni o riserve. I servizi e i
              prodotti di prognostica e di previsione interessati dalle CG sono
              solo per le persone fisiche, adulte che agiscono esclusivamente a
              fini privati e non professionali, stabilite in Francia e
              all&apos;estero.
            </p>
            <p>&nbsp;</p>
            <p>
              1.5. Per utilizzare i servizi e i prodotti di previsioni e
              Analisi, DATA-SPORT fornisce al Cliente l&apos;accesso, sia
              dall&apos;url www.data-sport.io (il &quot;Sito&quot;).
            </p>
            <p>&nbsp;</p>
            <p>
              1.6. Il rinnovo di un abbonamento esistente o il rinnovo dei
              servizi implica anche il consenso pieno e senza riserve del
              Cliente e l&apos;adesione ai T&amp;C.
            </p>
            <p>&nbsp;</p>
            <p>
              1.7 Se il Cliente rifiuta di rispettare uno qualsiasi degli
              obblighi e delle condizioni dei T&amp;C o delle CDU, sar&agrave;
              sua responsabilit&agrave; smettere di utilizzare i servizi e i
              prodotti di Pr&eacute;dictions.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <h2>2. DESCRIZIONE DEI PRODOTTI E SERVIZI DI PREVISIONI</h2>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              2.1. DATA-SPORT offre servizi a pagamento di Analisi e previsioni
              sui risultati delle partite di calcio professionistico dei
              campionati europei e non europei utilizzando l&apos;intelligenza
              artificiale DATA-SPORT e altre informazioni e strumenti
              specificamente progettati, sviluppati e commercializzati da
              DATA-SPORT (i &quot;Analisi&quot;). I Analisi forniscono al
              Cliente un&apos;assistenza decisionale per l&apos;elaborazione dei
              suoi Analisi e il piazzamento delle sue scommesse sportive. Il
              cliente &egrave; quindi libero di piazzare le sue scommesse con
              l&apos;operatore approvato dalla National Gaming Authority.
            </p>
            <p>&nbsp;</p>
            <p>
              2/6 2.2. Il Cliente ha la possibilit&agrave; di acquistare uno o
              pi&ugrave; prodotti di previsione sul Sito secondo il prezzo e la
              formula da lui sottoscritta.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <h2>3. CESSAZIONE E DURATA DEI DIRITTI CONCESSI</h2>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              3.1. Il Cliente effettua, sul Sito, delle richieste di Prodotti di
              Previsione per l&apos;abbonamento settimanale
              (&quot;l&apos;Abbonamento&quot;).
            </p>
            <p>&nbsp;</p>
            <p>
              3.2. La cancellazione deve essere fatta dal cliente inviando una
              semplice email nell&apos;area clienti del sito. L&apos;abbonamento
              &egrave; senza impegno di durata. La richiesta di cancellazione
              deve essere fatta almeno 3 giorni prima della fine del periodo in
              corso.
            </p>
            <p>&nbsp;</p>
            <p>
              3.3. La decisione di non rinnovare l&apos;abbonamento non
              comporter&agrave; il pagamento di danni o di qualsiasi
              compensazione, fatto salvo il pagamento delle fatture emesse da
              DATA-SPORT.
            </p>
            <p>&nbsp;</p>
            <p>
              3.4 Se il cliente beneficia di un periodo gratuito, sarà
              automaticamente rinnovato in un abbonamento alla fine di esso.
            </p>
            <p>&nbsp;</p>
            <h2>
              4. PREVENZIONE E DIVIETO VOLONTARIO DEL GIOCO D&apos;AZZARDO
            </h2>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              4.1. Al fine di prevenire la dipendenza dal gioco, ogni operatore
              autorizzato fornisce ai giocatori eccessivi o patologici e al loro
              entourage sul suo sito web un numero di telefono sotto la
              responsabilit&agrave; dell&apos;Istituto Nazionale di Prevenzione
              ed Educazione alla Salute.&nbsp;Questa chiamata &egrave; fatturata
              all&apos;abbonato al prezzo di una chiamata locale.
            </p>
            <p>&nbsp;</p>
            <p>
              4.2 Si ricorda al cliente che qualsiasi persona che desideri
              essere interdetta dal gioco deve rivolgersi al Ministero degli
              Interni. Questo divieto &egrave; valido nei casin&ograve;, nei
              circoli di gioco e nei siti di gioco online autorizzati dalla
              legge n. 2010-476 del 12 maggio 2010. Questo divieto &egrave;
              anche valido sul sito della persona giuridica che detiene i
              diritti esclusivi di offrire giochi online sulla base
              dell&apos;articolo 136 della legge del 31 maggio 1933 che fissa il
              bilancio generale per l&apos;esercizio 1933.&nbsp;Si pronuncia per
              un periodo non riducibile a tre anni.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <h2>5. PREZZI DEI PRODOTTI DI PREVISIONE</h2>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              5.1. Il Cliente ha un abbonamento mensile per ottenere le
              previsioni proposte da data-sport.io
            </p>
            <p>
              I dettagli dell&apos;Abbonamento sono portati all&apos;attenzione
              del Cliente sulle presenti CG: il prezzo dell&apos;abbonamento
              &egrave; di 39&euro; / mese con un&apos;offerta di prova di 7
              giorni per 1&euro;.
            </p>
            <p>&nbsp;</p>
            <p>
              5.2. L&apos;Abbonamento ha un proprio sistema di prezzi e
              corrisponde all&apos;importo comprensivo di tutte le tasse
              (&quot;TTC&quot;) indicato sul Sito.
            </p>
            <p>&nbsp;</p>
            <p>
              5.3. Se necessario, il Cliente fornir&agrave; a DATA-SPORT le
              informazioni necessarie per l&apos;identificazione, il trattamento
              e l&apos;attivazione del suo conto elettronico, come previsto
              dagli articoli 4 e seguenti delle CDU. Il Cliente &egrave;
              l&apos;unico responsabile della protezione della password del suo
              conto elettronico (art. 4.3 delle CDU).
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <h2>6. PAGAMENTO</h2>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              Il cliente deve pagare l&apos;importo del prodotto di predizione
              secondo l&apos;abbonamento sottoscritto prima di qualsiasi
              fornitura di dati o informazioni relative alla predizione da parte
              di DATA-SPORT. Si accettano pagamenti con carta di credito, Stripe
              o con &quot;Internet + servizio&quot;.
            </p>
            <p>&nbsp;</p>
            <p>
              7.1. Il Cliente ha il diritto di utilizzare, secondo i termini e
              le condizioni dei T&amp;C, le informazioni accessibili, estratte o
              fornite al Cliente nel corso dell&apos;utilizzo dei Prodotti di
              Previsione. Queste informazioni e previsioni sono destinate
              esclusivamente all&apos;uso personale e non commerciale.
            </p>
            <p>&nbsp;</p>
            <p>
              7.2. Tranne che con il previo consenso scritto di DATA-SPORT
              nell&apos;ambito di un accordo speciale, i diritti d&apos;uso
              concessi al Cliente in relazione alle informazioni accessibili,
              estratte o fornite al Cliente nel corso dell&apos;utilizzo dei
              Prodotti di Previsione proibiscono espressamente al Cliente di: a.
              copiare, riprodurre, utilizzare, commercializzare, e pi&ugrave; in
              generale sfruttare, tutte o qualsiasi parte delle informazioni con
              qualsiasi mezzo e in qualsiasi forma, per qualsiasi scopo diverso
              da quelli stabiliti nella clausola 7. 1 delle GTC; b. trasmettere,
              comunicare, concedere a terzi la totalit&agrave; o una parte delle
              informazioni, a titolo oneroso o gratuito, a fini commerciali o
              meno; c. aggiungere alla totalit&agrave; o a una parte delle
              informazioni qualsiasi elemento o qualsiasi database il cui
              effetto sarebbe quello di distorcerle; d. raccogliere le
              informazioni allo scopo di creare, confrontare o arricchire i
              database o uno strumento software (appartenente a lui o a terzi);
            </p>
            <p>&nbsp;</p>
            <p>
              I Analisi possono tuttavia essere oggetto di una &quot;edizione
              cartacea&quot; nel limite di un uso privato, normale, non
              commerciale, nel rispetto rigoroso delle GTC e delle leggi sul
              copyright.
            </p>
            <p>&nbsp;</p>
            <p>
              7.3. I diritti d&apos;uso sono personali per il Cliente, non
              esclusivi, non trasferibili e non cedibili. In caso di uso non
              conforme, violazione, dichiarazione errata o violazione da parte
              del Cliente di una qualsiasi delle disposizioni dei T&amp;C,
              DATA-SPORT si riserva il diritto di ritenere il Cliente
              responsabile, senza pregiudizio, della sospensione senza
              preavviso, dell&apos;account elettronico del Cliente e
              dell&apos;accesso ai Prodotti Analysis.
            </p>
            <p>&nbsp;</p>
            <h2>8. PROPRIET&Agrave; INTELLETTUALE</h2>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              8.1. I prodotti di predizione sono la piena e completa
              propriet&agrave; di DATA-SPORT in conformit&agrave; con le
              disposizioni del Codice della Propriet&agrave; Intellettuale. 8.2.
              Le previsioni, i Analisi, i dati e le informazioni consultate,
              estratte o fornite al Cliente nell&apos;ambito dell&apos;utilizzo
              dei Prodotti di Previsione rimangono di propriet&agrave;
              intellettuale esclusiva di DATA-SPORT, in conformit&agrave; con le
              disposizioni del Codice della Propriet&agrave; Intellettuale.
            </p>
            <p>&nbsp;</p>
            <p>
              8.3. L&apos;utilizzo dei Prodotti Analysis non comporta alcun
              trasferimento al Cliente di alcun diritto di propriet&agrave;
              intellettuale o di altra natura sui Prodotti Analysis, o su
              qualsiasi elemento che li compone. I T&amp;C non trasferiscono al
              Cliente alcun diritto di propriet&agrave; intellettuale sui
              componenti caratteristici relativi al know-how o ai segreti di
              fabbricazione (che siano o meno brevettati o protetti allo stato
              attuale della legislazione dal diritto d&apos;autore o in
              qualsiasi altro modo), n&eacute; sui segni distintivi o qualsiasi
              altro elemento organizzativo, strutturale, concettuale,
              editoriale, informativo, visivo o sonoro, come, in particolare, i
              marchi, i nomi o il logo appartenenti a DATA-SPORT . Il cliente
              deve astenersi dall&apos;utilizzare qualsiasi degli elementi di
              cui sopra, in qualsiasi forma e per qualsiasi scopo, senza la
              previa ed esplicita autorizzazione di DATA-SPORT.
            </p>
            <p>&nbsp;</p>
            <p>
              8.4. DATA-SPORT ha un diritto di sfruttamento dei dati forniti e
              ottenuti dal Cliente nel contesto dell&apos;utilizzo dei Prodotti
              di Predizione.
            </p>
            <p>&nbsp;</p>
            <p>
              8.5. Inoltre, in caso di qualsiasi atto sleale, reato penale,
              frode, riproduzione illegale o non autorizzata, hacking del
              sistema, usurpazione dell&apos;autenticazione per accedere ai
              Prodotti di Predizione o qualsiasi altra compromissione della
              fiducia legittima di DATA-SPORT nei confronti del Cliente,
              DATA-SPORT si riserva il diritto di agire per far valere i suoi
              diritti di propriet&agrave; intellettuale, sulla base di qualsiasi
              mezzo di riparazione del danno subito da DATA-SPORT a questo
              proposito.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <h2>9. DATI PERSONALI</h2>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              9.1. Gli impegni di DATA-SPORT relativi ai dati personali sono
              regolati dagli articoli da 5.1 a 5.5 delle CGU.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <h2>10 GARANZIE</h2>
            <p>&nbsp;</p>
            <p>
              10.1 DATA-SPORT garantisce al Cliente che possiede i diritti per
              fornire al Cliente i Prodotti Analysis.
            </p>
            <p>&nbsp;</p>
            <p>
              10.2. DATA-SPORT utilizzer&agrave; mezzi coerenti e appropriati ed
              eserciter&agrave; la cura e la diligenza normalmente richieste per
              l&apos;uso dei prodotti di previsione da parte del cliente.
            </p>
            <p>&nbsp;</p>
            <p>
              10.3. I prodotti di previsione sono, in linea di principio,
              accessibili 24 ore al giorno, 7 giorni alla settimana.
            </p>
            <p>&nbsp;</p>
            <p>
              10.4. Il cliente riconosce e dichiara di aver ottenuto da
              DATA-SPORT tutte le informazioni necessarie sul contenuto dei
              prodotti di predizione acquisiti, sulla loro presentazione,
              funzionalit&agrave; e caratteristiche.
            </p>
            <p>&nbsp;</p>
            <p>
              10.5. DATA-SPORT non fornisce altre garanzie, espresse o
              implicite, oltre a quelle contenute in questa sezione.
            </p>
            <p>&nbsp;</p>
            <h2>11. LIMITAZIONI DI RESPONSABILIT&Agrave;</h2>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              11.1. La responsabilit&agrave; di DATA-SPORT &egrave; strettamente
              limitata agli obblighi definiti dalla richiesta del Cliente nel
              contesto del suo Abbonamento.
            </p>
            <p>&nbsp;</p>
            <p>
              11.2. La garanzia concessa da DATA-SPORT al Cliente per quanto
              riguarda l&apos;utilizzo dei Prodotti di Predizione si riferisce
              esclusivamente alla fornitura di una o pi&ugrave; previsioni
              relative ai risultati dei concorsi disponibili sul Sito.
            </p>
            <p>&nbsp;</p>
            <p>
              La qualit&agrave; dei prodotti di previsione e la pertinenza delle
              informazioni fornite nel contesto del loro utilizzo dipendono
              tuttavia da un grado di casualit&agrave; che il Cliente deve
              prendere in considerazione. DATA-SPORT non &egrave; in alcun modo
              responsabile di eventuali errori nei Analisi che possono derivare
              dalla trasmissione di elementi o informazioni errate da parte
              delle federazioni o leghe di calcio professionistiche quando si
              utilizzano i Prodotti di Analisi.
            </p>
            <p>&nbsp;</p>
            <p>
              11.3. Le previsioni, le previsioni, i dati e le informazioni
              fornite al cliente nel contesto dell&apos;uso dei prodotti di
              previsione sono solo a scopo informativo e non impegnano, per
              qualsiasi motivo, la responsabilit&agrave; di DATA-SPORT.
            </p>
            <p>&nbsp;</p>
            <p>
              11.4. La responsabilit&agrave; di DATA-SPORT non sar&agrave;
              impegnata e non sar&agrave; ricercata dal Cliente, in caso di
              guasto, interruzione o indisponibilit&agrave; dei mezzi di accesso
              al Sito e ai Prodotti Analysis. La responsabilit&agrave; di
              DATA-SPORT non sar&agrave; impegnata e non sar&agrave; ricercata
              dal Cliente per qualsiasi danno o pregiudizio, finanziario,
              materiale o immateriale, risultante
              dall&apos;indisponibilit&agrave; dei mezzi di accesso al Sito e ai
              Prodotti di Previsione o dall&apos;utilizzo fatto dal Cliente dei
              Prodotti di Previsione. La responsabilit&agrave; di DATA-SPORT non
              sar&agrave;, in nessun caso, sostenuta o ricercata dal Cliente in
              caso di utilizzo dei Prodotti di Analisi senza un rapporto diretto
              con un análisis relativo ad una o pi&ugrave; competizioni
              calcistiche che sono state oggetto di una o pi&ugrave; richieste
              del Cliente dal Sito.
            </p>
            <p>&nbsp;</p>
            <p>
              11.5 Per tutte le cause, rivendicazioni e danni, il limite
              aggregato di responsabilit&agrave; di DATA-SPORT non deve in
              nessun caso superare l&apos;importo pagato dal cliente per
              l&apos;acquisto del prodotto di previsione in questione.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <h2>12. DISPOSIZIONI VARIE</h2>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              12.1. Se una qualsiasi disposizione o parte di una disposizione
              dei T&amp;C &egrave; ritenuta non valida, la validit&agrave; delle
              restanti disposizioni non sar&agrave; inficiata.
            </p>
            <p>&nbsp;</p>
            <p>
              12.2. Il fatto che DATA-SPORT non si avvalga di una qualsiasi
              delle disposizioni dei T&amp;C non deve essere interpretato come
              una rinuncia al suo diritto di farlo in un momento successivo.
            </p>
            <p>&nbsp;</p>
            <p>
              12.3. I T&amp;C costituiscono un contratto di adesione e possono
              essere emendati o modificati solo da un documento scritto redatto
              da DATA-SPORT e firmato dai suoi rappresentanti autorizzati.
            </p>
            <p>&nbsp;</p>
            <h2>13. ATTRIBUZIONE DI GIURISDIZIONE</h2>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>13.1. Le CG sono soggette alla legge britannica.</p>
            <p>&nbsp;</p>
            <p>
              13.2 Qualsiasi controversia derivante dalla validit&agrave;,
              interpretazione o esecuzione di una qualsiasi delle disposizioni
              delle GTC sar&agrave; soggetta alla giurisdizione naturalmente
              competente.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>Informazioni relative all&apos;offerta di affiliazione</p>
            <p>&nbsp;</p>
            <p>
              Il sito www.offre.data-sport.io fornisce ai suoi utenti un
              servizio di affiliazione ai siti di scommesse sportive in Francia.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              L&apos;editore presenta solo i siti di scommesse sportive francesi
              che hanno ricevuto una licenza dall&apos;ARJEL, quindi non
              pu&ograve; essere considerato un intermediario.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              Il sito non fa riferimento a tutte le offerte di scommesse
              sportive disponibili sul mercato, ma solo a quelle proposte dalle
              societ&agrave; che pagano l&apos;editore del sito per essere
              elencate.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              Il numero di offerte proposte &egrave; variabile in funzione
              dell&apos;evoluzione delle collaborazioni dell&apos;editore del
              sito.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              Le informazioni relative alle offerte di credito che appaiono sul
              Sito sono fornite dai nostri partner sotto la loro esclusiva
              responsabilit&agrave;.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              L&apos;elenco delle offerte dei partner sul sito www.data-sport.io
              &egrave; aggiornato manualmente dal direttore della pubblicazione
              in caso di cambiamenti nelle offerte
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>Attenzione</p>
            <p>&nbsp;</p>
            <p>
              Le informazioni contenute in questo sito non possono essere
              interpretate come consigli di giochi o come una sollecitazione ai
              giochi.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              www.data-sport.io e www.offre.data-sport.io non sono responsabili
              per qualsiasi denaro perso o vinto in siti di gioco d&apos;azzardo
              su Internet, indipendentemente dal fatto che i nomi di questi siti
              siano stati menzionati in passato, o siano attualmente menzionati
              su questi siti di notizie.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              Questi siti web non saranno responsabili nei vostri confronti per
              qualsiasi danno diretto, indiretto, speciale, incidentale o
              consequenziale. Solo le persone che accettano questi termini
              possono utilizzare www.data-sport.io.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              I giocatori interessati al gioco d&apos;azzardo dovrebbero
              consultare le leggi del loro paese prima di giocare su Internet,
              poich&eacute; alcuni siti di gioco d&apos;azzardo su Internet non
              seguono le leggi a cui i giocatori sono vincolati.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              Il gioco d&apos;azzardo &egrave; vietato ai minori. &Egrave;
              severamente vietato ai minori di giocare d&apos;azzardo.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              I giocatori che scelgono di giocare d&apos;azzardo lo fanno di
              loro spontanea volont&agrave; e a loro rischio, sapendo che in
              qualsiasi gioco d&apos;azzardo, c&apos;&egrave; il rischio di
              perdere denaro.
            </p>
            <br />
            <br />
            <br />
            <h2>14. Politica di cancellazione e rimborso </h2>
            <br />
            <br />
            Convalidando la vostra adesione ai servizi proposti da Data-Sport,
            confermate che la vostra adesione di 39 € inizierà automaticamente
            alla fine del periodo di prova di una settimana, a meno che non vi
            annulliate prima della fine del periodo di prova. Sfortunatamente,
            non c’è rimborso dell’abbonamento mensile una volta che l’importo è
            stato pagato.
            <br />
            <br />
            <br />
            <h2>15. Dettagli dell’azienda</h2>
            <br />
            <br />
            Azienda : SUB ONLINE BUSINESS LIMITED, Numero registrato: 726045,
            Azienda: Kemp House 160 City Road, EC1V 2
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    );
  }

  if (i18n.language === 'en') {
    return (
      <div className="terms-screen">
        {/* <HeaderBar /> */}
        <Navbar />

        <div className="options col-center">
          <div className="content">
            <h1>Terms and Conditions of Sale</h1>
            <br />
            <br />
            <h2>1. OPPOSABILITY OF THE GENERAL CONDITIONS OF SALE</h2>
            <br />
            <br />
            1.1. The present general terms and conditions of sale (the "GTC")
            are applicable to forecasting and analyses services and products
            services and products designed, developed and marketed by
            DATA-SPORT, notably under the DATA-SPORT" brand.
            <br />
            <br />
            1.2. These terms and conditions govern the contractual relationship
            between DATA-SPORT and the customer (the "Customer"). They shall
            prevail over any other document of legal or legal or commercial
            scope. The Customer also agrees to comply with to comply with the
            general terms and conditions of use of the site (the "GCU ").
            <br />
            <br />
            1.3. DATA-SPORT reserves the right to change, adapt or modify the at
            any time and without prior notice; the new terms and conditions will
            be applicable being applicable to all new orders.
            <br />
            <br />
            1.4. By placing an order for services and products of analyses and
            analyses, the Customer recognizes to have taken knowledge of the CGV
            and declares to adhere to them without limitation or reservation.
            The services and products services and products of forecasts and
            analyses concerned by the CGV concern only natural persons, major
            acting exclusively for private purposes and not professional private
            and not professional, established in France and abroad.
            <br />
            <br />
            1.5. In order to use the forecasting and analyses services and
            products analyses, DATA-SPORT provides the customer with access to
            the site, either from the url www.data-sport.io (the "Site").
            <br />
            <br />
            1.6. The renewal of a current subscription or the renewal of
            services also services also implies the consent and full and
            unconditional adherence of the unreservedly to the Terms and
            Conditions of Sale.
            <br />
            <br />
            1.7. If the Customer refuses to comply with any of the obligations
            and of the T&Cs or the TOU, it is the responsibility of the Customer
            to use the services and products of Analysis.
            <br />
            <br /> <br />
            <h2>
              2. DESCRIPTION OF THE FORECASTING AND ANALYSE PRODUCTS AND
              SERVICES
            </h2>
            <br />
            <br />
            2.1. DATA-SPORT offers paid analyses and forecasts on the results of
            professional soccer matches in European and non-European leagues
            European and non-European leagues using DATA-SPORT's artificial
            intelligence DATA-SPORT and other specifically designed information
            and tools developed and marketed by DATA-SPORT (the "Analysis"). ").
            The Analysis provide the Customer with decision support for decision
            making process for the Customer's analyses and the placement of his
            or her sports bets. The Customer is then free to place his bets with
            the operator approved by the National Gaming Authority.
            <br />
            <br />
            2/6 2.2. The Customer has the faculty to acquire on the Site one or
            product(s) of Analysis according to the pricing and the formula
            subscribed by him.
            <br />
            <br />
            <br />
            <h2>3. TERMINATION AND DURATION OF THE RIGHTS GRANTED</h2>
            <br />
            <br />
            3.1. The Customer makes, on the Site, requests for Analysis for the
            weekly subscription ("the Subscription").
            <br />
            <br />
            3.2. The cancellation must be done by the customer by sending a
            simple email in the mail in the customer area of the website. The
            subscription is without commitment of duration. The cancellation
            request must be made at least 3 days before the end of the current
            period.
            <br />
            <br />
            3.3. The decision not to renew the Subscription will not result in
            the payment of compensation of any kind, subject to payment of
            invoices issued by DATA-SPORT. subject to the payment of the
            invoices issued by DATA-SPORT.
            <br />
            <br />
            3.4 In the case where the customer benefits from a free period, it
            will be automatically automatically renewed to a subscription at the
            end of the period. end of the period.
            <br />
            <br />
            <br />
            <h2 id="anchor">4. PREVENTION & VOLUNTARY GAME PROHIBITION</h2>
            <br />
            <br />
            4.1. To prevent gambling addiction, each licensed operator makes
            available to excessive or pathological gamblers and their entourage
            on its website, a telephone number under the responsibility of the
            National Institute for Prevention and Education for health. This
            call is billed to the subscriber at the price of a local call.
            <br />
            <br />
            4.2. The Customer is reminded that any person wishing to be banned
            from gambling must apply to the Ministry of the Interior. This ban
            is valid in casinos casinos, gaming circles and online gaming sites
            authorized under the authorized under the law n°2010-476 of May 12,
            2010. This is also valid on the website of the legal entity that
            holds the holder of exclusive rights to offer online games to the
            public on the basis of on the basis of Article 136 of the Act of 31
            May 1933 on the fixing the general budget for the year 1933. It is
            pronounced for a non reducible period of three years.
            <br />
            <br />
            <br />
            <h2>5. PRICE OF ANALYSE PRODUCTS</h2>
            <br />
            <br />
            5.1. The Customer has a monthly subscription to obtain the Analysis
            offered by <a href="https://data-sport.io">data-sport.io</a>
            <br />
            The Customer is informed of the details of the Subscription on the
            present GTC :{' '}
            <span style={{ fontFamily: 'AvenirNext' }}>
              <strong>
                the price of the subscription is 39.90€ / month with a 7 days
                trial offer of 7 days for 1€.
              </strong>
            </span>
            <br />
            <br />
            5.2. The Subscription has its own pricing system and the amount
            including all taxes ("TTC") indicated on the Site.
            <br />
            <br />
            5.3. If necessary, the Customer shall provide DATA-SPORT with the
            information necessary for the identification, processing and
            activation of the customer's account, as provided for in article 4
            and following of the of the GCU. The customer is solely responsible
            for the protection of his/her electronic password (art. 4.3 of the
            GTU).
            <br />
            <br />
            <br />
            <h2>6. PAYMENT</h2>
            <br />
            <br />
            The Client pays the amount of the Analyse Product according to
            Subscription prior to DATA-SPORT providing any data or information
            information from DATA-SPORT. The payments by credit card, Stripe or
            by "Internet service +" are accepted.
            <br />
            <br />
            6.1. The Customer has a right to use, under the terms and conditions
            of the terms and conditions of the GTC, the information consulted,
            extracted or provided to to the Customer in the context of the use
            of the Analysis Products. This information and analyses are
            exclusively intended for personal use and personal use and for
            non-commercial purposes.
            <br />
            <br />
            6.2. Unless DATA-SPORT has given its prior written consent under a
            specific agreement, the rights of use granted to the Customer for
            information for information consulted, extracted or provided to the
            Customer in the context of the use of the Analysis Products, the
            Customer is expressly the express prohibition for the Client to : a.
            copy, reproduce a. copy, reproduce, use, commercialize, and more
            generally exploit, all or a. copy, reproduce, use, market, and more
            generally exploit, all or part of the information by any means and
            in any form, for any form, for purposes other than those set out in
            Article 6.1 of the b. transmit, communicate, grant to any third
            party all or part of the information or part of the information to
            any third party, whether in return for payment or free of charge,
            for commercial or non-commercial purposes; c. to add to all or part
            of the information any c. to add to all or part of the information
            any element or any data base whose effect would be to to distort
            them; d. to collect the information in order to create, compare or
            d. collect the Information for the purpose of creating, comparing or
            enhancing any database or software tool (whether owned or owned by
            any third party);
            <br />
            <br />
            The Analysis can however be the object of a " paper edition "within
            the limits of a private, normal, non-commercial use, in the strict
            respect of the respect of the GTC and the copyright laws.
            <br />
            <br />
            6.3. The rights of use are personal to the Customer, non-exclusive
            exclusive, non-transferable and non-transferable. In the event of
            improper use use, breach, misrepresentation or violation by the
            customer of any of the breach of any of the provisions of the GTC,
            DATA-SPORT reserves the right to reserves the right to hold the
            customer responsible, without prejudice to without prejudice to the
            suspension, without prior notice, of the customer's electronic
            account and access to the Analysis Products.
            <br />
            <br />
            <br />
            <h2>7. INTELLECTUAL PROPERTY</h2>
            <br />
            <br />
            7.1. The Analysis Products are the full and complete property of
            DATA-SPORT of DATA-SPORT in accordance with the provisions of the
            Intellectual Property Code. Code. 7.2. The analyses, forecasts, data
            and information consulted, extracted or provided to the Customer in
            the context of the the use of the Analyse Products remain the
            exclusive intellectual property of property of DATA-SPORT, in
            accordance with the provisions of the Intellectual Intellectual
            Property Code.
            <br />
            <br />
            7.2. The use of the Products of Analysis does not involve any
            transfer to the benefit of the Customer of any intellectual property
            right or intellectual property right or right of another nature on
            the Products of Analysis, or on any element composing them. The GTC
            do not imply any transfer to the benefit of the Customer of the
            rights of intellectual property rights on the characteristic
            components of the know-how or know-how or manufacturing secret
            (whether or not they can be patented or protected be patented or
            protected in the current state of the law by a copyright or any
            other by copyright or otherwise), nor on the distinctive signs or
            any or any other organizational, structural, conceptual, editorial,
            informative or conceptual, editorial, informative, visual or sound
            elements, such as such as brands, names or logos belonging to
            DATA-SPORT. The customer is prohibited from using any of the above
            elements in any form whatsoever in any form whatsoever and for any
            purpose whatsoever, without prior without DATA-SPORT's prior and
            express authorization.
            <br />
            <br />
            7.3. DATA-SPORT has the right to use the data provided and obtained
            by the obtained by the Customer in connection with the use of the
            Analyse Products. Products.
            <br />
            <br />
            7.4. In addition, in the event of an unfair act, criminal offence,
            fraud unauthorized reproduction, hacking of the system, usurpation
            of the system, usurpation of authentication to access the Analyse
            Products or any the Analyse Products or any other impairment of
            DATA-SPORT's legitimate DATA-SPORT's legitimate trust in the
            Customer, DATA-SPORT reserves the right to the right to assert its
            intellectual property rights, based on any property rights, on the
            basis of any and all means of redress for the loss the damage
            suffered by DATA-SPORT as a result.
            <br />
            <br />
            <br />
            <h2>8. PERSONAL DATA</h2>
            <br />
            <br />
            8.1. DATA-SPORT's commitments regarding personal data are governed
            by articles 5.1 to 5.5 of the personal data are governed by articles
            5.1 to 5.5 of the TOS.
            <br />
            <br />
            <br />
            <h2>9 GUARANTEES</h2>
            <br />
            9.1 DATA-SPORT warrants to the Customer that it has the rights to
            provide to provide the Analyse Products to the Customer.
            <br />
            <br />
            9.2. DATA-SPORT implements consistent and appropriate means and
            provides care and diligence normally required for the use of the
            Analyse Analyse Products by the Customer.
            <br />
            <br />
            9.3. The Analyse Products are, in principle, available 24 hours a
            day hours a day, 7 days a week.
            <br />
            <br />
            9.4. The Customer acknowledges and declares that he/she has obtained
            from DATA-SPORT all necessary information on the content of the
            analyses products purchased, their products, their presentation,
            functionality and characteristics.
            <br />
            <br />
            9.5. DATA-SPORT makes no warranties of any kind, express or implied,
            other than those or implied, other than as set forth in this
            section.
            <br />
            <br />
            <br />
            <h2>10. LIMITATIONS OF LIABILITY</h2>
            <br />
            <br />
            10.1. DATA-SPORT's liability is strictly limited to the obligations
            defined by the obligations defined by the Customer's request in the
            context of his Subscription.
            <br />
            <br />
            10.2. The warranty granted by DATA-SPORT to the Customer in respect
            of use of the Analyse Products is limited to the provision of one or
            more the provision of one or more analyses relating to the results
            of competitions available on the Site.
            <br />
            <br />
            The quality of the Analyse Products and the relevance of the
            information provided within the framework of their use depend
            nevertheless on a on a certain amount of randomness that the
            customer must take into account. DATA-SPORT will not be held
            responsible for any error in the analyses analyses resulting from
            the transmission of erroneous information or by the professional
            football federations or leagues when using the leagues when using
            the Analyse Products. Products.
            <br />
            <br />
            10.3. The analyses, forecasts, data and information provided to the
            within the framework of the use of the Products of Analysis it
            products are provided for informational purposes only and DATA-SPORT
            shall not be liable for any DATA-SPORT is not liable for any reason
            whatsoever.
            <br />
            <br />
            10.4. DATA-SPORT shall not be held liable and shall not be sought by
            the customer in the event of be sought by the customer, in the event
            of failure, interruption or unavailability interruption or
            unavailability of the means of access to the Site and the Analysis
            products. DATA-SPORT will not be held responsible for any and cannot
            be held liable by the customer for any financial, material or
            financial, material or immaterial damage or loss resulting from the
            unavailability the unavailability of the means of access to the Site
            and the Products of Products or the use made by the Client of the
            Products of Products. DATA-SPORT shall not be held liable in any way
            whatsoever, be engaged or sought by the Customer in the case of the
            use of the the use of the Analyse Products without any direct
            connection to a analyses on one or more football matches that are
            the subject of a analyses request. competition that has been
            requested by the customer from the site. Site.
            <br />
            <br />
            10.5. For all causes, all losses and all damages, DATA-SPORT's
            overall DATA-SPORT's overall liability shall in no case exceed the
            amount paid by the customer exceed the amount paid by the customer
            for the purchase of the analyses product in question.
            <br />
            <br />
            <br />
            <h2>11. MISCELLANEOUS PROVISIONS</h2>
            <br />
            <br />
            11.1. If any provision or part of any provision of the T&Cs is held
            to be invalid provisions of the GTC shall be declared ineffective,
            the validity of the the validity of the remaining provisions shall
            remain unaffected.
            <br />
            <br />
            11.2. The fact that DATA-SPORT does not take advantage of any of the
            provisions provisions of the GTC shall not be construed as a waiver
            of its right to to take advantage of it at a later date.
            <br />
            <br />
            11.3. These terms and conditions constitute a contract of adhesion
            and may only be amended or modified only by a written document
            issued by DATA-SPORT and signed by signed by its authorized
            representatives.
            <br />
            <br />
            <br />
            <h2>12. ATTRIBUTION OF JURISDICTION</h2>
            <br />
            <br />
            12.1. The GTC are subject to Irish law.
            <br />
            <br />
            12.2. Any dispute arising from the validity, interpretation or
            performance of of any of the provisions of the GTC shall be subject
            to the natural jurisdiction the naturally competent jurisdiction.
            <br />
            <br />
            <br />
            <h2>13. CANCELLATION AND REFUND POLICY</h2>
            <br />
            <br />
            By validating your membership to the services offered by Data-Sport,
            you confirm that your membership of 39.90€ will automatically start
            at the end of the one week trial period, unless you cancel before
            the end of this trial period. Unfortunately, no refund of the
            monthly subscription is possible once the amount has been paid.
            <br />
            <br />
            <br />
            <h2>14. COMPANY DETAILS</h2>
            <br />
            <br />
            Company : SUB ONLINE BUSINESS LIMITED, Registered number: 726045,
            Address: 77, Lower Camden Street, Dublin 2, D02 XE80
            <br />
            <br />
            <br />
            <h1>Information about the affiliation offer</h1>
            <br />
            <p>
              The Site www.offre.data-sport.io provides its users with a service
              of of affiliation to the sites of sporting bets in France.
            </p>
            <br />
            <p>
              The Editor only presents the French sports betting sites that have
              received a license that have received a license by the ARJEL, so
              it can not be considered as an intermediary.
            </p>
            <br />
            <p>
              The Site does not refer to all the sports betting offers available
              on the available on the market, but only those proposed by the
              companies that companies that pay the Site Editor to be listed.
            </p>
            <br />
            <p>
              The number of offers proposed is variable according to the
              evolution of the partnerships of the Website Editor.
            </p>
            <br />
            <p>
              The information relating to the credit offers appearing on the
              Site are provided by our partners under their sole responsibility.
              responsibility.
            </p>
            <br />
            <p>
              The list of partner offers on the site www.data-sport.io is
              updated manually by the director of the publication in case of
              modifications of the offers
            </p>
            <br />
            <h2>Warning</h2>
            <br />
            <p>
              The information contained on this site should not be construed as
              gambling advice or advice or as a solicitation to play games.{' '}
            </p>
            <br />
            <p>
              www.data-sport.io and www.offre.data-sport.io are not responsible
              for for any money lost or won at Internet gambling sites,
              regardless of whether the Internet gambling sites, whether the
              names of these sites have been mentioned in the past or are
              currently listed on these news sites.
            </p>
            <br />
            <p>
              These websites shall not be liable to you for any direct,
              indirect, special, incidental or consequential damages whatsoever.
              incidental or consequential damages whatsoever. Only persons who
              agree to these conditions may use the www.data-sport.io website.
            </p>
            <br />
            <p>
              Players interested in gambling should consult the laws of their
              country before laws in their country before gambling on the
              Internet, as some Internet gambling, as some Internet gambling
              sites do not follow the laws laws to which players are bound.
            </p>
            <br />
            <p>
              Gambling is prohibited for minors. It is strictly prohibited for
              minors to gamble.
            </p>
            <br />
            <p>
              Players who choose to gamble do so willingly and at their own risk
              and at their own risk, knowing that in any game of chance there is
              a risk of risk of losing money.{' '}
            </p>
          </div>
        </div>

        <Footer />
      </div>
    );
  }

  if (i18n.language === 'es') {
    return (
      <div className="terms-screen">
        {/* <HeaderBar /> */}
        <Navbar />

        <div className="options col-center">
          <div className="content">
            <h1>Condiciones generales de venta</h1>
            <br />
            <br />
            <h2>1. APLICABILIDAD DE LAS CONDICIONES GENERALES DE VENTA</h2>
            <br />
            <br />
            1.1. Las presentes condiciones generales de venta (las "CGV") son
            aplicable a los servicios y productos de previsión y predicción
            DATA-SPORT bajo el Marca "DATA-SPORT".
            <br />
            <br />
            1.2. Las CGC regulan la relación contractual entre DATA-SPORT y el
            cliente (el "Cliente"). Prevalecen sobre cualquier otro documento
            que tenga ámbito jurídico o comercial. El Cliente también se
            compromete a cumplir con las condiciones generales de uso del sitio
            (las "CGU ").
            <br />
            <br />
            1.3. DDATA-SPORT se reserva el derecho de cambiar, adaptar o
            modificar el modificar las CGV en cualquier momento y sin previo
            aviso; las nuevas CGV serán entonces siendo entonces aplicable a
            cualquier nuevo pedido.
            <br />
            <br />
            1.4. Al hacer un pedido de servicios y productos de previsión y
            predicción predicciones, el Cliente reconoce haber leído las CGV y
            declara que las acepta sin limitaciones ni reservas. Los servicios y
            productos Los servicios y productos a los que se refieren las CGV
            son sólo para personas físicas mayores de edad que actúan
            exclusivamente para fines privados y no privadas y no profesionales,
            establecidas en Francia y en el extranjero.
            <br />
            <br />
            1.5. Para utilizar los servicios y productos de previsión y
            predicción predicciones, DATA-SPORT proporciona al Cliente el acceso
            desde la url www.data-sport.io (el "Sitio").
            <br />
            <br />
            1.6. La renovación de un abono actual o la renovación de servicios
            servicios también implica el consentimiento total e incondicional
            del Cliente y sin reservas a la GTC.
            <br />
            <br />
            1.7. Si el Cliente se niega a cumplir alguna de las obligaciones y
            condiciones de los TCG o las CDU, es responsabilidad del Cliente
            servicios y productos de Analyses.
            <br />
            <br /> <br />
            <h2>
              2. DESCRIPCIÓN DE LOS PRODUCTOS Y SERVICIOS DE PREVISIÓN Y
              PREDICCIÓN
            </h2>
            <br />
            <br />
            2.1. DATA-SPORT ofrece servicios de pronóstico y predicción de pago
            sobre los resultados de los partidos de fútbol profesional en las
            ligas europeas y no europeas Ligas europeas y no europeas que
            utilizan la inteligencia artificial de DATA-SPORT DATA-SPORT y otras
            informaciones y herramientas específicamente diseñadas, desarrollado
            y comercializado por DATA-SPORT (las "Predicciones "). Las
            predicciones proporcionan al cliente una ayuda para la toma de
            decisiones para toma de decisiones para la elaboración de
            pronósticos y la colocación de deportes apuestas deportivas. El
            cliente es entonces libre de realizar sus apuestas con el operador
            aprobado por la Autoridad Nacional del Juego.
            <br />
            <br />
            2/6 2.2. El Cliente tiene la opción de adquirir uno o producto(s) de
            Analysis según los precios y fórmula suscrita por él.
            <br />
            <br />
            <br />
            <h2>3. TERMINACIÓN Y DURACIÓN DE LOS DERECHOS CONCEDIDOS</h2>
            <br />
            <br />
            3.1. El Cliente realiza solicitudes en el Sitio para Pronósticos
            para el abono semanal ("el Abono").
            <br />
            <br />
            3.2. La cancelación debe ser realizada por el cliente mediante el
            envío de un simple correo electrónico correo en el área de clientes
            del sitio web. El abono es sin compromiso de tiempo. La solicitud de
            anulación debe realizarse como mínimo 3 días antes del final del
            período actual.
            <br />
            <br />
            3.3. La decisión de no renovar el Abono no supondrá el pago de
            compensación de cualquier tipo, a reserva del pago de las facturas
            emitidas por DATA-SPORT. el pago de las facturas emitidas por
            DATA-SPORT.
            <br />
            <br />
            3.4 Si el cliente se beneficia de un periodo gratuito, se renovará
            automáticamente a un renovado automáticamente a una suscripción al
            final del período. al final del periodo.
            <br />
            <br />
            <br />
            <h2 id="anchor">
              4. PPREVENCIÓN Y PROHIBICIÓN VOLUNTARIA DE LA CAZA
            </h2>
            <br />
            <br />
            4.1. Para prevenir la adicción al juego, cada operador con licencia
            deberá poner a disposición de jugadores excesivos o patológicos y su
            entorno en su página web, una su entorno en su página web, un número
            de teléfono bajo la responsabilidad del la responsabilidad del
            Instituto Nacional de Prevención y Educación para la salud. Esta
            llamada se factura al abonado al precio de una llamada local.
            <br />
            <br />
            4.2. Se recuerda al cliente que cualquier persona que desee que se
            le prohíba jugar que se le prohíba jugar debe solicitarlo al
            Ministerio del Interior Ministerio del Interior. Esta prohibición es
            válida en casinos, círculos de juego y sitios de juego en línea
            autorizados por autorizado por la ley n°2010-476 de 12 de mayo de
            2010. Este la prohibición también es válida en el sitio web de la
            persona jurídica titular de derechos exclusivos para ofrecer juegos
            en línea al público sobre la base de juegos en línea sobre la base
            del artículo 136 de la ley de 31 de mayo de 1933 Fijación del
            presupuesto general para el ejercicio 1933. Es pronunciada por un
            periodo no reducible de tres años.
            <br />
            <br />
            <br />
            <h2>5. PRECIOS DE LOS PRODUCTOS DE PREDICCIÓN</h2>
            <br />
            <br />
            5.1. El Cliente tiene una suscripción mensual para obtener el
            Predicciones ofrecidas por{' '}
            <a href="https://data-sport.io">data-sport.io</a>
            <br />
            El Cliente es informado de los detalles de la Suscripción en estos
            GTCs:{' '}
            <span style={{ fontFamily: 'AvenirNext' }}>
              <strong>
                el precio de la suscripción es de 39€/mes con una oferta de 7
                días de prueba por 1€. Oferta de 7 días de prueba por 1€.
              </strong>
            </span>
            <br />
            <br />
            5.2. La Suscripción tiene su propio sistema de precios y corresponde
            al importe con todos los impuestos incluidos ("TTC") indicado en el
            Sitio.
            <br />
            <br />
            5.3. En caso de ser necesario, el Cliente facilitará a DATA-SPORT la
            información necesario para la identificación, el tratamiento y la
            activación de su cuenta, según lo previsto en los artículos 4 y
            siguientes de la de la GCU. El cliente es el único responsable de la
            protección de su cuenta electrónica El cliente es el único
            responsable de la protección de la contraseña de su cuenta
            electrónica (art. 4.3 de las CGC).
            <br />
            <br />
            <br />
            <h2>6. PAGO</h2>
            <br />
            <br />
            El Cliente paga por el Producto de Predicción según Suscripción
            antes de que DATA-SPORT proporcione cualquier dato o información
            información de DATA-SPORT. El pagos por tarjeta de crédito, Stripe o
            por "servicio de Internet + servicio" se aceptan.
            <br />
            <br />
            7.1. El Cliente tiene derecho a utilizar, en los términos y
            condiciones del los términos y condiciones de las CGV, la
            información consultada, extraída o proporcionada al Cliente en el
            contexto del uso de los Productos de Predicción. Esta información y
            predicciones están destinadas exclusivamente al uso personal y uso
            personal y para fines no comerciales.
            <br />
            <br />
            7.2. A menos que DATA-SPORT haya dado su consentimiento previo por
            escrito en el contexto de una los derechos de uso concedidos al
            Cliente para la información por la información consultada, extraída
            o facilitada al Cliente en el contexto del uso de los Productos de
            Predicción, el cliente está expresamente la prohibición expresa para
            el Cliente de : a. copiar, reproducir, utilizar, comercializar y a.
            copiar, reproducir, utilizar, comercializar y, en general, explotar
            la totalidad o a. copiar, reproducir, utilizar, comercializar y, en
            general, explotar, total o parcialmente, la información por
            cualquier medio y en cualquier cualquier forma, para fines distintos
            a los establecidos en el artículo 7.1 de las CGC b. transmitir,
            comunicar o ceder a terceros la totalidad o parte de la información
            información a cualquier tercero, ya sea a cambio de un pago o de
            forma gratuita, para comerciales o no comerciales; c. añadir a toda
            o parte de la información cualquier c. añadir a toda o parte de la
            información cualquier elemento o cualquier base de datos que tenga
            como efecto d. recoger la información con el fin de crear, comparar
            o enriquecer d. recoger la información con el fin de crear, comparar
            o mejorar las bases de datos o las herramientas de software (ya sea
            de propiedad o de un tercero);
            <br />
            <br />
            Sin embargo, las Predicciones pueden ser objeto de una "edición en
            papel "dentro de los límites de un uso privado, normal y no
            comercial, en el estricto respeto respeto a las CGC y a las leyes de
            derechos de autor.
            <br />
            <br />
            7.3. Los derechos de uso son personales del Cliente, no exclusivos
            exclusivo, intransferible e intransferible. En caso de no uso,
            incumplimiento, tergiversación o violación por parte del cliente de
            cualquier Si el usuario incumple alguna de las disposiciones de las
            CGV, DATA-SPORT se reserva el derecho de se reserva el derecho de
            hacer responsable al cliente, sin perjuicio de sin perjuicio de la
            suspensión, sin previo aviso, de la cuenta electrónica del cliente y
            el acceso a los productos de predicción.
            <br />
            <br />
            <br />
            <h2>8. PROPIEDAD INTELECTUAL</h2>
            <br />
            <br />
            8.1. Los Productos de Predicción son propiedad total y completa de
            de DATA-SPORT de acuerdo con las disposiciones del Código de la
            Propiedad Intelectual. Código. 8.2. Las predicciones, pronósticos,
            datos y la información consultada, extraída o proporcionada al
            Cliente en el contexto de la uso de los Productos de Predicción
            siguen siendo propiedad intelectual exclusiva de propiedad de
            DATA-SPORT, de acuerdo con las disposiciones de la Código de la
            Propiedad Intelectual.
            <br />
            <br />
            8.3. El uso de los Productos de Predicción no implica ninguna
            transferencia de transferencia al Cliente de cualquier derecho de
            propiedad intelectual o cualquier otro o cualquier otro derecho
            sobre los Productos de Predicciones, o sobre cualquier Productos, o
            en cualquiera de sus componentes. El GTC no transfieren al Cliente
            ningún derecho de propiedad intelectual derechos de propiedad
            intelectual sobre los componentes característicos del conocimientos
            técnicos o secretos de fabricación (estén o no patentados o
            protegidos estar patentado o protegido por derechos de autor o
            cualquier otra protección legal). por derechos de autor o de
            cualquier otra forma), ni en los signos distintivos o o cualquier
            otro aspecto organizativo, estructural, conceptual, editorial,
            informativo o elementos conceptuales, editoriales, informativos,
            visuales o sonoros, como marcas, nombres o logotipos pertenecientes
            a DATA-SPORT. Se prohíbe al cliente utilizar cualquiera de los
            elementos anteriores de cualquier forma. en cualquier forma y para
            cualquier propósito, sin previa sin la autorización previa y expresa
            de DATA-SPORT.
            <br />
            <br />
            8.4. DATA-SPORT tiene derecho a utilizar los datos facilitados y
            obtenidos por el obtenidos por el Cliente en el curso de la
            utilización de los Productos de Predicción. Productos.
            <br />
            <br />
            8.5. Además, en caso de acto desleal, delito, fraude la reproducción
            no autorizada, el pirateo del sistema, la usurpación de la
            autentificación para acceder sistema, la usurpación de la
            autenticación para acceder a los Productos de Predicción o cualquier
            los Productos de Predicción o cualquier otro perjuicio a la legítima
            La confianza legítima de DATA-SPORT en el cliente, DATA-SPORT se
            reserva el derecho de el derecho a hacer valer sus derechos de
            propiedad intelectual por cualquier medio derechos de propiedad,
            sobre la base de cualquier medio de reparación de la pérdida los
            daños sufridos por DATA-SPORT a este respecto.
            <br />
            <br />
            <br />
            <h2>9. DATOS PERSONALESL</h2>
            <br />
            <br />
            9.1. Los compromisos de DATA-SPORT en materia de datos personales se
            rigen por los artículos 5.1 a 5.5 de la Los datos personales se
            rigen por los artículos 5.1 a 5.5 de las CGU.
            <br />
            <br />
            <br />
            <h2>10 GARANTÍAS</h2>
            <br />
            10.1 DATA-SPORT garantiza al Cliente que tiene los derechos para
            proporcionar para proporcionar los Productos de Predicción al
            Cliente.
            <br />
            <br />
            10.2. DATA-SPORT utiliza medios coherentes y adecuados y proporciona
            cuidado y diligencia normalmente requeridos para el uso de la
            Predicción Productos de predicción por el cliente.
            <br />
            <br />
            10.3. Los Productos de Predicción están, en principio, disponibles
            24 horas al día, 7 días a la semana. horas al día, 7 días a la
            semana.
            <br />
            <br />
            10.4. El cliente reconoce y declara que ha obtenido de DATA-SPORT
            todos los información necesaria sobre el contenido de los Productos
            de Predicción adquiridos, su productos, su presentación,
            funcionalidad y características.
            <br />
            <br />
            10.5. DATA-SPORT no ofrece ningún tipo de garantía, expresa o
            implícita, aparte de las o implícita, que no sea la establecida en
            esta sección.
            <br />
            <br />
            <br />
            <h2>11. LIMITACIONES DE RESPONSABILIDAD</h2>
            <br />
            <br />
            11.1. La responsabilidad de DATA-SPORT se limita estrictamente a las
            obligaciones definidas por el obligaciones definidas por la
            solicitud del cliente en el contexto de su Suscripción.
            <br />
            <br />
            11.2. La garantía otorgada por DATA-SPORT al Cliente en relación con
            El uso de los Productos de Predicción se limita al suministro de uno
            o más el suministro de una o más predicciones relacionadas con los
            resultados de concursos disponibles en el Sitio.
            <br />
            <br />
            La calidad de los productos de predicción y la relevancia de la la
            información proporcionada en el marco de su uso depende en un cierto
            grado de aleatoriedad que el cliente debe tener en cuenta.
            DATA-SPORT no se hace responsable de ningún error en las
            predicciones predicciones resultantes de la transmisión de elementos
            erróneos o por las federaciones o ligas de fútbol profesional al
            utilizar el ligas al utilizar los Productos de Predicción.
            Productos.
            <br />
            <br />
            11.3. Las predicciones, los pronósticos, los datos y la información
            proporcionada al Cliente en el contexto del uso de los Productos de
            Predicción son sólo indicativos y productos se facilitan únicamente
            con fines informativos y DATA-SPORT no será responsable de ningún
            DATA-SPORT no se hace responsable por ningún motivo.
            <br />
            <br />
            11.4. DATA-SPORT no se responsabiliza ni puede ser responsabilizado
            por el cliente que el cliente puede solicitar, en caso de fallo,
            interrupción o indisponibilidad de la interrupción o
            indisponibilidad de los medios de acceso al Sitio y al Productos de
            predicción. La responsabilidad de DATA-SPORT no será y no puede ser
            considerado responsable por el cliente de cualquier problema
            financiero, material o daños y perjuicios financieros, materiales o
            inmateriales derivados de la falta de disponibilidad la
            indisponibilidad de los medios de acceso al Sitio y a los Productos
            de Productos o el uso que hace el Cliente de los Productos de
            Productos. La responsabilidad de DATA-SPORT no puede, en ningún
            caso, ser comprometida o buscada por el cliente, ser contratado o
            buscado por el cliente en el caso de la utilización del el uso de
            los Productos de Predicción sin ninguna conexión directa con una
            previsión sobre uno o varios partidos de fútbol que han sido objeto
            de una solicitud de pronóstico. competencia que ha sido solicitada
            por el Cliente a la Sitio por el Cliente.
            <br />
            <br />
            11.5. Para todas las causas, todas las pérdidas y todos los daños,
            el conjunto La responsabilidad global de DATA-SPORT no superará en
            ningún caso el importe pagado por el cliente superar el importe
            pagado por el cliente por la compra del Predicción Producto
            afectado.
            <br />
            <br />
            <br />
            <h2>12. DISPOSICIONES DIVERSAS</h2>
            <br />
            <br />
            12.1. Si alguna disposición o parte de alguna disposición de las CGC
            fuera de los CGV debe ser declarada ineficaz, la validez de la la
            validez de las restantes disposiciones no se verá afectada.
            <br />
            <br />
            12.2. El hecho de que DATA-SPORT no invoque ninguna de las
            disposiciones de la Las disposiciones de los TCG no se interpretarán
            como una renuncia al derecho de para aprovecharlo más adelante.
            <br />
            <br />
            12.3. Las condiciones generales constituyen un contrato de adhesión
            y sólo pueden ser modificadas o sólo puede ser modificada por un
            documento escrito redactado por DATA-SPORT y firmado por sus
            representantes autorizados.
            <br />
            <br />
            <br />
            <h2>13. ATRIBUCIÓN DE COMPETENCIA</h2>
            <br />
            <br />
            13.1. Las CGC están sujetas a la derecho británico.
            <br />
            <br />
            13.2. Todo litigio que se derive de la validez, la interpretación o
            la ejecución de cualquiera de las disposiciones de los TCG estará
            sujeta a la natural la jurisdicción naturalmente competente.
            <br />
            <br />
            <br />
            <h2>14. Política de cancelación y reembolso </h2>
            <br />
            <br />
            Al validar su afiliación a los servicios ofrecidos por Data-Sport,
            confirma que su afiliación de 39 euros se iniciará automáticamente
            al final del período de prueba de una semana, a menos que se cancele
            antes del final del período de prueba. Lamentablemente, no hay
            devolución de la suscripción mensual una vez que se ha pagado el
            importe.
            <br />
            <br />
            <br />
            <h2>15. Datos de la empresa </h2>
            <br />
            <br />
            Empresa : SUB ONLINE BUSINESS LIMITED, Numero registrato: 726045,
            Dirección: Kemp House 160 City Road, EC1V 2
            <br />
            <br />
            <br />
            <h1>Información sobre la oferta de afiliación</h1>
            <br />
            <p>
              El sitio www.offre.data-sport.io proporciona a sus usuarios un
              servicio servicio de afiliación a sitios de apuestas deportivas en
              Francia.
            </p>
            <br />
            <p>
              El Editor sólo presenta los sitios de apuestas deportivas
              franceses que han sido autorizados por la ARJEL. con licencia de
              la ARJEL, por lo que no puede ser considerado como intermediario.
              considerado como un intermediario.
            </p>
            <br />
            <p>
              El Sitio no se refiere a todas las ofertas de apuestas deportivas
              disponibles en el disponibles en el mercado, pero sólo los
              propuestos por las empresas empresas que pagan al Editor del Sitio
              para aparecer en la lista.
            </p>
            <br />
            <p>
              El número de ofertas propuestas es variable en función de la
              evolución de de las asociaciones de editores de sitios.
            </p>
            <br />
            <p>
              La información relativa a las ofertas de crédito que aparecen en
              el El sitio es comunicado por nuestros socios bajo su exclusiva
              responsabilidad. responsabilidad.
            </p>
            <br />
            <p>
              La lista de ofertas de socios en el sitio web www.data-sport.io es
              actualizado manualmente por el director de la publicación en caso
              de modificaciones de las ofertas.
            </p>
            <br />
            <h2>Advertencia</h2>
            <br />
            <p>
              La información contenida en este sitio no debe interpretarse como
              un consejo sobre juegos de azar asesoramiento o como una
              invitación a jugar.{' '}
            </p>
            <br />
            <p>
              www.data-sport.io y www.offre.data-sport.io no son responsables de
              por cualquier dinero perdido o ganado en los sitios de juego de
              Internet, independientemente de que los nombres Sitios de juego en
              Internet, si los nombres de estos sitios han sido mencionados en
              el pasado o aparecen actualmente en estos sitios de noticias.
            </p>
            <br />
            <p>
              Estos sitios web no serán responsables ante usted por cualquier
              daño directo, indirecto, especial, incidental o consecuente. o
              daños consecuentes. Sólo las personas que acepten estas
              condiciones pueden utilizar el sitio web www.data-sport.io.
            </p>
            <br />
            <p>
              Los jugadores interesados en apostar deben consultar las leyes de
              su país antes de leyes de su país antes de apostar en Internet, ya
              que algunos juegos de azar por Internet, ya que algunos sitios de
              juegos de azar por Internet no siguen la leyes a las que están
              sujetos los jugadores.
            </p>
            <br />
            <p>
              Los juegos de azar están prohibidos para los menores. Es
              estrictamente prohibido que los menores apuesten.
            </p>
            <br />
            <p>
              Los jugadores que deciden apostar lo hacen voluntariamente y bajo
              su propio riesgo y bajo su propio riesgo, sabiendo que en todos
              los juegos de azar existe el riesgo de perder dinero. riesgo de
              perder dinero.{' '}
            </p>
            <br />
            <br />
            <br />
          </div>
        </div>

        <Footer />
      </div>
    );
  }
  return (
    <div className="terms-screen">
      {/* <HeaderBar /> */}
      <Navbar />

      <div className="options col-center">
        <div className="content">
          <h1>Conditions d'utilisation</h1>
          <br />
          Bienvenue sur DATA-SPORT (le « Service »). Vous acceptez d'être lié
          par les présentes Conditions d'utilisation chaque fois que vous
          utilisez ou accédez à une partie du Service, que vous créez un compte
          ou que vous souscrivez un abonnement. Si vous ne comprenez pas ou
          n'acceptez pas les présentes Conditions d'utilisation, veuillez ne pas
          utiliser le Service.
          <br />
          <br />
          <h2>Le Service</h2>
          <br />
          Le Service comprend le site web Data-Sport (les « Sites web »), les
          applications (les « Apps ») et tout ce qui est mis à disposition sur
          les Sites web et les Apps, y compris toutes les caractéristiques,
          fonctionnalités et interfaces utilisateur, ainsi que tout contenu et
          données que vous pouvez visualiser ou auquel vous pouvez accéder, tels
          que des images, des photos, des sons, de la musique, du texte, des
          articles, des graphiques, des logiciels, des vidéos, des programmes,
          des flux en direct et des chaînes (le « Contenu »).
          <br />
          Le Contenu du Service changera régulièrement - ce qui signifie que de
          nouveaux articles, programmes, chaînes, événements sportifs et autres
          contenus peuvent devenir disponibles tandis que d'autres contenus
          existants cesseront d'être disponibles. Nous pouvons également mettre
          à jour et modifier des éléments du Service lui-même à tout moment -
          par exemple la conception, la mise en page, les caractéristiques et
          les fonctionnalités.
          <br />
          Qui sommes-nous?
          <br />
          Le Service est exploité et vous est fourni par SUB ONLINE BUSINESS
          LIMITED, immatriculée en Irlande sous le numéro 726045, dont le siège
          social est situé à 77, Lower Camden Street, Dublin 2, D02 XE80
          <br />
          <br />
          <br />
          <br />
          <h2>Conditions d'utilisation</h2>
          <br />
          <br />
          <h2>1. Accès au Service</h2>
          <br />
          <br />
          (b) souscrivez un abonnement à durée déterminée ou renouvelable («
          Abonnement »).
          <br />
          Le Service (y compris le Contenu, les caractéristiques et les
          fonctionnalités) peut varier en fonction:
          <br />
          (a) de votre appareil et votre lieu de résidence
          <br />
          (b) que vous accédiez au Service en tant qu'invité non enregistré,
          titulaire d'un Compte Data-Sport ou abonné;
          <br />
          (c) du type d'Abonnement que vous avez choisi;
          <br />
          (d) de toute restriction imposée par nos partenaires commerciaux, y
          compris en ce qui concerne les droits qui nous sont accordés; et
          <br />
          (e) de si vous avez souscrit un Abonnement directement auprès de nous,
          via un autre service ou auprès de l'un de nos partenaires tiers
          (veuillez consulter l’article 11 pour plus d’information).
          <br />
          <br />
          <h2>2. Votre Compte</h2>
          <br />
          <br />
          Lors de la création de votre Compte ou de la souscription d'un
          Abonnement, vous devez nous fournir des informations véridiques,
          exactes et exhaustives. Il se peut que vous puissiez utiliser un
          compte que vous détenez déjà auprès d'une plateforme tierce ou d'un
          autre partenaire pour créer un Compte, par exemple vos données de
          connexion avec un service de média social.
          <br />
          Vous êtes responsable des identifiants de connexion de votre Compte,
          de leur confidentialité et de toutes les activités qui sont effectuées
          sous leur couvert (y compris tout accès et toute utilisation du
          Service par le biais de votre Compte). Nous vous recommandons de ne
          pas révéler vos informations de paiement et vos identifiants de
          connexion à toute autre personne. Vous acceptez de nous informer
          immédiatement si vous avez connaissance ou si vous soupçonnez une
          violation de la sécurité ou une utilisation non autorisée de votre mot
          de passe ou d'autres identifiants de connexion.
          <br />
          Vous acceptez de conserver les identifiants de connexion de votre
          Compte (y compris les coordonnées et les informations de paiement) à
          jour, exacts et corrects à tout moment pendant l'utilisation du
          Service. Nous ne sommes pas responsables des pertes ou des problèmes
          résultant des informations concernant votre Compte que vous nous avez
          fournies et qui seraient inexactes ou incorrectes.
          <br />
          <br />
          <h2>3. Votre Abonnement</h2>
          <br />
          <br />
          <br />
          Le Client dispose d’un abonnement mensuel pour obtenir les
          informations proposées par data-sport.io
          <br />
          Le détail de l’Abonnement est porté à la connaissance du Client sur
          les présentes CGV : le prix de l’abonnement est de 39.90€ / mois avec
          une offre d’essai de 7 jours pour 1€.
          <br />
          Si nécessaire, le Client fournit à DATA-SPORT les renseignements
          nécessaires à son identification, au traitement et à l’activation de
          son compte électronique, tel que prévu aux articles 4 et suivants des
          CGU. Le Client est seul responsable de la protection du mot de passe
          de son compte électronique (art. 4.3 des CGU).
          <br />
          Si vous souscrivez un Abonnement auprès de nous, les informations
          relatives au prix, à la devise et au renouvellement automatique ou non
          de l'Abonnement vous seront fournies avant que vous ne finalisiez
          votre souscription. Une fois votre inscription finalisée, vous pouvez
          accéder aux informations concernant votre Abonnement à tout moment en
          vous rendant sur votre Compte.
          <br />
          <br />
          <h2>4. Renouvellement automatique</h2>
          <br />
          <br />
          <br />
          Certains Abonnements sont automatiquement renouvelés, sauf si vous
          annulez votre Abonnement avant la date de renouvellement suivante (ou
          avant la fin de toute période d'accès gratuit). Si vous achetez un
          Abonnement par notre intermédiaire, nous vous ferons savoir, au moment
          de l'inscription, si votre type d'Abonnement sera automatiquement
          renouvelé.
          <br />
          Pour plus d'informations sur la manière de désactiver le
          renouvellement automatique, veuillez consulter notre Centre d'aide. Si
          vous avez acheté un Abonnement directement par notre intermédiaire,
          vous pouvez également désactiver le renouvellement automatique dans
          votre Compte.
          <br />
          <h2>5. Offres promotionnelles</h2>
          <br />
          DATA-SPORT, des sociétés du même groupe que DATA-SPORT et/ou nos
          partenaires tiers, peuvent mettre à disposition des codes de
          réduction, des remises ou d'autres offres promotionnelles qui peuvent
          vous offrir un accès à prix réduit au Service ou un accès sans frais
          au Service (les « Offres promotionnelles »).
          <br />
          Les Offres promotionnelles peuvent revêtir diverses formes et peuvent
          être mises à disposition de manière autonome ou fournies dans le cadre
          d'une offre groupée avec d'autres produits ou services vendus par
          DATA-SPORT (ou d'autres sociétés du même groupe que DATA-SPORT) ou
          avec les produits ou services de l'un de nos partenaires tiers.
          <br />
          Vous ne pouvez bénéficier de et utiliser les Offres promotionnelles
          que conformément aux conditions générales spécifiques qui leur sont
          applicables. Veuillez consulter les conditions générales pertinentes
          de l'Offre promotionnelle pour plus d’informations, y compris: (a) qui
          peut bénéficier de l'offre; et (b) si des restrictions s'appliquent à
          la combinaison d'une Offre promotionnelle avec une période d'accès
          gratuit, ou avec toute autre Offre promotionnelle.
          <br />
          Lorsqu'une Offre promotionnelle est fournie par un partenaire tiers ou
          par une autre société du même groupe que DATA-SPORT, des conditions
          supplémentaires peuvent s'appliquer.
          <br />
          <br />
          <h2>6. Facturation</h2>
          <br />
          <br />
          <br />
          Au début de votre Abonnement (ou à la fin de votre période d'accès
          gratuit (le cas échéant)) et lors de tout renouvellement, vous serez
          facturé en utilisant le mode de paiement que vous avez choisi lorsque
          vous vous êtes abonné la première fois (le « Mode de Paiement
          Principal »). Voir l’article 7.6 ci-dessous pour savoir comment mettre
          à jour ou modifier votre mode de paiement.
          <br />
          Vous pouvez avoir la possibilité de fournir plusieurs méthodes de
          paiement (chacune d'entre elles étant appelée « Mode de paiement de
          secours » dans les présentes Conditions d'utilisation), associées à
          votre Compte. Dans le cas où vous renseignez plusieurs Modes de
          paiement de secours, vous autorisez par les présentes DATA-SPORT à
          débiter ces Modes de paiement de secours si le Mode de Paiement
          Principal fait défaut ou ne peut être débité pour toute autre raison.
          Vous ne devez pas fournir un Mode de Paiement Principal ou un Mode de
          paiement de secours sans l'autorisation du propriétaire concerné.
          <br />
          Si un paiement échoue, parce que votre Mode de Paiement Principal a
          expiré, que vous n'avez pas suffisamment de fonds, ou pour toute autre
          raison, et que vous ne fournissez pas un Mode de paiement de secours
          valide ou n'annulez pas votre Abonnement renouvelable, nous nous
          réservons le droit de suspendre l'accès à votre Abonnement et/ou à
          votre Compte jusqu'à ce que nous (ou le tiers concerné) ayons obtenu
          un mode de paiement valide.
          <br />
          Si vous avez un Abonnement renouvelable, les paiements seront
          effectués automatiquement le premier jour de chaque nouvelle période
          d'Abonnement ou aux alentours de cette date, au même prix (sauf si
          nous vous avons informé d'un changement de prix, conformément à
          l’article 8). En général, le premier paiement sera effectué le jour où
          vous vous abonnez ou, si vous bénéficiez d'une période d'accès
          gratuit, le jour suivant la fin de votre période d'accès gratuit.
          <br />
          Si vous êtes éligible à une Offre Promotionnelle qui permet d'accéder
          à un Abonnement gratuitement pendant une période déterminée et qui se
          transforme ensuite en un Abonnement payant, votre premier paiement
          sera effectué à la fin de la période de promotion ou aux alentours de
          cette date. Si vous êtes éligible à une Offre promotionnelle
          permettant une réduction, vos paiements d'Abonnement seront réduits en
          fonction des conditions de cette Offre promotionnelle. Après la
          période de promotion avec réduction, le prix standard de l'Abonnement
          s'appliquera.
          <br />
          Pour consulter vos informations de facturation, désactiver le
          renouvellement automatique ou mettre à jour ou modifier votre mode de
          paiement, rendez-vous sur votre Compte (sauf si vous payez par
          l'intermédiaire d'un tiers ou d'un autre service, par exemple via
          Apple, Google ou un autre de nos partenaires tiers, auquel cas
          veuillez-vous référer à l’article 11 ci-dessous).
          <br />
          Nous faisons appel à d'autres sociétés (y compris d'autres sociétés du
          même groupe que DATA-SPORT), à des agents et à des prestataires pour
          traiter les transactions par carte et autres méthodes de paiement.
          Pour certaines méthodes de paiement, l'émetteur concerné peut vous
          facturer certains frais, tels que des frais de transaction étrangère
          ou d'autres frais liés au traitement de votre
          <br />
          méthode de paiement. Les frais imposés par l'émetteur concerné (et
          toute taxe applicable à ces frais) peuvent varier en fonction de la
          méthode de paiement utilisée. Vous serez seul responsable de ces frais
          (et de toute taxe sur ces frais) qui peuvent s'appliquer.
          Veuillez-vous rapprocher de votre prestataire de paiement pour plus
          d’informations.
          <br />
          Si vous souscrivez auprès de nous un abonnement, fournissez un mode de
          paiement lors de l’inscription ou si vous mettez à jour votre mode de
          paiement, un montant nominal peut être prélevé temporairement à des
          fins de vérification.
          <br />
          <br />
          <h2>7. Modifications</h2>
          <br />
          <br />
          <br />
          Changements de prix
          <br />
          (a) Nous pouvons modifier le prix de nos Abonnements à tout moment,
          mais, si vous êtes un abonné, nous vous donnerons un préavis d'au
          moins 30 jours pour tout changement de prix. Les modifications de prix
          n'entreront pas en vigueur pendant la période d'Abonnement en cours et
          ne s'appliqueront donc que lors du renouvellement (si vous avez un
          Abonnement qui se renouvelle automatiquement). Si vous ne souhaitez
          pas poursuivre votre Abonnement au nouveau prix, vous pouvez résilier
          votre Abonnement avant le début de la période d'Abonnement suivante.
          <br />
          (b) Si vous avez souscrit un Abonnement par l'intermédiaire d'un de
          nos partenaires tiers ou d'un autre service, les changements de prix
          peuvent également être soumis aux conditions générales dudit tiers ou
          dudit service.
          <br />
          Changements de Contenu
          <br />
          Comme nous l'avons mentionné plus haut lors de la description du
          Service, le Contenu changera régulièrement. La disponibilité du
          Contenu peut changer pour diverses raisons, par exemple lorsque des
          tiers titulaires de droits résilient ou limitent notre droit
          d'utiliser ce Contenu sur le Service, ou pour des raisons juridiques
          ou réglementaires.
          <br />
          Mises à jour et modifications du Service
          <br />
          (a) Nous pouvons régulièrement effectuer des mises à jour et des
          modifications du Service pour: (i) assurer la conformité avec les lois
          applicables et/ou refléter les changements dans les lois et les
          exigences réglementaires pertinentes, par exemple en droit de la
          consommation; (ii) effectuer une maintenance temporaire, corriger des
          bugs, mettre en œuvre des ajustements techniques et apporter des
          améliorations, telles que l'adaptation du Service à un nouvel
          environnement technique, le transfert du Service vers une nouvelle
          plate-forme d'hébergement, ou assurer la compatibilité du Service avec
          les appareils et les logiciels énumérés dans notre Centre d'aide (tel
          que mis à jour à tout moment); (iii) mettre à niveau (upgrade) ou
          modifier le Service, notamment en mettant fin à la prise en charge des
          anciennes versions des Apps ou à leur compatibilité avec certains
          appareils comme indiqué dans le Centre d'aide, en publiant une
          nouvelle version des Apps sur certains appareils, ou en modifiant ou
          en apportant des modifications aux caractéristiques et fonctionnalités
          existantes; (iv) modifier la structure, la conception ou la mise en
          page du Service, y compris le changement de nom du Service ou le
          changement de marque, ou modifier, améliorer et/ou élargir les
          caractéristiques et fonctionnalités disponibles; (vi) pour des raisons
          de sécurité; et (v) pour des raisons de lutte contre le piratage.
          Toutes les modifications qui sont nécessaires pour assurer la
          conformité continue du Service sont effectuées sans que vous n’ayez à
          supporter de frais supplémentaires.
          <br />
          (b) Si nous effectuons des modifications telles que détaillées à
          l’article 8.3(a) et qu'il s'agit de modifications qui auront une
          incidence négative sur votre accès ou votre utilisation du Service, à
          moins que ladite incidence ne soit mineure, nous vous donnerons le
          droit de résoudre le contrat qui nous lie. Si ce type de modification
          doit entrer en vigueur pendant votre période d'Abonnement en cours,
          vous pourrez résilier votre Abonnement (sans frais) dans les 30 jours
          suivant la date de la notification que nous vous enverrons ou dans les
          30 jours suivant l'entrée en vigueur de la modification, la date la
          plus tardive étant retenue. Si vous résiliez dans ces circonstances,
          nous vous rembourserons les montants que vous avez payés pour votre
          Abonnement mais uniquement pour la partie de votre Abonnement que nous
          n’avons pas encore fournie. Si vous ne refusez pas ces modifications
          ou si vous n'annulez pas votre Abonnement dans un délai de 30 jours à
          compter de la date de notre notification ou de 30 jours à compter de
          l'entrée en vigueur de la modification, la date la plus tardive étant
          retenue, nous considérerons que vous acceptez les modifications.
          <br />
          Modifications des présentes Conditions d'utilisation
          <br />
          (a) Nous avons le droit de modifier les présentes Conditions
          d'utilisation à tout moment pour les raisons suivantes: (i) pour
          améliorer les Conditions d'utilisation, pour rendre nos Conditions
          d'utilisation plus claires ou plus faciles à comprendre ou pour que
          tous nos clients aient les mêmes Conditions d'utilisation; (ii) pour
          nous conformer aux exigences légales ou réglementaires, telles que les
          lois qui nous sont applicables et régissent le contrat conclu avec
          vous, ou lorsque nous sommes soumis à une ordonnance ou au jugement
          d'un tribunal; (iii) pour vous fournir des informations
          supplémentaires sur le Service, (iv) lorsque nous apportons des
          modifications au Service ou à tout Abonnement, y compris lorsque nous
          modifions la manière dont nous structurons notre Service ou étendons
          la portée du Service en ajoutant des caractéristiques, des
          fonctionnalités, des Abonnements ou du Contenu supplémentaires; (v)
          lorsque nous réorganisons la manière dont nous gérons notre
          entreprise, y compris en fusionnant avec une autre marque ou un autre
          service; ou (vi) pour des raisons de sécurité, y compris lorsque nous
          introduisons des contrôles de sécurité ou des logiciels
          supplémentaires pour protéger notre Contenu ou le Service.
          <br />
          (b) En outre, nous fournissons le Service sur une base continue et
          nous ne pouvons pas prévoir ce qui peut changer à l'avenir. Cela
          signifie que nous pouvons apporter des modifications ou des ajouts aux
          présentes Conditions d'utilisation pour des raisons autres que celles
          énoncées ci-dessus, à condition que ces modifications soient
          raisonnables.
          <br />
          (c) Si nous modifions les présentes Conditions d'utilisation d'une
          manière qui aura un impact sur vos droits ou obligations légales, nous
          vous en informerons dans la mesure du possible et vous donnerons la
          possibilité de lire les nouvelles conditions avant que ces
          modifications ne prennent effet, à moins qu'une modification ne doive
          être mise en œuvre rapidement pour refléter une modification soudaine
          du Service, ou pour des raisons de sécurité, légales ou réglementaires
          (auquel cas nous vous informerons des modifications dès que possible).
          <br />
          (d) Si nous considérons raisonnablement qu'une modification des
          présentes Conditions d'utilisation aura un impact négatif important
          sur vos droits ou obligations, nous vous en informerons au moins 30
          jours à l'avance et vous expliquerons comment nous faire savoir, le
          cas échéant, que vous refusez d'accepter les nouvelles conditions.
          <br />
          (i) Si vous refusez d'accepter les nouvelles Conditions d'utilisation
          avant que les modifications ne prennent effet, la version des
          Conditions d'utilisation que vous avez précédemment acceptée
          continuera de s'appliquer à votre utilisation du Service jusqu'à la
          fin de votre période d'abonnement.
          <br />
          (ii) Si vous ne refusez pas d'accepter ces modifications avant
          qu'elles n’entrent en vigueur, nous considérerons que vous les
          acceptez.
          <br />
          Si vous résidez en France, les stipulations de l'Annexe 2 (section B)
          s'appliquent à la place du présent article 8.4(c) et 8.4(d).
          <br />
          (e) La version la plus récente des Conditions d'utilisation sera
          toujours disponible sur les Sites web et les Apps à partir de sa date
          d'entrée en vigueur.
          <br />
          <br />
          <h2>8. Annulation: Résiliation</h2>
          <br />
          <br />
          <br />
          La résiliation doit se faire par le client en envoyant un simple mail
          dans l’espace client du site internet. L’abonnement est sans
          engagement de durée. La demande de résiliation devra se faire au moins
          3 jours avant l’échéance de la période en cours.
          <br />
          La décision de ne pas renouveler l’Abonnement n'entraînera pas le
          versement de dommages-intérêts ou d’une quelconque indemnité, sous
          réserve du règlement des factures émises par DATA-SPORT.
          <br />
          Dans le cas où le client bénéficie d’une période offerte, elle sera
          automatiquement reconduite vers un abonnement à la fin de celle-ci.
          <br />
          Si vous avez souscrit auprès de nous un Abonnement programmé pour se
          renouveler automatiquement ou assorti d'une période initiale d'accès
          gratuit, vous pouvez l'annuler à tout moment avant la fin de chaque
          période d'Abonnement (ou période d'accès gratuit). En cas de
          résiliation, vous pourrez continuer à utiliser votre Abonnement
          jusqu'à la fin de la période d'Abonnement en cours (ou de la période
          d'accès gratuit) et vous ne pourrez donc pas bénéficier d'un
          remboursement pour la période d'Abonnement en cours. Pour gérer ou
          annuler votre abonnement, rendez-vous sur votre Compte.
          <br />
          Vous serez informé, au moment de la souscription d'un Abonnement, de
          toute durée minimale applicable, et notamment du fait que celle-ci
          correspond toujours à votre période de facturation.
          <br />
          Si vous avez souscrit un Abonnement par le biais d'un tiers qui est
          configuré pour se renouveler automatiquement, et que vous souhaitez
          annuler votre Abonnement, vous devrez le faire par l’intermédiaire
          dudit tiers. Par exemple, vous devrez peut-être accéder aux paramètres
          de votre appareil ou vous rendre sur votre compte de magasin
          d'applications et désactiver le renouvellement automatique pour
          Data-Sport.
          <br />
          <br />
          <h2>9. Frais supplémentaires</h2>
          <br />
          <br />
          <br />
          Vous êtes responsable de tous les frais d'accès à Internet, de données
          mobiles ou autres encourus lors de l'utilisation du Service et de
          votre Abonnement. N'oubliez pas que la diffusion en continu et le
          téléchargement de contenus audiovisuels tels que des vidéos peuvent
          consommer beaucoup de données.
          <br />
          Les tarifs de messagerie standard de votre opérateur de téléphonie
          mobile peuvent s'appliquer à tous les messages SMS que vous recevez de
          notre part.
          <br />
          <br />
          <h2>10. Votre utilisation du Service</h2>
          <br />
          <br />
          <br />
          Vous ne devez pas, et ne devez pas permettre à des tiers de:
          <br />
          (a) transmettre, diffuser, afficher, exécuter, publier, accorder une
          licence, offrir à la vente, faire et/ou distribuer des copies de toute
          partie du Service, sauf dans les cas expressément autorisés par les
          présentes Conditions d'utilisation;
          <br />
          (b) exposer tout Contenu ou toute partie du Service dans un lieu
          public;
          <br />
          (c) encapsuler tout élément du Contenu ou du Service sur (ou
          incorporer toute partie du Service) dans un autre site web, une autre
          application, un autre service en ligne ou service audiovisuel;
          <br />
          (d) accéder à ou visualiser toute partie du Service et/ou souscrire
          votre Abonnement en utilisant un réseau proxy virtuel;
          <br />
          (e) utiliser vos identifiants de connexion pour accéder à votre Compte
          ou à votre Abonnement sans autorisation de notre part, ou faire quoi
          que ce soit qui vous permette d'obtenir un accès non autorisé au
          Service ou à tout compte, système informatique ou réseau connecté au
          Service, par des moyens tels que le piratage, l'extraction de mots de
          passe ou d'autres moyens illicites;
          <br />
          (f) tenter d'altérer, de modifier, de faire de l'ingénierie inverse,
          de désassembler, de décompiler, de transférer, d'échanger ou de
          traduire le Service, sauf si vous avez le droit légal de le faire;
          <br />
          (g) supprimer, désactiver, dégrader ou contrer l'une des protections
          du contenu du Service ou du Contenu; et / ou
          <br />
          (h) collecter ou récolter des données personnelles d'un utilisateur du
          Service (y compris tout nom de compte) ou utiliser un robot, un bot,
          un scraper, une application de recherche/récupération de site, un
          proxy ou tout autre dispositif, méthode, système ou processus manuel
          ou automatique pour accéder, récupérer, indexer, extraire des données
          (data miner) ou reproduire ou contourner de quelque manière que ce
          soit la structure de navigation ou la présentation du Service, de
          votre Abonnement ou du Contenu.
          <br />
          Vous pouvez être en mesure de créer plusieurs profils sous votre
          Compte. Si vous le faites, vous restez responsable de toutes les
          activités réalisées à l'aide de votre Compte.
          <br />
          Vous acceptez de ne pas vous inscrire (ou tenter de vous inscrire)
          plusieurs fois pour une période d'accès gratuit ou pour la même Offre
          promotionnelle, ou de prendre toute autre mesure visant à vous
          permettre de bénéficier de plusieurs périodes d'accès gratuit ou de la
          même Offre promotionnelle. Toute action de ce type constituera une
          violation des présentes Conditions d'utilisation et pourra entraîner
          la résiliation de votre Compte et/ou de votre Abonnement.
          <br />
          <br />
          <h2>11. Notre responsabilité envers vous</h2>
          <br />
          <br />
          <br />
          Sauf en cas de négligence ou de manquement de notre part, nous ne
          sommes pas responsables de:
          <br />
          (a) toute utilisation par vous du Service ou du Contenu qui n'est pas
          autorisée par nous en vertu des présentes Conditions d'utilisation, y
          compris la perte de profits si vous tentez d'utiliser ou d'afficher le
          Service à des fins commerciales;
          <br />
          (b) tout dysfonctionnement ou interruption du Service ou du Contenu dû
          à des circonstances indépendantes de notre volonté qui nous empêchent
          d’exécuter nos obligations envers vous, ou qui peuvent être
          considérées comme un " cas de force majeure " en vertu de la
          législation locale (le cas échéant). Cela peut être dû à des éléments
          tels que: (i) la foudre, une inondation, des conditions
          météorologiques sévères, un incendie, une explosion, des activités
          terroristes, une épidémie, une pandémie, des émeutes, une guerre,
          toute mesure prise par un gouvernement ou une autre autorité publique,
          ou des grèves ou autres actions industrielles; ou (ii) d'autres
          actions de tiers que nous ne contrôlons pas;
          <br />
          (c) tout manque de fonctionnalité ou défaut de fourniture de toute
          partie du Service ou du Contenu, ou toute perte de contenu ou de
          données qui est due à:
          <br />
          (i) les dysfonctionnements ou les défauts de l'équipement, des
          dispositifs, du système d'exploitation ou de la connexion Internet de
          votre choix (y compris les logiciels malveillants, les virus ou les
          bogues provenant de tiers ou de l'un de vos dispositifs);
          <br />
          (iv) des actions imprévisibles et insurmontables de la part des tiers
          visés à l’article 11 (par exemple un magasin d'applications ou l'un de
          nos partenaires tiers); ou
          <br />
          (v) votre connexion internet ne répond pas aux exigences minimales en
          matière de bande passante et/ou de vitesse, comme indiqué dans le
          Centre d'aide.
          <br />
          (d) l'incompatibilité d’une quelconque partie du Service ou du Contenu
          avec des appareils ou des systèmes d'exploitation autres que ceux
          énumérés dans notre Centre d'aide.
          <br />
          <br />
          12. Sites web de tiers
          <br />
          <br />
          Le Service peut inclure des liens vers d'autres sites web que nous ne
          détenons ni ne contrôlons.
          <br />
          (a) Nous n'avons aucun contrôle sur, et n'assumons aucune
          responsabilité quant à la disponibilité, au contenu, aux politiques de
          confidentialité ou aux pratiques de tout site Web tiers.
          <br />
          (b) Vous reconnaissez et acceptez que nous ne serons pas responsables
          de toute perte ou de tout dommage que vous pourriez subir en raison de
          la disponibilité de ces sites ou ressources externes de tiers, ou en
          raison de la confiance que vous accordez à l'exhaustivité, à
          l'exactitude ou à l'existence de toute publicité, de tout produit ou
          de tout autre matériel disponible sur ou à partir de ces sites ou
          ressources.
          <br />
          Nous vous encourageons à être attentif lorsque vous quittez le Service
          et à lire les conditions générales et la politique de confidentialité
          de chaque autre site web que vous visitez.
          <br />
          <br />
          13. Signaler des Contenus
          <br />
          <br />
          Si vous voyez un Contenu sur le Service que vous souhaitez signaler ou
          nous signaler parce que, par exemple, vous pensez qu'il enfreint les
          droits de propriété intellectuelle d'une autre personne, veuillez nous
          contacter par le biais de notre Centre d'aide.
          <br />
          <br />
          <h2>14. Autres stipulations</h2>
          <br />
          <br />
          Le contrat est conclu en considération de votre personne et aucun
          tiers n'est en droit d'en bénéficier. Vous acceptez que nous puissions
          transférer nos droits et obligations au titre des présentes Conditions
          d'utilisation à toute autre société du même groupe que DATA-SPORT ou à
          toute autre société ou entreprise ou personne, à condition que votre
          Abonnement et vos droits au titre du présent contrat ne soient pas
          affectés de manière négative à la suite d'un tel transfert. Vous ne
          pouvez pas transférer vos droits ou obligations en vertu des présentes
          Conditions d'utilisation à une autre personne.
          <br />
          Si un paragraphe ou un article, ou si une partie d'un paragraphe ou
          d'un article des présentes Conditions d'utilisation est jugé(e)
          illégal(e), invalide ou inapplicable par un tribunal ou une autorité
          judiciaire, ce paragraphe ou cet article, ou toute partie de ce
          paragraphe ou de cet article sera considéré(e) comme supprimé(e). La
          validité et l’opposabilité des autres parties des présentes Conditions
          d'utilisation seront maintenues et ne seront pas affectées.
          <br />
          Dans la mesure où nous n'exerçons pas ou décidons de ne pas exercer un
          droit ou une réclamation à votre encontre auquel nous avons droit,
          cela ne constituera pas une renonciation à ce droit, sauf à ce que
          nous indiquions le contraire par écrit.
          <br />
          Si vous vivez en Irlande ou au Pays de Galles, vous ne pourrez
          effectuer de réclamation concernant les présentes Conditions
          d'utilisation ou en découlant que devant les tribunaux d'Irlande et du
          Pays de Galles. Si vous ne vivez pas en Irlande ou au Pays de Galles,
          vous pouvez effectuer une réclamation devant les tribunaux de votre
          territoire de résidence.
          <br />
          <br />
          <h2>15. Nous contacter</h2>
          <br />
          <br />
          Vous pouvez nous contacter par l'intermédiaire de notre Centre d'aide
          ou en utilisant les coordonnées figurant dans les Mentions légales.
          <br />
          Customer Services, SUB ONLINE BUSINESS LIMITED, 77, Lower Camden
          Street, Dublin 2, D02 XE80
          <br />
          support@data-sport.io
          <br />
          Avertissement :
          <br />
          Les informations diffusées sur le Site data-sport.io n’engagent en
          aucun cas la responsabilité de l’équipe du Site data-sport.io, il ne
          s’agit en aucun cas d’un encouragement à placer des paris en ligne. Ce
          site ne doit pas être considéré comme un conseil en investissement.
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default TermsScreenFrench;
