import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CardGeneral from '../../Templates/Cards/CardGeneral';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
// import Grid from '@material-ui/core/Grid';
// import CardPronoPourcentage from '../../Templates/Cards/CardPronoPourcentage';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import { useTranslation } from 'react-i18next';
import { PronoTennis } from '../../../../types/pronoTennis';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getInfosPronoLibre } from '../../../../utils/pronoLibreTennis';
import BorderLinearProgress from '../../Templates/Progress/BorderLinearProgress';
import { capitalize } from '@material-ui/core';
import Simulateur from '../../Templates/Simulateur/Simulateur';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'transparent',
    width: '100%',
  },
  outBox: {
    background: 'linear-gradient(98deg, #63ee59 2%, #5dcc3a 35%, #2ae113 70%)',
    borderRadius: 25,
    height: '100%',
    width: '100%',
  },
  tabsContainer: {
    borderBottom: 'none',
    '& > div > .MuiTabs-indicator': {
      display: 'none',
    },
    '& > div > div': {
      display: 'inherit',
    },
  },
  tab: {
    color: '#7983a2 !important',
    fontWeight: 600,
    marginBottom: '3px',
    '&.Mui-selected': {
      color: 'white !important',
      border: '2px solid #86B4FF',
      borderRadius: '3rem',
    },
  },
  itemsContainer: {
    '& > div > div > div > div': {
      padding: '1.4rem 0',
    },
  },
  item: {
    padding: '2rem',
  },
}));

type TabPanelProps = {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
};

function a11yProps(index: number): { id: string; 'aria-controls': string } {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

type CardPronoTabPanel = {
  prono: PronoTennis;
};

const CardPronoTabPanel: React.FC<CardPronoTabPanel> = ({ prono }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [mainPronoName, setMainPronoName] = React.useState<string>('');
  const [percentage, setPercentage] = React.useState<number>();
  const { i18n } = useTranslation();
  const language = i18n.language;

  const getPronoInfos = () => {
    const { pronoName, pronoPercentage } = getInfosPronoLibre(prono, t);
    setMainPronoName(pronoName);
    setPercentage(pronoPercentage);
  };

  React.useEffect(() => {
    getPronoInfos();
  }, [prono, language]);

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: number,
  ): void => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number): void => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
          className={classes.tabsContainer}
        >
          <Tab
            label={t('Algorithme')}
            {...a11yProps(0)}
            className={classes.tab}
          />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
        className={classes.itemsContainer}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <CardGeneral blue className={classes.item}>
            <div className={classes.outBox}>
              <Box
                height="4.5rem"
                width="100%"
                fontWeight="bold"
                style={{
                  backgroundColor: '#18244f',
                  backgroundClip: 'padding-box',
                }}
                borderRadius="25px"
                border="2px solid transparent"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                color="white"
                padding={mobile ? '4rem 2rem' : '4rem 0'}
                boxShadow="rgb(126 207 64 / 20%) 0px 0px 18px 7px, rgb(126 207 64 / 15%) 0px 0px 18px 7px inset"
                textAlign="center"
              >
                <Typography
                  variant={mobile ? 'h3' : 'h2'}
                  style={{
                    marginBottom: mobile ? '.4rem' : '-1rem',
                  }}
                >
                  {capitalize(mainPronoName.trim())}
                </Typography>
                <Box maxWidth="80%" width="100%">
                  {percentage && (
                    <BorderLinearProgress
                      pct={percentage}
                      green
                      shadow
                      legend
                    />
                  )}
                </Box>
              </Box>
            </div>
            {/*<Grid*/}
            {/*  container*/}
            {/*  xs={12}*/}
            {/*  justify="space-between"*/}
            {/*  className="noMargin margin-bottom2rem"*/}
            {/*  spacing={mobile ? 2 : 0}*/}
            {/*>*/}
            {/*  <Grid*/}
            {/*    item*/}
            {/*    xs={12}*/}
            {/*    sm={4}*/}
            {/*    style={{ paddingRight: mobile ? '.5rem' : '1rem' }}*/}
            {/*  >*/}
            {/*    <CardPronoPourcentage*/}
            {/*      team1={winnerLastName}*/}
            {/*      team2={prono.loser.label}*/}
            {/*      value={prono.teamResults?.winner}*/}
            {/*      value2={prono.teamResults?.winner}*/}
            {/*      legend1={winnerLastName}*/}
            {/*      legend2={'Nul'}*/}
            {/*      legend3={loserLastName}*/}
            {/*      title="Chance de victoire"*/}
            {/*      subtitle="pour chaque équipe"*/}
            {/*    />*/}
            {/*  </Grid>*/}
            {/*  <Grid*/}
            {/*    item*/}
            {/*    xs={12}*/}
            {/*    sm={4}*/}
            {/*    style={{ padding: mobile ? '.5rem' : '0 1rem' }}*/}
            {/*  >*/}
            {/*    <CardPronoPourcentage*/}
            {/*      team1={winnerLastName}*/}
            {/*      value={prono.teamResults?.winner}*/}
            {/*      value2={prono.teamResults?.winner}*/}
            {/*      legend1={winnerLastName}*/}
            {/*      legend2={loserLastName}*/}
            {/*      title="Chance de victoire"*/}
            {/*      subtitle="pour chaque équipe hors match nul"*/}
            {/*    />*/}
            {/*  </Grid>*/}
            {/*  <Grid*/}
            {/*    item*/}
            {/*    xs={12}*/}
            {/*    sm={4}*/}
            {/*    style={{ paddingLeft: mobile ? '.5rem' : '1rem' }}*/}
            {/*  >*/}
            {/*    <CardPronoPourcentage*/}
            {/*      team1={winnerLastName}*/}
            {/*      team2={loserLastName}*/}
            {/*      value={prono.teamResults?.winner}*/}
            {/*      legend1={'Oui'}*/}
            {/*      legend2={'Non'}*/}
            {/*      title="Les deux équipes marquent"*/}
            {/*      singleText*/}
            {/*    />*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}
          </CardGeneral>
          {/* {language === 'fr' && (
            <Simulateur mobile={mobile} t={t} id={prono.id} />
          )} */}
        </TabPanel>
      </SwipeableViews>
    </div>
  );
};

export default CardPronoTabPanel;
