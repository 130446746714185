import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import '../../../../theme/datasportTheme.css';
import { useTranslation } from 'react-i18next';
import { Time } from 'services';
import CardGeneral from '../../Templates/Cards/CardGeneral';
import BorderLinearProgress from '../../Templates/Progress/BorderLinearProgress';
import CircularProgress from '../../Templates/Progress/CircularProgress';
import OtherPronoTeamRow from './OtherPronoTeamRow/OtherPronoTeamRow';
import { useHistory } from 'react-router-dom';
import { PronoBASEBALL } from '../../../../types/pronoBASEBALL';
import { getInfosPronoLibre } from '../../../../utils/pronoLibreBASEBALL';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: '100%',
    },
    content: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'space-between',
      height: '100%',
      padding: '1rem !important',
    },
    rowMatch: {
      borderRadius: 10,
      padding: '1rem',
      cursor: 'pointer',
      transition: 'all .2s ease-out',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  }),
);

type CardOtherPredictionProps = {
  prono: PronoBASEBALL;
};

const CardOtherPrediction: React.FC<CardOtherPredictionProps> = ({ prono }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const future = prono.dateHideUntil?.seconds > new Date().getTime() / 1000;
  const history = useHistory();
  const [mainPronoName, setMainPronoName] = React.useState<string>('');
  const [percentage, setPercentage] = React.useState<number>();

  const getPronoInfos = () => {
    const { pronoName, pronoPercentage } = getInfosPronoLibre(prono, t);
    setMainPronoName(pronoName);
    setPercentage(pronoPercentage);
  };

  React.useEffect(() => {
    getPronoInfos();
  }, [prono]);

  const title = future ? 'Future analyse' : mainPronoName;

  return (
    <CardGeneral
      className={classes.card}
      blue
      cursorPointer
      shadow
      onClick={() => {
        if (!future) history.push('/pronos_baseball/' + prono.id);
        else history.push('/pronos');
      }}
    >
      <CardContent className={classes.content}>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="space-between"
            justifyContent="center"
            marginBottom="17"
          >
            <Typography variant="h5" component={'span'}>
              <Box
                color="white"
                fontWeight="600"
                marginBottom=".5rem"
                textAlign="center"
              >
                {t(title)}
              </Box>
            </Typography>
          </Box>
          {future ? (
            <Box width="100%" minHeight="20px" margin="2rem 0 1rem">
              <BorderLinearProgress hide_until={prono.dateHideUntil} />
            </Box>
          ) : (
            <Box width="100%" minHeight="150px" marginBottom="2.5rem">
              <CircularProgress
                value={percentage}
                verySmall
                text1Thin
                text1={Time.getDateFromSeconds(prono.date.seconds)}
                text2={`${percentage}%`}
              />
            </Box>
          )}
          <Box
            width="100%"
            display="flex"
            style={{ gap: 10 }}
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
            color="white"
            className={classes.rowMatch}
          >
            <OtherPronoTeamRow match={prono.match} />
          </Box>
        </Box>
      </CardContent>
    </CardGeneral>
  );
};

export default CardOtherPrediction;
