import { Box, Tab, Tabs, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connectMenu } from 'react-instantsearch-dom';

const CustomRefinementList = ({ items, refine }) => {
  const [selectedTab, setSelectedTab] = React.useState<number | false>(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    refine(items[newValue].value);
    setSelectedTab(prevValue => (prevValue === newValue ? false : newValue));
  };
  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
        marginBottom: mobile ? '2rem' : '3rem',
      }}
    >
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="secondary"
        color="primary"
        textColor="secondary"
        variant="fullWidth"
        TabIndicatorProps={{
          style: {
            height: '4px',
            transform: 'translate(0, 75%)',
          },
        }}
        className="noOverflow"
      >
        {items.map(item => {
          return (
            <Tab
              key={`pannel-${item.label}`}
              style={{ minWidth: 0 }}
              label={t(item.label)}
            />
          );
        })}
      </Tabs>
    </Box>
  );
};

export default connectMenu(CustomRefinementList);
