import React, { useState } from 'react';
import {
  makeStyles,
  Theme,
  useTheme,
  createStyles,
} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import 'video-react/dist/video-react.css'; // import css
import Typography from '@material-ui/core/Typography';
import { Player, BigPlayButton } from 'video-react';
import Box from '@material-ui/core/Box';
import '../../../../theme/datasportTheme.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
    },
    teamName: {
      color: 'white',
      width: 'fit-content',
      margin: 'auto',
      marginTop: '5%',
      fontWeight: 'bold',
    },
    smallTeamName: {
      width: 'initial',
    },
    disablePointerEvent: {
      pointerEvents: 'none',
    },
    bg: {
      backgroundImage:
        'linear-gradient(to bottom, rgba(11, 24, 71, 0) -16%, #0b1847 104%)',

      '&:hover': {
        backgroundImage:
          'linear-gradient(to bottom, rgba(11, 24, 71, 0) -16%, #000a30 104%)',
      },
    },
    btn: {
      zIndex: 3,
      marginTop: '0 !important',
      marginLeft: '0 !important',
      transform: 'translate(-50%, -50%) !important',
      borderRadius: '50% !important',
      backgroundColor: 'rgba(255, 255, 255, 0.25) !important',
      transition: 'background-color .2s esae-in-out',

      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.5) !important',
      },
    },
    bigBtn: {
      width: '4em !important',
      height: '4em !important',
      [theme.breakpoints.down('sm')]: {
        width: '2em !important',
        height: '2em !important',
      },
    },
    smallBtn: {
      width: '2em !important',
      height: '2em !important',
      [theme.breakpoints.down('sm')]: {
        width: '1em !important',
        height: '1em !important',
      },
    },
    card: {
      padding: 0,
      minHeight: '50%',
      height: '100%',
    },
    flag: {
      height: 80,
      width: 80,
      margin: 'auto',
      backgroundSize: 'contain',
      [theme.breakpoints.down('sm')]: {
        height: 40,
        width: 40,
      },
    },
    smallFlag: {
      width: 30,
      height: 30,
      [theme.breakpoints.down('sm')]: {
        height: 25,
        width: 25,
      },
    },
    date: {
      color: 'white',
      width: 'max-content',
      margin: 'auto',
      marginTop: '2%',
      fontWeight: 600,
    },
    video: {
      backgroundSize: 'contain',
    },
  }),
);

type CardHighlightProps = {
  url: string;
  team1Name: string;
  team2Name: string;
  timestamp: number;
  team1Logo: string;
  team2Logo: string;
  finalScore: string;
  little?: boolean;
  poster: string;
};

const CardHighlight: React.FC<CardHighlightProps> = ({
  url,
  team1Name,
  team2Name,
  timestamp,
  team1Logo,
  team2Logo,
  finalScore,
  little = null,
  poster,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [showImage, setShowImage] = useState(true);

  return (
    <Card className={classes.card}>
      <Box
        position="relative"
        width="100%"
        height="100%"
        p={0}
        onClick={() => setShowImage(false)}
      >
        {showImage && (
          <>
            <Box
              position="absolute"
              width="100%"
              height="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              className={clsx(classes.bg, classes.disablePointerEvent)}
              zIndex="2"
              p="10%"
            >
              <Box width="30%" textAlign="center">
                <CardMedia
                  image={team1Logo}
                  title="Drapeau équipe 1"
                  className={clsx(little && classes.smallFlag, classes.flag)}
                />
                <Typography
                  variant={
                    mobile ? (little ? 'body1' : 'h5') : little ? 'h6' : 'h4'
                  }
                  className={classes.teamName}
                >
                  {team1Name}
                </Typography>
              </Box>
              <Box width="30%" textAlign="center">
                <CardMedia
                  image={team2Logo}
                  title="Drapeau équipe 2"
                  className={clsx(little && classes.smallFlag, classes.flag)}
                />
                <Typography
                  variant={
                    mobile ? (little ? 'body1' : 'h5') : little ? 'h6' : 'h4'
                  }
                  className={classes.teamName}
                >
                  {team2Name}
                </Typography>
              </Box>
            </Box>
            <Box
              position="absolute"
              width="100%"
              height="50%"
              display="flex"
              justifyContent="center"
              alignItems="flex-end"
              className={classes.disablePointerEvent}
              bottom="0"
              zIndex="2"
              p="5%"
            >
              <Box width="100%" textAlign="center">
                <Typography
                  variant={
                    mobile ? (little ? 'body1' : 'h4') : little ? 'h5' : 'h2'
                  }
                  className={classes.teamName}
                >
                  {finalScore}
                </Typography>
                <Typography
                  variant={
                    mobile
                      ? little
                        ? 'body2'
                        : 'body1'
                      : little
                      ? 'body2'
                      : 'body1'
                  }
                  className={classes.date}
                >
                  {moment.unix(timestamp).format('LLL')}
                </Typography>
              </Box>
            </Box>
          </>
        )}
        <Box height="100%">
          <Player
            src={url}
            poster={poster}
            fluid={false}
            preload="none"
            height="100%"
          >
            <BigPlayButton
              position="center"
              className={clsx(
                little ? classes.smallBtn : classes.bigBtn,
                classes.btn,
              )}
            />
          </Player>
        </Box>
      </Box>
    </Card>
  );
};

export default CardHighlight;
