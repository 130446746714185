import React from 'react';
import {
  withStyles,
  useTheme,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useAsync } from 'hooks';
import { Fire } from 'services';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  AvailableCotes,
  Cotes,
  PronoTennis,
} from '../../../../types/pronoTennis';
import CardGeneral from '../../Templates/Cards/CardGeneral';
import ButtonFilled from '../../Templates/Buttons/ButtonFilled';
import { getCardPlayers, getDoublesPlayersNames } from '../utils';
import { DoubleCompetitorsEntity } from '../../../../types/pronoTennis';
import { useTranslation } from 'react-i18next';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: 'none',
      [theme.breakpoints.down('sm')]: {
        padding: '3px 7px',
        lineHeight: 1,
      },
    },
    head: {
      color: theme.palette.common.white,
      fontSize: 28,
      borderBottom: 'solid 1px #44507a',
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
    body: {
      fontSize: 22,
      color: '#fff',
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
    container: {
      overflowX: 'unset',
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 700,
      backgroundColor: 'transparent',
      fontSize: 22,
      [theme.breakpoints.down('sm')]: {
        minWidth: 'unset',
        fontSize: 18,
      },
    },
    paper: {
      backgroundColor: 'transparent',
      overflowX: 'unset',
      boxShadow: 'none',
    },
    down: {
      color: '#838ca6 !important',
      fontSize: 16,
      marginTop: 5,
      [theme.breakpoints.down('sm')]: {
        fontSize: 9,
      },
    },
    tr: {
      marginBottom: '1rem',
      '&:after': {
        content: '',
        display: 'block',
        height: '1.5em',
        width: '100%',
        background: 'transparent',
      },
    },
    tbody: {
      '&:before': {
        /* This doesn't work because of border-collapse */
        lineHeight: '1em',
        content: '.',
        color: 'white' /* bacground color */,
        display: 'block',
      },
      '&>tr>th': {
        padding: '.5rem',
      },
    },
    logoBM: {
      marginTop: 20,
    },
    bestOdd: {
      // color: '#68daa8',
      // fontWeight: 600,
    },
  }),
);

const getAvailableCotes = (data: Cotes): AvailableCotes => {
  const cotes = { ...data };
  Object.entries(cotes).forEach(([k, v]) => {
    if (!v) delete cotes[k];
  });
  return cotes as AvailableCotes;
};

const getWinnerBestOdd = (
  cotes: AvailableCotes,
  winnerIndex: number,
): number => {
  const winnerCotes = Object.values(cotes).map(cotes => cotes[winnerIndex]);
  return Math.max(...Object.values(winnerCotes).flat());
};

const getWinnerIndex = (prono: PronoTennis): number => {
  return prono.match.value.sport_event.competitors.findIndex(
    ({ id }) => id === prono.winner.value.id,
  );
};

type CardBookmakersTennisProps = {
  prono: PronoTennis;
};

const CardBookmakersTennis: React.FC<CardBookmakersTennisProps> = ({
  prono,
}) => {
  const classes = useStyles();
  const ref = Fire.store().collection('bonus');
  const [bonus] = useAsync(Fire.list(ref), []);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  bonus.sort((a, b) => a.order - b.order);
  const matchType = prono.competition.value.type;
  const [player1, player2] = getCardPlayers(
    prono.match.value.sport_event.competitors,
    matchType,
  );
  const [team1, team2] =
    matchType === 'singles'
      ? [getCardPlayers(prono.match.value.sport_event.competitors, matchType)]
      : getDoublesPlayersNames(
          prono.match.value.sport_event
            .competitors as DoubleCompetitorsEntity[],
        );

  const availableCotes = getAvailableCotes(prono.cotes);
  const winnerIndex = getWinnerIndex(prono);
  const bestOdd = getWinnerBestOdd(availableCotes, winnerIndex);

  const getCoteClassName = (
    cotes: [number, number],
    playerIndex: number,
  ): string => {
    return playerIndex === winnerIndex && cotes[playerIndex] === bestOdd
      ? classes.bestOdd
      : '';
  };

  if (!bonus.length) return null;
  return (
    <CardGeneral blue>
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        padding={mobile ? '1rem 0rem' : '0rem 1rem'}
      >
        <TableContainer component={Paper} className={classes.paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow className={classes.tr}>
                <StyledTableCell>
                  <div style={{ fontSize: mobile ? 13 : 20 }}>
                    {t('Bookmakers')}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div className="up" style={{ fontSize: mobile ? 12 : 20 }}>
                    {team1
                      ? matchType === 'singles'
                        ? team1[0].fullName
                        : team1[0].fullName + ' / ' + team1[1].fullName
                      : player1.lastName + player1.firstName}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div className="up" style={{ fontSize: mobile ? 12 : 20 }}>
                    {team1
                      ? matchType === 'singles'
                        ? team1[1].fullName
                        : team2[0].fullName + ' / ' + team2[1].fullName
                      : player2.lastName + player2.firstName}
                  </div>
                </StyledTableCell>
                <StyledTableCell />
              </TableRow>
            </TableHead>
            <TableBody className={classes.tbody}>
              <StyledTableRow style={{ height: 15 }} />
              {bonus.map(item => {
                const cotes: [number, number] = availableCotes[item.map_api];
                if (!cotes) return null;
                return (
                  <StyledTableRow key={item.title}>
                    <StyledTableCell component="th" scope="row">
                      <div onClick={() => window.open(item.link, '_blank')}>
                        <img
                          alt={`Data-Sport ${item.title}`}
                          style={{ borderRadius: 5 }}
                          className="logoBM cursorPointer"
                          src={item.logo}
                          height={mobile ? '20' : '32'}
                        />
                        <br />
                      </div>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={getCoteClassName(cotes, 0)}
                    >
                      {cotes[0].toFixed(2).toString()}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={getCoteClassName(cotes, 1)}
                    >
                      {cotes[1].toFixed(2).toString()}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <ButtonFilled
                        title="Découvrir"
                        blue={true}
                        medium={!mobile}
                        little={mobile}
                        onPress={() => window.open(item.link, '_blank')}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </CardGeneral>
  );
};

export default CardBookmakersTennis;
