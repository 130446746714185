import React from 'react';
import Grid from '@material-ui/core/Grid';
import { ListItemText } from '@material-ui/core';
import { TFunction } from 'i18next';

type ListItemHeaderProps = {
  mobile: boolean;
  t: TFunction;
};

const PreviewHeader: React.FC<ListItemHeaderProps> = ({ mobile, t }) => {
  return (
    <Grid
      container
      alignContent="center"
      style={{ justifyContent: 'center' }}
      alignItems="center"
    >
      <Grid container item xs={6}>
        <Grid item xs={5} />
        <Grid container item xs={7}>
          <ListItemText primary={t('Joueur')} className="bold" />
        </Grid>
      </Grid>
      {mobile ? (
        <Grid
          container
          item
          xs={6}
          direction="row"
          alignContent="center"
          style={{ justifyContent: 'center' }}
          alignItems="center"
        >
          <Grid item xs={6}>
            <ListItemText primary={'Points'} className="bold" />
          </Grid>
          <Grid item xs={6}>
            <ListItemText primary={t('Nationalité')} className="bold" />
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          item
          xs={6}
          direction="row"
          alignContent="center"
          style={{ justifyContent: 'center' }}
          alignItems="center"
        >
          <Grid item xs={5}>
            <ListItemText primary={t('Points')} className="bold" />
          </Grid>
          <Grid item xs={5}>
            <ListItemText primary={t('Nationalité')} className="bold" />
          </Grid>
          <Grid item xs={2} />
        </Grid>
      )}
    </Grid>
  );
};

export default PreviewHeader;
