import * as firebase from 'firebase';
import '@firebase/firestore';
import '@firebase/functions';
import '@firebase/storage';
import '@firebase/messaging';

export default class Fire {
  // Initialize Firebase
  static init() {
    if (firebase.apps.length) return;
    const cfg = require('../keys/firebase');
    firebase.initializeApp(cfg.default);
  }

  // Retrieve base firestore
  static store() {
    return firebase.firestore();
  }

  // Retrieve base messaging
  static messaging() {
    return firebase.messaging();
  }

  // Retrieve batch
  static batch() {
    return Fire.store().batch();
  }

  // Retrieve base auth
  static auth() {
    return firebase.auth();
  }

  // Retrieve base storage
  static storage() {
    return firebase.storage();
  }

  // Reauth
  static async reauthEmail(email, password) {
    const user = Fire.auth().currentUser;
    if (!user) return null;
    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      password,
    );
    return user.reauthenticateWithCredential(credential);
  }

  // Retrieve base functions
  static async cloud<T = any>(name, data?): Promise<T> {
    if (process.env.NODE_ENV == 'development') {
      console.log('USING LOCAL FUNCTIONS');
      firebase.functions().useFunctionsEmulator('http://localhost:7800');
    }
    // console.log("name: ", name, data)
    const callable = firebase
      .functions()
      .httpsCallable(name, { timeout: 540000 });
    const res = await callable(data);
    return res.data;
  }

  // Upload a file to Storage
  static async uploadFile(location, uri) {
    // Retrieve Blob
    const res = await fetch(uri);
    const blob = await res.blob();
    // Send it to Firebase Storage
    const store = Fire.storage().ref();
    const ref = store.child(location);
    const uploaded = await ref.put(blob);
    // Retrieve persistent URL
    return await uploaded.ref.getDownloadURL();
  }

  // Shortcuts
  static async doc<T extends { id: string } = any>(
    ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
  ): Promise<T | null> {
    const doc = await ref.get();
    if (!doc.exists) return null;
    return { id: doc.id, ...doc.data() } as T;
  }

  static async list<T extends { id: string } = any>(
    ref: firebase.firestore.Query,
  ): Promise<T[]> {
    const arr: T[] = [];
    const snap = await ref.get();
    snap.forEach(doc => {
      if (doc.exists) arr.push({ id: doc.id, ...doc.data() } as T);
    });
    return arr;
  }

  // Shortcuts

  static set(collection, id, data) {
    return Fire.store().collection(collection).doc(id).set(data);
  }
  static update(collection, id, data) {
    return Fire.store().collection(collection).doc(id).update(data);
  }

  // Dates

  // Retrieve timestamp for given date (for test purpopses only)
  static getTimeFor(date) {
    return firebase.firestore.Timestamp.fromDate(date);
  }

  // Retrieve date from timestamp
  static getDateFor(timestamp) {
    if (!timestamp.toDate) return new Date(timestamp);
    return timestamp.toDate();
  }
}
