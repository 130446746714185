import React from 'react';
import { Chip } from '@material-ui/core';

const PlayerHitChip: React.FC<{ label: string }> = ({ label }) => {
  return (
    <Chip
      style={{
        backgroundColor: '#192452',
        borderRadius: 10,
        padding: '0 .25rem',
      }}
      label={
        <p style={{ fontSize: 12, fontWeight: 500, color: '#848ca5' }}>
          {label}
        </p>
      }
    />
  );
};

export default PlayerHitChip;
