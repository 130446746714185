import React from 'react';
import CalendarNFLRegular from './CalendarNFLRegular/CalendarNFLRegular';
// import CalendarNFLPlayoffs from './CalendarNFLPlayoffs/CalendarNFLPlayoffs';

type CalendarNFLProps = {
  league: 'regular' | 'playoffs';
};

const CalendarNFL: React.FC<CalendarNFLProps> = ({ league }) => {
  if (league === 'regular') return <CalendarNFLRegular />;
  // if (league === 'playoffs') return <CalendarNFLPlayoffs />;
  return <></>;
};
export default CalendarNFL;
