import React from 'react';
import {
  makeStyles,
  createStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import Navbar from '../Templates/Navbar/Navbar';
import { Grid, Fade } from '@material-ui/core';
import TopSection from './TopSection/TopSection';
import BottomSection from './BottomSection/BottomSection';
import usePagePlayer from './usePagePlayer';
import BackButton from '../Templates/BackButton/BackButton';
import Tour from 'reactour';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from 'actions/auth.action';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import ButtonFilled from '../Templates/Buttons/ButtonFilled';
import { useTranslation } from 'react-i18next';
import { lightTheme } from '../../../theme/_datasportTheme';
import { disableTour, activateNextTour } from '../../../utils/reactour.utils';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      maxWidth: 1300,
      margin: 'auto',
      width: '100%',
    },
  }),
);

export default function PagePlayer() {
  const classes = useStyles();
  const { playerStats, playerInfo } = usePagePlayer();
  const [isTourOpen, setIsTourOpen] = React.useState(false);
  const user = useSelector((state: any) => state.authReducer.user);
  const [info, setInfo] = React.useState<any>({ ...user });
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const stepsPlayer = [
    {
      selector: '[data-tour="player-presentation-step"]',
      highlightedSelectors: [
        '[data-tour="player-stats-step"]',
        '[data-tour="player-historique-step"]',
      ],
      content: t(
        "Ici c'est la page de Kylian Mbappé, vous retrouverez toutes sortes de statistiques sur le joueur.",
      ),
      scrollDuration: 5,
      position: 'bottom',
    },
    {
      selector: '[data-tour="player-stats-step"]',
      content: t('Ses statistiques personnelles...'),
      scrollDuration: 5,
    },
    {
      selector: '[data-tour="player-historique-step"]',
      content: t('...et tous les matchs dans lesquels il a joué.'),
      scrollDuration: 5,
    },
  ];

  const testFirstConnexion = async () => {
    setIsTourOpen(true);
    info['isFirstConnexion']['player'] = false;
    const payload = {
      ...info,
    };
    setInfo({ ...info });
    await dispatch(updateUser(payload));
  };

  React.useEffect(() => {
    if (user.isFirstConnexion?.player === true) {
      testFirstConnexion();
    }
  }, [testFirstConnexion]);

  const disableBody = target => disableBodyScroll(target);
  const enableBody = target => enableBodyScroll(target);
  const history = useHistory();

  return (
    <Fade in={true} timeout={500}>
      <ThemeProvider theme={lightTheme}>
        <Grid container className={classes.container} spacing={3}>
          <Tour
            steps={stepsPlayer}
            isOpen={isTourOpen}
            onRequestClose={e =>
              disableTour(info, setIsTourOpen, dispatch, updateUser, e)
            }
            rounded={5}
            maskSpace={15}
            onAfterOpen={disableBody}
            onBeforeClose={enableBody}
            lastStepNextButton={
              <ButtonFilled
                title="Fin du tutoriel"
                little
                onPress={() =>
                  activateNextTour(
                    info,
                    setIsTourOpen,
                    dispatch,
                    updateUser,
                    history,
                    {
                      home1: false,
                      home2: false,
                      database: false,
                      player: false,
                      pronos: false,
                      prono: false,
                    },
                    '/',
                  )
                }
              />
            }
            disableInteraction={true}
          />
          <Grid item container xs={12}>
            <Navbar extend />
            <BackButton />
          </Grid>
          {playerInfo && <TopSection playerInfo={playerInfo} />}
          {playerStats && <BottomSection playerStats={playerStats} />}
        </Grid>
      </ThemeProvider>
    </Fade>
  );
}
