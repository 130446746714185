import React from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import CardGeneral from '../Templates/Cards/CardGeneral';
import clsx from 'clsx';
import CardPronoPourcentage from '../Templates/Cards/CardPronoPourcentage';
import CardPronoGoals from '../Templates/Cards/CardPronoGoals';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import '../../../theme/datasportTheme.css';
import { isTooLong } from '../../../utils/logic';
import BorderLinearProgress from '../Templates/Progress/BorderLinearProgress';
import { getInfosPronoLibre } from '../../../utils/pronoLibreSoccer';
import { capitalize, TextField } from '@material-ui/core';
import { Fire } from 'services';
import Simulateur from '../Templates/Simulateur/Simulateur';

const useStyles = makeStyles((theme: Theme) => ({
  outBox: {
    // background: 'linear-gradient(98deg, #63ee59 2%, #5dcc3a 35%, #2ae113 70%)',
    borderRadius: 25,
    height: '100%',
    width: '100%',
  },
  item: {
    padding: '2rem',
  },
  itemStats: {
    padding: '2rem',
    [theme.breakpoints.down('xs')]: {
      padding: '2rem 0',
    },
  },
  title: {
    margin: '.75rem 0 1.5rem 0',
  },
  title2: {
    margin: '4rem 0 1rem 0',
  },
  riskText: {
    marginBottom: '.5rem',
    marginLeft: '.5rem',
    fontWeight: 400,
    fontSize: '1.3rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.1rem',
    },
  },
  greenText: {
    color: '#10ff00',
  },
  orangeText: {
    color: '#ffa500',
  },
}));

export default function SinglePronoPredictions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();

  const team1_name = t(props.team1.name);
  const team1_label = t(props.team1.abbreviation);
  const team2_name = t(props.team2.name);
  const team2_label = t(props.team2.abbreviation);
  const [mainPronoName, setMainPronoName] = React.useState<string>('');
  const [percentage, setPercentage] = React.useState<number>(0);
  const isMorePronos =
    !!props.end_results.winner ||
    !!props.team_results.winner ||
    !!props.both_teams_scored ||
    !!props.goals.superior_1;

  const getPronoInfos = () => {
    const { pronoName, pronoPercentage } = getInfosPronoLibre(props, t);
    setMainPronoName(pronoName);
    setPercentage(pronoPercentage);
  };

  const { i18n } = useTranslation();
  const language = i18n.language;

  React.useEffect(() => {
    getPronoInfos();
  }, [props, language]);

  return (
    <>
      <Typography variant="h3" className={classes.title}>
        {t("Analyse de l'algorithme :")}
      </Typography>
      <CardGeneral
        blue
        className={classes.item}
        data-tour="presentation-prono-step"
      >
        <Typography
          className={clsx(
            classes.riskText,
            percentage >= 56 ? classes.greenText : classes.orangeText,
          )}
          variant="h4"
        >
          {percentage >= 56 ? '' : 'Risque élevé'}
        </Typography>
        <div
          className={clsx(
            classes.outBox,
            percentage >= 56
              ? 'animateBackgroundGreen'
              : 'animateBackgroundOrange',
          )}
        >
          <Box
            height={mobile ? '9rem' : '4.5rem'}
            width="100%"
            fontWeight="bold"
            style={{
              backgroundColor: '#18244f',
              backgroundClip: 'padding-box',
            }}
            borderRadius="25px"
            border="3px solid transparent"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            color="white"
            padding={mobile ? '4rem 2rem' : '4rem 0'}
            textAlign="center"
            className={clsx(
              percentage >= 56
                ? 'animateBoxShadowGreen'
                : 'animateBoxShadowOrange',
            )}
          >
            <Typography
              variant={mobile ? 'h3' : 'h2'}
              style={{
                marginBottom: mobile ? '.4rem' : '-1rem',
                maxWidth: '65%',
                fontSize: mobile ? '1.3rem' : '1.5rem',
              }}
            >
              {capitalize(mainPronoName.trim())}
            </Typography>
            <Box maxWidth="80%" width="100%">
              {percentage && (
                <BorderLinearProgress
                  pct={percentage}
                  green={percentage >= 56}
                  orange={percentage < 56}
                  shadow
                  legend
                  animate
                />
              )}
            </Box>
          </Box>
        </div>
      </CardGeneral>
      {/* {language === 'fr' && <Simulateur mobile={mobile} t={t} id={props.id} />} */}
      {isMorePronos && (
        <Grid container item xs={12} data-tour="presentation-algo-step">
          <Typography variant="h3" className={classes.title2}>
            {t("Probabilités calculées par l'algorithme :")}
          </Typography>
          <CardGeneral
            blue={!mobile}
            transparent={mobile}
            className={classes.itemStats}
          >
            <Grid
              container
              xs={12}
              justify="space-evenly"
              className="noMargin margin-bottom2rem"
              // spacing={mobile ? 2 : 0}
            >
              {!!props.end_results.winner && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{
                    paddingRight: mobile ? '0rem' : '1rem',
                    marginBottom: mobile ? '2rem' : 0,
                  }}
                >
                  <CardPronoPourcentage
                    team1={isTooLong(team1_name, 8) ? team1_label : team1_name}
                    team2={isTooLong(team2_name, 8) ? team2_label : team2_name}
                    value={props.end_results.winner}
                    value2={props.end_results.looser}
                    legend1={
                      isTooLong(team1_name, 15) ? team1_label : team1_name
                    }
                    legend2={t('Nul')}
                    legend3={
                      isTooLong(team2_name, 15) ? team2_label : team2_name
                    }
                    title={t('Chances de victoire')}
                    subtitle={t('pour chaque équipe')}
                    singleText={props.matchType !== 'byWin'}
                  />
                </Grid>
              )}
              {/* {props.team_results.winner && (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{
                    padding: mobile ? '0rem' : '0 1rem',
                    marginBottom: mobile ? '2rem' : 0,
                  }}
                >
                  <CardPronoPourcentage
                    team1={isTooLong(team1_name, 8) ? team1_label : team1_name}
                    team2={isTooLong(team2_name, 8) ? team2_label : team2_name}
                    value={props.team_results.winner}
                    legend1={
                      isTooLong(team1_name, 15) ? team1_label : team1_name
                    }
                    legend2={
                      isTooLong(team2_name, 15) ? team2_label : team2_name
                    }
                    title={t('Chances de victoire')}
                    subtitle={t('pour chaque équipe hors match nul')}
                    singleText
                  />
                </Grid>
              )} */}
              {!!props.both_teams_scored && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{
                    paddingLeft: mobile ? '0rem' : '1rem',
                  }}
                >
                  <CardPronoPourcentage
                    team1={isTooLong(team1_name, 8) ? team1_label : team1_name}
                    team2={isTooLong(team2_name, 8) ? team2_label : team2_name}
                    value={props.both_teams_scored}
                    legend1={'Oui'}
                    legend2={'Non'}
                    // value={props.both_teams_scored > 50 ? props.both_teams_scored : 100 - props.both_teams_scored}
                    // legend1={props.both_teams_scored > 50 ? "Oui" : "Non"}
                    // legend2={props.both_teams_scored > 50 ? "Non" : "Oui"}
                    title={t('Les deux équipes marquent')}
                    singleText
                  />
                </Grid>
              )}
            </Grid>
            {!!props.goals.superior_1 && (
              <Grid
                container
                xs={12}
                justify="space-between"
                spacing={mobile ? 2 : 0}
                className="noMargin"
              >
                <CardPronoGoals
                  title={t('Nombre de buts')}
                  prono1={props.goals.superior_1}
                  prono2={props.goals.superior_2}
                  prono3={props.goals.superior_3}
                />
              </Grid>
            )}
          </CardGeneral>
        </Grid>
      )}
    </>
  );
}
