export const getValidPronosFiltered = (
  pronos,
  isFootball = false,
  isProUser = false,
) => {
  const now = new Date().getTime() / 1000;
  const hideUntilProps = isFootball ? 'hide_until' : 'dateHideUntil';
  const winnerProps = isFootball ? 'team1' : 'winner';

  return pronos
    .filter(
      prono =>
        !prono[hideUntilProps] ||
        (prono[winnerProps] &&
          prono[hideUntilProps]?.seconds <= now &&
          prono.team1?.name),
    )
    .filter(prono => (!prono.isPronoTest && !prono.name_inactive) || isProUser);
};

export const getHideUntilPronosFiltered = (
  pronos,
  isFootball = false,
  isProUser = false,
) => {
  const now = new Date().getTime() / 1000;
  const hideUntilProps = isFootball ? 'hide_until' : 'dateHideUntil';
  const winnerProps = isFootball ? 'team1' : 'winner';

  return pronos
    .filter(prono => prono[hideUntilProps]?.seconds > now)
    .filter(prono => (!prono.isPronoTest && !prono.name_inactive) || isProUser);
};
