import React from 'react';
import clsx from 'clsx';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { useTranslation, initReactI18next } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    red: {
      '&>div': {
        backgroundColor: 'rgba(255, 0, 0, .75)',
      },
    },
    textRed: {
      color: 'rgba(255, 0, 0, .75)',
    },
    green: {
      '&>div': {
        backgroundImage: 'linear-gradient(40deg, #9dff7f, #68ea75)',
      },
    },
    orange: {
      '&>div': {
        backgroundImage: 'linear-gradient(40deg, #fbb054, #f6a43d)',
      },
    },
    textGreen: {
      color: '#10ff00',
    },
    textOrange: {
      color: '#ffa500',
    },
    shadow: {
      boxShadow:
        '0 0 18px 0 rgba(138, 225, 255, 0.25), inset 0 0 18px 0 rgb(138 225 255 / 15%)',
    },
  }),
);

const CustomLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: 'rgb(240 240 240 / 70%)',
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#7589ff',
    },
  }),
)(LinearProgress);

const BorderLinearProgress = props => {
  const { hide_until, red, pct, green, orange, shadow, legend, animate } =
    props;
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);
  const { t, i18n } = useTranslation();
  const [value, setValue] = React.useState(0);
  let now = new Date().getTime() / 1000;
  let nbDays = 3;

  React.useEffect(() => {
    setValue(
      pct ||
        (hide_until?.seconds < now
          ? // La valeur est déjà passée : on met la progress bar à 100%
            100
          : // Sinon on calcule le temps qu'il reste, avec une échelle de nbDays jours, en pourcentage
            100 -
            (hide_until?.seconds - now) /
              (60 *
                60 *
                24 *
                nbDays *
                0.01)) /*min*/ /*hours*/ /*days*/ /* in % */,
      /*sec*/
    );
    setProgress(0);
  }, [props]);

  React.useEffect(() => {
    if (progress < value - 4) {
      const timer = setInterval(() => {
        setProgress(prevProgress =>
          prevProgress < value - 4 ? prevProgress + 5 : prevProgress,
        );
      }, 10);

      return () => {
        clearInterval(timer);
      };
    }
  }, [progress, value]);

  return (
    <Box
      width="100%"
      height="100"
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-end"
    >
      {/* {red && (
        <Typography variant="body2" component={'span'}>
          <Box
            color="rgba(255, 0, 0, .75)"
            fontWeight={600}
            textAlign="center"
            width="max-content"
            marginBottom=".25rem"
          >
            {t("Match non choisi par l'algorithme")}
          </Box>
        </Typography>
      )} */}
      {legend && (
        <Typography variant="h4" component={'span'}>
          <Box
            fontWeight={700}
            marginBottom=".25rem"
            className={clsx(
              red ? classes.textRed : '',
              green ? classes.textGreen : '',
              orange ? classes.textOrange : '',
            )}
          >
            {pct}%
          </Box>
        </Typography>
      )}
      <CustomLinearProgress
        variant="determinate"
        value={red ? 100 : progress < 10 ? 10 : progress}
        style={{ width: '100%' }}
        className={clsx(
          red ? classes.red : '',
          green ? classes.green : '',
          orange ? classes.orange : '',
          shadow ? classes.shadow : '',
          animate && green ? 'animateGreenProgress' : '',
          animate && orange ? 'animateOrangeProgress' : '',
        )}
      />
    </Box>
  );
};

export default BorderLinearProgress;
