import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { capitalize, Typography } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useTranslation } from 'react-i18next';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonPrevNext: {
      transition: 'all .2s ease-in-out',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
      },
    },
    buttonContainer: {
      padding: 0,
      margin: '5px 10px',
      height: 'fit-content',
      borderRadius: '2rem',
    },
  }),
);

type CalendarNavigation = {
  currentRound: string;
  rounds: string[];
  setCurrentRound: (navNumber: number) => void;
};

const CalendarNavigation: React.FC<CalendarNavigation> = ({
  currentRound,
  rounds,
  setCurrentRound,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const firstRound = rounds[0];
  const lastRound = rounds[rounds.length - 1];
  return (
    <>
      {currentRound !== firstRound ? (
        <Button
          onClick={() => {
            setCurrentRound(-1);
          }}
          className={classes.buttonContainer}
        >
          <Box
            bgcolor="#242f56"
            color="white"
            padding=".5rem 1rem"
            borderRadius="2rem"
            alignItems="center"
            display="flex"
            fontWeight="600"
            className={classes.buttonPrevNext}
          >
            <ArrowBackIcon fontSize="small" style={{ marginRight: '.5rem' }} />{' '}
            {!mobile && t('Précédente')}
          </Box>
        </Button>
      ) : (
        <div style={{ opacity: '0' }}>{!mobile && t('Précédente')}</div>
      )}
      <div>
        <Typography variant="h3" style={{ margin: '1.5rem 0' }}>
          {isNaN(+currentRound)
            ? t(capitalize(currentRound))
            : language === 'fr'
            ? `${currentRound + (currentRound === '1' ? 'ère' : 'e')} ${t(
                'journée',
              )}`
            : language === 'it'
            ? `${currentRound}^${t('journée')}`
            : capitalize(`${t('journée')} ${currentRound}`)}
        </Typography>
      </div>
      {currentRound !== lastRound ? (
        <Button
          onClick={() => {
            setCurrentRound(1);
          }}
          className={classes.buttonContainer}
        >
          <Box
            bgcolor="#242f56"
            color="white"
            padding=".5rem 1rem"
            borderRadius="2rem"
            alignItems="center"
            display="flex"
            fontWeight="600"
            className={classes.buttonPrevNext}
            fontSize={''}
          >
            {!mobile && t('Suivante')}{' '}
            <ArrowForwardIcon
              fontSize="small"
              style={{ marginLeft: '.5rem' }}
            />
          </Box>
        </Button>
      ) : (
        <div style={{ opacity: '0' }}>{!mobile && t('Suivante')}</div>
      )}
    </>
  );
};

export default CalendarNavigation;
