import React from 'react';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/it';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import {
  List,
  ListItem,
  IconButton,
  Typography,
  useTheme,
  Theme,
  createStyles,
  Box,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import { useTranslation, initReactI18next } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from 'actions/auth.action';
import { Link } from 'react-router-dom';
import flag_fr from 'images/flag_fr.png';
import flag_en from 'images/flag_en.png';
import flag_es from 'images/flag_es.png';
import flag_it from 'images/flag_it.png';
import ImageButton from '../../../Home/Templates/ImageButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
    drawer: {
      width: 200,
      flexShrink: 0,
    },
    drawerPaper: {
      width: 200,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    drawerLinkText: {
      textDecoration: `none`,
      textTransform: `capitalize`,
      fontWeight: 700,
      color: 'white',
      fontSize: '1.5rem',
      padding: `8px 18px`,
      margin: '0 10px',
    },
    btnIcon: {
      color: 'white !important',
      [theme.breakpoints.down('xs')]: {
        padding: '5px 0',
      },
    },
    menuBackground: {
      background: '#0b1847',
    },
    drawerContainer: {
      '& div:nth-child(0n + 3)': {
        background: '#0b1847',
      },
    },
    menuItemText: {
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: 3.5,
    },
  }),
);

type Anchor = 'left' | 'top' | 'right' | 'bottom';

export default function LeftDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const pathname = document.location.pathname;
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.authReducer.user);
  const [info, setInfo] = React.useState<any>({ ...user });
  const history = useHistory();
  const [state, setState] = React.useState({
    left: false,
  });

  const { menu, promptLogout } = props;
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    moment.locale(lng);
  };

  const resetTutoriel = async () => {
    let userAgent = navigator.userAgent;
    if (
      !userAgent.match(/chrome|chromium|crios/i) &&
      userAgent.match(/safari/i)
    ) {
      alert("Ce tutoriel n'est pas disponible sur mobile Safari");
      return null;
    }
    const allSteps = [
      'bonus',
      'home1',
      'home2',
      'database',
      'player',
      'prono',
      'pronos',
    ];

    allSteps.forEach(step => {
      info['isFirstConnexion'][step] = true;
    });
    const payload = {
      ...info,
    };
    history.push('/');
    await dispatch(updateUser(payload));
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {menu.map((item, index) => (
          <ListItem
            component={Link}
            to={item.path || ''}
            onClick={() =>
              item.disconnect
                ? promptLogout()
                : item.tuto
                ? resetTutoriel()
                : console.log('redirection')
            }
            className={classes.drawerLinkText}
            selected={item.path === pathname}
            key={item.path + index}
          >
            <Typography variant="body1" className={classes.menuItemText}>
              {item.title}
            </Typography>
          </ListItem>
        ))}
        <ListItem className={classes.drawerLinkText}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <ImageButton
              height={21}
              width={21}
              onPress={() => changeLanguage('fr')}
              image={flag_fr}
              selected={i18n.language === 'fr'}
              marginRight="1rem"
            />
            <ImageButton
              height={21}
              width={21}
              onPress={() => changeLanguage('en')}
              image={flag_en}
              selected={i18n.language === 'en'}
              marginRight="1rem"
            />
            <ImageButton
              height={21}
              width={21}
              onPress={() => changeLanguage('es')}
              image={flag_es}
              selected={i18n.language === 'es'}
              marginRight="1rem"
            />
            <ImageButton
              height={21}
              width={21}
              onPress={() => changeLanguage('it')}
              image={flag_it}
              selected={i18n.language === 'it'}
            />
          </Box>
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      {(['left'] as Anchor[]).map(anchor => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={toggleDrawer(anchor, true)}> */}
          <IconButton
            aria-label="open drawer"
            edge="start"
            className={classes.btnIcon}
            onClick={toggleDrawer(anchor, true)}
          >
            <MenuIcon />
          </IconButton>
          {/* </Button> */}
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
            className={classes.drawerContainer}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
