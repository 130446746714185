import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import {
  Grid,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import SportsSoccerSharp from '@material-ui/icons/SportsSoccerSharp';
import Loading from '../../../Loading/Loading';
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import '../../../../theme/datasportTheme.css';
import medal1 from 'images/medal1.png';
import medal2 from 'images/medal2.png';
import medal3 from 'images/medal3.png';
import {
  Competitor,
  StandingsEntity,
} from '../../../../types/sportradar/soccer/sport-event';
import { useHistory } from 'react-router-dom';
import ButtonFilled from '../../Templates/Buttons/ButtonFilled';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      margin: theme.spacing(4, 0, 2),
    },
    tabs: {
      backgroundColor: 'transparent',
      borderBottom: 'none',
      [theme.breakpoints.down('xs')]: {
        overflow: 'scroll',
      },
    },
    tab: {
      boxShadow: 'none',
      textTransform: 'none',
      fontWeight: 'bold',
      minWidth: '100px',

      '&>span': {
        '&.MuiTab-wrapper': {
          backgroundColor: '#242f56',
          borderRadius: '50px',
          color: 'white !important',
          height: '100%',
          padding: '.8rem 2.5rem',
          width: 'max-content',
          transition: 'all .2s ease-in-out',
        },
      },
      '&.Mui-selected': {
        '&> span': {
          '&.MuiTab-wrapper': {
            backgroundColor: theme.palette.secondary.main,
            color: `${theme.palette.primary.main} !important`,
          },
        },
      },
      '&:hover> span': {
        '&.MuiTab-wrapper': {
          backgroundColor: theme.palette.secondary.main,
          color: `${theme.palette.primary.main} !important`,
        },
      },
    },
    card: {
      height: 'unset',
      padding: '10px 25px',
      [theme.breakpoints.down('sm')]: {
        padding: 5,
        width: 'calc(100% + 40px)',
        transform: 'translate(-20px)',
        margin: 0,
      },
      '&> *': {
        textAlign: 'center',
      },

      '& > div > div': {
        [theme.breakpoints.down('sm')]: {
          padding: '5px !important',
        },
      },
    },
    singleCard: {
      backgroundColor: '#f8f9fa',
      margin: '.625rem 0',
      padding: '1rem',
      borderRadius: '15px',
      border: 'none',
      boxShadow: 'none',
      minHeight: 'unset',
      minWidth: '100%',
      [theme.breakpoints.down('sm')]: {
        padding: '1rem',
      },
      '&> *': {
        fontWeight: 500,
        textAlign: 'center',
      },
      '&:hover': {
        backgroundColor: 'white',
        boxShadow: '0 5px 10px 0 rgba(10, 22, 62, 0.08)',
      },
    },
    headerItems: {
      [theme.breakpoints.down('sm')]: {
        padding: '10px 2rem !important',
      },
    },
    img: {
      borderRadius: '50px',
      [theme.breakpoints.down('sm')]: {
        width: 20,
        height: 20,
      },
    },
    countryImg: {
      display: 'flex',
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        height: 30,
        width: 30,
      },
    },
    appBar: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      borderBottom: 'solid 2px #dbdfe8',

      '&,& > div, &>div>div': {
        overflow: 'scroll !important',
      },
    },
    button: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '13px',
      borderBottomRightRadius: '18px',

      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
        boxShadow: '0 12px 23px 0 rgb(10 22 62 / 16 %)',
      },
    },
    selectedMatch: {
      border: '2px solid',
    },
  }),
);

type Props = {
  standings: StandingsEntity[];
  loading: boolean;
  competitors: Competitor[];
  competition: any;
};

const RankingSoccer: React.FC<Props> = ({
  standings,
  loading,
  competitors,
  competition,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const { t } = useTranslation();
  const competitorIds = competitors.map(({ id }) => id);
  const { i18n } = useTranslation();
  const language = i18n.language.toUpperCase();

  const mapGroups = standings.map((team, index) => {
    return (
      <ListItem
        component={Card}
        className={clsx({
          [classes.singleCard]: true,
          [classes.selectedMatch]: competitorIds.includes(team.teamId),
        })}
        key={index}
        style={{ cursor: 'pointer' }}
        onClick={() => history.push(`/team/${team.teamId}`)}
      >
        <Grid
          container
          item
          xs={12}
          alignContent="center"
          justify="center"
          alignItems="center"
        >
          <Grid
            container
            item
            alignContent="center"
            justify="center"
            alignItems="center"
            xs={mobile ? 5 : 3}
          >
            <Grid item xs={1}>
              {team.position == 1 ? (
                <img src={medal1}></img>
              ) : team.position == 2 ? (
                <img src={medal2}></img>
              ) : team.position == 3 ? (
                <img src={medal3}></img>
              ) : (
                <ListItemText primary={team.position} className="bold" />
              )}
            </Grid>
            <Grid item xs={4}>
              <img
                src={team.logoPath}
                width="40 px"
                className={classes.countryImg}
              />
            </Grid>
            <Grid item xs={7}>
              <Box textAlign="left">
                <ListItemText primary={team.teamName} className="bold" />
              </Box>
            </Grid>
          </Grid>
          {mobile ? (
            <Grid container item xs={7}>
              <Grid item xs={2}>
                <ListItemText primary={team.totalPoints} />
              </Grid>
              <Grid container item xs={5} alignItems="center" justify="center">
                {team.latestData.map((winnerTeamId, index) =>
                  index < 5 ? (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {winnerTeamId === null ? (
                        <FiberManualRecordIcon
                          fontSize="small"
                          style={{ color: '#848ca5' }}
                        />
                      ) : winnerTeamId === team.actualTeamId ? (
                        <FiberManualRecordIcon
                          fontSize="small"
                          style={{ color: '#68daa8' }}
                        />
                      ) : (
                        <FiberManualRecordIcon
                          fontSize="small"
                          style={{ color: '#e0153f' }}
                        />
                      )}
                    </Box>
                  ) : (
                    ''
                  ),
                )}
              </Grid>
              {/* <Grid item xs={3}>
                      <ListItemText
                        primary={"+46"}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      {positionChange(1)}
                    </Grid> */}
            </Grid>
          ) : (
            <Grid container item xs={9}>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <ListItemText
                  primary={team.totalPoints}
                  style={{ fontWeight: 'bold' }}
                />
              </Grid>
              <Grid item xs={1}>
                <ListItemText primary={team.overallGamesPlayed} />
              </Grid>
              <Grid item xs={1}>
                <ListItemText primary={team.overallWon} />
              </Grid>
              <Grid item xs={1}>
                <ListItemText primary={team.overallDraw} />
              </Grid>
              <Grid item xs={1}>
                <ListItemText primary={team.overallLost} />
              </Grid>
              <Grid item xs={1}>
                <ListItemText primary={team.overallGoalsScored} />
              </Grid>
              <Grid item xs={1}>
                <ListItemText primary={team.overallGoalsAgainst} />
              </Grid>
              <Grid item xs={1}>
                <ListItemText
                  primary={team.overallGoalsScored - team.overallGoalsAgainst}
                />
              </Grid>
              <Grid container item xs={2} alignItems="center" justify="center">
                {team.latestData &&
                  team.latestData.map((winnerTeamId, index) =>
                    index < 5 ? (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        key={index}
                      >
                        {winnerTeamId === null ? (
                          <FiberManualRecordIcon
                            fontSize="small"
                            style={{ color: '#848ca5' }}
                          />
                        ) : winnerTeamId === team.actualTeamId ? (
                          <FiberManualRecordIcon
                            fontSize="small"
                            style={{ color: '#68daa8' }}
                          />
                        ) : (
                          <FiberManualRecordIcon
                            fontSize="small"
                            style={{ color: '#e0153f' }}
                          />
                        )}
                      </Box>
                    ) : (
                      ''
                    ),
                  )}
              </Grid>
              <Grid item xs={1}>
                <ButtonFilled icon small hoverOutlined />
              </Grid>
            </Grid>
          )}
        </Grid>
      </ListItem>
    );
  });

  return (
    <React.Fragment>
      <Grid container item xs={12} justify="space-around">
        <Box width="100" textAlign="center" marginBottom=".5rem">
          <Typography variant="h6">
            {competition.name[language] || competition.name}
          </Typography>
        </Box>
        <Box width="100%" position="relative">
          {loading ? (
            <Grid container item xs={12}>
              <Loading nobg />
            </Grid>
          ) : (
            <Card className={classes.card}>
              <ListItem
                component={Card}
                className={clsx(classes.card, classes.headerItems)}
              >
                <Grid
                  container
                  item
                  xs={12}
                  alignContent="center"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={mobile ? 5 : 3}></Grid>
                  {mobile ? (
                    <Grid
                      container
                      item
                      xs={7}
                      direction="row"
                      alignContent="center"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={2}>
                        <ListItemText
                          primary={mobile ? 'Pts' : t('Points')}
                          className="bold"
                        />
                      </Grid>
                      <Grid xs={5}>
                        <Box
                          width="100%"
                          justifyContent="center"
                          textAlign="center"
                        >
                          <Typography variant="h5" component={'span'}>
                            <Box
                              display="flex"
                              textAlign="center"
                              justifyContent="center"
                            >
                              <Box color="#68daa8" width="25%">
                                {t('G.')}
                              </Box>
                              <Box color="#848ca5" width="25%">
                                {t('N.')}
                              </Box>
                              <Box color="#e0153f" width="25%">
                                {t('P.')}
                              </Box>
                            </Box>
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid xs={5}></Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      item
                      xs={9}
                      direction="row"
                      alignContent="center"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={1}></Grid>
                      <Grid item xs={1}>
                        <ListItemText primary={t('Points')} className="bold" />
                      </Grid>
                      <Grid item xs={1}>
                        <ListItemText primary={t('Joués')} className="bold" />
                      </Grid>
                      <Grid item xs={1}>
                        <ListItemText primary={t('Gagnés')} className="bold" />
                      </Grid>
                      <Grid item xs={1}>
                        <ListItemText primary={t('Nuls')} className="bold" />
                      </Grid>
                      <Grid item xs={1}>
                        <ListItemText primary={t('Perdus')} className="bold" />
                      </Grid>
                      <Grid item xs={1}>
                        <ListItemIcon>
                          <Button style={{ margin: 'auto' }}>
                            <SportsSoccerSharp
                              style={{ fontSize: '1.9rem', color: '#f5ef69' }}
                            />
                          </Button>
                        </ListItemIcon>
                      </Grid>
                      <Grid item xs={1}>
                        <ListItemIcon>
                          <Button style={{ margin: 'auto' }}>
                            <SportsSoccerSharp
                              style={{ fontSize: '1.9rem', color: '#848ca5' }}
                            />
                          </Button>
                        </ListItemIcon>
                      </Grid>
                      <Grid item xs={1}>
                        <ListItemText primary={'+/-'} className="bold" />
                      </Grid>
                      <Grid item xs={2}>
                        <Box
                          width="100%"
                          justifyContent="center"
                          textAlign="center"
                        >
                          <Typography variant="h5" component={'span'}>
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Box color="#68daa8" width="25%">
                                {t('G.')}
                              </Box>
                              <Box color="#848ca5" width="25%">
                                {t('N.')}
                              </Box>
                              <Box color="#e0153f" width="25%">
                                {t('P.')}
                              </Box>
                            </Box>
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={1}></Grid>
                    </Grid>
                  )}
                </Grid>
              </ListItem>
              {mapGroups}
            </Card>
          )}
        </Box>
      </Grid>
    </React.Fragment>
  );
};

export default RankingSoccer;
