import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Container,
  MenuItem,
  Menu,
  MenuList,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { AccountCircle } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Fire } from 'services';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Theme,
  useTheme,
  createStyles,
} from '@material-ui/core/styles';
import LeftDrawer from './LeftDrawer';
import clsx from 'clsx';
import Fade from '@material-ui/core/Fade';
import { useTranslation } from 'react-i18next';
import { logout } from 'actions/auth.action';
import LangSelect from './LangSelect';
import { updateUser } from 'actions/auth.action';
import { Sport } from '../../Home/ToggleSport';
import BackButton from '../../Templates/BackButton/BackButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outsideContainer: {
      padding: 0,
      [theme.breakpoints.down('xs')]: {
        height: 0,
      },
    },
    navbarDisplayFlex: {
      display: `flex`,
      justifyContent: `space-between`,
      alignItems: `center`,
      padding: `1.5rem`,
      [theme.breakpoints.down('md')]: {
        padding: '15px 25px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '9px 20px 5px',
      },
    },
    navDisplayFlex: {
      display: `flex`,
      justifyContent: `space-between`,
      gap: '1rem',

      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    navDisplayFlexCollapsed: {
      display: 'none',
    },
    linkText: {
      textDecoration: `none`,
      fontWeight: 700,
      color: '#848ca5 !important',
      fontSize: '1rem',
      padding: `8px 18px`,
      margin: '0 0px',
      borderRadius: 20,

      '&.Mui-selected': {
        backgroundColor: 'rgba(22, 35, 79, 0.9)',
        color: 'white !important',
        border: '2px solid #848ca5',
        boxSizing: 'border-box !important',
      },

      '&:hover': {
        transition: 'color, .2s ease-in-out',
        color: 'white !important',
        backgroundColor: '#0a1640 !important',
      },
    },
    outBox: {
      background:
        'linear-gradient(98deg, #7489ff 2%, #86b4ff 35%, #7fc1ff 70%)',
      borderRadius: 20,
    },
    activeMulticolor: {
      '&.Mui-selected': {
        position: 'relative',
        border: '2px solid transparent',
        borderRadius: 20,
        backgroundClip: 'padding-box',
      },
    },
    appBar: {
      borderRadius: 25,
      zIndex: 1,
    },
    transparent: {
      backgroundColor: 'rgba(25,38,83,0.85)',
    },
    noBg: {
      backgroundColor: 'transparent',
    },
    noRadius: {
      borderRadius: '0px',
    },
    title: {
      fontWeight: 700,
      fontSize: 21,
    },
    iconUser: {
      fontSize: 40,
      padding: 0,
      '&:hover': {
        boxShadow: '0 0 33px 0 rgb(255 255 255 / 11%)',
      },
    },
    popoverMenu: {
      '& div:nth-child(0n + 3)': {
        background: 'white',
        padding: '.5rem',

        '&>ul>li': {
          fontWeight: 600,
          color: '#0b1847',
          fontSize: '.9rem',
          paddingRight: '2rem',
          '&:hover': {
            backgroundColor: '#ecf0f8',
          },
        },
      },
    },
    boxLangSelect: {
      '&>div::before': {
        borderBottom: 'none !important',
      },
      '&>div>div::before': {
        content: 'none',
      },
    },
    colorWhite: {
      color: 'white !important',
    },
  }),
);

export interface NavLinksPhone {
  title: string;
  path?: string;
  disconnect?: boolean;
  tuto?: boolean;
}

export default function Navbar(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const pathname = `/${window.location.pathname.split('/')[1]}`;
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const {
    extend,
    smallExtend,
    mediumExtend,
    bigExtend,
    transparent,
    activeMulticolor,
    toggleDarkTheme,
    isDarkTheme,
    isTourOpen,
    collapsed,
    noRadius,
    onlyBack,
    noList,
    noBg,
  } = props;
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  //Drawer Account

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const user = useSelector((state: any) => state.authReducer.user);
  const [info, setInfo] = React.useState<any>({ ...user });
  const selectedSport: Sport = useSelector(
    (state: any) => state.sportsReducer.selectedSport,
  );
  const [navLinks, setNavLinks] = React.useState([
    { title: t(`Accueil`), path: `/` },
    { title: t(`Analyses`), path: `/pronos` },
    { title: t(`Database`), path: `/library` },
    // { title: t(`Highlights`), path: `/highlights` },
    // {
    //   title: t(`Live score`),
    //   path: `/livescore/${selectedSport.toLowerCase()}`,
    // },
    // { title: t(`Bonus`), path: `/bonus` },
    { title: t('FAQ'), path: '/faq' },
    // { title: t(`Conseils`), path: `/conseils` },
  ]);

  const promptLogout = () => {
    const confirm = window.confirm(
      t('Êtes-vous sûr de vouloir vous déconnecter ?'),
    );
    if (confirm) {
      dispatch(logout());
      history.push('/');
    }
  };

  const resetTutoriel = async () => {
    const allSteps = [
      'bonus',
      'home1',
      'home2',
      'database',
      'player',
      'prono',
      'pronos',
    ];

    allSteps.forEach(step => {
      info['isFirstConnexion'][step] = true;
    });
    const payload = {
      ...info,
    };
    history.push('/');
    await dispatch(updateUser(payload));
  };

  const navLinksPhone: NavLinksPhone[] = [
    { title: t(`Accueil`), path: `/` },
    { title: t(`Analyses`), path: `/pronos` },
    { title: t(`Database`), path: `/library` },
    // { title: t(`Highlights`), path: `/highlights` },
    // {
    //   title: t(`Live score`),
    //   path: `/livescore/${selectedSport.toLowerCase()}`,
    // },
    // { title: t(`Bonus`), path: `/bonus` },
    { title: t('FAQ'), path: '/faq' },
    // { title: t(`Conseils`), path: `/conseils` },
    { title: t('Tutoriel'), tuto: true },
    { title: t(`Mon compte`), path: `/account` },
    { title: t(`Déconnexion`), disconnect: true },
  ];

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container className={classes.outsideContainer} maxWidth={false}>
      <Fade in={true} timeout={500}>
        <Box position="relative">
          <AppBar
            position="static"
            className={clsx(
              classes.appBar,
              transparent && classes.transparent,
              noBg && classes.noBg,
              collapsed,
              noRadius && classes.noRadius,
            )}
            data-tour="nav-step"
          >
            <Toolbar style={{ zIndex: 1 }}>
              <Container className={classes.navbarDisplayFlex} maxWidth={false}>
                {onlyBack && (
                  <BackButton transparent onClick={() => history.push('/')} />
                )}
                {!onlyBack && (
                  <Typography
                    variant="h4"
                    className={classes.title}
                    onClick={() => history.push('/')}
                    style={{ cursor: 'pointer' }}
                  >
                    DATA
                    <span style={{ fontStyle: 'italic' }}>SPORT</span>
                  </Typography>
                )}
                {(mobile || collapsed) && (
                  <LeftDrawer
                    menu={navLinksPhone}
                    promptLogout={promptLogout}
                  />
                )}
                {!onlyBack && !noList && (
                  <MenuList
                    aria-labelledby="main navigation"
                    className={clsx(
                      classes.navDisplayFlex,
                      collapsed && classes.navDisplayFlexCollapsed,
                    )}
                  >
                    {navLinks.map(({ title, path }, index) =>
                      path === pathname ||
                      (path === '/' && pathname === '/dashboard') ? (
                        <div className={classes.outBox} key={index}>
                          <MenuItem
                            key={index}
                            component={Link}
                            to={path}
                            className={clsx(
                              classes.linkText,
                              activeMulticolor && classes.activeMulticolor,
                            )}
                            selected={
                              path === pathname ||
                              (path === '/' && pathname === '/dashboard')
                            }
                          >
                            <Typography variant="body1">{title}</Typography>
                          </MenuItem>
                        </div>
                      ) : (
                        <MenuItem
                          key={index}
                          component={Link}
                          to={path}
                          className={clsx(
                            classes.linkText,
                            activeMulticolor && classes.activeMulticolor,
                            isTourOpen && classes.colorWhite,
                          )}
                          selected={
                            path === pathname ||
                            (path ===
                              `/livescore/${selectedSport.toLowerCase()}` &&
                              pathname === '/livescore') ||
                            (path === '/dashboard' && pathname === '/') ||
                            (pathname.includes('/pronos') && path === '/pronos')
                          }
                          data-tour={
                            path === '/library'
                              ? 'nav-database-step'
                              : path === '/dashboard' || path === '/'
                              ? 'nav-home-step'
                              : `nav-${path.split('/')[1].toLowerCase()}-step`
                          }
                        >
                          <Typography variant="body1">{title}</Typography>
                        </MenuItem>
                      ),
                    )}
                  </MenuList>
                )}
                {!mobile && !collapsed && !onlyBack && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    className={classes.boxLangSelect}
                  >
                    {/* {pathname === '/' && (
                      <ThemeSwitch
                        toggleDarkTheme={toggleDarkTheme}
                        isDarkTheme={isDarkTheme}
                      />
                    )} */}
                    <LangSelect />
                    {!noList && (
                      <>
                        <IconButton
                          aria-label="account of current user"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          className={classes.iconUser}
                          onClick={handleMenu}
                          // onClick={() => history.push('/account')}
                          color="inherit"
                        >
                          <AccountCircle />
                        </IconButton>
                        <Menu
                          id="menu-appbar"
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          keepMounted
                          getContentAnchorEl={null}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          open={open}
                          onClose={handleClose}
                          className={classes.popoverMenu}
                        >
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              history.push('/account');
                            }}
                          >
                            {t('Mon compte')}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              resetTutoriel();
                            }}
                          >
                            {t('Tutoriel')}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              promptLogout();
                            }}
                          >
                            {t('Déconnexion')}
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </Box>
                )}
              </Container>
            </Toolbar>
          </AppBar>
          {bigExtend && (
            <Box
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height={mobile || collapsed ? '1000%' : '700%'}
              bgcolor={theme.palette.primary.main}
              zIndex="0"
              borderRadius={25}
            ></Box>
          )}
          {extend && (
            <Box
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height={mobile || collapsed ? '800%' : '600%'}
              bgcolor={theme.palette.primary.main}
              zIndex="0"
              borderRadius={25}
            ></Box>
          )}
          {mediumExtend && (
            <Box
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height="500%"
              bgcolor={theme.palette.primary.main}
              zIndex="0"
              borderRadius={25}
            ></Box>
          )}
          {smallExtend && (
            <Box
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height="400%"
              bgcolor={theme.palette.primary.main}
              zIndex="0"
              borderRadius={25}
            ></Box>
          )}
        </Box>
      </Fade>
    </Container>
  );
}
