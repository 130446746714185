import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom';
import { Fire } from 'services';
import { PronoTennis } from '../../../types/pronoTennis';
import SinglePronoTennisLayout from './SinglePronoTennisLayout/SinglePronoTennisLayout';
import CardPronoTennisFille from './CardPronoTennisFille/CardPronoTennisFille';
import CardInfoMatch from './CardInfoMatch/CardInfoMatch';
import CardPronoTabPanel from './CardPronoTabPanel/CardPronoTabPanel';
import CardOtherProno from './CardOtherProno/CardOtherProno';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CardBookmakersTennis from './CardBookmakersTennis/CardBookmakersTennis';
import { useTranslation } from 'react-i18next';
import { getInfosPronoLibre } from '../../../utils/pronoLibreTennis';
import SinglePronoPredictions from './SinglePronoPredictions/SinglePronoPredictions';

const SinglePronoTennis: React.FC = () => {
  const theme = useTheme();
  const { id } = useParams<{ id: string }>();
  const [pronoTennis, setPronoTennis] = useState<PronoTennis | null>(null);
  const [otherPronos, setOtherPronos] = useState<PronoTennis[]>([]);
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t, i18n } = useTranslation();

  const language = i18n.language;

  const getProno = useCallback(async () => {
    const ref = Fire.store().collection('pronosTennis').doc(id);
    const pronoTennis = await Fire.doc<PronoTennis>(ref);
    setPronoTennis(pronoTennis);
  }, [id]);

  const getOtherPronos = useCallback(async () => {
    const now = new Date();
    now.setHours(now.getHours() - 4);
    const ref = await Fire.store()
      .collection('pronosTennis')
      .where('date', '>=', now)
      .orderBy('date', 'desc')
      .limit(4);
    const data = await Fire.list<PronoTennis>(ref);
    const otherPronos = data
      .filter(prono => prono.id !== id)
      .filter(
        prono =>
          !prono.dateHideUntil ||
          prono.dateHideUntil?.seconds > now.getTime() / 1000,
      )
      .sort((a, b) => (a.dateHideUntil && !b.dateHideUntil ? 1 : -1));
    setOtherPronos(otherPronos);
  }, [id]);

  useEffect(() => {
    getProno();
    getOtherPronos();
  }, [getProno, getOtherPronos]);

  return (
    <>
      <SinglePronoTennisLayout
        leftChildren={
          <>
            <Grid item xs={12} sm={8}>
              {pronoTennis && <CardPronoTennisFille prono={pronoTennis} />}
            </Grid>
            <Grid item container xs={12} sm={4}>
              {pronoTennis && !mobile && <CardInfoMatch prono={pronoTennis} />}
            </Grid>
            <Grid item xs={12}>
              {pronoTennis && <CardPronoTabPanel prono={pronoTennis} />}
            </Grid>
            <Grid item xs={12}>
              {pronoTennis && <SinglePronoPredictions {...pronoTennis} />}
            </Grid>
            {pronoTennis?.cotes && language === 'fr' && (
              <Grid item xs={12}>
                {/* <Box
                  marginBottom=".5rem"
                  color="white"
                  width="100%"
                  fontSize="1.375rem"
                  fontWeight={600}
                >
                  {t('Trouvez la meilleure cote en direct')}
                </Box> */}
                <CardBookmakersTennis prono={pronoTennis} />
              </Grid>
            )}
          </>
        }
        rightChildren={
          <>
            {otherPronos.map(prono => (
              <Grid
                key={`div_otherprono_${prono.id}`}
                item
                xs={12}
                sm={6}
                md={4}
                lg={12}
                className="margin-bottom2rem"
              >
                <CardOtherProno
                  prono={prono}
                  key={`card_otherprono_${prono.id}`}
                />
              </Grid>
            ))}
          </>
        }
      />
    </>
  );
};

export default SinglePronoTennis;
