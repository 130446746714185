import React from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { Fire } from 'services';
import { Grid, Typography, ListItem, ListItemText } from '@material-ui/core';
import Loading from '../../../../Loading/Loading';
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@material-ui/icons/ArrowDropUpRounded';
import '../../../../../theme/datasportTheme.css';
import medal1 from 'images/medal1.png';
import medal2 from 'images/medal2.png';
import medal3 from 'images/medal3.png';
import countryCodes from '../../../../../utils/countryCodes.json';
import SinglesImages from './SinglesImages/SinglesImages';
import { useQuery } from 'react-query';
import CardGeneral from '../../../Templates/Cards/CardGeneral';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      margin: theme.spacing(4, 0, 2),
    },
    tabs: {
      backgroundColor: 'transparent',
      borderBottom: 'none',
      [theme.breakpoints.down('xs')]: {
        overflow: 'scroll',
      },
    },
    tab: {
      boxShadow: 'none',
      textTransform: 'none',
      fontWeight: 'bold',
      minWidth: '100px',

      '&>span': {
        '&.MuiTab-wrapper': {
          backgroundColor: '#242f56',
          borderRadius: '50px',
          color: 'white !important',
          height: '100%',
          padding: '.8rem 2.5rem',
          width: 'max-content',
          transition: 'all .2s ease-in-out',
        },
      },
      '&.Mui-selected': {
        '&> span': {
          '&.MuiTab-wrapper': {
            backgroundColor: theme.palette.secondary.main,
            color: `${theme.palette.primary.main} !important`,
          },
        },
      },
      '&:hover> span': {
        '&.MuiTab-wrapper': {
          backgroundColor: theme.palette.secondary.main,
          color: `${theme.palette.primary.main} !important`,
        },
      },
    },
    card: {
      height: 'unset',
      padding: '1rem',
      [theme.breakpoints.down('sm')]: {
        padding: 5,
        width: 'calc(100% + 40px)',
        transform: 'translate(-20px)',
        margin: 0,
      },
      '&> *': {
        textAlign: 'center',
      },

      '& > div > div': {
        [theme.breakpoints.down('sm')]: {
          padding: '5px !important',
        },
      },
    },
    singleCard: {
      backgroundColor: 'rgba(38, 52, 112, 1)',
      margin: '.625rem 0',
      padding: '1rem',
      borderRadius: '15px',
      border: 'none',
      boxShadow: 'none',
      minHeight: 'unset',
      minWidth: '100%',
      [theme.breakpoints.down('sm')]: {
        padding: '1rem',
      },
      '&> *': {
        fontWeight: 500,
        textAlign: 'center',
      },
      '&:hover': {
        backgroundColor: '#1d2a64',
      },
    },
    headerItems: {
      backgroundColor: 'transparent',
      [theme.breakpoints.down('sm')]: {
        padding: '10px 2rem !important',
      },
    },
    img: {
      borderRadius: '50px',
      [theme.breakpoints.down('sm')]: {
        width: 20,
        height: 20,
      },
    },
    countryImg: {
      display: 'flex',
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        height: 30,
        width: 30,
      },
    },
    appBar: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      borderBottom: 'solid 2px #dbdfe8',

      '&,& > div, &>div>div': {
        overflow: 'scroll !important',
      },
    },
    button: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '13px',
      borderBottomRightRadius: '18px',

      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
        boxShadow: '0 12px 23px 0 rgb(10 22 62 / 16 %)',
      },
    },
  }),
);

const RankingTennis: React.FC<{ indexTab: number }> = ({ indexTab }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const { data: players, isLoading } = useQuery(
    'tennis:standings',
    () => Fire.cloud('tennis', { method: 'getRankings' }),
    { staleTime: 900000 /* 15 mn */ },
  );

  const positionChange = variation => {
    const bgColor = variation < 0 ? '#ffeff2' : '#e7f8f1';
    const textColor = variation < 0 ? '#e0153f' : '#68daa8';
    const arrow =
      variation < 0 ? (
        <ArrowDropDownRoundedIcon fontSize="large" />
      ) : (
        variation > 0 && <ArrowDropUpRoundedIcon fontSize="large" />
      );
    if (variation < 0) variation = -variation;
    if (variation != 0) {
      return (
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          height="90%"
          width="90%"
          borderRadius="1rem"
          justifyContent="center"
          alignItems="center"
          fontWeight="bold"
        >
          <Typography
            style={{
              color: textColor,
              display: 'flex',
              borderRadius: '1rem',
              padding: mobile ? '0 .2rem 0 .5rem' : '0 .2rem 0 .8rem',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: bgColor,
            }}
          >
            {variation}
            {arrow}
          </Typography>
        </Box>
      );
    } else {
      return;
    }
  };

  const mapGroups =
    players &&
    players !== undefined &&
    players[0]?.length !== 0 &&
    players[indexTab]?.map((player, index) => {
      if (index < 100) {
        return (
          <ListItem
            component={Card}
            className={clsx(classes.singleCard)}
            key={index}
          >
            <Grid
              container
              item
              xs={12}
              alignContent="center"
              justify="center"
              alignItems="center"
            >
              <Grid
                container
                item
                alignContent="center"
                justify="center"
                alignItems="center"
                xs={mobile ? 7 : 3}
              >
                <Grid item xs={1}>
                  {player.rank == 1 ? (
                    <img
                      src={medal1}
                      style={{ width: mobile ? '20px' : '29px' }}
                    ></img>
                  ) : player.rank == 2 ? (
                    <img
                      src={medal2}
                      style={{ width: mobile ? '20px' : '29px' }}
                    ></img>
                  ) : player.rank == 3 ? (
                    <img
                      src={medal3}
                      style={{ width: mobile ? '20px' : '29px' }}
                    ></img>
                  ) : (
                    <ListItemText primary={player.rank} className="bold" />
                  )}
                </Grid>
                <Grid item xs={4}>
                  <SinglesImages
                    gender={indexTab === 0 ? 'men' : 'women'}
                    playerId={player.competitor.id}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ gap: 5, textAlign: mobile ? 'left' : 'center' }}
                    color="white"
                  >
                    <ListItemText
                      primary={
                        player.competitor.name.split(',')[1] +
                        ' ' +
                        player.competitor.name.split(',')[0]
                      }
                      className="bold"
                    />
                  </Box>
                </Grid>
              </Grid>
              {mobile ? (
                <Grid container item xs={5}>
                  <Grid item xs={3}>
                    <ListItemText primary={player.points} />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={6}
                    alignItems="center"
                    justify="center"
                  >
                    <ListItemText primary={player.competitions_played} />
                  </Grid>
                  {/* <Grid container item xs={3} alignItems="center" justify="center">
                      <ListItemText primary={player.infos.matches_played} />
                    </Grid> */}
                  {/* <Grid container item xs={3} alignItems="center" justify="center">
                      <ListItemText primary={player.infos.matches_won} />
                    </Grid> */}
                  <Grid item xs={3}>
                    {positionChange(player.movement)}
                  </Grid>
                </Grid>
              ) : (
                <Grid container item xs={9}>
                  <Grid item xs={1} style={{ placeSelf: 'center' }}>
                    <img
                      width={40}
                      src={`https://cdn.sportmonks.com/images/countries/png/short/${
                        player.competitor.country === 'Neutral'
                          ? 'ru'
                          : countryCodes[player.competitor.country_code] ||
                            player.competitor.country_code
                      }.png`}
                      className={classes.countryImg}
                      alt="Data-Sport country flag"
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <ListItemText
                      primary={player.points}
                      style={{ fontWeight: 'bold' }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Box color="white">
                      <ListItemText primary={player.competitor.country} />
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <ListItemText primary={player.competitions_played} />
                  </Grid>
                  {/* <Grid item xs={2}>
                      <ListItemText primary={player.infos.matches_played} />
                      </Grid>
                      <Grid item xs={2}>
                      <ListItemText primary={player.infos.matches_won} />
                      </Grid>
                      <Grid item xs={2}>
                        <ListItemText primary={player.infos.competitions_played} />
                      </Grid>
                    <Grid item xs={2}>
                      <ListItemText primary={player.infos.competitions_won} />
                    </Grid> */}
                  <Grid item xs={5}></Grid>
                  <Grid item xs={1}>
                    {positionChange(player.movement)}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </ListItem>
        );
      }
    });

  return (
    <Grid container justify="center" alignItems="center">
      <Grid container item xs={10} sm={12} justify="space-around">
        <Box width="100%" position="relative">
          {isLoading ? (
            <Grid container item xs={12}>
              <Loading nobg />
            </Grid>
          ) : (
            <CardGeneral className={classes.card}>
              <ListItem
                component={Card}
                className={clsx(classes.card, classes.headerItems)}
              >
                <Grid
                  container
                  item
                  xs={12}
                  alignContent="center"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={mobile ? 7 : 3}></Grid>
                  {mobile ? (
                    <Grid
                      container
                      item
                      xs={5}
                      direction="row"
                      alignContent="center"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={3}>
                        <ListItemText primary={'Points'} className="bold" />
                      </Grid>
                      <Grid item xs={6}>
                        <ListItemText
                          primary={'Jouées'}
                          className="bold"
                          style={{ textAlign: 'center' }}
                        />
                      </Grid>
                      {/* <Grid item xs={3}>
                            <ListItemText primary={"Gagnés"} className="bold" style={{ textAlign: "center" }} />
                          </Grid> */}
                      <Grid item xs={3}></Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      item
                      xs={9}
                      direction="row"
                      alignContent="center"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={1} />
                      <Grid item xs={1}>
                        <ListItemText primary={t('Points')} className="bold" />
                      </Grid>
                      <Grid item xs={2}>
                        <ListItemText
                          primary={t('Nationalité')}
                          className="bold"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <ListItemText
                          primary={t('Compétitions Jouées')}
                          className="bold"
                        />
                      </Grid>
                      {/*<Grid item xs={2}>*/}
                      {/*  /!* <ListItemText primary={t("Matchs Joués")} className="bold" /> *!/*/}
                      {/*</Grid>*/}
                      <Grid item xs={2}>
                        {/* <ListItemText primary={t("Matchs Gagnés")} className="bold" /> */}
                      </Grid>
                      <Grid item xs={2}>
                        {/* <ListItemText primary={t("Compétitions Gagnées")} className="bold" /> */}
                      </Grid>
                      <Grid item xs={2}></Grid>
                    </Grid>
                  )}
                </Grid>
              </ListItem>
              {mapGroups}
            </CardGeneral>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default RankingTennis;
