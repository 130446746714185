import React from 'react';
import Box from '@material-ui/core/Box';

const NBALogo: React.FC<{ src: string }> = ({ src }) => {
  return (
    <Box
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        borderRadius="50%"
        bgcolor="white"
        display="flex"
        justifyContent="center"
        alignItems="center"
        boxShadow="0 0 33px 0 rgb(255 255 255 / 11%)"
      >
        <img
          alt="Data-Sport NBA Logo"
          src={src}
          height="85%"
          style={{
            width: 30,
            height: 30,
            borderRadius: '50%',
          }}
        />
      </Box>
    </Box>
  );
};

export default NBALogo;
