import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  useTheme,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import '../../../../../theme/datasportTheme.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import ButtonFilled from '../../Buttons/ButtonFilled';
import CardGeneral from '../CardGeneral';
import backgroundTennis from 'images/backgroundTennis.svg';
import backgroundTennis_Dark from 'images/backgroundTennis_Dark.svg';
import BorderLinearProgress from '../../Progress/BorderLinearProgress';
import {
  DoubleCompetitorsEntity,
  Gender,
  PronoTennis,
} from '../../../../../types/pronoTennis';
import moment from 'moment';
import {
  getCardPlayers,
  getDoublesPlayersNames,
} from '../../../SinglePronoTennis/utils';
import DoublesImages from './DoublesImages/DoublesImages';
import SinglesImages from './SinglesImages/SinglesImages';
import { getInfosPronoLibre } from '../../../../../utils/pronoLibreTennis';
import { capitalize } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: '23rem',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        height: '26rem',
      },
    },
    content: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'space-between',
      height: '100%',
      minHeight: '15rem',
    },
    backgroundTennis: {
      background: `url(${backgroundTennis}) no-repeat top right`,
      backgroundColor: '#dadde5',
    },
    backgroundTennis_Dark: {
      background: `url(${backgroundTennis_Dark}) no-repeat top right`,
      backgroundColor: 'rgba(91, 94, 105, .85)',
    },
    greenShadow: {
      boxShadow: '0px 5px 17px 1px #04ff0063 !important',
    },
    orangeShadow: {
      boxShadow: '0px 5px 17px 1px #ffbc006b !important',
    },
    greenText: {
      color: '#10ff00',
    },
    orangeText: {
      color: '#ffb000b5',
    },
  }),
);

export type CardPredictionTennisProps = {
  prono: PronoTennis;
  future?: boolean;
  blue?: boolean;
};

const CardPredictionTennis: React.FC<CardPredictionTennisProps> = ({
  prono,
  future = false,
  blue = false,
}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const language = i18n.language.toUpperCase();
  const isDark = theme.palette.type === 'dark';
  const [mainPronoName, setMainPronoName] = React.useState<string>('');
  const [percentage, setPercentage] = React.useState<number>(0);

  const getMainPronoName = () => {
    const pronoNames = getInfosPronoLibre(prono, t);
    setMainPronoName(pronoNames.pronoName);
    setPercentage(pronoNames.pronoPercentage);
  };

  React.useEffect(() => {
    getMainPronoName();
  }, [prono, language]);

  if (!prono.match?.value?.sport_event?.competitors) return null;
  const matchType = prono.competition.value.type;
  const [team1, team2] =
    matchType === 'singles'
      ? [getCardPlayers(prono.match.value.sport_event.competitors, matchType)]
      : getDoublesPlayersNames(
          prono.match.value.sport_event
            .competitors as DoubleCompetitorsEntity[],
        );
  const gender: Gender = prono.competition.value.gender;

  return (
    <CardGeneral
      className={clsx(
        classes.card,
        !future &&
          !prono.name_inactive &&
          percentage >= 56 &&
          classes.greenShadow,
        !future &&
          !prono.name_inactive &&
          percentage > 0 &&
          percentage < 56 &&
          classes.orangeShadow,
        future &&
          (isDark ? classes.backgroundTennis_Dark : classes.backgroundTennis),
      )}
      cursorPointer={!future}
      shadow={!future}
      blue={blue}
      onClick={() => {
        if (!future) {
          history.push(`/pronos_tennis/${prono.id}`);
        }
      }}
    >
      <CardContent className={classes.content}>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          position="relative"
        >
          {prono.isPronoTest && (
            <Box
              position="absolute"
              top="0"
              right="0"
              fontSize="12px"
              color="#ff3f3f"
              fontWeight={600}
              style={{ transform: 'translate(0, -100%)' }}
            >
              <span>Prono {prono.name_inactive ? 'f' : 'test'}</span>
            </Box>
          )}
          <Box height="10%" width="100%">
            <Box
              width="100%"
              height="100%"
              display="flex"
              flexDirection="row"
              flexWrap="nowrap"
              fontWeight={600}
              justifyContent="space-between"
            >
              <Box color="#838ca6" textAlign="left" fontSize=".7rem">
                <Typography variant={mobile ? 'body1' : 'body2'}>
                  {moment(prono.date?.toDate()).format('DD/MM/YYYY')}
                </Typography>
              </Box>
              <Box color="#838ca6" textAlign="right" fontWeight="600">
                <Typography
                  variant={mobile ? 'body1' : 'body2'}
                  component="span"
                >
                  {/* {!future && percentage >= 50 && (
                    <Box className={classes.greenText} fontWeight={600}>
                      {t('Risque faible')}
                    </Box>
                  )} */}
                  {!future && percentage < 56 && percentage > 0 && (
                    <Box className={classes.orangeText} fontWeight={600}>
                      {t('Risque élevé')}
                    </Box>
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
          {future ? (
            <Box height="20%" width="100%">
              <Box
                height="100%"
                width="100%"
                display="flex"
                flexDirection="column"
              >
                <Box
                  color="#838ca6"
                  textAlign="center"
                  fontWeight="600"
                  width="100%"
                  height="50%"
                >
                  <Typography variant={mobile ? 'body1' : 'body2'}>
                    {prono.round?.[language] ||
                      (typeof prono.round === 'string' ? prono.round : '-')}
                  </Typography>
                </Box>
                <Box
                  color={blue ? '#fff' : theme.palette.text.primary}
                  textAlign="center"
                  width="100%"
                  height="50%"
                >
                  <Typography
                    variant={mobile ? 'h3' : 'h4'}
                    style={mobile ? {} : { fontSize: '1.24rem' }}
                  >
                    {t('Prochaine analyse')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box height="20%" width="100%">
              <Box
                height="100%"
                width="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Box
                  color={blue ? '#fff' : theme.palette.text.primary}
                  textAlign="center"
                  width="100%"
                >
                  <Typography
                    variant={mobile ? 'h3' : 'h4'}
                    style={mobile ? {} : { fontSize: '1.24rem' }}
                  >
                    {capitalize(mainPronoName.trim())}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          <Box height="45%" width="100%">
            <Box width="100%" height="60%" display="flex">
              {matchType === 'singles' ? (
                <SinglesImages
                  future={future}
                  mobile={mobile}
                  gender={gender}
                  playerId={team1[0].id}
                />
              ) : (
                <DoublesImages
                  future={future}
                  mobile={mobile}
                  gender={gender}
                  player1Id={team1[0].id}
                  player2Id={team1[1].id}
                />
              )}
              <Box
                width="33%"
                height="100%"
                color="#838ca6"
                textAlign="center"
                alignItems="center"
                display="flex"
                justifyContent="center"
                fontWeight="600"
                marginTop={!mobile && '10px'}
              >
                <Typography variant={mobile ? 'body1' : 'body2'}>
                  {prono.round?.[language] || prono.round}
                </Typography>
              </Box>
              {matchType === 'singles' ? (
                <SinglesImages
                  future={future}
                  mobile={mobile}
                  gender={gender}
                  playerId={team1[1].id}
                />
              ) : (
                <DoublesImages
                  future={future}
                  mobile={mobile}
                  gender={gender}
                  player1Id={team2[0].id}
                  player2Id={team2[1].id}
                />
              )}
            </Box>
            <Box width="100%" height="40%" display="flex">
              <Box
                width="33%"
                height="100%"
                color={blue ? '#fff' : theme.palette.text.primary}
                display="flex"
                textAlign="center"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                fontSize={15}
              >
                {matchType === 'singles' ? (
                  <div>
                    <Typography
                      variant={mobile ? 'h6' : 'body1'}
                      component={'span'}
                    >
                      <Box fontWeight={600}>{team1?.[0].firstName}</Box>
                    </Typography>
                    <Typography
                      variant={mobile ? 'h6' : 'body1'}
                      component={'span'}
                    >
                      <Box fontWeight={600}>{team1?.[0].lastName}</Box>
                    </Typography>
                  </div>
                ) : (
                  team1.map(player => (
                    <Typography variant="body1" component="span">
                      <Box fontWeight={600}>
                        {`${player.firstName} ${player.lastName.slice(0, 1)}.`}
                      </Box>
                    </Typography>
                  ))
                )}
              </Box>
              <Box width="33%" height="100%" />
              <Box
                width="33%"
                height="100%"
                color={blue ? '#fff' : theme.palette.text.primary}
                display="flex"
                textAlign="center"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                fontSize={15}
              >
                {matchType === 'singles' ? (
                  <div>
                    <Typography
                      variant={mobile ? 'h6' : 'body1'}
                      component={'span'}
                    >
                      <Box fontWeight={600}>{team1?.[1].firstName}</Box>
                    </Typography>
                    <Typography
                      variant={mobile ? 'h6' : 'body1'}
                      component={'span'}
                    >
                      <Box fontWeight={600}>{team1?.[1].lastName}</Box>
                    </Typography>
                  </div>
                ) : (
                  team2.map(player => (
                    <Typography variant="body1" component="span">
                      <Box fontWeight={600}>
                        {`${player.lastName} ${player.firstName.slice(0, 1)}.`}
                      </Box>
                    </Typography>
                  ))
                )}
              </Box>
            </Box>
          </Box>
          <Box
            height="20%"
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {future ? (
              <BorderLinearProgress hide_until={prono.dateHideUntil} />
            ) : (
              <ButtonFilled title="Voir les statistiques" blue={blue} medium />
            )}
          </Box>
        </Box>
      </CardContent>
    </CardGeneral>
  );
};

export default CardPredictionTennis;
